import React, { useEffect, useState } from "react";
import { getFilesWithMetaData } from "../talimUtils";
import DRFileSelection from "./drFileSelection";
import DRFileTabs from "./drFileTabs";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFiles } from "./talimDrSlice";
import DRRequest from "./drRequest";
import { Button } from "@mui/material";
import DRResponseViewer from "./drResponseViewer";

interface DRPageProps {
  bucketName: string;
  pathPrefix: string;
}

const DRPage: React.FC<DRPageProps> = ({ bucketName, pathPrefix }) => {
  const talim_dr = useAppSelector((state) => state.talim.talimDrSlice);
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadFiles = async () => {
      const fileWithJsonData = await getFilesWithMetaData(
        bucketName,
        pathPrefix
      );
      dispatch(setFiles({ files: fileWithJsonData }));
    };
    loadFiles();
  }, [pathPrefix]);

  const [viewJobs, setViewJobs] = React.useState(false);

  const handleClick = () => {
    setViewJobs(!viewJobs);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px",
          textAlign: "center",
        }}
      >
        <Button variant="outlined" onClick={handleClick}>
          {viewJobs ? "Request a New Job" : "View Completed Jobs"}
        </Button>
      </div>
      <div key={"main"} hidden={viewJobs}>
        <DRFileSelection bucketName={bucketName} pathPrefix={pathPrefix} />
        <DRFileTabs bucketName={bucketName} />
        <DRRequest bucketName={bucketName} pathPrefix={pathPrefix} />
      </div>
      <div key={"dialog"} hidden={!viewJobs}>
        <DRResponseViewer
          refresh=""
          bucketName={bucketName}
          pathPrefix={pathPrefix}
          serviceName="dr"
        ></DRResponseViewer>
      </div>
    </div>
  );
};

export default DRPage;
