import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { Button } from "@mui/material";
import TSMRequest from "./tsmRequest";

interface TSMPageProps {
  bucketName: string;
  pathPrefix: string;
}

const TSMPage: React.FC<TSMPageProps> = ({ bucketName, pathPrefix }) => {
  const talim_inference = useAppSelector(
    (state) => state.talim.talimInferenceSlice
  );
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   const loadFiles = async () => {
  //     const fileWithJsonData = await getFilesWithMetaData(
  //       bucketName,
  //       pathPrefix
  //     );
  //     dispatch(setFiles({ files: fileWithJsonData }));
  //   };
  //   loadFiles();
  // }, [pathPrefix]);

  const [viewJobs, setViewJobs] = React.useState(false);

  const handleClick = () => {
    setViewJobs(!viewJobs);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px",
          textAlign: "center",
        }}
      >
        <Button variant="outlined" onClick={handleClick}>
          {viewJobs ? "Request a New Job" : "View Completed Jobs"}
        </Button>
      </div>
      <div key={"main"} hidden={viewJobs}>
        <TSMRequest bucketName={bucketName} pathPrefix={pathPrefix} />
      </div>
      {/* <div key={"dialog"} hidden={!viewJobs}>
        <TrainResponseViewer
          refresh=""
          bucketName={bucketName}
          pathPrefix={pathPrefix}
          // serviceName="train"
        ></TrainResponseViewer>
      </div> */}
    </div>
  );
};

export default TSMPage;
