import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../redux/store";
import { S3File } from "../../../../../components/S3/S3Utils";
import { WritableDraft } from "immer";

export interface TableData {
  columns: string[];
  data: any[];
}

export interface FileInfo {
  fileKey: string;
  metaData: any;
  tableData: TableData;
  columnActions: any;
  lowLevelDetectionTypes: any;
  lowLevelDetectionValues: any;
  highLevelDetectionTypes: any;
  highLevelDetectionValues: any;
}

export interface talimCleaningState {
  files: S3File[];
  filesInfo: { [key: string]: FileInfo };
  selectedReferenceFile: string;
  knn_clusters: number;
  imputation_input_features: string[] | null;
  strings_to_remove: string[];
  register_to_litholens: boolean;
  register_to_logsapi: boolean;
  descriptive_log_name: string;
  notification_email: string | null;
  structures_to_process: [] | null;
  drillholes_to_process: [] | null;
  s3_data_save_name: string | null;
  litholens_save_path_type: "s3" | "logsAPI" | null;
}

const initialState: talimCleaningState = {
  files: [],
  filesInfo: {},
  selectedReferenceFile: "",
  imputation_input_features: null,
  knn_clusters: 2,
  register_to_litholens: false,
  register_to_logsapi: false,
  strings_to_remove: ["<LOD", "<x", "<DL"] as string[],
  descriptive_log_name: "new_log",
  notification_email: "",
  structures_to_process: null,
  drillholes_to_process: null,
  s3_data_save_name: null,
  litholens_save_path_type: "s3",
};

type TalimCleaningStateKey = keyof talimCleaningState;

export const talimCleaningSlice = createSlice({
  name: "talim_cleaning",
  initialState: initialState,
  reducers: {
    // -------------------------------------------------------------------------------
    // General setter for updating any state attribute
    setState: <T extends TalimCleaningStateKey>(
      state: WritableDraft<talimCleaningState>,
      action: PayloadAction<{ key: T; value: talimCleaningState[T] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    // -------------------------------------------------------------------------------
    setSelectedReferenceFile: (
      state,
      action: PayloadAction<{ key: string }>
    ) => {
      state.selectedReferenceFile = action.payload.key;
    },
    // -------------------------------------------------------------------------------
    setFiles: (state, action: PayloadAction<{ files: S3File[] }>) => {
      state.files = action.payload.files;
    },
    // -------------------------------------------------------------------------------
    setFilesInfo: (
      state,
      action: PayloadAction<{ key: string; info: FileInfo }>
    ) => {
      state.filesInfo[action.payload.key] = action.payload.info;
    },
    // -------------------------------------------------------------------------------
  },
});

export const selectTalim = (state: RootState) => state.talim;

// Action creators are generated for each case reducer function
export const { setState, setSelectedReferenceFile, setFiles, setFilesInfo } =
  talimCleaningSlice.actions;

export default talimCleaningSlice.reducer;
