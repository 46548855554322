import React, { useEffect, useState } from "react";
import { getFilesWithMetaData } from "../talimUtils";
import EDAFileSelection from "./edaFileSelection";
import EDAFileTabs from "./edaFileTabs";
import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFiles } from "./talimEdaSlice";
import EDARequest from "./edaRequest";
import EDAResponseViewer from "./edaResponseViewer";

interface EDAPageProps {
  bucketName: string;
  pathPrefix: string;
}

const EDAPage: React.FC<EDAPageProps> = ({ bucketName, pathPrefix }) => {
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadFiles = async () => {
      console.log(pathPrefix);
      const fileWithJsonData = await getFilesWithMetaData(
        bucketName,
        pathPrefix
      );
      dispatch(setFiles({ files: fileWithJsonData }));
    };
    loadFiles();
  }, [pathPrefix]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const fileWithJsonData = await getFilesWithMetaData(
        bucketName,
        pathPrefix
      );
      dispatch(setFiles({ files: fileWithJsonData }));
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const [viewJobs, setViewJobs] = React.useState(false);

  const handleClick = () => {
    setViewJobs(!viewJobs);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px",
          textAlign: "center",
        }}
      >
        <Button variant="outlined" onClick={handleClick}>
          {viewJobs ? "Request a New Job" : "View Completed Jobs"}
        </Button>
      </div>
      <div key={"main"} hidden={viewJobs}>
        <EDAFileSelection bucketName={bucketName} pathPrefix={pathPrefix} />
        <EDAFileTabs bucketName={bucketName} />
        <EDARequest bucketName={bucketName} pathPrefix={pathPrefix} />
      </div>
      <div key={"dialog"} hidden={!viewJobs}>
        <EDAResponseViewer
          refresh=""
          bucketName={bucketName}
          pathPrefix={pathPrefix}
          serviceName="eda"
        ></EDAResponseViewer>
      </div>
    </div>
  );
};

export default EDAPage;
