import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/store";
import { S3File } from "../../../../components/S3/S3Utils";
import { WritableDraft } from "immer";

export interface TableData {
  columns: string[];
  data: any[];
}

export interface FileInfo {
  fileKey: string;
  metaData: any;
  tableData: TableData;
}

export interface OperationMetaData {
  operation: string;
  argument: string;
}

export interface smsState {
  files: S3File[];
  filesInfo: { [key: string]: FileInfo };
  selectedAssayFile: string;
  selectedReassayFiles: string[];
  selectedMinZoneFile: string;
  columns_of_interest: string[];
  columns_to_convert: string[];
  target_unit: "pct" | "ppm" | "ppb";
  suffixes_to_remove: string[] | null;
  columns_to_clean: string[];
  missing_values_treatment: "drop" | "fill";
  decimal_digits: number | null;
  attributes: string[] | null;
  minerals: string[] | null;
  mineral_zone: string | null;
  save_name: string | null;
  min_prop_save_name: string | null;
  label_attr_save_name: string | null;
  copper_calcs_save_name: string | null;
  col_CuSH: string | null;
  col_CuCN: string | null;
  col_CuRES: string | null;
  operations: OperationMetaData[];
}

const initialState: smsState = {
  files: [],
  filesInfo: {},
  selectedAssayFile: "",
  selectedReassayFiles: [],
  selectedMinZoneFile: "",
  columns_of_interest: [],
  columns_to_convert: [],
  target_unit: "pct",
  suffixes_to_remove: null,
  columns_to_clean: [],
  missing_values_treatment: "drop",
  decimal_digits: null,
  attributes: null,
  minerals: null,
  mineral_zone: null,
  save_name: null,
  min_prop_save_name: null,
  label_attr_save_name: null,
  copper_calcs_save_name: null,
  col_CuSH: null,
  col_CuCN: null,
  col_CuRES: null,
  operations: [],
};

type SMSStateKey = keyof smsState;

export const smsSlice = createSlice({
  name: "sms",
  initialState: initialState,
  reducers: {
    // -------------------------------------------------------------------------------
    // General setter for updating any state attribute
    setState: <T extends SMSStateKey>(
      state: WritableDraft<smsState>,
      action: PayloadAction<{ key: T; value: smsState[T] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    // -------------------------------------------------------------------------------
    setFiles: (state, action: PayloadAction<{ files: S3File[] }>) => {
      state.files = action.payload.files;
    },
    // -------------------------------------------------------------------------------
    setFilesInfo: (
      state,
      action: PayloadAction<{ key: string; info: FileInfo }>
    ) => {
      state.filesInfo[action.payload.key] = action.payload.info;
    },
    // -------------------------------------------------------------------------------
    setselectedReassayFiles: (
      state,
      action: PayloadAction<{ keys: string[] }>
    ) => {
      state.selectedReassayFiles = action.payload.keys;
    },
    // -------------------------------------------------------------------------------
    setSelectedAssayFile: (state, action: PayloadAction<{ key: string }>) => {
      state.selectedAssayFile = action.payload.key;
    },
    // -------------------------------------------------------------------------------
    setSelectedMinZoneFile: (state, action: PayloadAction<{ key: string }>) => {
      state.selectedMinZoneFile = action.payload.key;
    },
  },
});

export const selectSMS = (state: RootState) => state.sms;

// Action creators are generated for each case reducer function
export const {
  setState,
  setFiles,
  setFilesInfo,
  setSelectedAssayFile,
  setselectedReassayFiles,
  setSelectedMinZoneFile,
} = smsSlice.actions;

export default smsSlice.reducer;
