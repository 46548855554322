import React, { useEffect, useState } from "react";
import { Tab, Tabs, Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getFileInfo } from "../../../../../components/S3/S3Utils";
import CustomTable from "../../../../../components/DataDisplay/DisplayTabularData";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";

interface FileTabProps {
  bucketName: string;
}

const EDAFileTabs: React.FC<FileTabProps> = ({ bucketName }) => {
  const talim_eda = useAppSelector((state) => state.talim.talimEdaSlice);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const allFileKeys = [talim_eda.selectedReferenceFile];

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      {/* <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="file tabs"
        sx={{
          backgroundColor: "white", // Light teal background for the whole tabs bar
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Adding a subtle shadow under the tabs bar
          "& .MuiTabs-flexContainer": {
            gap: "10px", // Adds space between each tab/button
          },
        }}
      >
        {allFileKeys.map((fileKey, index) => (
          <Tab
            key={fileKey}
            label={
              index === 0
                ? `Reference:  ${getFileInfo(fileKey).baseName}`
                : `Support: ${getFileInfo(fileKey).baseName}`
            }
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
          />
        ))}
      </Tabs>
      {allFileKeys.map((fileKey, index) => (
        <TabPanel value={value} index={index} key={fileKey}>
          <Typography component={"span"} variant="h6">
            {getFileInfo(fileKey).baseName}
          </Typography>
          <Typography component={"span"} variant="body1">
            {filesInfo[fileKey] && (
              <CustomTable
                tableHeaders={filesInfo[fileKey].tableData.columns}
                tableData={filesInfo[fileKey].tableData.data}
              />
            )}
          </Typography>
        </TabPanel>
      ))} */}
      {talim_eda.filesInfo[talim_eda.selectedReferenceFile] && (
        <CustomTable
          tableHeaders={
            talim_eda.filesInfo[talim_eda.selectedReferenceFile].tableData
              .columns
          }
          tableData={
            talim_eda.filesInfo[talim_eda.selectedReferenceFile].tableData.data
          }
        />
      )}
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default EDAFileTabs;
