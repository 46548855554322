import React, { useEffect, useState } from "react";
import { Box, Button, Grid, CircularProgress, Typography } from "@mui/material";
import { useForm, Controller, useWatch, FormProvider } from "react-hook-form";
import DrillholesSelector from "../../../projects/drillholes_and_images/drillholesSelector";
import ImageSelector from "../../../projects/drillholes_and_images/imagesSelector";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getFileInfo } from "../../../../components/S3/S3Utils";
import {
  TextInput,
  SelectInput,
  AccordionSection,
  BooleanSwitchInput,
} from "./../formComponents";
import {
  setSelectedDrillholes,
  setSelectedImages,
} from "../../../projects/projectsSlice";
import { createJobItem } from "../../../../components/Jobs/jobUtils";
import {
  filterNullProperties,
  useRunServiceMutation,
} from "../../../api/endpoints/superApi";
import { ENVIRONMENT } from "../../../../config";
import JobsInfo from "../../../../components/Jobs/jobsInfo";

interface FormValues {
  project_name: string;
  step_size?: number;
  data_category?: string;
  hs_wavelength?: string;
  swath?: number;
  custom_drillholes?: boolean;
  custom_images?: boolean;
  drillholes_to_process?: string[];
  images_to_process: string[];
}

const defaultValues: FormValues = {
  project_name: "",
  step_size: 0.2,
  data_category: "core",
  hs_wavelength: "",
  swath: 25,
  custom_drillholes: false,
  custom_images: false,
  drillholes_to_process: [],
  images_to_process: [],
};

const TextureReportPage: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);
  const methods = useForm<FormValues>({ defaultValues });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = methods;
  const service = "texture_report";
  const dispatch = useAppDispatch();
  const dataCategory = useWatch({ control, name: "data_category" });
  const customDrillholes = useWatch({ control, name: "custom_drillholes" });
  const customImages = useWatch({ control, name: "custom_images" });
  const [submittedData, setSubmittedData] = useState<{} | null>(null);

  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  useEffect(() => {
    if (projects.selectedProject) {
      setValue("project_name", projects.selectedProject.id);
    }
    if (projects.selectedDrillholes[service]) {
      const mappedDrillholes = projects.selectedDrillholes[service].map(
        (drillholeItem) => drillholeItem.name
      );
      setValue("drillholes_to_process", mappedDrillholes);
    }
    if (projects.selectedImages[service]) {
      const mappedImages = projects.selectedImages[service].map(
        (imageItem) => imageItem.name
      );
      setValue("images_to_process", mappedImages);
    }
  }, [
    projects.selectedDrillholes[service],
    projects.selectedImages[service],
    setValue,
    projects.selectedProject,
  ]);

  useEffect(() => {
    if (!customDrillholes) {
      dispatch(
        setSelectedDrillholes({
          service: service,
          drillholes: [],
        })
      );
      setValue("custom_images", false);
    }
    if (!customImages) {
      dispatch(
        setSelectedImages({
          service: service,
          images: [],
        })
      );
    }
  }, [setValue, projects.selectedProject, customDrillholes, customImages]);

  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = `logs.${service}`;
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);

  const onSubmit = (data: FormValues) => {
    const formData = {
      project_name: data.project_name,
      step_size: data.step_size,
      data_category: data.data_category,
      hs_wavelength: data.hs_wavelength ? data.hs_wavelength : null,
      swath: data.swath,
      drillholes_to_process:
        data.drillholes_to_process && data.drillholes_to_process?.length > 0
          ? data.drillholes_to_process
          : null,
      images_to_process:
        data.images_to_process && data.images_to_process?.length > 0
          ? data.images_to_process
          : null,
    };
    setSubmittedData(formData);
    const request_body = filterNullProperties(formData);
    runServiceMutation({
      service: "ll_generate_percentage_report",
      request_body: request_body,
      environment: ENVIRONMENT,
      job_item: jobItem,
    });
  };

  return (
    projects.selectedProject && (
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", margin: "20px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                name="step_size"
                control={control}
                label="Step Size (meters)"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                name="data_category"
                control={control}
                label="Data Category"
                options={[
                  { value: "core", label: "core" },
                  { value: "atv", label: "atv" },
                  { value: "otv", label: "otv" },
                  { value: "wet", label: "wet" },
                  { value: "dry", label: "dry" },
                  { value: "hs", label: "hs" },
                ]}
              />
            </Grid>
            {dataCategory === "hs" && (
              <Grid item xs={12}>
                <TextInput
                  name="hs_wavelength"
                  control={control}
                  label="HS Wavelength"
                  type="number"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <AccordionSection title="Advanced Options">
                <Grid item xs={12}>
                  <BooleanSwitchInput
                    name="custom_drillholes"
                    control={control}
                    label="Custom Drillholes"
                    helpText="Limit to specific drillholes"
                  />
                </Grid>
                {customDrillholes && (
                  <Grid item xs={12}>
                    <DrillholesSelector
                      companyName={projects.selectedProject.company}
                      projectName={projects.selectedProject.id}
                      service={service}
                    />
                  </Grid>
                )}
                {customDrillholes && (
                  <Grid item xs={12}>
                    <BooleanSwitchInput
                      name="custom_images"
                      control={control}
                      label="Custom Images"
                      helpText="Limit to specific images of the selected drillholes"
                    />
                  </Grid>
                )}
                {customDrillholes && customImages && (
                  <Grid item xs={12}>
                    <ImageSelector
                      companyName={projects.selectedProject.company}
                      projectName={projects.selectedProject.id}
                      service={service}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextInput
                    name="swath"
                    control={control}
                    label="Swath"
                    type="number"
                    helpText="The number of pixels in the vertical middle to be considered"
                  />
                </Grid>
              </AccordionSection>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                // disabled={!isValid}
              >
                Submit
              </Button>
            </Grid>
            {submittedData && (
              <Grid item xs={12}>
                <Box mt={2}>
                  <Typography variant="h6">Payload:</Typography>
                  <pre>{JSON.stringify(submittedData, null, 2)}</pre>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <JobsInfo jobType={"litholens.texture_report"} />
      </FormProvider>
    )
  );
};

export default TextureReportPage;
