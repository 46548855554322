import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { readJsonFromS3 } from "../../components/S3/S3Utils";

interface ColorSegment {
  veinColor: string;
  fromDepth: number;
  toDepth: number;
}

interface PercentageReportViewerProps {
  company: string;
  projectName: string;
  selectedDrillhole: string;
  meterPixelRatio: number;
  projects_s3_bucket: string;
}

const HEIGHT = 70; // Constant height for all images and gaps

const renderColorSegments = (
  colorSegments: ColorSegment[],
  meterPixelRatio: number
) => {
  if (colorSegments.length === 0) return null;

  const segments: JSX.Element[] = [];
  let currentDepth = colorSegments[0].fromDepth;

  colorSegments.forEach((segment) => {
    if (segment.fromDepth > currentDepth) {
      const gapWidth = (segment.fromDepth - currentDepth) * meterPixelRatio;
      segments.push(
        <div
          key={`gap-${currentDepth}`}
          style={{
            display: "inline-block",
            width: gapWidth,
            height: HEIGHT,
            backgroundColor: "#eee",
          }}
        />
      );
    }

    const segmentWidth =
      (segment.toDepth - segment.fromDepth) * meterPixelRatio;
    segments.push(
      <div
        key={`${segment.fromDepth}-${segment.toDepth}`}
        style={{
          display: "inline-block",
          width: segmentWidth,
          height: HEIGHT,
          backgroundColor: segment.veinColor,
        }}
      />
    );

    currentDepth = segment.toDepth;
  });

  return (
    <div
      style={{ display: "inline-flex", alignItems: "center", height: HEIGHT }}
    >
      {segments}
    </div>
  );
};

const VeinNetReportViewer: React.FC<PercentageReportViewerProps> = ({
  company,
  projectName,
  selectedDrillhole,
  meterPixelRatio,
  projects_s3_bucket,
}) => {
  const [colorSegments, setColorSegments] = useState<ColorSegment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setColorSegments([]);
  }, [selectedDrillhole]);

  useEffect(() => {
    const fetchData = async () => {
      const s3Key = `${company}/${projectName}/!_LITHOLENS-DATA/03_MachineLearning/04_VeiNet/!_EXPORT-API/${selectedDrillhole}.json`;
      console.log(s3Key);
      setLoading(true);
      setError(null);

      try {
        const data = await readJsonFromS3(projects_s3_bucket, s3Key);
        if (data) {
          const filteredItems = data.items.map((item: any) => ({
            veinColor: "blue",
            fromDepth: item.fromDepth,
            toDepth: item.toDepth,
          }));

          const sortedSegments = filteredItems.sort(
            (a: ColorSegment, b: ColorSegment) => a.fromDepth - b.fromDepth
          );

          setColorSegments(sortedSegments);
        }
      } catch (error) {
        setError("Failed to fetch data");
        console.error("Failed to fetch data:", error);
        setColorSegments([]);
      } finally {
        setLoading(false);
      }
    };

    if (selectedDrillhole) {
      fetchData();
    }
  }, [selectedDrillhole, company, projectName, projects_s3_bucket]);

  return (
    <div style={{ position: "relative" }}>
      {loading && <CircularProgress />}
      {error && <p>{error}</p>}
      {renderColorSegments(colorSegments, meterPixelRatio)}
    </div>
  );
};

export default VeinNetReportViewer;
