import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../redux/store";
import config, { ENVIRONMENT } from "../../config";

const baseCognitoUrl = config[ENVIRONMENT].cognitoUrl;
const cognitoClientId = config[ENVIRONMENT].cognitoClientId;

export const cognitoApiSlice = createApi({
  reducerPath: "cognito_api",
  baseQuery: fetchBaseQuery({
    baseUrl: baseCognitoUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/x-amz-json-1.1");
      headers.set(
        "X-Amz-Target",
        "AWSCognitoIdentityProviderService.InitiateAuth"
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({}),
});

// --------------------------------------------------------------------------------------------
export interface LoginUserRequest {
  username: string;
  password: string;
}

export interface LoginUserTokenResponse {
  AuthenticationResult: {
    AccessToken: string;
    ExpiresIn: number;
    IdToken: string;
    RefreshToken: string;
    TokenType: string;
  };
  ChallengeParameters: {};
}

export interface RefreshTokenRequest {
  refreshToken: string;
}

export interface RefreshTokenResponse {
  AuthenticationResult: {
    AccessToken: string;
    ExpiresIn: number;
    IdToken: string;
    RefreshToken: string;
    TokenType: string;
  };
  ChallengeParameters: {};
}

export const extendedCognitoApiSlice = cognitoApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginUserToken: builder.mutation<LoginUserTokenResponse, LoginUserRequest>({
      query: (data) => {
        console.log(data.username);
        return {
          url: "",
          method: "post",
          body: JSON.stringify({
            AuthFlow: "USER_PASSWORD_AUTH",
            AuthParameters: {
              USERNAME: data.username,
              PASSWORD: data.password,
            },
            ClientId: cognitoClientId,
          }),
        };
      },
    }),
    refreshToken: builder.mutation<RefreshTokenResponse, RefreshTokenRequest>({
      query: (data) => {
        return {
          url: "",
          method: "post",
          body: JSON.stringify({
            AuthFlow: "REFRESH_TOKEN_AUTH",
            AuthParameters: {
              REFRESH_TOKEN: data.refreshToken,
            },
            ClientId: cognitoClientId,
          }),
        };
      },
    }),
  }),
});

// --------------------------------------------------------------------------------------------
export const { useLoginUserTokenMutation, useRefreshTokenMutation } =
  extendedCognitoApiSlice;
// --------------------------------------------------------------------------------------------
