import React, {
  useState,
  useRef,
  useEffect,
  MouseEvent as ReactMouseEvent,
  ReactNode,
} from "react";
import { Menu, MenuItem, TextField } from "@mui/material";
import "./PolygonEditor.css";

interface Point {
  x: number;
  y: number;
}

interface Polygon {
  id: number;
  points: Point[];
  className: string;
}

interface PolygonEditorProps {
  component?: ReactNode;
  imageUrl?: string;
}

const PolygonEditor: React.FC<PolygonEditorProps> = ({
  component,
  imageUrl,
}) => {
  const [polygons, setPolygons] = useState<Polygon[]>([]);
  const [selectedPolygonId, setSelectedPolygonId] = useState<number | null>(
    null
  );
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dragIndex, setDragIndex] = useState<number | null>(null);
  const [offset, setOffset] = useState<Point>({ x: 0, y: 0 });
  const [isMovingPolygon, setIsMovingPolygon] = useState<boolean>(false);
  const [isMKeyHeld, setIsMKeyHeld] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [currentPolygonClass, setCurrentPolygonClass] = useState<string>("");
  const containerRef = useRef<HTMLDivElement>(null);

  const handleContainerClick = (e: ReactMouseEvent) => {
    if ((e.ctrlKey || e.metaKey) && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const newPoint = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };

      if (selectedPolygonId === null) {
        const newPolygon: Polygon = {
          id: Date.now(),
          points: [newPoint],
          className: "",
        };
        setPolygons([...polygons, newPolygon]);
        setSelectedPolygonId(newPolygon.id);
      } else {
        const polygon = polygons.find((p) => p.id === selectedPolygonId);
        if (polygon) {
          if (polygon.points.length < 2) {
            polygon.points.push(newPoint);
          } else {
            let closestIndex = 0;
            let minDist = Infinity;

            for (let i = 0; i < polygon.points.length; i++) {
              const nextIndex = (i + 1) % polygon.points.length;
              const p1 = polygon.points[i];
              const p2 = polygon.points[nextIndex];
              const midPoint = {
                x: (p1.x + p2.x) / 2,
                y: (p1.y + p2.y) / 2,
              };
              const dist = Math.hypot(
                newPoint.x - midPoint.x,
                newPoint.y - midPoint.y
              );

              if (dist < minDist) {
                minDist = dist;
                closestIndex = nextIndex;
              }
            }

            polygon.points.splice(closestIndex, 0, newPoint);
          }
          setPolygons([...polygons]);
        }
      }
    } else if (!isMKeyHeld) {
      setSelectedPolygonId(null);
    }
  };

  const handlePointMouseDown = (
    polygonId: number,
    index: number,
    e: ReactMouseEvent
  ) => {
    e.stopPropagation(); // Prevent starting polygon drag
    setSelectedPolygonId(polygonId);
    setDragIndex(index);
    setIsDragging(true);
    setOffset({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isMovingPolygon && selectedPolygonId !== null) {
      const polygon = polygons.find((p) => p.id === selectedPolygonId);
      if (polygon) {
        const dx = e.clientX - offset.x;
        const dy = e.clientY - offset.y;
        polygon.points = polygon.points.map((point) => ({
          x: point.x + dx,
          y: point.y + dy,
        }));
        setPolygons([...polygons]);
        setOffset({ x: e.clientX, y: e.clientY });
      }
    } else if (isDragging && dragIndex !== null && selectedPolygonId !== null) {
      const polygon = polygons.find((p) => p.id === selectedPolygonId);
      if (polygon) {
        polygon.points[dragIndex] = {
          x: polygon.points[dragIndex].x + e.clientX - offset.x,
          y: polygon.points[dragIndex].y + e.clientY - offset.y,
        };
        setPolygons([...polygons]);
        setOffset({ x: e.clientX, y: e.clientY });
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragIndex(null);
    setIsMovingPolygon(false);
  };

  const handleRemovePoint = (polygonId: number, index: number) => {
    const polygon = polygons.find((p) => p.id === polygonId);
    if (polygon) {
      polygon.points = polygon.points.filter((_, i) => i !== index);
      setPolygons([...polygons]);
    }
  };

  const handlePolygonMouseDown = (polygonId: number, e: ReactMouseEvent) => {
    if (isMKeyHeld) {
      setIsMovingPolygon(true);
      setOffset({ x: e.clientX, y: e.clientY });
    }
    setSelectedPolygonId(polygonId);
  };

  const handlePolygonContextMenu = (polygonId: number, e: ReactMouseEvent) => {
    e.preventDefault();
    setSelectedPolygonId(polygonId);
    setMenuAnchor(e.currentTarget as HTMLElement);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleRemovePolygon = () => {
    setPolygons(polygons.filter((p) => p.id !== selectedPolygonId));
    setSelectedPolygonId(null);
    handleCloseMenu();
  };

  const handleAssignClass = () => {
    const polygon = polygons.find((p) => p.id === selectedPolygonId);
    if (polygon) {
      polygon.className = currentPolygonClass;
      setPolygons([...polygons]);
    }
    handleCloseMenu();
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "m" || e.key === "M") {
      setIsMKeyHeld(true);
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === "m" || e.key === "M") {
      setIsMKeyHeld(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [isDragging, dragIndex, offset, polygons, isMovingPolygon, isMKeyHeld]);

  const logPath = () => {
    console.log(polygons);
  };

  return (
    <div className="polygon-editor">
      <div
        ref={containerRef}
        className="editable-container"
        onClick={handleContainerClick}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="Editable" className="editable-image" />
        ) : (
          component
        )}
      </div>
      <svg className="polygon-overlay">
        {polygons.map((polygon) => (
          <g
            key={polygon.id}
            onMouseDown={(e) => handlePolygonMouseDown(polygon.id, e)}
            onContextMenu={(e) => handlePolygonContextMenu(polygon.id, e)}
            className={
              polygon.id === selectedPolygonId ? "selected-polygon" : ""
            }
          >
            <polygon
              points={polygon.points
                .map((point) => `${point.x},${point.y}`)
                .join(" ")}
              className={polygon.className}
            />
            {polygon.points.map((point, index) => (
              <circle
                key={index}
                cx={point.x}
                cy={point.y}
                r={5}
                className="polygon-point"
                onMouseDown={(e) => handlePointMouseDown(polygon.id, index, e)}
                onDoubleClick={() => handleRemovePoint(polygon.id, index)}
              />
            ))}
          </g>
        ))}
      </svg>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleRemovePolygon}>Remove Polygon</MenuItem>
        <MenuItem>
          <TextField
            label="Class Name"
            value={currentPolygonClass}
            onChange={(e) => setCurrentPolygonClass(e.target.value)}
          />
          <button onClick={handleAssignClass}>Assign</button>
        </MenuItem>
      </Menu>
      <button onClick={logPath}>Log Path</button>
    </div>
  );
};

export default PolygonEditor;
