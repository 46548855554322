import config from "../../../config";
import { superApiSlice } from "../superApiSlice";

export interface GetServicesResponse {
  services: string[];
}

export interface GetServiceResponse {
  description: string;
  properties: {};
  required: string[];
}
export interface GetServiceRequest {
  service: string;
}

export interface RunServiceResponse {}

export interface RunServiceRequest {
  service: string;
  request_body: {};
  environment?: string | null; // optional (DataBase environment)
  region_name?: string | null; // optional (EC2: region)
  instance_type?: string | null; // optional (EC2: instance type)
  spot?: boolean | null; // optional (EC2: whether spot or on-demand)
  terminate?: boolean | null; // optional (EC2: whether to terminater at finish)
  num_instances?: number | null; // optional (EC2: number of instances to launch)
  has_large_payload?: boolean;
  job_item?: {} | null;
}

export function filterNullProperties<T extends Record<string, any>>(
  obj: T
): Partial<T> {
  return Object.entries(obj)
    .filter(([_, value]) => value !== null && value !== undefined)
    .reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {} as Partial<T>
    );
}

export const extendedApiSlice = superApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // -------------------------------------------------------------------------------------------
    getServices: builder.query<GetServicesResponse, void>({
      query: () => {
        return {
          url: "/services",
          method: "get",
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
    getService: builder.query<GetServiceResponse, GetServiceRequest>({
      query: (data) => {
        return {
          url: `/services/${data.service}`,
          method: "get",
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
    runService: builder.mutation<RunServiceResponse, RunServiceRequest>({
      query: (data) => {
        const body: any = {
          service: data.service,
          request_body: data.request_body,
        };

        if (data.environment !== null && data.environment !== undefined) {
          body.environment = data.environment;
        }
        if (data.region_name !== null && data.region_name !== undefined) {
          body.region_name = data.region_name;
        }
        if (data.instance_type !== null && data.instance_type !== undefined) {
          body.instance_type = data.instance_type;
        }
        if (data.spot !== null && data.spot !== undefined) {
          body.spot = data.spot;
        }
        if (data.terminate !== null && data.terminate !== undefined) {
          body.terminate = data.terminate;
        }
        if (data.num_instances !== null && data.num_instances !== undefined) {
          body.num_instances = data.num_instances;
        }
        if (data.job_item !== null && data.job_item !== undefined) {
          body.job_item = data.job_item;
        }
        body.has_large_payload = true;

        return {
          url: "/run",
          method: "post",
          body: body,
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
  }),
});

export const {
  useGetServicesQuery,
  useLazyGetServicesQuery,
  useGetServiceQuery,
  useLazyGetServiceQuery,
  useRunServiceMutation,
} = extendedApiSlice;
