import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EraserIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path d="M3 21h18v-2H3v2zm12.66-3H21v-2.34l-7.32-7.32-4.95 4.95 7.32 7.32.61.61zM12.27 9.59L9.5 12.36l7.32 7.32 2.77-2.77-7.32-7.32zm7.67-2.66c.78.78.78 2.05 0 2.83l-2.34 2.34-2.83-2.83 2.34-2.34c.78-.78 2.05-.78 2.83 0z" />
    </SvgIcon>
);

export default EraserIcon;
