import React, { useEffect, useState } from "react";
import { Box, Button, Grid, CircularProgress, Typography } from "@mui/material";
import { useForm, Controller, useWatch, FormProvider } from "react-hook-form";
import DrillholesSelector from "../../../projects/drillholes_and_images/drillholesSelector";
import ImageSelector from "../../../projects/drillholes_and_images/imagesSelector";
import { useAppSelector } from "../../../../redux/hooks";
import { getFileInfo } from "../../../../components/S3/S3Utils";
import { TextInput, SelectInput, AccordionSection } from "../formComponents";
import {
  filterNullProperties,
  useRunServiceMutation,
} from "../../../api/endpoints/superApi";
import { createJobItem } from "../../../../components/Jobs/jobUtils";
import { ENVIRONMENT } from "../../../../config";

interface FormValues {
  project_name: string;
  generic_model_name: string;
  train_model_name: string;
  step_size?: number;
  threshold?: number;
  data_category?: string;
  hs_wavelength?: string;
  swath?: number;
  drillholes_to_process?: string[];
  images_to_process: string[];
}

const defaultValues: FormValues = {
  project_name: "",
  generic_model_name: "GaussianMixture",
  train_model_name: "",
  step_size: 0.2,
  threshold: 0.7,
  data_category: "core",
  hs_wavelength: "",
  swath: 25,
  drillholes_to_process: [],
  images_to_process: [],
};

const RoqenetReportPage: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);
  const methods = useForm<FormValues>({ defaultValues });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = methods;
  const service = "roqenet_report";
  const dataCategory = useWatch({ control, name: "data_category" });
  const [submittedData, setSubmittedData] = useState<{} | null>(null);

  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  useEffect(() => {
    if (projects.selectedProject) {
      setValue("project_name", projects.selectedProject.id);
    }
    if (projects.selectedDrillholes[service]) {
      const mappedDrillholes = projects.selectedDrillholes[service].map(
        (drillholeItem) => drillholeItem.name
      );
      setValue("drillholes_to_process", mappedDrillholes);
    }
    if (projects.selectedImages[service]) {
      const mappedImages = projects.selectedImages[service].map(
        (imageItem) => imageItem.name
      );
      setValue("images_to_process", mappedImages);
    }
    // if (modelsList) {
    //   const firstModel = modelsList.items[0]?.path
    //     ? getFileInfo(modelsList.items[0].path).baseName
    //     : "";
    //   // setValue("train_model_name", firstModel);
    // }
  }, [
    projects.selectedDrillholes[service],
    projects.selectedImages[service],
    setValue,
    projects.selectedProject,
  ]);

  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = "logs.roqenet";
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);

  const onSubmit = (data: FormValues) => {
    const formData = {
      project_name: data.project_name,
      data_category: data.data_category,
      hs_wavelength: data.hs_wavelength ? data.hs_wavelength : null,
      drillholes_to_process:
        data.drillholes_to_process && data.drillholes_to_process?.length > 0
          ? data.drillholes_to_process
          : null,
      images_to_process:
        data.images_to_process && data.images_to_process?.length > 0
          ? data.images_to_process
          : null,
    };
    setSubmittedData(formData);
    const request_body = filterNullProperties(formData);
    runServiceMutation({
      service: "ll_run_roqenet",
      request_body: request_body,
      environment: ENVIRONMENT,
      job_item: jobItem,
    });
  };

  return (
    projects.selectedProject && (
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", margin: "20px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AccordionSection title="Advanced Options">
                <Grid item xs={12}>
                  <DrillholesSelector
                    companyName={projects.selectedProject.company}
                    projectName={projects.selectedProject.id}
                    service={service}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ImageSelector
                    companyName={projects.selectedProject.company}
                    projectName={projects.selectedProject.id}
                    service={service}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectInput
                    name="data_category"
                    control={control}
                    label="Data Category"
                    options={[
                      { value: "core", label: "core" },
                      { value: "atv", label: "atv" },
                      { value: "otv", label: "otv" },
                      { value: "wet", label: "wet" },
                      { value: "dry", label: "dry" },
                      { value: "hs", label: "hs" },
                    ]}
                  />
                </Grid>
                {dataCategory === "hs" && (
                  <Grid item xs={12}>
                    <TextInput
                      name="hs_wavelength"
                      control={control}
                      label="HS Wavelength"
                      type="number"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextInput
                    name="swath"
                    control={control}
                    label="Swath"
                    type="number"
                  />
                </Grid>
              </AccordionSection>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                // disabled={!isValid}
              >
                Submit
              </Button>
            </Grid>
            {submittedData && (
              <Grid item xs={12}>
                <Box mt={2}>
                  <Typography variant="h6">Payload:</Typography>
                  <pre>{JSON.stringify(submittedData, null, 2)}</pre>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </FormProvider>
    )
  );
};

export default RoqenetReportPage;
