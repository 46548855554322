import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { RJSFSchema } from "@rjsf/utils";
import {
  useGetServicesQuery,
  useGetServiceQuery,
  useRunServiceMutation,
  RunServiceRequest,
} from "./../api/endpoints/superApi";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import Papa from "papaparse";

const DashboardPage: React.FC = () => {
  //   const workingLms = useAppSelector((state) => state.lms.workingLms);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    data: servicesData,
    error: servicesError,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetServicesQuery();

  const [selectedService, setSelectedService] = useState("");
  const [schema, setSchema] = useState<RJSFSchema | null>(null);

  const [formData, setFormData] = React.useState(null);

  const {
    data: selectedServiceData,
    error: selectedServiceError,
    isLoading: selectedServiceLoading,
  } = useGetServiceQuery(
    { service: selectedService },
    {
      skip: !selectedService, // Skip the query if no service is selected
    }
  );

  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  useEffect(() => {
    if (selectedServiceData) {
      const newSchema: RJSFSchema = {
        title: selectedServiceData.description,
        type: "object",
        properties: selectedServiceData.properties,
        required: selectedServiceData.required,
      };
      setSchema(newSchema);
    }
  }, [selectedServiceData]);

  const handleChange = (event: any) => {
    setSelectedService(event.target.value);
  };

  const handleSubmit = async (requestData: RunServiceRequest) => {
    runServiceMutation(requestData);
  };

  // const schema: RJSFSchema = {
  //   title: "Todo",
  //   type: "object",
  //   required: ["title"],
  //   properties: {
  //     title: { type: "string", title: "Title", default: "A new task" },
  //     done: { type: "boolean", title: "Done?", default: false },
  //   },
  // };

  const log = (type: any) => console.log.bind(console, type);

  return (
    <div>
      <div className="grey-title-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to="/"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <div>Dashboard</div>
        </Breadcrumbs>
      </div>
      <p className="grey-title-container">Select Your Desired Service</p>

      <div
        style={{
          marginTop: 15,
        }}
      >
        {isLoading && <p>Loading...</p>}
        {servicesError && <p>Error</p>}
        {isSuccess && (
          <div>
            <FormControl fullWidth>
              <InputLabel>Select a Service</InputLabel>
              <Select
                label="Select a Service"
                value={selectedService}
                onChange={handleChange}
              >
                {servicesData &&
                  servicesData.services.map((service) => (
                    <MenuItem key={service} value={service}>
                      {service}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        )}
        {selectedServiceLoading && <p>Loading selected service...</p>}
        {selectedServiceError && (
          <p>Error fetching selected service: {"An error occurred"}</p>
        )}

        {
          // selectedServiceData && (
          //   <div>
          //     {/* Render selected service data here */}
          //     <p>Selected Service Data: {JSON.stringify(selectedServiceData)}</p>
          //   </div>
          // )
        }
      </div>

      <p className="grey-title-container">Send Request</p>

      {schema && (
        <Form
          schema={schema}
          validator={validator}
          formData={formData}
          onChange={(e) => setFormData(e.formData)}
          onSubmit={(e) =>
            handleSubmit({
              service: selectedService,
              request_body: e.formData,
              environment: "test",
              region_name: "us-east-1",
              instance_type: "m5a.large",
              spot: false,
              job_item: {},
              terminate: true,
              num_instances: 1,
            })
          }
          onError={log("errors")}
        />
      )}

      {formData && (
        <div>
          <pre>{JSON.stringify(formData, null, 2)}</pre>
        </div>
      )}

      <div>
        {runServiceMutationResult.isLoading && <p>Sending Request ...</p>}
        {runServiceMutationResult.data ? (
          <div>
            {/* Render the data here */}
            <p>Data: {JSON.stringify(runServiceMutationResult.data)}</p>
          </div>
        ) : (
          <p>No Response Available</p>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
