import config from "../../../config";
import { kuberApiSlice } from "../kuberApiSlice";

export interface GetServicesResponse {
  services: string[];
}

export interface GetServiceResponse {
  description: string;
  properties: {};
  required: string[];
}
export interface GetServiceRequest {
  service: string;
}

export interface RunServiceResponse {}

export interface RunServiceRequest {
  service: string;
  request_body: {};
  has_large_payload?: boolean;
  job_item?: {} | null;
}

export function filterNullProperties<T extends Record<string, any>>(
  obj: T
): Partial<T> {
  return Object.entries(obj)
    .filter(([_, value]) => value !== null && value !== undefined)
    .reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {} as Partial<T>
    );
}

export const extendedApiSlice = kuberApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // -------------------------------------------------------------------------------------------
    getServices: builder.query<GetServicesResponse, void>({
      query: () => {
        return {
          url: "/services",
          method: "get",
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
    getService: builder.query<GetServiceResponse, GetServiceRequest>({
      query: (data) => {
        return {
          url: `/services/${data.service}`,
          method: "get",
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
    runService: builder.mutation<RunServiceResponse, RunServiceRequest>({
      query: (data) => {
        const body: any = data.request_body;
        if (data.job_item !== null && data.job_item !== undefined) {
          body.job_item = data.job_item;
        }
        console.log(body);
        return {
          url: data.service,
          method: "post",
          body: body,
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
  }),
});

export const {
  useGetServicesQuery,
  useLazyGetServicesQuery,
  useGetServiceQuery,
  useLazyGetServiceQuery,
  useRunServiceMutation,
} = extendedApiSlice;
