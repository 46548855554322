import React, { useEffect, useState, useRef } from "react";
import { CircularProgress } from "@mui/material";
import config, { DRILLHOLES_FOLDER_PATH, ENVIRONMENT } from "../../config";
import {
  fetchFiles,
  getDownloadUrl,
  getFileInfo,
} from "../../components/S3/S3Utils";
import { getImageDimensions, parseImageKey } from "../gallery/imageUtils";
import PolygonEditor from "./PolygonEditor/PolygonEditor";

const HEIGHT = 70; // Constant height for all images and gaps

export interface ImageItem {
  key: string;
  url: string;
  width: number;
  height: number;
  from: number;
  to: number;
}

interface DrillholeViewerProps {
  company: string;
  projectName: string;
  selectedDrillhole: string;
  meterPixelRatio: number;
  projects_s3_bucket: string;
}

const renderDrillhole = (imageItems: ImageItem[], meterPixelRatio: number) => {
  if (imageItems.length === 0) return null;

  const sortedImages = [...imageItems].sort((a, b) => a.from - b.from);
  const segments: JSX.Element[] = [];
  let currentDepth = sortedImages[0].from;

  sortedImages.forEach((image) => {
    if (image.from > currentDepth) {
      const gapWidth = (image.from - currentDepth) * meterPixelRatio;
      segments.push(
        <div
          key={`gap-${currentDepth}`}
          style={{
            display: "inline-block",
            width: gapWidth,
            height: HEIGHT,
            backgroundColor: "#eee",
          }}
        />
      );
    }

    const imageWidth = (image.to - image.from) * meterPixelRatio;
    segments.push(
      <img
        key={image.key}
        src={image.url}
        style={{
          display: "inline-block",
          width: imageWidth,
          height: HEIGHT,
        }}
      />
    );
    currentDepth = image.to;
  });

  return (
    <div
      style={{ display: "inline-flex", alignItems: "center", height: HEIGHT }}
    >
      {segments}
    </div>
  );
};

const DrillholeViewer: React.FC<DrillholeViewerProps> = ({
  company,
  projectName,
  selectedDrillhole,
  meterPixelRatio,
  projects_s3_bucket,
}) => {
  const [images, setImages] = useState<ImageItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const splitFileMap = useRef<Map<string, string>>(new Map());

  const bucketName = projects_s3_bucket;
  const pathPrefix = `${company}/${projectName}/${DRILLHOLES_FOLDER_PATH}`;

  useEffect(() => {
    setImages([]);
  }, [selectedDrillhole]);

  useEffect(() => {
    const fetchImages = async () => {
      if (!selectedDrillhole) return;
      setLoading(true);
      setError(null);
      try {
        const splitFiles = await fetchFiles(
          bucketName,
          `${pathPrefix}/${selectedDrillhole}/thumbnails/split/`
        );

        splitFileMap.current = new Map(
          splitFiles.map((file) => [
            getFileInfo(file.Key).fileName,
            file.Key || "",
          ])
        );

        const imageItems = await Promise.all(
          Array.from(splitFileMap.current.keys()).map(async (fileName) => {
            const splitKey = splitFileMap.current.get(fileName);
            const url = await getDownloadUrl(bucketName, splitKey!);
            const { width, height } = await getImageDimensions(url);
            const { from, to } = parseImageKey(fileName);
            return {
              key: fileName,
              url,
              width,
              height,
              from,
              to,
            };
          })
        );

        setImages(imageItems.filter((item) => item.url !== ""));
      } catch (error) {
        setError("Error fetching images");
        console.error("Error fetching images: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [bucketName, pathPrefix, selectedDrillhole]);

  return (
    <div style={{ position: "relative" }}>
      {loading && <CircularProgress />}
      {error && <p>{error}</p>}
      {/* <div style={{ position: "relative" }}>
        {renderDrillhole(images, meterPixelRatio)}
      </div> */}
      <PolygonEditor
        component={
          <div style={{ position: "relative" }}>
            {renderDrillhole(images, meterPixelRatio)}
          </div>
        }
      />
    </div>
  );
};

export default DrillholeViewer;
