import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import {
  fetchFiles,
  getDownloadUrl,
  getFileInfo,
  listFolders,
} from "./../../components/S3/S3Utils";
import config, { DRILLHOLES_FOLDER_PATH, ENVIRONMENT } from "../../config";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import DrillholeSelector from "./DrillholeSelector";
import ImageGrid from "./ImageGrid";
import LightboxToolbar from "./LightboxToolbar";

interface ImageGalleryProps {
  company: string;
  projectName: string;
}

interface ImageItem {
  key: string;
  url: string;
  isCleaned: boolean;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({
  company,
  projectName,
}) => {
  const projects_s3_bucket = config[ENVIRONMENT].projects_s3_bucket;
  const [drillholes, setDrillholes] = useState<string[]>([]);
  const [selectedDrillhole, setSelectedDrillhole] = useState<string>("");
  const [images, setImages] = useState<ImageItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [openLightbox, setOpenLightbox] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<string>("");
  const [currentImageKey, setCurrentImageKey] = useState<string>("");
  const [imageType, setImageType] = useState<string>("Original");

  const bucketName = projects_s3_bucket;
  const pathPrefix = `${company}/${projectName}/${DRILLHOLES_FOLDER_PATH}`;

  const originalFileMap = useRef<Map<string, string>>(new Map());
  const thumbnailFileMap = useRef<Map<string, string>>(new Map());
  const splitFileMap = useRef<Map<string, string>>(new Map());
  const filteredFileMap = useRef<Map<string, string>>(new Map());
  const masksFileMap = useRef<Map<string, string>>(new Map());
  const splitMasksFileMap = useRef<Map<string, string>>(new Map());

  useEffect(() => {
    const fetchDrillholes = async () => {
      setLoading(true);
      setError(null);
      try {
        const folders = await listFolders(bucketName, pathPrefix);
        const drillholeNames = folders.map((folder) => {
          const parts = folder.split("/");
          return parts[parts.length - 2]; // Get the folder name
        });
        setDrillholes(drillholeNames);
        if (drillholeNames.length > 0) {
          setSelectedDrillhole(drillholeNames[0]);
        }
      } catch (error) {
        setError("Error fetching drillholes");
        console.error("Error fetching drillholes: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDrillholes();
  }, [bucketName, pathPrefix]);

  useEffect(() => {
    const fetchImages = async () => {
      if (!selectedDrillhole) return;
      setLoading(true);
      setError(null);
      try {
        const originalFiles = await fetchFiles(
          bucketName,
          `${pathPrefix}/${selectedDrillhole}/Original/`
        );

        originalFileMap.current = new Map(
          originalFiles.map((file) => [
            getFileInfo(file.Key).fileName,
            file.Key || "",
          ])
        );

        const thumbnailFiles = await fetchFiles(
          bucketName,
          `${pathPrefix}/${selectedDrillhole}/thumbnails/Original/`
        );

        thumbnailFileMap.current = new Map(
          thumbnailFiles.map((file) => [
            getFileInfo(file.Key).fileName,
            file.Key || "",
          ])
        );

        const splitFiles = await fetchFiles(
          bucketName,
          `${pathPrefix}/${selectedDrillhole}/split/`
        );

        splitFileMap.current = new Map(
          splitFiles.map((file) => [
            getFileInfo(file.Key).fileName,
            file.Key || "",
          ])
        );

        const filteredFiles = await fetchFiles(
          bucketName,
          `${pathPrefix}/${selectedDrillhole}/filtered/`
        );

        filteredFileMap.current = new Map(
          filteredFiles.map((file) => [
            getFileInfo(file.Key).fileName,
            file.Key || "",
          ])
        );

        const masksFiles = await fetchFiles(
          bucketName,
          `${pathPrefix}/${selectedDrillhole}/masks/`
        );

        masksFileMap.current = new Map(
          masksFiles.map((file) => [
            getFileInfo(file.Key).fileName,
            file.Key || "",
          ])
        );

        const splitMasksFiles = await fetchFiles(
          bucketName,
          `${pathPrefix}/${selectedDrillhole}/split_masks/`
        );

        splitMasksFileMap.current = new Map(
          splitMasksFiles.map((file) => [
            getFileInfo(file.Key).fileName,
            file.Key || "",
          ])
        );

        const imageItems = await Promise.all(
          Array.from(originalFileMap.current.keys()).map(async (fileName) => {
            const originalKey = originalFileMap.current.get(fileName);
            const thumbnailKey = thumbnailFileMap.current.get(fileName);
            const url = thumbnailKey
              ? await getDownloadUrl(bucketName, thumbnailKey)
              : originalKey
              ? await getDownloadUrl(bucketName, originalKey)
              : "";
            const isCleaned = splitFileMap.current.has(fileName);
            return {
              key: fileName,
              url,
              isCleaned,
            };
          })
        );

        setImages(imageItems.filter((item) => item.url !== ""));
      } catch (error) {
        setError("Error fetching images");
        console.error("Error fetching images: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [bucketName, pathPrefix, selectedDrillhole]);

  const handleOpenLightbox = async (imageKey: string) => {
    setCurrentImageKey(imageKey);
    try {
      const url = await getDownloadUrl(
        bucketName,
        originalFileMap.current.get(imageKey)!
      );
      setCurrentImage(url);
      setOpenLightbox(true);
    } catch (error) {
      // setError("Error loading image");
      console.error("Error loading image: ", error);
    }
  };

  const handleCloseLightbox = () => {
    setOpenLightbox(false);
  };

  const handleLoadImage = async (type: string) => {
    setImageType(type);

    try {
      const fileName = currentImageKey;
      let fileKey = "";
      switch (type) {
        case "Original":
          fileKey = originalFileMap.current.get(fileName) || "";
          break;
        case "filtered":
          fileKey = filteredFileMap.current.get(fileName) || "";
          break;
        case "split":
          fileKey = splitFileMap.current.get(fileName) || "";
          break;
        case "masks":
          fileKey = masksFileMap.current.get(fileName) || "";
          break;
        case "split_masks":
          fileKey = splitMasksFileMap.current.get(fileName) || "";
          break;
        default:
          break;
      }
      if (fileKey) {
        const url = await getDownloadUrl(bucketName, fileKey);
        setCurrentImage(url);
      } else {
        // setError("Error loading image: Missing file key");
        console.error("Error loading image: Missing file key for", fileName);
      }
    } catch (error) {
      // setError("Error loading image");
      console.error("Error loading image: ", error);
    }
  };

  const fileMaps = {
    original: originalFileMap.current.has(currentImageKey),
    filtered: filteredFileMap.current.has(currentImageKey),
    split: splitFileMap.current.has(currentImageKey),
    masks: masksFileMap.current.has(currentImageKey),
    split_masks: splitMasksFileMap.current.has(currentImageKey),
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <DrillholeSelector
        drillholes={drillholes}
        selectedDrillhole={selectedDrillhole}
        onDrillholeChange={setSelectedDrillhole}
      />

      <ImageGrid
        images={images}
        loading={loading}
        error={error}
        onImageClick={handleOpenLightbox}
      />

      {openLightbox && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={handleCloseLightbox}
          toolbarButtons={[
            <LightboxToolbar
              imageType={imageType}
              onLoadImage={handleLoadImage}
              fileMaps={fileMaps}
            />,
          ]}
        />
      )}
    </Box>
  );
};

export default ImageGallery;
