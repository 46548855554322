import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../redux/store";
import config, { ENVIRONMENT } from "../../config";

const superApiUrl = config[ENVIRONMENT].superApiUrl;

export const superApiSlice = createApi({
  reducerPath: "superapi",
  baseQuery: fetchBaseQuery({
    baseUrl: superApiUrl,
    prepareHeaders: (headers, { getState }) => {
      // const cognitoIdToken = (getState() as RootState).auth.cognitoIdToken;
      // console.log(cognitoIdToken);
      // if (cognitoIdToken) {
      //   headers.set("Authorization", `Bearer ${cognitoIdToken}`);
      // }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints: (builder) => ({}),
});
