import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  Button,
  Alert,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Container,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  TextField,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  JobItem,
  createJobItem,
} from "../../../../../components/Jobs/jobUtils";
import {
  RunServiceRequest,
  useRunServiceMutation,
} from "../../../../api/endpoints/kuberApi";
import { ENVIRONMENT } from "../../../../../config";
import { writeJsonToS3 } from "../../../../../components/S3/S3Utils";
import { OperationMetaData, setState } from "../smsSlice";
import { CheckBox } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import {
  fetchFiles,
  deleteFile,
  getDownloadUrl,
  S3File,
} from "../../../../../components/S3/S3Utils";

// --------------------------------------------------------------------------------------------------
interface ApiFileMetaData {
  path: string;
  data_type: string | null;
  drillhole_column_name: string | null;
  interval_from_column_name: string | null;
  interval_to_column_name: string | null;
  interval_depth_column_name: string | null;
  sample_id_column_name: string | null;
  mineral_zone_column_name: string | null;
}

// --------------------------------------------------------------------------------------------------
interface ApiRequest {
  project_name: string;
  environment: string;
  // assay_info: ApiFileMetaData;
  // reassay_info_list: ApiFileMetaData[];
  minzones_info: ApiFileMetaData;
  columns_of_interest: string[];
  columns_to_convert: string[] | null;
  target_unit: "pct" | "ppm" | "ppb";
  suffixes_to_remove: string[] | null;
  columns_to_clean: string[] | null;
  missing_values_treatment: "drop" | "fill";
  decimal_digits: number | null;
  mineral_zone: string | null;
  operations: OperationMetaData[];
  col_CuSH: string | null;
  col_CuCN: string | null;
  col_CuRES: string | null;
  save_path: string | null;
}

// --------------------------------------------------------------------------------------------------
interface CopperCalculationsRequestProps {
  bucketName: string;
  pathPrefix: string;
}

// --------------------------------------------------------------------------------------------------
const CopperCalculationsRequest: React.FC<CopperCalculationsRequestProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const sms = useAppSelector((state) => state.sms.SMSSlice);
  const dispatch = useAppDispatch();

  // --------------------------------------------------------------------------------------------------
  // Job Item & API Request

  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);

  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = "logs.label";
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);

  const [lastJobID, setLastJobID] = useState<string | null>(null);
  const env = process.env.REACT_APP_ENV || "test";

  // --------------------------------------------------------------------------------------------------
  const [apiRequest, setApiRequest] = useState<ApiRequest>({
    // assay_info: {} as ApiFileMetaData,
    // reassay_info_list: [] as ApiFileMetaData[],
    minzones_info: {} as ApiFileMetaData,
    project_name: projectName,
    environment: env,
    columns_of_interest: sms.columns_of_interest,
    columns_to_convert: sms.columns_to_convert,
    target_unit: sms.target_unit,
    suffixes_to_remove: sms.suffixes_to_remove,
    columns_to_clean: sms.columns_to_clean,
    missing_values_treatment: sms.missing_values_treatment,
    decimal_digits: sms.decimal_digits,
    mineral_zone: sms.mineral_zone,
    operations: sms.operations,
    col_CuSH: sms.col_CuSH,
    col_CuCN: sms.col_CuCN,
    col_CuRES: sms.col_CuRES,
    save_path: sms.copper_calcs_save_name,
  });

  // --------------------------------------------------------------------------------------------------
  // Send API Request
  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const handleSubmit = async (requestData: RunServiceRequest) => {
    const tempRequestData = {
      ...requestData,
      request_body: {
        ...requestData.request_body,
        operations: apiRequest.operations.map((operation, index) => [
          operation.operation,
          operation.argument,
        ]),
      },
    } as RunServiceRequest;
    runServiceMutation(tempRequestData);
    const fixedItem = requestData.job_item as JobItem;
    setLastJobID(fixedItem.id);
    // const pendingMessage = { status: "requested" };
    // writeJsonToS3(
    //   bucketName,
    //   pathPrefix + "/responses/label_attributes/" + jobItem.id + ".json",
    //   pendingMessage
    // );
  };

  const log = (type: any) => console.log.bind(console, type);

  // --------------------------------------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const handleAlertClose = (event: SyntheticEvent) => {
    setOpen(false);
  };

  useEffect(() => {
    var newAssayStructuresInfo = {} as ApiFileMetaData;
    var newMinZoneStructuresInfo = {} as ApiFileMetaData;
    var newReassayStructuresInfo = [] as ApiFileMetaData[];
    Object.values(sms.filesInfo).forEach((element) => {
      if (element.fileKey === sms.selectedAssayFile) {
        newAssayStructuresInfo = {
          path: "s3://" + bucketName + "/" + element.fileKey,
          data_type: element.metaData.selectedType,
          drillhole_column_name: element.metaData.drillhole,
          interval_from_column_name: element.metaData.begin,
          interval_to_column_name: element.metaData.end,
          interval_depth_column_name: element.metaData.pointDepth,
          sample_id_column_name: element.metaData.samplesID,
          mineral_zone_column_name: element.metaData.minZones,
        };
      } else if (element.fileKey === sms.selectedMinZoneFile) {
        newMinZoneStructuresInfo = {
          path: "s3://" + bucketName + "/" + element.fileKey,
          data_type: element.metaData.selectedType,
          drillhole_column_name: element.metaData.drillhole,
          interval_from_column_name: element.metaData.begin,
          interval_to_column_name: element.metaData.end,
          interval_depth_column_name: element.metaData.pointDepth,
          sample_id_column_name: element.metaData.samplesID,
          mineral_zone_column_name: element.metaData.minZones,
        };
      } else if (sms.selectedReassayFiles.includes(element.fileKey)) {
        newReassayStructuresInfo.push({
          path: "s3://" + bucketName + "/" + element.fileKey,
          data_type: element.metaData.selectedType,
          drillhole_column_name: element.metaData.drillhole,
          interval_from_column_name: element.metaData.begin,
          interval_to_column_name: element.metaData.end,
          interval_depth_column_name: element.metaData.pointDepth,
          sample_id_column_name: element.metaData.samplesID,
          mineral_zone_column_name: element.metaData.minZones,
        });
      }
    });
    setApiRequest({
      ...apiRequest,
      // assay_info: newAssayStructuresInfo,
      // reassay_info_list: newReassayStructuresInfo,
      minzones_info: newMinZoneStructuresInfo,
      columns_of_interest: sms.columns_of_interest,
      columns_to_convert: sms.columns_to_convert,
      target_unit: sms.target_unit,
      suffixes_to_remove: sms.suffixes_to_remove,
      columns_to_clean: sms.columns_to_clean,
      operations: sms.operations,
      mineral_zone: sms.mineral_zone,
      col_CuSH: sms.col_CuSH,
      col_CuCN: sms.col_CuCN,
      col_CuRES: sms.col_CuRES,
      save_path: sms.copper_calcs_save_name
        ? "s3://" +
          bucketName +
          "/" +
          pathPrefix +
          "/data/" +
          sms.copper_calcs_save_name +
          ".csv"
        : null,
    });
  }, [sms, projects.selectedProject]);

  useEffect(() => {
    setOpen(true);
  }, [runServiceMutationResult]);

  const operationOptions: {
    acid_soluble_copper: string[];
    cyanide_leachable_copper: string[];
    residual_copper: string[];
    min_calc: string[];
  } = {
    acid_soluble_copper: ["malachite", "azurite", "chrysocolla"],
    cyanide_leachable_copper: [
      "chalcocite",
      "covellite",
      "bornite",
      "chalcopyrite",
    ],
    residual_copper: ["covellite", "bornite", "chalcopyrite"],
    min_calc: [
      "galena",
      "sphalerite",
      "molybdenite",
      "anhydrite",
      "gypsum",
      "pyrite",
      "calcite",
      "jarosite",
    ],
  };

  const [operationStack, setOperationStack] = useState<
    { operation: string; argument: string }[]
  >([]);
  const [selectedOperation, setSelectedOperation] = useState<
    keyof typeof operationOptions | ""
  >("");
  const [selectedArgument, setSelectedArgument] = useState<string>("");

  const handleAddOperation = () => {
    if (selectedOperation && selectedArgument) {
      setOperationStack([
        ...operationStack,
        { operation: selectedOperation, argument: selectedArgument },
      ]);
      setSelectedOperation("");
      setSelectedArgument("");
    }
  };

  const handleRemoveOperation = (index: number) => {
    const updatedStack = [...operationStack];
    updatedStack.splice(index, 1);
    setOperationStack(updatedStack);
  };

  const handleMoveUp = (index: number) => {
    if (index > 0) {
      const updatedStack = [...operationStack];
      const temp = updatedStack[index - 1];
      updatedStack[index - 1] = updatedStack[index];
      updatedStack[index] = temp;
      setOperationStack(updatedStack);
    }
  };

  const handleMoveDown = (index: number) => {
    if (index < operationStack.length - 1) {
      const updatedStack = [...operationStack];
      const temp = updatedStack[index + 1];
      updatedStack[index + 1] = updatedStack[index];
      updatedStack[index] = temp;
      setOperationStack(updatedStack);
    }
  };

  const handleS3SaveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "copper_calcs_save_name",
        value: event.target.value as string,
      })
    );
  };

  const handleColCushChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "col_CuSH",
        value: event.target.value as string,
      })
    );
  };

  const handleColCucnChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "col_CuCN",
        value: event.target.value as string,
      })
    );
  };

  const handleColCuresChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "col_CuRES",
        value: event.target.value as string,
      })
    );
  };

  const handleMineralZoneChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "mineral_zone",
        value: event.target.value as string,
      })
    );
  };

  useEffect(() => {
    dispatch(
      setState({
        key: "operations",
        value: operationStack,
      })
    );
  }, [operationStack]);

  const handleDownload = async (key: string) => {
    try {
      const url = await getDownloadUrl(bucketName, key);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  // --------------------------------------------------------------------------------------------------
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Container>
        <Typography variant="h6" gutterBottom>
          Define Operation Stack
        </Typography>
        <Grid container spacing={2}>
          {/* Select Operation Dropdown */}
          <Grid item xs={6}>
            <Select
              fullWidth
              value={selectedOperation}
              onChange={(e) => {
                setSelectedOperation(
                  e.target.value as keyof typeof operationOptions
                );
                setSelectedArgument(""); // Reset argument when operation changes
              }}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select an Operation
              </MenuItem>
              {Object.keys(operationOptions).map((operation, index) => (
                <MenuItem key={index} value={operation}>
                  {operation}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Select Argument Dropdown */}
          <Grid item xs={6}>
            <Select
              fullWidth
              value={selectedArgument}
              onChange={(e) => setSelectedArgument(e.target.value as string)}
              displayEmpty
              disabled={!selectedOperation} // Disable if no operation selected
            >
              <MenuItem value="" disabled>
                {"Select the target feature."}
              </MenuItem>
              {selectedOperation &&
                operationOptions[selectedOperation].map((mineral, index) => (
                  <MenuItem key={index} value={mineral}>
                    {mineral}
                  </MenuItem>
                ))}
            </Select>
          </Grid>

          {/* Add Operation Button */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddOperation}
              disabled={!selectedOperation || !selectedArgument} // Disable if either field is not selected
              fullWidth
            >
              Add Operation
            </Button>
          </Grid>
        </Grid>

        {/* Display Stack of Operations */}
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ marginTop: "20px" }}
        >
          Stack of Operations
        </Typography>
        <Paper style={{ maxHeight: 500, overflow: "auto" }}>
          <List>
            {operationStack.map((item, index) => (
              <Card key={index} style={{ marginBottom: "10px" }}>
                <CardContent>
                  <ListItem>
                    <ListItemText
                      primary={`Operation: ${item.operation}`}
                      secondary={`Argument: ${item.argument}`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        color="primary"
                        onClick={() => handleMoveUp(index)}
                        disabled={index === 0}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleMoveDown(index)}
                        disabled={index === operationStack.length - 1}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleRemoveOperation(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </CardContent>
              </Card>
            ))}
          </List>
        </Paper>
      </Container>

      <Grid container spacing={2} sx={{ mt: 6 }}>
        <Grid item xs={12} md={4}>
          <Select
            fullWidth
            value={sms.col_CuSH || ""}
            onChange={handleColCushChange}
            displayEmpty
            disabled={
              !sms.operations
                .map((element) => element.operation)
                .includes("acid_soluble_copper")
            } // Disable if no operation selected
          >
            <MenuItem value="" disabled>
              {"Set CuSH Column."}
            </MenuItem>
            {sms.filesInfo[sms.selectedMinZoneFile] &&
              sms.filesInfo[sms.selectedMinZoneFile].tableData.columns
                .filter(
                  (element) =>
                    !Object.keys(
                      sms.filesInfo[sms.selectedMinZoneFile].metaData
                    ).includes(element)
                )
                .map((col, index) => (
                  <MenuItem key={index} value={col}>
                    {col}
                  </MenuItem>
                ))}
          </Select>
        </Grid>

        <Grid item xs={12} md={4}>
          <Select
            fullWidth
            value={sms.col_CuCN || ""}
            onChange={handleColCucnChange}
            displayEmpty
            disabled={
              !sms.operations
                .map((element) => element.operation)
                .includes("cyanide_leachable_copper")
            } // Disable if no operation selected
          >
            <MenuItem value="" disabled>
              {"Set CuCN Column."}
            </MenuItem>
            {sms.filesInfo[sms.selectedMinZoneFile] &&
              sms.filesInfo[sms.selectedMinZoneFile].tableData.columns
                .filter(
                  (element) =>
                    !Object.keys(
                      sms.filesInfo[sms.selectedMinZoneFile].metaData
                    ).includes(element)
                )
                .map((col, index) => (
                  <MenuItem key={index} value={col}>
                    {col}
                  </MenuItem>
                ))}
          </Select>
        </Grid>

        <Grid item xs={12} md={4}>
          <Select
            fullWidth
            value={sms.col_CuRES || ""}
            onChange={handleColCuresChange}
            displayEmpty
            disabled={
              !sms.operations
                .map((element) => element.operation)
                .includes("residual_copper")
            } // Disable if no operation selected
          >
            <MenuItem value="" disabled>
              {"Set CuRES Column."}
            </MenuItem>
            {sms.filesInfo[sms.selectedMinZoneFile] &&
              sms.filesInfo[sms.selectedMinZoneFile].tableData.columns
                .filter(
                  (element) =>
                    !Object.keys(
                      sms.filesInfo[sms.selectedMinZoneFile].metaData
                    ).includes(element)
                )
                .map((col, index) => (
                  <MenuItem key={index} value={col}>
                    {col}
                  </MenuItem>
                ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6}>
          <Select
            fullWidth
            value={sms.mineral_zone || ""}
            onChange={handleMineralZoneChange}
            displayEmpty
            // disabled={
            //   !Object.keys(
            //     sms.filesInfo[sms.selectedMinZoneFile].metaData
            //   ).includes("mineral_zones")
            // } // Disable if no operation selected
          >
            <MenuItem value="" disabled>
              {"Select Mineral Zone."}
            </MenuItem>
            {sms.filesInfo[sms.selectedMinZoneFile] &&
              Object.keys(
                sms.filesInfo[sms.selectedMinZoneFile].metaData
              ).includes("mineral_zones") &&
              Object(
                sms.filesInfo[sms.selectedMinZoneFile].metaData.mineral_zones
              ).map((element: string, index: number) => (
                <MenuItem key={index} value={element}>
                  {element}
                </MenuItem>
              ))}
          </Select>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="save-name"
            label="S3 Save Name"
            value={sms.save_name}
            error={sms.save_name === null || sms.save_name === ""}
            onChange={handleS3SaveNameChange}
          />
        </Grid>
      </Grid>

      <Button
        sx={{ mt: 2 }}
        variant="contained"
        onClick={(e) =>
          handleSubmit({
            service: "sms_mineralogy/copper_estimator",
            request_body: apiRequest,
            job_item: jobItem,
            environment: env,
          } as RunServiceRequest)
        }
        disabled={runServiceMutationResult.isLoading}
        onError={log("errors")}
      >
        Send Request
      </Button>

      <Collapse in={open}>
        {(runServiceMutationResult?.isSuccess ||
          runServiceMutationResult?.isError) && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity={runServiceMutationResult?.isSuccess ? "success" : "error"}
            onClose={handleAlertClose}
          >
            {runServiceMutationResult?.isSuccess
              ? Object(runServiceMutationResult)?.data?.message
              : "Request Sending has been ecnountered an error!"}
          </Alert>
        )}
      </Collapse>
      {runServiceMutationResult?.isSuccess &&
        Object(runServiceMutationResult)?.data?.response &&
        Object(runServiceMutationResult)?.data?.response?.output_path && (
          <Tooltip title="Download">
            <IconButton
              onClick={() =>
                handleDownload(
                  Object(
                    runServiceMutationResult
                  )?.data?.response?.output_path.replace(
                    "s3://" + bucketName + "/",
                    ""
                  )
                )
              }
              disabled={
                Object(runServiceMutationResult)?.data?.response
                  ?.output_path === null ||
                Object(runServiceMutationResult)?.data?.response
                  ?.output_path === ""
              }
              aria-label="download"
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}
    </div>
  );
};

export default CopperCalculationsRequest;
