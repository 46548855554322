import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Chip, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setProjectDrillholes, setSelectedDrillholes } from "../projectsSlice";
import {
  DrillholeItem,
  useGetDrillholesQuery,
} from "../../api/endpoints/projectsApi";

interface DrillholeSelectorProps {
  companyName: string;
  projectName: string;
  service: string;
}

const DrillholesSelector: React.FC<DrillholeSelectorProps> = ({
  companyName,
  projectName,
  service,
}) => {
  const {
    data: drillholesData,
    error: drillholesError,
    isLoading,
    isFetching,
    refetch,
  } = useGetDrillholesQuery(
    { companyName: companyName, projectName: projectName || "" },
    { skip: !projectName }
  );

  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();

  const [selectedDrillholes, setSelectedDrillholesState] = useState<
    DrillholeItem[]
  >([]);

  useEffect(() => {
    refetch();
  }, [projectName]);

  useEffect(() => {
    if (drillholesData && projectName) {
      setSelectedDrillholesState([]);
      dispatch(
        setSelectedDrillholes({
          service: service,
          drillholes: [],
        })
      );
      dispatch(
        setProjectDrillholes({
          project: projectName,
          drillholes: drillholesData.items,
        })
      );
    }
  }, [drillholesData]);

  const handleDrillholeChange = (
    event: React.SyntheticEvent,
    value: DrillholeItem[]
  ) => {
    if (value.some((item) => item.name === "Loading...")) return;
    setSelectedDrillholesState(value);
    dispatch(
      setSelectedDrillholes({
        service: service,
        drillholes: value,
      })
    );
  };

  const loadingOption: DrillholeItem = {
    id: -1, // Use a negative number to represent loading state
    name: "Loading...",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    path: "",
  };

  return (
    <>
      <Autocomplete
        multiple
        id="drillholes-outlined"
        options={
          isLoading || isFetching
            ? [loadingOption]
            : projects.projectsDrillholes[projectName] || []
        }
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        value={selectedDrillholes}
        onChange={handleDrillholeChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Drillholes"
            placeholder="Select Drillholes"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            {option.name === "Loading..." ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              option.name
            )}
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option.name} {...getTagProps({ index })} />
          ))
        }
      />
      {drillholesError && <p>Error: {drillholesError.toString()}</p>}
      {!isLoading &&
        !isFetching &&
        (!drillholesData || drillholesData.items.length === 0) && (
          <p>No drillholes found</p>
        )}
    </>
  );
};

export default DrillholesSelector;
