import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../features/api/apiSlice";
import authSlice from "../features/authentication/authSlice";
import projectsSlice from "../features/projects/projectsSlice";
import { cognitoApiSlice } from "../features/api/cognitoApiSlice";
import { coreApiSlice } from "../features/api/coreApiSlice";
import talimMergeSlice from "../features/services/litholens/talim/merge_components/talimMergeSlice";
import talimCleaningSlice from "../features/services/litholens/talim/cleaning_components/talimCleaningSlice";
import talimDimSlice from "../features/services/litholens/talim/dim_components/talimDimSlice";
import talimFengSlice from "../features/services/litholens/talim/feng_components/talimFengSlice";
import talimEdaSlice from "../features/services/litholens/talim/eda_components/talimEdaSlice";
import talimDrSlice from "../features/services/litholens/talim/dr_components/talimDrSlice";
import talimSlice from "../features/services/litholens/talim/talimSlice";
import talimInferenceSlice from "../features/services/litholens/talim/inference_components/talimInferenceSlice";
import talimTrainSlice from "../features/services/litholens/talim/train_components/talimTrainSlice";
import { superApiSlice } from "../features/api/superApiSlice";
import { kuberApiSlice } from "../features/api/kuberApiSlice";
import SMSSlice, { smsSlice } from "../features/services/litholens/sms/smsSlice";
import XRDSlice from "../features/services/litholens/xrd/xrdSlice";
import tsmSlice from "../features/services/litholens/model_gym/tsm/tsmSlice";
import EnvironmentSlice from "../features/services/litholens/environment_services/EnvironmentSlice";

const TalimReducers = combineReducers({
  talimSlice: talimSlice,
  talimMergeSlice: talimMergeSlice,
  talimEdaSlice: talimEdaSlice,
  talimFengSlice: talimFengSlice,
  talimCleaningSlice: talimCleaningSlice,
  talimDimSlice: talimDimSlice,
  talimDrSlice: talimDrSlice,
  talimInferenceSlice: talimInferenceSlice,
  talimTrainSlice: talimTrainSlice,
});

const SMSReducers = combineReducers({
  SMSSlice: SMSSlice,
});

const XRDReducers = combineReducers({
  XRDSlice: XRDSlice,
});

const EnvironmentReducers = combineReducers({
  EnvironmentSlice: EnvironmentSlice,
});

const TSMReducers = combineReducers({
  TSMSlice: tsmSlice,
});

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [cognitoApiSlice.reducerPath]: cognitoApiSlice.reducer,
    [superApiSlice.reducerPath]: superApiSlice.reducer,
    [kuberApiSlice.reducerPath]: kuberApiSlice.reducer,
    [coreApiSlice.reducerPath]: coreApiSlice.reducer,
    auth: authSlice,
    talim: TalimReducers,
    sms: SMSReducers,
    xrd: XRDReducers,
    environment: EnvironmentReducers,
    tsm: TSMReducers,
    projects: projectsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      apiSlice.middleware,
      cognitoApiSlice.middleware,
      superApiSlice.middleware,
      kuberApiSlice.middleware,
      coreApiSlice.middleware,
    ]),
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
