import React, { ReactNode, cloneElement, Children, useState } from 'react';

interface ActionProviderProps {
    createChatBotMessage: (message: string) => void;
    setState: React.Dispatch<React.SetStateAction<any>>;
    children: ReactNode;
}

interface ChildProps {
    actions?: any; // You can specify a more detailed type if needed
}

const ActionProvider: React.FC<ActionProviderProps> = ({ createChatBotMessage, setState, children }) => {
    // Loading state to manage the loading indicator
    const [isLoading, setIsLoading] = useState(false);

    // Function to call the API and handle the response
    const callApi = async (userPrompt: string) => {
        setIsLoading(true); // Set loading to true before API call
        const loadingMessage = createChatBotMessage("Please be patient while the bot puts your answer together ..."); // Create a loading message
        setState((prev: any) => ({
            ...prev,
            messages: [...prev.messages, loadingMessage], // Add loading message to the state
        }));

        try {
            const response = await fetch("http://ec2-18-188-116-37.us-east-2.compute.amazonaws.com:8000/chat", {
                method: "POST",
                headers: {
                    "X-Password": "12369420",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ user_prompt: userPrompt })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            // Assuming the API returns a response in a field called "message"
            const botMessage = createChatBotMessage(data.assistant_response || 'Sorry, I did not understand that.');

            setState((prev: any) => ({
                ...prev,
                messages: [...prev.messages.slice(0, -1), botMessage], // Replace loading message with the bot message
            }));
        } catch (error) {
            console.error("Error fetching the API:", error);
            const botMessage = createChatBotMessage('An error occurred while fetching the response.');

            setState((prev: any) => ({
                ...prev,
                messages: [...prev.messages.slice(0, -1), botMessage], // Replace loading message with the error message
            }));
        } finally {
            setIsLoading(false); // Set loading to false after API call is complete
        }
    };

    const handleChat = (prompt: string) => {
        callApi(prompt);
    };

    const handleHello = () => {
        const botMessage = createChatBotMessage('Hello. Nice to meet you.');

        setState((prev: any) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };

    const handleMessage = (text: string) => {
        const botMessage = createChatBotMessage(text);

        setState((prev: any) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };

    return (
        <div>
            {Children.map(children, (child) => {
                if (React.isValidElement<ChildProps>(child)) {
                    return cloneElement(child, {
                        actions: { handleHello, handleMessage, handleChat }, // Define the actions object as needed
                    });
                }
                return child;
            })}
        </div>
    );
};

export default ActionProvider;
