import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Slider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setState } from "./talimTrainSlice";
import { Margin } from "@mui/icons-material";

const availableModels = {
  classification: {
    lr: "Logistic Regression",
    knn: "K Nearest Neighbors",
    nb: "Naive Bayes",
    dt: "Decision Tree Classifier",
    svm: "SVM - Linear Kernel",
    rbfsvm: "SVM - Radial Kernel",
    gpc: "Gaussian Process Classifier",
    ridge: "Ridge Classifier",
    rf: "Random Forest Classifier",
    qda: "LQuadratic Discriminant Analysis",
    ada: "Ada Boost Classifier",
    gbc: "Gradient Boosting Classifier",
    lda: "Linear Discriminant Analysis",
    et: "Extra Trees Classifier",
    xgboost: "Extreme Gradient Boosting",
    lightgbm: "Light Gradient Boosting Machine",
    catboost: "CatBoost Classifier",
  },
  regression: {
    lr: "Linear Regression",
    knn: "K Nearest Neighbors",
    en: "Elastic Net",
    dt: "Decision Tree Regressor",
    svm: "Support Vector Regression",
    lar: "Least Angle Regression",
    llar: "Lasso Least Angle Regression",
    omp: "Orthogonal Matching Pursuit",
    br: "Bayesian Ridge",
    ard: "Automatic Relevance Determination",
    par: "Passive Aggressive Regressor",
    ransac: "Random Sample Consensus",
    tr: "TheilSen Regressor",
    huber: "Huber Regressor",
    kr: "Kernel Ridge",
    gpc: "Gaussian Process Classifier",
    ridge: "Ridge Regression",
    rf: "Random Forest Regressor",
    qda: "LQuadratic Discriminant Analysis",
    ada: "Ada Boost Regressor",
    gbr: "Gradient Boosting Regressor",
    lda: "Linear Discriminant Analysis",
    et: "Extra Trees Regressor",
    xgboost: "Extreme Gradient Boosting",
    lightgbm: "Light Gradient Boosting Machine",
    catboost: "CatBoost Regressor",
  },
  clustering: {
    kmeans: "KMeans Clustering",
    ap: "Affinity Propagation",
    meanshift: "Mean shift Clustering",
    sc: "Spectral Clustering",
    hclust: "Agglomerative Clustering",
    dbscan: "Density-Based Spatial Clustering",
    optics: "OPTICS Clustering",
    birch: "Birch Clustering",
    gmm: "Gaussian Mixture Models",
  },
};

const TrainConfigParams: React.FC = () => {
  const talim_train = useAppSelector((state) => state.talim.talimTrainSlice);
  const dispatch = useAppDispatch();

  const maxDimensions =
    talim_train.filesInfo[talim_train.selectedReferenceFile]?.tableData.columns
      .length - 3;
  // --------------------------------------------------------------------------------------------------
  // Shared Parameters

  // const [taskType, setTaskType] = useState("classification");

  const [selectedModels, setSelectedModels] = useState<{
    [key: string]: string;
  }>(availableModels.classification);

  const handleTaskTypeChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "task_type",
        value: event.target.value as string,
      })
    );
    if (event.target.value === "classification") {
      setSelectedModels(availableModels.classification);
    }
    if (event.target.value === "regression") {
      setSelectedModels(availableModels.regression);
    }
    if (event.target.value === "clustering") {
      setSelectedModels(availableModels.clustering);
    }
  };

  useEffect(() => {
    dispatch(
      setState({
        key: "method",
        value: Object.keys(selectedModels)[0],
      })
    );
  }, [selectedModels]);

  const handleModeChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "mode",
        value: event.target.value as string,
      })
    );
  };

  const handleMethodChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "method",
        value: event.target.value as string,
      })
    );
  };

  const handleTargetFeatureChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "target_feature",
        value: event.target.value as string,
      })
    );
  };

  const handlePCAComponentsChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "pca_components",
          value: parseFloat(newValue),
        })
      );
    }
  };

  const handleTrainSizeChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "train_size",
          value: parseFloat(newValue),
        })
      );
    }
  };

  const handleImbalanceFixChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "imbalance_fix",
        value: checked,
      })
    );
  };

  const handleCrossValidationChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "cross_validation",
        value: checked,
      })
    );
  };

  const handleEnsembleMethodChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "ensemble_method",
        value:
          event.target.value !== "none" ? (event.target.value as string) : null,
      })
    );
  };

  const handleNumberOfEnsemblesChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "number_of_ensembles",
          value: parseInt(newValue),
        })
      );
    }
  };

  const handleTuningMetricChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "tuning_metric",
        value:
          event.target.value !== "none" ? (event.target.value as string) : null,
      })
    );
  };

  const handleExplainModelsChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "explain_models",
        value: checked,
      })
    );
  };

  const handleS3SaveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "s3_model_save_name",
        value: event.target.value as string,
      })
    );
  };

  const handleRegisterModelToLithoLensChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "register_model_to_litholens",
        value: checked,
      })
    );
  };

  // const handleRegisterToLogsApiChange = (
  //   event: ChangeEvent<HTMLInputElement>,
  //   checked: boolean
  // ) => {
  //   dispatch(
  //     setState({
  //       key: "register_to_logsapi",
  //       value: checked,
  //     })
  //   );
  // };

  // const handleNotificationEmailChange = (
  //   event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   dispatch(
  //     setState({
  //       key: "notification_email",
  //       value: event.target.value as string,
  //     })
  //   );
  // };

  // const handleS3SaveNameChange = (
  //   event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   dispatch(
  //     setState({
  //       key: "s3_data_save_name",
  //       value: event.target.value as string,
  //     })
  //   );
  // };

  // const handleLogDescriptiveNameChange = (
  //   event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   dispatch(
  //     setState({
  //       key: "descriptive_log_name",
  //       value: event.target.value as string,
  //     })
  //   );
  // };

  const metaData =
    talim_train.filesInfo[talim_train.selectedReferenceFile]?.metaData;

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Target Feature </InputLabel>
            <Select
              value={talim_train.target_feature}
              label="Target Feature"
              onChange={handleTargetFeatureChange}
            >
              {talim_train.filesInfo[
                talim_train.selectedReferenceFile
              ]?.tableData.columns.map(
                (column) =>
                  !Object.values(metaData).includes(column) && (
                    <MenuItem key={column} value={column}>
                      {column}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Task Type
            </InputLabel>
            <Select
              defaultValue={talim_train.task_type}
              onChange={handleTaskTypeChange}
            >
              <MenuItem value={"classification"}>Classification</MenuItem>
              <MenuItem value={"regression"}>Regression</MenuItem>
              <MenuItem value={"clustering"}>Clustering</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Set Train API Parameters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "120ch" } }}
          >
            <Typography>Model Selection</Typography>
            <div style={{ margin: "15px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard">
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Select Training Mode
                    </InputLabel>
                    <Select
                      defaultValue={talim_train.mode}
                      onChange={handleModeChange}
                    >
                      <MenuItem value={"single"}>
                        Train a Specific Model
                      </MenuItem>
                      <MenuItem value={"leaderboard"}>
                        Leaderboard Mode
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Select Model Architecture
                    </InputLabel>
                    <Select
                      value={talim_train.method}
                      onChange={handleMethodChange}
                      disabled={talim_train.mode === "leaderboard"}
                    >
                      {/* {Object.keys(selectedModels).map((key) => ({
                        
                        <MenuItem key={key} value={key}>
                        {selectedModels.[key]}
                        </MenuItem>}))} */}
                      {Object.keys(selectedModels).map((key) => (
                        <MenuItem key={key} value={key}>
                          {selectedModels[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Box>

          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "120ch" } }}
          >
            <Typography>Preprocessing</Typography>
            <div style={{ margin: "15px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth variant="standard">
                    <TextField
                      label="Fraction of Principal Components to Use"
                      variant="outlined"
                      type="number"
                      defaultValue={talim_train.pca_components}
                      onChange={handlePCAComponentsChange}
                      inputProps={{
                        // Optional: Define min and max values
                        min: 0, // Minimum value
                        max: 1, // Maximum value
                        step: 0.01, // Step increment for keyboard arrows
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth variant="standard">
                    <TextField
                      label="Train - Test Split"
                      variant="outlined"
                      type="number"
                      defaultValue={talim_train.train_size}
                      onChange={handleTrainSizeChange}
                      inputProps={{
                        // Optional: Define min and max values
                        min: 0, // Minimum value
                        max: 1, // Maximum value
                        step: 0.01, // Step increment for keyboard arrows
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        value="imbalance_fix"
                        checked={talim_train.imbalance_fix}
                        onChange={handleImbalanceFixChange}
                      />
                    }
                    label="Data Imbalance Fix"
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        value="cross_validation"
                        checked={talim_train.cross_validation}
                        onChange={handleCrossValidationChange}
                      />
                    }
                    label="Cross Validation"
                  />
                </Grid>
              </Grid>
            </div>
          </Box>

          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "120ch" } }}
          >
            <Typography>Advanced Options</Typography>
            <div style={{ margin: "15px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Ensemble Method
                    </InputLabel>
                    <Select
                      defaultValue={talim_train.ensemble_method || "none"}
                      onChange={handleEnsembleMethodChange}
                    >
                      <MenuItem value={"none"}>None</MenuItem>
                      <MenuItem value={"Bagging"}>Bagging</MenuItem>
                      <MenuItem value={"Boosting"}>Boosting</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth variant="standard">
                    <TextField
                      label="Number of Ensemble Models"
                      variant="outlined"
                      type="number"
                      defaultValue={talim_train.ensemble_method}
                      disabled={talim_train.ensemble_method === null}
                      onChange={handleNumberOfEnsemblesChange}
                      inputProps={{
                        // Optional: Define min and max values
                        min: 0, // Minimum value
                        max: 15, // Maximum value
                        step: 1, // Step increment for keyboard arrows
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Fine-Tuning Metric
                    </InputLabel>
                    <Select
                      defaultValue={talim_train.tuning_metric}
                      onChange={handleTuningMetricChange}
                    >
                      <MenuItem value={"none"}>No Fine-Tuning</MenuItem>
                      <MenuItem value={"Accuracy"}>Accuracy</MenuItem>
                      <MenuItem value={"AUC"}>Area Under Curve (AUC)</MenuItem>
                      <MenuItem value={"Recall"}>Recall</MenuItem>
                      <MenuItem value={"Precision"}>Precision</MenuItem>
                      <MenuItem value={"F1"}>F-1 Score</MenuItem>
                      <MenuItem value={"Kappa"}>Kappa</MenuItem>
                      <MenuItem value={"MCC"}>MCC</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        value="explain_models"
                        checked={talim_train.explain_models}
                        onChange={handleExplainModelsChange}
                      />
                    }
                    label="Get Model Explanation Results"
                  />
                </Grid>
              </Grid>
            </div>
          </Box>

          <Typography>Output Registration Options</Typography>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControlLabel
              control={
                <Switch
                  value="register_to_litholens"
                  checked={talim_train.register_model_to_litholens}
                  onChange={handleRegisterModelToLithoLensChange}
                />
              }
              label="LithoLens"
            />
            <TextField
              id="s3-save-name"
              label="Model Save Name"
              defaultValue={talim_train.s3_model_save_name}
              onChange={handleS3SaveNameChange}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TrainConfigParams;
