import React, { useState, useEffect, ChangeEvent, SyntheticEvent } from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  TableCell,
  Grid,
  Checkbox,
  TextField,
  AutocompleteChangeReason,
  Autocomplete,
  AutocompleteChangeDetails,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFilesInfo } from "./talimTrainSlice";

interface TableDataDropdownProps {
  fileKey: string;
}

interface Selections {
  [key: string]: string;
}

// ----------------------------------------------------------------------------------------------
const ColumnActions: React.FC<TableDataDropdownProps> = ({ fileKey }) => {
  const talim_train = useAppSelector((state) => state.talim.talimTrainSlice);
  const dispatch = useAppDispatch();

  const [selections, setSelections] = useState<Record<string, string[]>>({
    training_features: [] as string[],
  });

  const tableData = talim_train.filesInfo[fileKey].tableData.data;

  const handleSelectionChange =
    (key: string) =>
    (
      event: SyntheticEvent<Element, Event>,
      value: string[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<string> | undefined
    ) => {
      const newSelections = {
        ...selections,
        [key]: value,
      };
      setSelections(newSelections);
      dispatch(
        setFilesInfo({
          key: fileKey,
          info: {
            ...talim_train.filesInfo[fileKey],
            fileKey: talim_train.filesInfo[fileKey].fileKey,
            metaData: talim_train.filesInfo[fileKey].metaData,
            tableData: talim_train.filesInfo[fileKey].tableData,
            [key]: value,
          },
        })
      );
    };

  useEffect(() => {
    // console.log(selections);
  }, [tableData]);

  const metaData = talim_train.filesInfo[fileKey].metaData;

  return (
    <div>
      <FormControl fullWidth margin="normal">
        <Autocomplete
          multiple
          options={talim_train.filesInfo[fileKey].tableData.columns.filter(
            (value) =>
              !Object.values(metaData).includes(value) &&
              value != talim_train.target_feature
          )}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          value={selections.training_features}
          onChange={handleSelectionChange("training_features")}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Customized Training Features (keep it empty to select all features)"
              variant="outlined"
            />
          )}
          fullWidth
        />
      </FormControl>
    </div>
  );
};

export default ColumnActions;
