import React, { useEffect, useState } from "react";
import { Tab, Tabs, Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getFileInfo } from "../../../../../components/S3/S3Utils";
import CustomTable from "../../../../../components/DataDisplay/DisplayTabularData";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";

interface FileTabProps {
  bucketName: string;
}

const DRFileTabs: React.FC<FileTabProps> = ({ bucketName }) => {
  const talim_dr = useAppSelector((state) => state.talim.talimDrSlice);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const allFileKeys = [talim_dr.selectedReferenceFile];

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      {talim_dr.filesInfo[talim_dr.selectedReferenceFile] && (
        <CustomTable
          tableHeaders={
            talim_dr.filesInfo[talim_dr.selectedReferenceFile].tableData.columns
          }
          tableData={
            talim_dr.filesInfo[talim_dr.selectedReferenceFile].tableData.data
          }
        />
      )}
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default DRFileTabs;
