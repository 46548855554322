// S3FileViewer.tsx
import React, { useEffect, useState } from "react";
import { fetchFiles, deleteFile, getDownloadUrl, S3File } from "./S3Utils";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Alert,
  Checkbox,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText, Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { readJsonFromS3 } from "./S3Utils";

interface ApiResponse {
  merged_report?: string | null;
  out_location?: string | null;
  out_log_id?: string | null;
  out_generic_model_id?: number | null;
  warnings?: string[] | null;
  error?: string | null;
  status?: string | null;
}

interface S3ResponseViewerProps {
  refresh: any;
  bucketName: string;
  pathPrefix: string;
  serviceName: string;
}

const S3ResponseViewer: React.FC<S3ResponseViewerProps> = ({
  refresh,
  bucketName,
  pathPrefix,
  serviceName,
}) => {
  const [files, setFiles] = useState<S3File[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const responseKeys = [
    "merged_report",
    "out_location",
    "out_log_id",
    "out_generic_model_id",
    "warnings",
    "error",
  ];

  useEffect(() => {
    const loadFiles = async () => {
      try {
        const fetchedFiles = await fetchFiles(
          (bucketName = bucketName),
          (pathPrefix = pathPrefix + "/responses/" + serviceName),
          ["json"]
        );
        setFiles(fetchedFiles);
      } catch (error) {
        console.error("Failed to fetch files:", error);
      }
    };
    loadFiles();
  }, [refresh, bucketName, pathPrefix]);

  // const handleDownload = async (key: string) => {
  //   try {
  //     const url = await getDownloadUrl(bucketName, key);
  //     window.open(url, "_blank");
  //   } catch (error) {
  //     console.error("Failed to download file:", error);
  //   }
  // };

  // const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     const newSelected = files.map((n) => n.Key);
  //     setSelected(newSelected);
  //   } else {
  //     setSelected([]);
  //   }
  // };

  // const handleClick = (key: string) => {
  //   const selectedIndex = selected.indexOf(key);
  //   let newSelected: string[] = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, key);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleDeleteSelected = () => {
  //   setOpenDialog(true);
  // };

  // const handleConfirmDelete = async () => {
  //   try {
  //     for (let key of selected) {
  //       await deleteFile(bucketName, key);
  //     }
  //     setFiles(files.filter((file) => !selected.includes(file.Key)));
  //     setSelected([]);
  //   } catch (error) {
  //     console.error("Error during deletion:", error);
  //   }
  //   setOpenDialog(false);
  // };

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const [apiResponse, setApiResponse] = useState<ApiResponse>(
    files.length > 0
      ? (readJsonFromS3(bucketName, files[selectedIndex].Key) as ApiResponse)
      : ({} as ApiResponse)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedIndex >= 0 && selectedIndex < files.length) {
          const response = await readJsonFromS3(
            bucketName,
            files[selectedIndex].Key
          );
          setApiResponse(response as ApiResponse);
        }
      } catch (error) {
        console.error("Got an error: ", error);
      }
    };

    fetchData();
  }, [selectedIndex, files]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ maxHeight: 400, overflow: "auto" }}>
            <List
              component="nav"
              aria-label="main mailbox folders"
              title="List of Merge Jobs"
            >
              {files.map((file, index) => (
                <ListItemButton
                  key={index} // Ensure unique key for each item
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemText
                    primary={file.Key.replace(
                      `${pathPrefix}/responses/${serviceName}/`,
                      ""
                    )}
                  />
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div>
            {apiResponse?.status === "requested" && (
              <Alert severity="warning">Job in progress</Alert>
            )}

            {apiResponse?.status === "failed" && (
              <Alert severity="error">Job failed!</Alert>
            )}

            {!apiResponse?.status && (
              <TableContainer component={Paper} key={"table-of-job-results"}>
                <TableBody>
                  <TableRow>
                    <TableCell key={"s3"}>Out S3 File Name</TableCell>
                    <TableCell>
                      {apiResponse.out_location?.substring(
                        apiResponse.out_location?.lastIndexOf("/") + 1
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Out Log ID</TableCell>
                    <TableCell>
                      {apiResponse.out_log_id ? apiResponse.out_log_id : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Out LithoLens Generic Model ID</TableCell>
                    <TableCell>
                      {apiResponse.out_generic_model_id
                        ? apiResponse.out_generic_model_id
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Warnings</TableCell>
                    <TableCell>
                      {apiResponse.warnings
                        ? apiResponse.warnings.length > 0
                          ? apiResponse.warnings
                          : "N/A"
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Error</TableCell>
                    <TableCell>
                      {apiResponse.error ? apiResponse.error : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>
                  <Chip label={apiResponse.status === "requested" ? "requested" : apiResponse.status === "failed" ? "failed" : "done"}  color={apiResponse.status === "requested" ? "warning" : apiResponse.status === "failed" ? "error" : "success"} />

                </TableCell>
              </TableRow>
                </TableBody>
              </TableContainer>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default S3ResponseViewer;
