import React, { useEffect, useState } from "react";
import {
  fetchFiles,
  S3File,
  readJsonFromS3,
} from "../../../../components/S3/S3Utils";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  AlertTitle,
  CircularProgress,
  Slider,
  Box,
  Typography,
} from "@mui/material";
import Plot from "react-plotly.js";

interface Props {
  [key: string]: number[];
}

interface EnvironmentFileProps {
  bucketName: string;
  pathPrefix: string;
}

const PlotEnvironmentJson: React.FC<EnvironmentFileProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const [files, setFiles] = useState<S3File[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [environmentData, setEnvironmentData] = useState<Props>({
    x: [],
    y: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [plotData, setPlotData] = React.useState<any[]>([]);
  const [relativeAmplitudeThreshold, setRelativeAmplitudeThreshold] = useState<number>(50);
  const [windowSize, setWindowSize] = useState<number>(5);
  const [maxTransformedCountsValue, setMaxTransformedCountsValue] = useState<number>(0);
  // const [exponent, setExponent] = useState<number>(0.4); // Exponent for power transformation

  const [columns, setColumns] = useState<string[]>([]);
  const [selectedXColumn, setSelectedXColumn] = useState<string>("");
  const [selectedYColumn, setSelectedYColumn] = useState<string>("");

  useEffect(() => {
    const loadFiles = async () => {
      setIsLoading(true);
      try {
        const fetchedFiles = await fetchFiles(
          bucketName,
          pathPrefix + "/extracted_data",
          ["json"]
        );
        const jsonFiles = fetchedFiles.filter((file) => file.Key.endsWith(".json"));
        setFiles(jsonFiles);

      } catch (error) {
        console.error("Failed to fetch files:", error);
      }
      setIsLoading(false);
    };
    loadFiles();
  }, [bucketName, pathPrefix]);

  const handleFileSelect = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const key = event.target.value as string;
    setSelectedFile(key);
    setSelectedXColumn("");
    setSelectedYColumn("");
  };

  const handleColumnXSelect = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const key = event.target.value as string;
    setSelectedXColumn(key);
  };

  const handleColumnYSelect = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const key = event.target.value as string;
    setSelectedYColumn(key);
  };

  useEffect(() => {
    const getFileData = async () => {
      setIsLoading(true);
      if (selectedFile === "") {
        setIsLoading(false);
        return;
      }
      const data = await readJsonFromS3(bucketName, selectedFile);
      const data_keys = Object.keys(data);
      setEnvironmentData(data);
      setColumns(data_keys);
      setIsLoading(false);
    };

    getFileData();
  }, [bucketName, selectedFile]);

  function detectPeaks(
    x: number[],
    y: number[],
    relativeThreshold: number,
    windowSize: number,
    // exponent: number
  ) {
    const peaks = [];
    const halfWindow = Math.floor(windowSize / 2);

    // Apply power transformation to counts
    const transformedY = y.map(value => value + Math.min(...y) + 1); // Add 1 to avoid log(0)

    // Calculate maximum of transformed counts
    const maxTransformedYValue = Math.max(...transformedY);
    setMaxTransformedCountsValue(maxTransformedYValue);

    // Calculate amplitude threshold based on relative percentage
    const amplitudeThreshold = (relativeThreshold / 100) * maxTransformedYValue;

    for (let i = halfWindow; i < transformedY.length - halfWindow; i++) {
      const windowStart = i - halfWindow;
      const windowEnd = i + halfWindow;
      const windowTransformedY = transformedY.slice(windowStart, windowEnd + 1);

      const maxTransformedY = Math.max(...windowTransformedY);
      const isPeak = transformedY[i] === maxTransformedY && transformedY[i] > amplitudeThreshold;

      if (isPeak) {
        peaks.push({ x: x[i], y: y[i] }); // Use original counts for plotting
      }
    }

    return peaks;
  }

  function computeMovingAverage(data: number[], windowSize: number): number[] {
    const result = [];
    const halfWindow = Math.floor(windowSize / 2);

    for (let i = 0; i < data.length; i++) {
      let sum = 0;
      let count = 0;
      for (let j = i - halfWindow; j <= i + halfWindow; j++) {
        if (j >= 0 && j < data.length) {
          sum += data[j];
          count++;
        }
      }
      result.push(sum / count);
    }
    return result;
  }

  useEffect(() => {
    const xColumnKeys = selectedXColumn ? Object.keys(environmentData[selectedXColumn]) : [];
    const yColumnKeys = selectedYColumn ? Object.keys(environmentData[selectedYColumn]) : [];
    if (xColumnKeys.length > 0 && yColumnKeys.length > 0) {
      setAlertMessage(null);
      const x_axis = Object.values(environmentData[selectedXColumn]);
      const y_axis = Object.values(environmentData[selectedYColumn]);
      // Detect peaks using power-transformed counts
      const peaks = detectPeaks(
        x_axis,
        y_axis,
        relativeAmplitudeThreshold,
        windowSize,
        // exponent
      );

      // Compute moving averages
      const movingAverages: { [key: number]: number[] } = {};
      const windowSizes = [5, 25, 100];
      windowSizes.forEach((ws) => {
        movingAverages[ws] = computeMovingAverage(y_axis, ws);
      });

      const plotDataArray = [
        {
          type: "scatter",
          x: x_axis,
          y: y_axis,
          mode: "lines",
          line: { shape: "linear", dash: "solid", color: "black" },
          name: "Environment Data",
        },
        {
          type: "scatter",
          x: peaks.map((p) => p.x),
          y: peaks.map((p) => p.y),
          mode: "markers",
          marker: { color: "red", size: 8 },
          name: "Peaks",
        },
      ];

      // Add moving averages to plotData
      const colors = ["blue", "green", "orange"];
      windowSizes.forEach((ws, index) => {
        plotDataArray.push({
          type: "scatter",
          x: x_axis,
          y: movingAverages[ws],
          mode: "lines",
          line: {
            shape: "linear",
            dash: "dot",
            color: colors[index % colors.length],
          },
          name: `Moving Average (Window ${ws})`,
        });
      });

      setPlotData(plotDataArray);
    } else {
      setPlotData([]);
      if (selectedFile !== "") {
        setAlertMessage("No data found in the selected file.");
      }
    }
  }, [environmentData, columns, selectedXColumn, selectedYColumn, relativeAmplitudeThreshold, windowSize]); //, exponent]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "20px",
        minHeight: "150vh",
      }}
    >
      {/* File Selection */}
      <FormControl fullWidth>
        <InputLabel id="file-select-label">Select File</InputLabel>
        <Select
          labelId="file-select-label"
          value={selectedFile}
          onChange={(event) =>
            handleFileSelect(event as React.ChangeEvent<{ value: unknown }>)
          }
          displayEmpty
          label="Select File"
        >
          {files.map((file) => (
            <MenuItem key={file.Key} value={file.Key}>
              {file.Key.replace(pathPrefix + "/extracted_data" + "/", "")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedFile && (
        <Box display="flex" justifyContent="space-between" mt={2}>
          <FormControl fullWidth>
            <InputLabel id="x-data-label">Select X Data</InputLabel>
            <Select
              labelId="x-data-label"
              value={selectedXColumn}
              onChange={(event) =>
                handleColumnXSelect(event as React.ChangeEvent<{ value: unknown }>)
              }
              displayEmpty
              label="Select Data for X Column"
            >
              {columns.map((column) => (
                <MenuItem key={column} value={column} disabled={column === selectedYColumn}>
                  {column}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box mx={2} />
          <FormControl fullWidth>
            <InputLabel id="y-data-label">Select Y Data</InputLabel>
            <Select
              labelId="y-data-label"
              value={selectedYColumn}
              onChange={(event) =>
                handleColumnYSelect(event as React.ChangeEvent<{ value: unknown }>)
              }
              displayEmpty
              label="Select Data for Y Column"
            >
              {columns.map((column) => (
                <MenuItem key={column} value={column} disabled={column === selectedXColumn}>
                  {column}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {alertMessage && (
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          {alertMessage}
        </Alert>
      )}

      {/* Controls Container */}
      {selectedFile && selectedXColumn && selectedYColumn && (
        <>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Box flex={1} mr={1}>
              <Typography gutterBottom>
                Relative Amplitude Threshold (%)
              </Typography>
              <Slider
                value={relativeAmplitudeThreshold}
                onChange={(e, value) =>
                  setRelativeAmplitudeThreshold(value as number)
                }
                aria-labelledby="relative-amplitude-threshold-slider"
                min={0}
                max={100}
                step={1}
                valueLabelDisplay="auto"
              />
            </Box>

            {/* Window Size Input */}
            <Box flex={1} mx={1}>
              <Typography gutterBottom>Window Size</Typography>
              <Slider
                value={windowSize}
                onChange={(e, value) => setWindowSize(value as number)}
                aria-labelledby="window-size-slider"
                min={1}
                max={150}
                step={10}
                valueLabelDisplay="auto"
              />
            </Box>
          </Box>

          {/* Display Max Transformed Counts Value */}
          <Box mt={2}>
            <Typography>
              Maximum Transformed Counts Value: {maxTransformedCountsValue.toFixed(2)}
            </Typography>
          </Box>
        </>
      )}

      {/* Plot Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
        >
          {isLoading ? (
            <CircularProgress color="primary" />
          ) : (
            <div>
              {plotData.length > 0 && (
                <Plot
                  data={plotData}
                  layout={{
                    title:
                      "Environment Data Line Chart for " +
                      selectedFile.split("/").pop(),
                    xaxis: {
                      title: "Two Theta",
                    },
                    yaxis: {
                      title: "Counts",
                    },
                    legend: {
                      orientation: "h",
                      y: -0.2,
                    },
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlotEnvironmentJson;
