import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { Tabs, Tab } from "@mui/material";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import S3FileUploader from "../../../../components/S3/S3FileUploader";
import EnvironmentFileConvert from "./EnvironmentFileConvert";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProjectsSelector from "../../../projects/projectSelector";
import config, { ENVIRONMENT } from "../../../../config";
import PlotEnvironmentJson from "./plotEnvironmentjson";

const steps: string[] = ["Upload Environment Data", "Convert Files", "Plot Pressure Data"];

const Environment: React.FC = () => {
  const environment = useAppSelector((state) => state.environment.EnvironmentSlice);
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useAppSelector((state) => state.auth);
  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";

  const [activeStep, setActiveStep] = React.useState<number>(
    parseInt(new URLSearchParams(location.search).get("step")!) || 0
  );

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const user = useAppSelector((state) =>
    state.auth.isAuthenticated ? state.auth.user?.firstName : "guest@"
  );

  const bucketName = config[ENVIRONMENT].environment_s3_bucket;
  const userFolder = auth.user?.username
    ? auth.user?.username.lastIndexOf("@") > -1
      ? auth.user?.username.substring(0, auth.user?.username.lastIndexOf("@"))
      : auth.user?.username
    : "guest";
  const [pathPrefix, setPathPrefix] = React.useState(
    "general/" + userFolder + "/environment"
  );

  console.log(pathPrefix);

  const handleStep = (step: number) => () => {
    setActiveStep(step);
    navigate(`?step=${step}`);
  };

  React.useEffect(() => {
    const userFolder = auth.user?.username
      ? auth.user?.username.lastIndexOf("@") > -1
        ? auth.user?.username.substring(0, auth.user?.username.lastIndexOf("@"))
        : auth.user?.username
      : "guest";
    setPathPrefix(
      projects.selectedProject
        ? company + "/" + project + "/environment"
        : "general/" + userFolder + "/environment"
    );
    console.log(pathPrefix);
  }, [projects.selectedProject]);

  return (
    <div>
      <ProjectsSelector />
      <Box>
        <div
          style={{
            border: "1px solid #ccc",
            padding: "20px",
            borderRadius: "4px",
          }}
        >
          <Stepper
            nonLinear
            activeStep={activeStep}
            orientation={matches ? "vertical" : "horizontal"}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <div>
          <React.Fragment>
            {activeStep === 0 && (
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <S3FileUploader
                      bucketName={bucketName}
                      pathPrefix={pathPrefix + "/data"}
                      allowedExtensions={["brml", "xml", "png", "jpg", "csv"]}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            {activeStep === 1 && (
              <EnvironmentFileConvert bucketName={bucketName} pathPrefix={pathPrefix} />
            )}
            {activeStep === 2 && (
              <PlotEnvironmentJson bucketName={bucketName} pathPrefix={pathPrefix} />
            )}
          </React.Fragment>
        </div>
      </Box>
    </div>
  );
};

export default Environment;
