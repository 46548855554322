import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../redux/store";

export const coreApiSlice = createApi({
  reducerPath: "core_api",
  baseQuery: fetchBaseQuery({
    baseUrl: "",
    prepareHeaders: (headers, { getState }) => {
      const cognitoIdToken = (getState() as RootState).auth.cognitoTokens
        ?.IdToken;
      const cognitoTokenType = (getState() as RootState).auth.cognitoTokens
        ?.TokenType;
      // console.log(cognitoIdToken);
      if (cognitoIdToken) {
        headers.set("Content-Type", "application/json");
        headers.set("Authorization", `${cognitoTokenType} ${cognitoIdToken}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({}),
});
