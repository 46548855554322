const calculateHistogram = (imageData: ImageData) => {
    const histR = new Array(256).fill(0);
    const histG = new Array(256).fill(0);
    const histB = new Array(256).fill(0);

    for (let i = 0; i < imageData.data.length; i += 4) {
        histR[imageData.data[i]]++;
        histG[imageData.data[i + 1]]++;
        histB[imageData.data[i + 2]]++;
    }

    return { histR, histG, histB };
};

const findLowHighInputLevels = (hist: number[], totalPixels: number, clipPercent = 0.01) => {
    const cumulativeSum = hist.reduce((acc, value) => {
        acc.push((acc.length > 0 ? acc[acc.length - 1] : 0) + value);
        return acc;
    }, [] as number[]);

    const clipLimit = clipPercent * totalPixels / 100.0;
    let lowInput = 0;
    let highInput = 255;

    for (let i = 0; i < 256; i++) {
        if (cumulativeSum[i] > clipLimit) {
            lowInput = i;
            break;
        }
    }

    for (let i = 255; i >= 0; i--) {
        if (cumulativeSum[i] < (totalPixels - clipLimit)) {
            highInput = i;
            break;
        }
    }

    return { lowInput, highInput };
};

const stretchChannel = (channelData: Uint8ClampedArray, lowInput: number, highInput: number) => {
    const result = new Uint8ClampedArray(channelData.length);
    for (let i = 0; i < channelData.length; i++) {
        if (highInput - lowInput > 0) {
            result[i] = Math.min(255, Math.max(0, ((channelData[i] - lowInput) * (255.0 / (highInput - lowInput)))));
        } else {
            result[i] = channelData[i];
        }
    }
    return result;
};

const normalizeChannel = (channelData: Uint8ClampedArray) => {
    const meanVal = channelData.reduce((sum, value) => sum + value, 0) / channelData.length;
    const targetMean = 128.0;
    const result = new Uint8ClampedArray(channelData.length);

    for (let i = 0; i < channelData.length; i++) {
        result[i] = Math.min(255, Math.max(0, channelData[i] * (targetMean / meanVal)));
    }

    return result;
};

const whiteBalance = (imageData: ImageData, clipPercent = 0.01) => {
    const totalPixels = imageData.width * imageData.height;
    const { histR, histG, histB } = calculateHistogram(imageData);

    const { lowInput: lowInputR, highInput: highInputR } = findLowHighInputLevels(histR, totalPixels, clipPercent);
    const { lowInput: lowInputG, highInput: highInputG } = findLowHighInputLevels(histG, totalPixels, clipPercent);
    const { lowInput: lowInputB, highInput: highInputB } = findLowHighInputLevels(histB, totalPixels, clipPercent);

    const stretchedR = stretchChannel(imageData.data.filter((_, i) => i % 4 === 0), lowInputR, highInputR);
    const stretchedG = stretchChannel(imageData.data.filter((_, i) => i % 4 === 1), lowInputG, highInputG);
    const stretchedB = stretchChannel(imageData.data.filter((_, i) => i % 4 === 2), lowInputB, highInputB);

    const normalizedR = normalizeChannel(stretchedR);
    const normalizedG = normalizeChannel(stretchedG);
    const normalizedB = normalizeChannel(stretchedB);

    for (let i = 0; i < imageData.data.length; i += 4) {
        imageData.data[i] = normalizedR[i / 4];
        imageData.data[i + 1] = normalizedG[i / 4];
        imageData.data[i + 2] = normalizedB[i / 4];
    }

    return imageData;
};

const areImagesEqual = (imageData1: ImageData, imageData2: ImageData): boolean => {
    if (imageData1.width !== imageData2.width || imageData1.height !== imageData2.height) return false;
    const diff = new Uint8ClampedArray(imageData1.data.length);
    for (let i = 0; i < imageData1.data.length; i++) {
        diff[i] = Math.abs(imageData1.data[i] - imageData2.data[i]);
    }
    const unique = new Set(diff);
    // not equal if has values other than 0 or 1
    return unique.size <= 2 && (unique.has(0) || (unique.has(0) && unique.has(1)));
};

const processImage = (outputCanvas: HTMLCanvasElement): Uint8ClampedArray => {
    const ctx = outputCanvas.getContext('2d');
    if (!ctx) throw new Error('Context image data not available');

    const imageData = ctx.getImageData(0, 0, outputCanvas.width, outputCanvas.height);
    return imageData.data;
};

export { whiteBalance, areImagesEqual, processImage }