import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { Tab, Tabs } from "@mui/material";
import Typography from "@mui/material/Typography";
import ClassificationResponseViewer from "./classificationResponseViewer";
import RegressionResponseViewer from "./regressionResponseViewer";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SettingsIcon from "@mui/icons-material/Settings";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import { useLocation, useNavigate } from "react-router-dom";
import ClusteringResponseViewer from "./clusteringResponseViewer";

// Define the steps as an array of objects with label and icon.
const steps = [
  { label: "Dimension Reduction", icon: <MergeTypeIcon fontSize="small" /> },
  {
    label: "Data Imputation/ Manipulation",
    icon: <MergeTypeIcon fontSize="small" />,
  },
  { label: "Feature Engineering", icon: <MergeTypeIcon fontSize="small" /> },
];

interface TrainResponsePageProps {
  bucketName: string;
  pathPrefix: string;
  refresh: string;
}

const TrainResponseViewer: React.FC<TrainResponsePageProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveStep(newValue);
  };

  return (
    <Box style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={activeStep}
        onChange={handleChange}
        aria-label="file tabs"
        sx={{
          backgroundColor: "gray", // Light teal background for the whole tabs bar
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Adding a subtle shadow under the tabs bar
          "& .MuiTabs-flexContainer": {
            gap: "10px", // Adds space between each tab/button
          },
        }}
      >
        <Tab
          key="dimension-reduction"
          label="Classification"
          id="dimension-reduction"
          aria-controls={`tabpanel-dimension-reduction`}
        />
        <Tab
          key="data-imputation-manipulation"
          label="Regression"
          id="data-imputation-manipulation"
          aria-controls={`tabpanel-data-imputation-manipulation`}
        />
        <Tab
          key="feature-engineering"
          label="Clustering"
          id="feature-engineering"
          aria-controls={`feature-engineering`}
        />
      </Tabs>
      <div hidden={activeStep !== 0} id="feature-engineering">
        <ClassificationResponseViewer
          bucketName={bucketName}
          pathPrefix={pathPrefix}
          serviceName="classification"
          refresh={""}
        />
      </div>
      <div hidden={activeStep !== 1} id="data-imputation-manipulation">
        <RegressionResponseViewer
          bucketName={bucketName}
          pathPrefix={pathPrefix}
          serviceName="regression"
          refresh={""}
        />
      </div>
      <div hidden={activeStep !== 2} id="feature-engineering">
        <ClusteringResponseViewer
          bucketName={bucketName}
          pathPrefix={pathPrefix}
          serviceName="clustering"
          refresh={""}
        />
        {/* <div>Coming Soon...</div> */}
      </div>
    </Box>
  );
};

export default TrainResponseViewer;
