import React, { useEffect, useState } from "react";
import { Box, Button, Grid, CircularProgress, Typography } from "@mui/material";
import { useForm, Controller, useWatch, FormProvider } from "react-hook-form";
import DrillholesSelector from "../../../projects/drillholes_and_images/drillholesSelector";
import ImageSelector from "../../../projects/drillholes_and_images/imagesSelector";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getFileInfo } from "../../../../components/S3/S3Utils";
import {
  TextInput,
  SelectInput,
  AccordionSection,
  BooleanSwitchInput,
} from "../formComponents";
import { createJobItem } from "../../../../components/Jobs/jobUtils";
import {
  FileItem,
  useGetFilesListQuery,
} from "../../../api/endpoints/filesApi";
import {
  filterNullProperties,
  useRunServiceMutation,
} from "../../../api/endpoints/superApi";
import { CLEANING_MODELS_FOLDER_PATH, ENVIRONMENT } from "../../../../config";
import {
  setSelectedDrillholes,
  setSelectedImages,
} from "../../../projects/projectsSlice";
import JobsInfo from "../../../../components/Jobs/jobsInfo";
import { usePutJobMutation } from "../../../api/endpoints/projectsApi";
import { LoadingButton } from "@mui/lab";

interface FormValues {
  project_name: string;
  model_s3_path: string;
  classification_threshold?: number;
  data_category?: string;
  hs_wavelength?: string;
  force_clean?: boolean;
  custom_drillholes?: boolean;
  custom_images?: boolean;
  drillholes_to_process?: string[];
  images_to_process: string[];
}

const defaultValues: FormValues = {
  project_name: "",
  model_s3_path: "default",
  classification_threshold: 0.7,
  data_category: "core",
  hs_wavelength: "",
  force_clean: false,
  custom_drillholes: false,
  custom_images: false,
  drillholes_to_process: [],
  images_to_process: [],
};

const CleanImagesPage: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);
  const methods = useForm<FormValues>({ defaultValues });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = methods;
  // const { data: modelsList, isLoading: modelsLoading } = useGetFilesListQuery({
  //   companyName: projects.selectedProject?.company || "",
  //   projectName: projects.selectedProject?.id || "",
  //   folder: CLEANING_MODELS_FOLDER_PATH,
  // });
  const service = "percentage_report";
  const dispatch = useAppDispatch();
  const dataCategory = useWatch({ control, name: "data_category" });
  const customDrillholes = useWatch({ control, name: "custom_drillholes" });
  const customImages = useWatch({ control, name: "custom_images" });
  const [submittedData, setSubmittedData] = useState<{} | null>(null);

  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const [putJobMutation, putJobMutationResult] = usePutJobMutation();

  useEffect(() => {}, [runServiceMutationResult]);

  useEffect(() => {
    if (projects.selectedProject) {
      setValue("project_name", projects.selectedProject.id);
    }
    if (projects.selectedDrillholes[service]) {
      const mappedDrillholes = projects.selectedDrillholes[service].map(
        (drillholeItem) => drillholeItem.name
      );
      setValue("drillholes_to_process", mappedDrillholes);
    }
    if (projects.selectedImages[service]) {
      const mappedImages = projects.selectedImages[service].map(
        (imageItem) => imageItem.name
      );
      setValue("images_to_process", mappedImages);
    }
  }, [
    projects.selectedDrillholes[service],
    projects.selectedImages[service],
    setValue,
    projects.selectedProject,
  ]);

  useEffect(() => {
    if (!customDrillholes) {
      dispatch(
        setSelectedDrillholes({
          service: service,
          drillholes: [],
        })
      );
      setValue("custom_images", false);
    }
    if (!customImages) {
      dispatch(
        setSelectedImages({
          service: service,
          images: [],
        })
      );
    }
  }, [setValue, projects.selectedProject, customDrillholes, customImages]);

  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName = projects.selectedProject?.id || "company_project";
  const type: string = "litholens.cleanimages"; //`logs.${service}`;
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);

  const onSubmit = (data: FormValues) => {
    const formData = {
      project_name: data.project_name,
      model_s3_path:
        data.model_s3_path === "default" ? null : data.model_s3_path,
      classification_threshold: data.classification_threshold,
      data_category: data.data_category,
      hs_wavelength: data.hs_wavelength ? data.hs_wavelength : null,
      force_clean: data.force_clean,
      drillholes_to_process:
        data.drillholes_to_process && data.drillholes_to_process?.length > 0
          ? data.drillholes_to_process
          : null,
      images_to_process:
        data.images_to_process && data.images_to_process?.length > 0
          ? data.images_to_process
          : null,
    };
    setSubmittedData(formData);
    const request_body = filterNullProperties(formData);
    // runServiceMutation({
    //   service: "ll_clean_images",
    //   request_body: request_body,
    //   environment: ENVIRONMENT,
    //   job_item: jobItem,
    // });
    putJobMutation({
      company: company,
      project: projectName,
      jobType: "litholens.cleanimages",
      requestBody: request_body,
      // description: "",
      metadata: "",
      requestMetadata: request_body,
    });
  };

  // useEffect(() => {
  //   console.log(putJobMutationResult);
  // }, [putJobMutationResult]);

  // const CleaningModelOptions =
  //   modelsList?.items.map(
  //     (item: FileItem) => getFileInfo(item.path).baseName
  //   ) || [];

  return (
    projects.selectedProject && (
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", margin: "20px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectInput
                name="model_s3_path"
                control={control}
                label="Cleaning Model"
                options={[
                  { value: "default", label: "Default Model" },
                  { value: "model_1", label: "Model 1" },
                  { value: "model_2", label: "Model 2" },
                  { value: "model_3", label: "Model 3" },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                name="data_category"
                control={control}
                label="Data Category"
                options={[
                  { value: "core", label: "core" },
                  { value: "atv", label: "atv" },
                  { value: "otv", label: "otv" },
                  { value: "wet", label: "wet" },
                  { value: "dry", label: "dry" },
                  { value: "hs", label: "hs" },
                ]}
              />
            </Grid>
            {dataCategory === "hs" && (
              <Grid item xs={12}>
                <TextInput
                  name="hs_wavelength"
                  control={control}
                  label="HS Wavelength"
                  type="number"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <AccordionSection title="Advanced Options">
                <Grid item xs={12}>
                  <TextInput
                    name="classification_threshold"
                    control={control}
                    label="Classification Threshold"
                    type="number"
                    rules={{
                      min: {
                        value: 0.5,
                        message: "Threshold should be between 0.5 and 1",
                      },
                      max: {
                        value: 1,
                        message: "Threshold should be between 0.5 and 1",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BooleanSwitchInput
                    name="custom_drillholes"
                    control={control}
                    label="Custom Drillholes"
                    helpText="Limit to specific drillholes"
                  />
                </Grid>
                {customDrillholes && (
                  <Grid item xs={12}>
                    <DrillholesSelector
                      companyName={projects.selectedProject.company}
                      projectName={projects.selectedProject.id}
                      service={service}
                    />
                  </Grid>
                )}
                {customDrillholes && (
                  <Grid item xs={12}>
                    <BooleanSwitchInput
                      name="custom_images"
                      control={control}
                      label="Custom Images"
                      helpText="Limit to specific images of the selected drillholes"
                    />
                  </Grid>
                )}
                {customDrillholes && customImages && (
                  <Grid item xs={12}>
                    <ImageSelector
                      companyName={projects.selectedProject.company}
                      projectName={projects.selectedProject.id}
                      service={service}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <BooleanSwitchInput
                    name="force_clean"
                    control={control}
                    label="Force Re-Clean"
                    helpText="By default, previously cleaned images are skipped, 
                    setting this will prevent skipping and re-cleans"
                  />
                </Grid>
              </AccordionSection>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                loading={putJobMutationResult.isLoading}
                // disabled={!isValid}
              >
                Submit
              </LoadingButton>
            </Grid>
            {submittedData && (
              <Grid item xs={12}>
                <Box mt={2}>
                  {/* <Typography variant="h6">Payload:</Typography>
                  <pre>{JSON.stringify(submittedData, null, 2)}</pre> */}
                  <div>
                    {runServiceMutationResult.isLoading && (
                      <p>Sending Request ...</p>
                    )}
                    {runServiceMutationResult.data ? (
                      <div>
                        <p>
                          Response:{" "}
                          {JSON.stringify(runServiceMutationResult.data)}
                        </p>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <JobsInfo
          jobType={"litholens.cleanimages"}
          refresh={putJobMutationResult}
        />
      </FormProvider>
    )
  );
};

export default CleanImagesPage;
