import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../redux/store";
import { S3File } from "../../../../../components/S3/S3Utils";
import { WritableDraft } from "immer";
import {
  JobItem,
  createJobItem,
} from "../../../../../components/Jobs/jobUtils";

export interface TableData {
  columns: string[];
  data: any[];
}

export interface FileInfo {
  fileKey: string;
  metaData: any;
  tableData: TableData;
  columnStrategies: any;
}

export interface talimMergeState {
  files: S3File[];
  filesInfo: { [key: string]: FileInfo }; // accumulates loaded data for all files
  selectedReferenceFile: string; // s3 key of the selected reference file
  selectedSupportFiles: string[]; // list of s3 keys of the selected support files
  categorical_encoding: string;
  numeric_encoding: string;
  intervalization_radius: number;
  load_litholens_reports: boolean;
  load_litholens_roqenet_reports: boolean;
  load_litholens_veinet_reports: boolean;
  merging_type: string;
  register_to_litholens: boolean;
  register_to_logsapi: boolean;
  descriptive_log_name: string;
  notification_email: string;
  structures_to_process: [] | null;
  drillholes_to_process: [] | null;
  s3_data_save_name: string | null;
  litholens_save_path_type: string;
}

const initialState: talimMergeState = {
  files: [],
  filesInfo: {},
  selectedReferenceFile: "",
  selectedSupportFiles: [],
  categorical_encoding: "multihot",
  numeric_encoding: "mean",
  intervalization_radius: 0,
  load_litholens_reports: false,
  load_litholens_roqenet_reports: false,
  load_litholens_veinet_reports: false,
  merging_type: "weighted",
  register_to_litholens: false,
  register_to_logsapi: false,
  descriptive_log_name: "new_log",
  notification_email: "",
  structures_to_process: null,
  drillholes_to_process: null,
  s3_data_save_name: null,
  litholens_save_path_type: "s3",
};

type TalimMergeStateKey = keyof talimMergeState;

export const talimMergeSlice = createSlice({
  name: "talim_merge",
  initialState: initialState,
  reducers: {
    // -------------------------------------------------------------------------------
    // General setter for updating any state attribute
    setState: <T extends TalimMergeStateKey>(
      state: WritableDraft<talimMergeState>,
      action: PayloadAction<{ key: T; value: talimMergeState[T] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    // -------------------------------------------------------------------------------
    setselectedSupportFiles: (
      state,
      action: PayloadAction<{ keys: string[] }>
    ) => {
      state.selectedSupportFiles = action.payload.keys;
    },
    // -------------------------------------------------------------------------------
    setSelectedReferenceFile: (
      state,
      action: PayloadAction<{ key: string }>
    ) => {
      state.selectedReferenceFile = action.payload.key;
    },
    // -------------------------------------------------------------------------------
    setFiles: (state, action: PayloadAction<{ files: S3File[] }>) => {
      state.files = action.payload.files;
    },
    // -------------------------------------------------------------------------------
    setFilesInfo: (
      state,
      action: PayloadAction<{ key: string; info: FileInfo }>
    ) => {
      state.filesInfo[action.payload.key] = action.payload.info;
    },
    // -------------------------------------------------------------------------------
  },
});

export const selectTalim = (state: RootState) => state.talim.talimMergeSlice;

// Action creators are generated for each case reducer function
export const {
  setState,
  setSelectedReferenceFile,
  setselectedSupportFiles,
  setFiles,
  setFilesInfo,
} = talimMergeSlice.actions;

export default talimMergeSlice.reducer;
