// S3FileViewer.tsx
import React, { useEffect, useState } from "react";
import {
  fetchFiles,
  deleteFile,
  getDownloadUrl,
  S3File,
} from "../../../../../components/S3/S3Utils";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  Button,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { readJsonFromS3 } from "../../../../../components/S3/S3Utils";
import Plot from "react-plotly.js";
import { Data } from "plotly.js";

interface ResidualsProps {
  true_values?: number[];
  residuals?: number[];
  mean?: number;
  median?: number;
}

interface PredictionErrorProps {
  true_values?: number[];
  predicted_values?: number[];
  mean?: number;
  median?: number;
}

interface MetricsProps {
  metrics: { [key: string]: any };
}

interface VisualizationPlotProps {
  residuals?: ResidualsProps;
  prediction_error_plot?: PredictionErrorProps;
  // cooks?: ConfusionMatrixProps;
}

interface ApiResponse {
  task_type?: string | null;
  out_location?: string | null;
  visualization_plots?: VisualizationPlotProps;
  explanation_plots?: any;
  metrics?: MetricsProps;
  status?: string | null;
}

interface TrainResponseViewerProps {
  refresh: any;
  bucketName: string;
  pathPrefix: string;
  serviceName: string;
}

const RegressionResponseViewer: React.FC<TrainResponseViewerProps> = ({
  refresh,
  bucketName,
  pathPrefix,
  serviceName,
}) => {
  const [files, setFiles] = useState<S3File[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const loadFiles = async () => {
      try {
        const fetchedFiles = await fetchFiles(
          (bucketName = bucketName),
          (pathPrefix = pathPrefix + "/responses/" + serviceName),
          ["json"]
        );
        setFiles(fetchedFiles);
      } catch (error) {
        console.error("Failed to fetch files:", error);
      }
    };
    loadFiles();
  }, [refresh, bucketName, pathPrefix]);

  // Residuals Plot

  interface ResidualsPlotProps {
    data?: ResidualsProps;
  }

  const ResidualsPlot: React.FC<ResidualsPlotProps> = ({ data }) => {
    if (!data) {
      return <div>Residuals Plot Not Generated!</div>;
    }

    if (!data.true_values || !data.residuals || !data.mean || !data.median) {
      return <div>Residuals Plot Not Generated!</div>;
    }
    const { true_values, residuals, mean, median } = data;

    return (
      <Plot
        data={[
          {
            x: true_values,
            y: residuals,
            type: "scatter" as const,
            mode: "markers" as const,
            name: "Residuals",
            marker: { color: "blue" },
          },
          // {
          //   x: [Math.min(...true_values), Math.max(...true_values)],
          //   y: [mean, mean],
          //   type: "line" as const,
          //   name: "Mean",
          //   line: { color: "red", width: 2 },
          // },
          // {
          //   x: [Math.min(...true_values), Math.max(...true_values)],
          //   y: [median, median],
          //   type: "line" as const,
          //   name: "Median",
          //   line: { color: "green", width: 2, dash: "dot" },
          // },
        ]}
        layout={{
          title: "Prediction Residuals Plot",
          xaxis: { title: "True Values" },
          yaxis: { title: "Residuals" },
          legend: { orientation: "h", x: 0, xanchor: "left", y: 1.1 },
        }}
        style={{ width: "100%", height: "100%" }}
      />
    );
  };

  // Prediction Error Plot

  interface PredictionErrorPlotProps {
    data?: PredictionErrorProps;
  }

  const PredictionErrorPlot: React.FC<PredictionErrorPlotProps> = ({
    data,
  }) => {
    if (!data) {
      return <div>Prediction Error Plot Not Generated!</div>;
    }

    if (!data.true_values || !data.predicted_values) {
      return <div>Prediction Error Plot Not Generated!</div>;
    }
    const { true_values, predicted_values, mean, median } = data;

    return (
      <Plot
        data={[
          {
            x: true_values,
            y: predicted_values,
            type: "scatter" as const,
            mode: "markers" as const,
            name: "Predictions",
            marker: { color: "blue" },
          },
        ]}
        layout={{
          title: "Prediction Error Plot",
          xaxis: { title: "True Values" },
          yaxis: { title: "Predicted Values" },
          legend: { orientation: "h", x: 0, xanchor: "left", y: 1.1 },
        }}
        style={{ width: "100%", height: "100%" }}
      />
    );
  };

  // Metrics Table
  interface MetricsTableProps {
    metrics?: MetricsProps;
  }

  const MetricsTable: React.FC<MetricsTableProps> = ({ metrics }) => {
    if (!metrics) {
      return <div> Metrics are not available! </div>;
    }

    if (Object.keys(metrics)[1] === "Model") {
      const models = Object.values(Object.values(metrics)[1]) as string[];
      console.log(models);
      return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Metric</TableCell>
                {models.map((value) => {
                  return (
                    <TableCell sx={{ fontWeight: "bold" }}>{value}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(metrics).map(([key, value]) => {
                console.log(key);
                console.log(key === "Model" || key === "Unnamed: 0");
                if (key === "Model" || key === "Unnamed: 0") {
                  return null;
                }
                if (typeof value === "object") {
                  const rows = Object.keys(value).map((kkey) => ({
                    id: kkey,
                    acc: value[kkey],
                  }));
                  return (
                    <TableRow
                      key={key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {key}
                      </TableCell>
                      {rows.map((row) => (
                        <TableCell component="th" scope="row">
                          {row.acc}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Metric</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(metrics).map(([key, value]) => {
              if (typeof value === "number") {
                return (
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    <TableCell>
                      {value === null ? "null" : value.toString()}
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const [apiResponse, setApiResponse] = useState<ApiResponse>(
    files.length > 0
      ? (readJsonFromS3(bucketName, files[selectedIndex].Key) as ApiResponse)
      : ({} as ApiResponse)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedIndex >= 0 && selectedIndex < files.length) {
          setIsLoading(true);
          const response = await readJsonFromS3(
            bucketName,
            files[selectedIndex].Key
          );
          setApiResponse(response as ApiResponse);
        }
      } catch (error) {
        console.error("Got an error: ", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [selectedIndex, files]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ maxHeight: 400, overflow: "auto" }}>
            <List
              component="nav"
              aria-label="main mailbox foldersfinished-jobs"
              title="List of Train Jobs"
            >
              {files.map((file, index) => (
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemText
                    primary={file.Key.replace(
                      pathPrefix + "/responses/" + serviceName + "/",
                      ""
                    )}
                  />
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <TableContainer component={Paper}>
            <TableBody>
              <TableRow>
                <TableCell>Out S3 File Name</TableCell>
                <TableCell>
                  {apiResponse.out_location?.substring(
                    apiResponse.out_location?.lastIndexOf("/") + 1
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Out Log ID</TableCell>
                <TableCell>
                  {apiResponse.out_log_id ? apiResponse.out_log_id : "N/A"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Out LithoLens Generic Model ID</TableCell>
                <TableCell>
                  {apiResponse.out_generic_model_id
                    ? apiResponse.out_generic_model_id
                    : "N/A"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Warnings</TableCell>
                <TableCell>
                  {apiResponse.warnings
                    ? apiResponse.warnings.length > 0
                      ? apiResponse.warnings
                      : "N/A"
                    : "N/A"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Error</TableCell>
                <TableCell>
                  {apiResponse.error ? apiResponse.error : "N/A"}
                </TableCell>
              </TableRow>
            </TableBody>
          </TableContainer> */}
        </Grid>
      </Grid>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={activeTab}
        onChange={handleChange}
        aria-label="file tabs"
        sx={{
          backgroundColor: "white", // Light teal background for the whole tabs bar
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Adding a subtle shadow under the tabs bar
          "& .MuiTabs-flexContainer": {
            gap: "10px", // Adds space between each tab/button
          },
        }}
      >
        <Tab
          key="metrics"
          label="Metrics Table"
          id="metrics"
          aria-controls={`tabpanel-metrics`}
        />
        <Tab
          key="residuals"
          label="Residuals Plot"
          id="residuals"
          aria-controls={`tabpanel-residuals`}
        />
        <Tab
          key="pred-error"
          label="Predictions Error Plot"
          id="pred-error"
          aria-controls={`tabpanel-pred-error`}
        />
      </Tabs>
      {isLoading ? (
        <div style={{ textAlign: "center", margin: "15px" }}>
          <CircularProgress />
        </div>
      ) : apiResponse?.status === "requested" ? (
        <div>
          <Alert severity="warning">Job in progress</Alert>
        </div>
      ) : apiResponse?.status === "failed" ? (
        <div>
          <Alert severity="error">Job failed!</Alert>
        </div>
      ) : (
        <div>
          <div
            hidden={activeTab !== 0}
            id="roc-curve"
            style={{ flex: "auto", textAlign: "center", margin: "20px" }}
          >
            <MetricsTable metrics={apiResponse?.metrics} />
          </div>
          <div
            hidden={activeTab !== 1}
            id="roc-curve"
            style={{ flex: "auto", textAlign: "center", margin: "20px" }}
          >
            <ResidualsPlot data={apiResponse.visualization_plots?.residuals} />
          </div>
          <div
            hidden={activeTab !== 2}
            id="pred-error"
            style={{ flex: "auto", textAlign: "center", margin: "20px" }}
          >
            <PredictionErrorPlot
              data={apiResponse.visualization_plots?.prediction_error_plot}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default RegressionResponseViewer;
