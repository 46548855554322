import React, { useEffect, useState } from "react";
import { getFilesWithMetaData, getModels } from "../talimUtils";
import TrainFileSelection from "./trainFileSelection";
import TrainFileTabs from "./trainFileTabs";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFiles } from "./talimTrainSlice";
import { Button } from "@mui/material";
import TrainRequest from "./trainRequest";
import TrainResponseViewer from "./trainResponseViewer";

interface TrainPageProps {
  bucketName: string;
  pathPrefix: string;
}

const TrainPage: React.FC<TrainPageProps> = ({ bucketName, pathPrefix }) => {
  const talim_inference = useAppSelector(
    (state) => state.talim.talimInferenceSlice
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadFiles = async () => {
      const fileWithJsonData = await getFilesWithMetaData(
        bucketName,
        pathPrefix
      );
      dispatch(setFiles({ files: fileWithJsonData }));
    };
    loadFiles();
  }, [pathPrefix]);

  const [viewJobs, setViewJobs] = React.useState(false);

  const handleClick = () => {
    setViewJobs(!viewJobs);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px",
          textAlign: "center",
        }}
      >
        <Button variant="outlined" onClick={handleClick}>
          {viewJobs ? "Request a New Job" : "View Completed Jobs"}
        </Button>
      </div>
      <div key={"main"} hidden={viewJobs}>
        <TrainFileSelection bucketName={bucketName} pathPrefix={pathPrefix} />
        <TrainFileTabs bucketName={bucketName} />
        <TrainRequest bucketName={bucketName} pathPrefix={pathPrefix} />
      </div>
      <div key={"dialog"} hidden={!viewJobs}>
        <TrainResponseViewer
          refresh=""
          bucketName={bucketName}
          pathPrefix={pathPrefix}
          // serviceName="train"
        ></TrainResponseViewer>
      </div>
    </div>
  );
};

export default TrainPage;
