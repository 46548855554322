import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { setUserProjects, setCompany } from "./projectsSlice";
import { ProjectItem, useGetProjectsQuery } from "../api/endpoints/projectsApi";

const ProjectsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const projects = useAppSelector((state) => state.projects);
  const [open, setOpen] = useState(false);
  const [companyDialogOpen, setCompanyDialogOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [projectUnit, setProjectUnit] = useState("");
  const [companyName, setCompanyName] = useState(projects.company || "");

  const {
    data: projectsData,
    error: projectsError,
    isLoading,
    isFetching,
    refetch,
  } = useGetProjectsQuery({ companyName: projects.company || "" });

  useEffect(() => {
    if (projectsData) {
      projectsData.items.forEach((projectItem) => {
        dispatch(
          setUserProjects({
            project: projectItem.id,
            projectItem: projectItem,
          })
        );
      });
    }
  }, [projectsData, dispatch]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateProject = () => {
    // Integration for project creation logic
    console.log({ projectName, description, projectUnit });
    handleClose();
  };

  const handleSetProjectState = (project: ProjectItem) => {
    // Integration for setting project state
    // dispatch(setselectedId({ key: project.id }));
  };

  const handleCompanyDialogOpen = () => {
    setCompanyDialogOpen(true);
  };

  const handleCompanyDialogClose = () => {
    setCompanyDialogOpen(false);
  };

  const handleSetCompany = () => {
    dispatch(setCompany({ company: companyName }));
    handleCompanyDialogClose();
    if (companyName) {
      refetch();
    }
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
        sx={{ m: 3 }}
      >
        Create New Project
      </Button>
      <Button
        variant="contained"
        onClick={handleCompanyDialogOpen}
        sx={{ m: 3 }}
      >
        Set Company
      </Button>
      {!projects.company ? (
        <Typography variant="h6" sx={{ m: 3 }}>
          Please set the company to fetch projects.
        </Typography>
      ) : (
        <>
          {isLoading || isFetching ? (
            <CircularProgress />
          ) : projectsError ? (
            <p>No projects found for the provided company</p>
          ) : (
            projectsData && (
              <Grid container spacing={2} sx={{ padding: 3 }}>
                {projectsData.items.map((item) => (
                  <Grid
                    item
                    key={item.id}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    onClick={() => handleSetProjectState(item)}
                  >
                    <Link to={`${item.id}`} style={{ textDecoration: "none" }}>
                      <Card
                        sx={{
                          height: "150px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          backgroundColor: "#f4f6f8",
                          ":hover": {
                            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                          },
                        }}
                      >
                        <CardContent>
                          <Typography variant="h5" component="h2" color="black">
                            {item.title}
                          </Typography>
                          <Typography color="textSecondary">
                            Click to view services
                          </Typography>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            )
          )}
        </>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Project Name"
            type="text"
            fullWidth
            variant="standard"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            variant="standard"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="project-unit-label">Unit</InputLabel>
            <Select
              labelId="project-unit-label"
              id="project-unit"
              value={projectUnit}
              label="Unit"
              onChange={(e) => setProjectUnit(e.target.value)}
            >
              <MenuItem value="meter">Meter</MenuItem>
              <MenuItem value="foot">Foot</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCreateProject}>Create</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={companyDialogOpen} onClose={handleCompanyDialogClose}>
        <DialogTitle>Set Company</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="company"
            label="Company"
            type="text"
            fullWidth
            variant="standard"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCompanyDialogClose}>Cancel</Button>
          <Button onClick={handleSetCompany}>Set</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectsPage;
