import React, { useEffect, useState } from "react";
import { Box, Button, Grid, CircularProgress, Typography } from "@mui/material";
import { useForm, Controller, useWatch, FormProvider } from "react-hook-form";
import DrillholesSelector from "../../../projects/drillholes_and_images/drillholesSelector";
import ImageSelector from "../../../projects/drillholes_and_images/imagesSelector";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getFileInfo } from "../../../../components/S3/S3Utils";
import {
  TextInput,
  SelectInput,
  AccordionSection,
  BooleanSwitchInput,
} from "./../formComponents";
import { createJobItem } from "../../../../components/Jobs/jobUtils";
import {
  FileItem,
  useGetFilesListQuery,
} from "../../../api/endpoints/filesApi";
import {
  filterNullProperties,
  useRunServiceMutation,
} from "../../../api/endpoints/superApi";
import { CLUSTERING_MODELS_FOLDER_PATH, ENVIRONMENT } from "../../../../config";
import {
  setSelectedDrillholes,
  setSelectedImages,
} from "../../../projects/projectsSlice";
import JobsInfo from "../../../../components/Jobs/jobsInfo";

interface FormValues {
  project_name: string;
  data_category?: string;
  hs_wavelength?: string;
  clustering_method?: string;
  num_clusters?: number;
}

const defaultValues: FormValues = {
  project_name: "",
  data_category: "core",
  hs_wavelength: "",
  clustering_method: "GaussianMixture",
  num_clusters: 4,
};

const ColorClusteringPage: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);
  const methods = useForm<FormValues>({ defaultValues });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = methods;
  const service = "color_clustering";
  const dispatch = useAppDispatch();
  const dataCategory = useWatch({ control, name: "data_category" });
  const [submittedData, setSubmittedData] = useState<{} | null>(null);

  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  useEffect(() => {
    if (projects.selectedProject) {
      setValue("project_name", projects.selectedProject.id);
    }
  }, [setValue, projects.selectedProject]);

  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = `logs.${service}`;
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);

  const onSubmit = (data: FormValues) => {
    const formData = {
      project_name: data.project_name,
      data_category: data.data_category,
      hs_wavelength: data.hs_wavelength ? data.hs_wavelength : null,
      clustering_method: [data.clustering_method],
      num_clusters: data.num_clusters,
    };
    setSubmittedData(formData);
    const request_body = filterNullProperties(formData);
    runServiceMutation({
      service: "ll_run_color_clustering",
      request_body: request_body,
      environment: ENVIRONMENT,
      job_item: jobItem,
    });
  };

  return (
    projects.selectedProject && (
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", margin: "20px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectInput
                name="clustering_method"
                control={control}
                label="Clustering Method"
                options={[
                  { value: "GaussianMixture", label: "GaussianMixture" },
                  { value: "KMeans", label: "KMeans" },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="num_clusters"
                control={control}
                label="Number of Clusters"
                type="number"
                helpText="The number of color clusters"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                name="data_category"
                control={control}
                label="Data Category"
                options={[
                  { value: "core", label: "core" },
                  { value: "atv", label: "atv" },
                  { value: "otv", label: "otv" },
                  { value: "wet", label: "wet" },
                  { value: "dry", label: "dry" },
                  { value: "hs", label: "hs" },
                ]}
              />
            </Grid>
            {dataCategory === "hs" && (
              <Grid item xs={12}>
                <TextInput
                  name="hs_wavelength"
                  control={control}
                  label="HS Wavelength"
                  type="number"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                // disabled={!isValid}
              >
                Submit
              </Button>
            </Grid>
            {submittedData && (
              <Grid item xs={12}>
                <Box mt={2}>
                  <Typography variant="h6">Payload:</Typography>
                  <pre>{JSON.stringify(submittedData, null, 2)}</pre>
                  <div>
                    {runServiceMutationResult.isLoading && (
                      <p>Sending Request ...</p>
                    )}
                    {runServiceMutationResult.data ? (
                      <div>
                        {/* Render the data here */}
                        <p>
                          Data: {JSON.stringify(runServiceMutationResult.data)}
                        </p>
                      </div>
                    ) : (
                      <p>No Response Available</p>
                    )}
                  </div>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <JobsInfo jobType={"litholens.run_color_clustering"} />
      </FormProvider>
    )
  );
};

export default ColorClusteringPage;
