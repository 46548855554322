import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import ImageItem from "./ImageItem";

interface ImageGridProps {
  images: {
    key: string;
    url: string;
    isCleaned: boolean;
  }[];
  loading: boolean;
  error: string | null;
  onImageClick: (imageKey: string) => void;
}

const ImageGrid: React.FC<ImageGridProps> = ({
  images,
  loading,
  error,
  onImageClick,
}) => {
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" mt={2}>
        {error}
      </Typography>
    );
  }

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center" gap={2} mt={2}>
      {images.map((image) => (
        <ImageItem key={image.key} image={image} onClick={onImageClick} />
      ))}
    </Box>
  );
};

export default ImageGrid;
