import React from 'react';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import './ChatComponent.css';
import config from './config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
// https://fredrikoseberg.github.io/react-chatbot-kit-docs/

const ChatComponent = () => {
    return (
        <div>
            <Chatbot
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
                validator={(input: string) => input.trim() !== ''}
            />
        </div>
    );
};

export default ChatComponent;
