import React, { useEffect, useState } from "react";
import {
  Checkbox,
  TextField,
  Autocomplete,
  FormControl,
  Grid,
} from "@mui/material";
import {
  S3File,
  getFileInfo,
  getTableDataFromCsv,
  readJsonFromS3,
} from "../../../../../components/S3/S3Utils";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  setFilesInfo,
  setModelsInfo,
  setSelectedReferenceFile,
  setSelectedReferenceModel,
} from "./talimInferenceSlice";

interface InferenceFileSelectionProps {
  bucketName: string;
  pathPrefix: string;
}

const InferenceFileSelection: React.FC<InferenceFileSelectionProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const talim_inference = useAppSelector(
    (state) => state.talim.talimInferenceSlice
  );
  const dispatch = useAppDispatch();
  const allFileKeys = [talim_inference.selectedReferenceFile];

  const allModelKeys = [talim_inference.selectedReferenceModel];

  useEffect(() => {
    allFileKeys.forEach(async (fileKey) => {
      if (fileKey && !talim_inference.filesInfo[fileKey]) {
        // console.log(fileKey);
        const metaDataKey = fileKey
          .replace("data", "metaData")
          .replace(".csv", ".json");
        try {
          const metaData = await readJsonFromS3(bucketName, metaDataKey);
          const { columns, data } = await getTableDataFromCsv(
            bucketName,
            fileKey
          );
          dispatch(
            setFilesInfo({
              key: fileKey,
              info: {
                fileKey: fileKey,
                metaData: metaData,
                tableData: { columns: columns, data: data },
                scatter_plots: [],
                crosstab_plots: [],
                binning_plots: [],
              },
            })
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    });
  }, [talim_inference.selectedReferenceFile, bucketName]);

  useEffect(() => {
    allModelKeys.forEach(async (fileKey) => {
      if (fileKey && !talim_inference.modelsInfo[fileKey]) {
        try {
          // const { columns, data } = await readJsonFromS3(
          //   bucketName,
          //   fileKey
          // );
          dispatch(
            setModelsInfo({
              key: fileKey,
              info: {
                source: fileKey,
              },
            })
          );
        } catch (error) {
          console.error("Error fetching model:", error);
        }
      }
    });
  }, [talim_inference.selectedReferenceModel, bucketName]);

  const handleReferenceFileChange = (
    event: React.ChangeEvent<{}>,
    value: S3File | null
  ) => {
    const newReferenceFileKey = value ? value.Key : "";

    dispatch(setSelectedReferenceFile({ key: newReferenceFileKey }));
  };

  const handleReferenceModelChange = (
    event: React.ChangeEvent<{}>,
    value: S3File | null
  ) => {
    const newReferenceFileKey = value ? value.Key : "";

    dispatch(setSelectedReferenceModel({ key: newReferenceFileKey }));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              options={talim_inference.files}
              getOptionLabel={(option) => getFileInfo(option.Key).baseName}
              value={
                talim_inference.files.find(
                  (file) => file.Key === talim_inference.selectedReferenceFile
                ) || null
              }
              onChange={handleReferenceFileChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Reference File"
                  variant="outlined"
                />
              )}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              options={talim_inference.models}
              getOptionLabel={(option) => getFileInfo(option.Key).baseName}
              value={
                talim_inference.models.find(
                  (file) => file.Key === talim_inference.selectedReferenceModel
                ) || null
              }
              onChange={handleReferenceModelChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Reference Model"
                  variant="outlined"
                />
              )}
              fullWidth
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};
export default InferenceFileSelection;
