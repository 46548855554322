import React, { ReactNode, ReactElement, cloneElement, Children } from 'react';

interface MessageParserProps {
    children: ReactNode;
    actions: any; // Adjust the type according to your needs
}

interface ChildProps {
    parse?: (message: string) => void;
    actions?: any; // Adjust the type according to your needs
}

const MessageParser: React.FC<MessageParserProps> = ({ children, actions }) => {
    const parse = (message: string) => {
        console.log(message);
        if (message.includes('hello')) {
            actions.handleHello();
        }
        else {
            actions.handleChat(message)
        }
    };

    return (
        <div>
            {Children.map(children, (child) => {
                if (React.isValidElement<ChildProps>(child)) {
                    return cloneElement(child, {
                        parse: parse,
                        actions: actions,
                    });
                }
                return child;
            })}
        </div>
    );
};

export default MessageParser;
