import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../redux/store";
import { S3File } from "../../../../../components/S3/S3Utils";
import { WritableDraft } from "immer";

export interface TableData {
  columns: string[];
  data: any[];
}

export interface FileInfo {
  fileKey: string;
  metaData: any;
  tableData: TableData;
  standard_scaling: string[];
  clr_transformation: string[];
  binning: string[];
  ignore: string[];
}

export interface talimFengState {
  files: S3File[];
  filesInfo: { [key: string]: FileInfo };
  selectedReferenceFile: string;
  task_type: string;
  target_feature: string;
  num_bins: number;
  service_sort_by: string;
  polynomial_degree: number | null;
  low_variance_threshold: number | null;
  outliers_method: string | null;
  outliers_threshold: number | null;
  transformation_method: string | null;
  normalize_method: string | null;
  feature_selection_method: string | null;
  n_features_to_select: number | null;
  register_to_litholens: boolean;
  register_to_logsapi: boolean;
  descriptive_log_name: string;
  notification_email: string | null;
  structures_to_process: [] | null;
  drillholes_to_process: [] | null;
  s3_data_save_name: string | null;
  litholens_save_path_type: "s3" | "logsAPI" | null;
}

const initialState: talimFengState = {
  files: [],
  filesInfo: {},
  selectedReferenceFile: "",
  task_type: "classification",
  target_feature: "",
  num_bins: 10,
  service_sort_by: "",
  polynomial_degree: 1,
  low_variance_threshold: 0,
  outliers_method: "",
  outliers_threshold: 0,
  transformation_method: "",
  normalize_method: "",
  feature_selection_method: "",
  n_features_to_select: 0,
  register_to_litholens: false,
  register_to_logsapi: false,
  descriptive_log_name: "new_log",
  notification_email: "",
  structures_to_process: null,
  drillholes_to_process: null,
  s3_data_save_name: null,
  litholens_save_path_type: "s3",
};

type TalimFengStateKey = keyof talimFengState;

export const talimFengSlice = createSlice({
  name: "talim_feng",
  initialState: initialState,
  reducers: {
    // -------------------------------------------------------------------------------
    // General setter for updating any state attribute
    setState: <T extends TalimFengStateKey>(
      state: WritableDraft<talimFengState>,
      action: PayloadAction<{ key: T; value: talimFengState[T] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    // -------------------------------------------------------------------------------
    setSelectedReferenceFile: (
      state,
      action: PayloadAction<{ key: string }>
    ) => {
      state.selectedReferenceFile = action.payload.key;
    },
    // -------------------------------------------------------------------------------
    setFiles: (state, action: PayloadAction<{ files: S3File[] }>) => {
      state.files = action.payload.files;
    },
    // -------------------------------------------------------------------------------
    setFilesInfo: (
      state,
      action: PayloadAction<{ key: string; info: FileInfo }>
    ) => {
      state.filesInfo[action.payload.key] = action.payload.info;
    },
    // -------------------------------------------------------------------------------
  },
});

export const selectTalim = (state: RootState) => state.talim.talimFengSlice;

// Action creators are generated for each case reducer function
export const { setState, setSelectedReferenceFile, setFiles, setFilesInfo } =
  talimFengSlice.actions;

export default talimFengSlice.reducer;
