import React, { useEffect, useState } from "react";
import { Tab, Tabs, Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getFileInfo } from "../../../../../components/S3/S3Utils";
import CustomTable from "../../../../../components/DataDisplay/DisplayTabularData";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import ColumnActions from "./ColumnActionsNew";

interface FileTabProps {
  bucketName: string;
}

const CleaningFileTabs: React.FC<FileTabProps> = ({ bucketName }) => {
  const talim_cleaning = useAppSelector(
    (state) => state.talim.talimCleaningSlice
  );
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const allFileKeys = [talim_cleaning.selectedReferenceFile];

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      {talim_cleaning.filesInfo[talim_cleaning.selectedReferenceFile] && (
        <CustomTable
          tableHeaders={
            talim_cleaning.filesInfo[talim_cleaning.selectedReferenceFile]
              .tableData.columns
          }
          tableData={
            talim_cleaning.filesInfo[talim_cleaning.selectedReferenceFile]
              .tableData.data
          }
        />
      )}
      {talim_cleaning.filesInfo[talim_cleaning.selectedReferenceFile] &&
        talim_cleaning.filesInfo[talim_cleaning.selectedReferenceFile].metaData
          ?.missing_values && (
          <CustomTable
            tableHeaders={[
              "Measurement", // Correct casing for consistency
              ...Object.keys(
                talim_cleaning.filesInfo[talim_cleaning.selectedReferenceFile]
                  .metaData.missing_values
              ),
            ]}
            tableData={["count", "missing", "missing_percent"].map(
              (measurement) => {
                const row: { [key: string]: any } = {
                  Measurement: measurement,
                };
                Object.keys(
                  talim_cleaning.filesInfo[talim_cleaning.selectedReferenceFile]
                    .metaData.missing_values
                ).forEach((key) => {
                  row[key] =
                    measurement == "missing_percent"
                      ? talim_cleaning.filesInfo[
                          talim_cleaning.selectedReferenceFile
                        ].metaData.missing_values[key][measurement].toFixed(1)
                      : talim_cleaning.filesInfo[
                          talim_cleaning.selectedReferenceFile
                        ].metaData.missing_values[key][measurement];
                });
                return row;
              }
            )}
          />
        )}
      {talim_cleaning.filesInfo[talim_cleaning.selectedReferenceFile] && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-strategies-content"
            id="panel-strategies-header"
          >
            <Typography>Specify/Edit Column Cleaning Actions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ColumnActions fileKey={talim_cleaning.selectedReferenceFile} />
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default CleaningFileTabs;
