import { createChatBotMessage } from 'react-chatbot-kit';

type ChatBotMessage = ReturnType<typeof createChatBotMessage>;

interface Config {
    initialMessages: ChatBotMessage[];
}

const config: Config = {
    initialMessages: [createChatBotMessage('Hello! How can SuperAPI help you today?', {})],
};

export default config;
