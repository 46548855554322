import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { Tabs, Tab } from "@mui/material";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
// import S3FileUploader from "../../../../components/S3/S3FileUploader";
// import S3ModelUploader from "../../../../components/S3/S3ModelUploader";
// import S3CsvViewer from "./S3CsvViewer";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProjectsSelector from "../../../../projects/projectSelector";
import { useGetProjectsQuery } from "../../../../api/endpoints/projectsApi";
import {
  CLUSTERING_MODELS_FOLDER_PATH,
  ENVIRONMENT,
} from "../../../../../config";
import config from "../../../../../config";
import TSMPage from "./tsmPage";
import S3DatasetUploader from "./S3DatasetUploader";
import TSMValidate from "./tsmValidate";

const steps: string[] = ["Upload Datasets", "Validate Datasets", "TSM"];

const TSMSteps: React.FC = () => {
  const talim = useAppSelector((state) => state.talim.talimSlice);
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useAppSelector((state) => state.auth);
  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";

  const [activeStep, setActiveStep] = React.useState<number>(
    parseInt(new URLSearchParams(location.search).get("step")!) || 0
  );

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const user = useAppSelector((state) =>
    state.auth.isAuthenticated ? state.auth.user?.firstName : "guest@"
  );

  // const bucketName = "superapi-dashboard";
  const bucketName = config[ENVIRONMENT].tsm_s3_bucket;
  // const pathPrefix = "talim";
  const userFolder = auth.user?.username
    ? auth.user?.username.lastIndexOf("@") > -1
      ? auth.user?.username.substring(0, auth.user?.username.lastIndexOf("@"))
      : auth.user?.username
    : "guest";
  const [pathPrefix, setPathPrefix] = React.useState(
    // "general/" + user?.substring(0, user.indexOf("@")) + "/talim"
    "general/" + userFolder + "/tsm"
  );

  console.log(pathPrefix);

  const handleStep = (step: number) => () => {
    setActiveStep(step);
    navigate(`?step=${step}`);
  };

  React.useEffect(() => {
    const userFolder = auth.user?.username
      ? auth.user?.username.lastIndexOf("@") > -1
        ? auth.user?.username.substring(0, auth.user?.username.lastIndexOf("@"))
        : auth.user?.username
      : "guest";
    setPathPrefix(
      projects.selectedProject
        ? company + "/" + project + "/tsm"
        : "general/" + userFolder + "/tsm"
      // : "general/" + user?.substring(0, user.indexOf("@")) + "/talim"
    );
    console.log(pathPrefix);
  }, [projects.selectedProject]);

  return (
    <div>
      <ProjectsSelector />
      <Box>
        <div
          style={{
            border: "1px solid #ccc",
            padding: "20px",
            borderRadius: "4px",
          }}
        >
          <Stepper
            nonLinear
            activeStep={activeStep}
            orientation={matches ? "vertical" : "horizontal"}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <div>
          <React.Fragment>
            {activeStep === 0 && (
              <div>
                <S3DatasetUploader
                  bucketName={bucketName}
                  pathPrefix={pathPrefix + "/datasets"}
                  // allowedExtensions={["csv"]}
                />
              </div>
            )}
            {activeStep === 1 && (
              <TSMValidate bucketName={bucketName} pathPrefix={pathPrefix} />
            )}
            {activeStep === 2 && (
              <TSMPage bucketName={bucketName} pathPrefix={pathPrefix} />
            )}
          </React.Fragment>
        </div>
      </Box>
    </div>
  );
};

export default TSMSteps;
