import React, { useState } from "react";
import { createSegmentationDatasetFolder } from "../../../../../components/S3/S3Utils";
import { TextField, Button, Box, Typography } from "@mui/material";

interface S3DatasetCreaterProps {
  bucketName: string;
  pathPrefix: string;
  onCreateSuccess?: () => void; // Optional callback after folder creation
}

const S3DatasetCreater: React.FC<S3DatasetCreaterProps> = ({
  bucketName,
  pathPrefix,
  onCreateSuccess,
}) => {
  const [folderName, setFolderName] = useState<string>(""); // Folder name input state
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleCreateDataset = async () => {
    if (!folderName.trim()) {
      setError("Folder name cannot be empty.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await createSegmentationDatasetFolder(bucketName, pathPrefix, folderName);
      setFolderName(""); // Clear the input after successful creation
      if (onCreateSuccess) {
        onCreateSuccess(); // Call the optional success callback
      }
    } catch (e) {
      setError((e as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 500,
        mx: "auto",
        p: 2,
        bgcolor: "background.paper",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h6" align="center">
        Create a New Folder
      </Typography>

      <TextField
        label="Folder Name"
        variant="outlined"
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
        error={!!error}
        helperText={error || "Enter the name of the folder to create."}
        fullWidth
        disabled={loading}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateDataset}
        disabled={loading || !folderName.trim()} // Disable button if loading or folderName is empty
      >
        {loading ? "Creating..." : "Create Folder"}
      </Button>
    </Box>
  );
};

export default S3DatasetCreater;
