import config, { ENVIRONMENT } from "../../../config";
import { coreApiSlice } from "../coreApiSlice";

export interface FileItem {
  path: string;
  type: string;
  sizeBytes: number;
  updatedAt: string;
}

export interface FilesResponse {
  message: string;
  items: FileItem[];
}

export interface FilesRequest {
  companyName: string;
  projectName: string;
  folder: string;
}

const filesApiUrl = config[ENVIRONMENT].filesApiUrl;

export const extendedCoreApiSlice = coreApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // -------------------------------------------------------------------------------------------
    getFilesList: builder.query<FilesResponse, FilesRequest>({
      query: (data) =>
        `${filesApiUrl}/${data.companyName}/${data.projectName}/${data.folder}/?limit=100`,
    }),
    // -------------------------------------------------------------------------------------------
  }),
});

export const { useGetFilesListQuery } = extendedCoreApiSlice;
