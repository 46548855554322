import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../redux/store";
import { S3File } from "../../../../../components/S3/S3Utils";
import { WritableDraft } from "immer";

export interface TableData {
  columns: string[];
  data: any[];
}

export interface FileInfo {
  fileKey: string;
  metaData: any;
  tableData: TableData;
  scatter_plots: string[];
  crosstab_plots: string[];
  binning_plots: string[];
}

export interface ModelInfo {
  source: string;
}

export interface talimInferenceState {
  files: S3File[];
  models: S3File[];
  filesInfo: { [key: string]: FileInfo };
  modelsInfo: { [key: string]: ModelInfo };
  selectedReferenceFile: string;
  selectedReferenceModel: string;
  task_type: string;
  // register_to_litholens: boolean;
  // register_to_logsapi: boolean;
  // descriptive_log_name: string;
  // notification_email: string | null;
  // structures_to_process: [] | null;
  // drillholes_to_process: [] | null;
  s3_save_name: string | null;
  // litholens_save_path_type: "s3" | "logsAPI" | null;
}

const initialState: talimInferenceState = {
  files: [],
  models: [],
  filesInfo: {},
  modelsInfo: {},
  selectedReferenceFile: "",
  selectedReferenceModel: "",
  task_type: "classification",
  s3_save_name: null,
  // litholens_save_path_type: "s3",
};

type TalimInferenceStateKey = keyof talimInferenceState;

export const talimInferenceSlice = createSlice({
  name: "talim_feng",
  initialState: initialState,
  reducers: {
    // -------------------------------------------------------------------------------
    // General setter for updating any state attribute
    setState: <T extends TalimInferenceStateKey>(
      state: WritableDraft<talimInferenceState>,
      action: PayloadAction<{ key: T; value: talimInferenceState[T] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    // -------------------------------------------------------------------------------
    setSelectedReferenceFile: (
      state,
      action: PayloadAction<{ key: string }>
    ) => {
      state.selectedReferenceFile = action.payload.key;
    },
    // -------------------------------------------------------------------------------
    setFiles: (state, action: PayloadAction<{ files: S3File[] }>) => {
      state.files = action.payload.files;
    },
    // -------------------------------------------------------------------------------
    setFilesInfo: (
      state,
      action: PayloadAction<{ key: string; info: FileInfo }>
    ) => {
      state.filesInfo[action.payload.key] = action.payload.info;
    },
    // -------------------------------------------------------------------------------
    setSelectedReferenceModel: (
      state,
      action: PayloadAction<{ key: string }>
    ) => {
      state.selectedReferenceModel = action.payload.key;
    },
    // -------------------------------------------------------------------------------
    setModels: (state, action: PayloadAction<{ models: S3File[] }>) => {
      state.models = action.payload.models;
    },
    // -------------------------------------------------------------------------------
    setModelsInfo: (
      state,
      action: PayloadAction<{ key: string; info: ModelInfo }>
    ) => {
      state.modelsInfo[action.payload.key] = action.payload.info;
    },
    // -------------------------------------------------------------------------------
  },
});

export const selectTalim = (state: RootState) =>
  state.talim.talimInferenceSlice;

// Action creators are generated for each case reducer function
export const {
  setState,
  setSelectedReferenceFile,
  setFiles,
  setFilesInfo,
  setSelectedReferenceModel,
  setModels,
  setModelsInfo,
} = talimInferenceSlice.actions;

export default talimInferenceSlice.reducer;
