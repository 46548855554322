import React from "react";
import { Box, Badge, Typography } from "@mui/material";
import { parseImageKey } from "./imageUtils";

interface ImageItemProps {
  image: {
    key: string;
    url: string;
    isCleaned: boolean;
  };
  onClick: (imageKey: string) => void;
}

const ImageItem: React.FC<ImageItemProps> = ({ image, onClick }) => {
  const imageInfo = parseImageKey(image.key);
  return (
    <Badge
      badgeContent="Cleaned"
      color="success"
      invisible={!image.isCleaned}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box
        sx={{
          width: 250,
          height: 250,
          cursor: "pointer",
          m: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
          bgcolor: "background.paper",
          boxShadow: 3,
          transition: "transform 0.3s, box-shadow 0.3s",
          "&:hover": {
            transform: "scale(1.05)",
            boxShadow: 6,
          },
        }}
        onClick={() => onClick(image.key)}
      >
        <img
          src={image.url}
          alt={image.key}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
          loading="lazy"
        />
        <Box
          sx={{
            p: 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography variant="body2" noWrap>
            {image.key}
          </Typography>
          <Typography variant="body2" noWrap>
            {`(${imageInfo.from} to ${imageInfo.to})`}
          </Typography>
        </Box>
      </Box>
    </Badge>
  );
};

export default ImageItem;
