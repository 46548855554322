import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/store";
import { S3File } from "../../../../components/S3/S3Utils";
import { WritableDraft } from "immer";

export interface TableData {
  columns: string[];
  data: any[];
}

export interface FileInfo {
  fileKey: string;
  metaData: any;
  tableData: TableData;
  columnStrategies: any;
}

export interface talimState {
  files: S3File[];
  filesInfo: { [key: string]: FileInfo }; // accumulates loaded data for all files
  // selectedProject: string | null;
}

// const selectedProject = localStorage.getItem("current_project");

const initialState: talimState = {
  files: [],
  filesInfo: {},
  // selectedProject: selectedProject ? selectedProject : null,
};

type TalimStateKey = keyof talimState;

export const talimSlice = createSlice({
  name: "talim_merge",
  initialState: initialState,
  reducers: {
    // -------------------------------------------------------------------------------
    // General setter for updating any state attribute
    setState: <T extends TalimStateKey>(
      state: WritableDraft<talimState>,
      action: PayloadAction<{ key: T; value: talimState[T] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    // -------------------------------------------------------------------------------
    setFiles: (state, action: PayloadAction<{ files: S3File[] }>) => {
      state.files = action.payload.files;
    },
    // -------------------------------------------------------------------------------
    setFilesInfo: (
      state,
      action: PayloadAction<{ key: string; info: FileInfo }>
    ) => {
      state.filesInfo[action.payload.key] = action.payload.info;
    },
    // -------------------------------------------------------------------------------
    // setCurrentProject: (state, action: PayloadAction<{ project: string }>) => {
    //   state.selectedProject = action.payload.project;
    //   localStorage.setItem("current_project", action.payload.project);
    // },
  },
});

export const selectTalim = (state: RootState) => state.talim.talimSlice;

// Action creators are generated for each case reducer function
export const { setState, setFiles, setFilesInfo } =
  talimSlice.actions;

export default talimSlice.reducer;
