import React, { useEffect } from "react";
import { Alert, AlertTitle } from "@mui/material";
import Plot from "react-plotly.js";

interface Metadata {
  selectedType: string;
  occurrences?: { [key: string]: number };
}

interface Props {
  metadata: Metadata;
}

const MinZonesPlot: React.FC<Props> = ({ metadata }) => {
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [plotData, setPlotData] = React.useState<any[]>([]);

  useEffect(() => {
    if (metadata.selectedType !== "minzone") {
      setAlertMessage("The selected file is not a mineral zones log.");
      setPlotData([]);
    } else if (!metadata.occurrences) {
      setAlertMessage(
        "The occurrences are not available." + JSON.stringify(metadata)
      );
      setPlotData([]);
    } else {
      setAlertMessage(null);
      const labels = Object.keys(metadata.occurrences);
      const values = Object.values(metadata.occurrences);
      setPlotData([
        {
          type: "pie",
          labels: labels,
          values: values,
        },
      ]);
    }
  }, [metadata]);

  return (
    <div>
      {alertMessage && (
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          {alertMessage}
        </Alert>
      )}
      {plotData.length > 0 && (
        <Plot
          data={plotData}
          layout={{ title: "Occurrences Pie Chart", height: 400, width: 500 }}
        />
      )}
    </div>
  );
};

export default MinZonesPlot;
