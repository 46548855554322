import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  RunServiceRequest,
  useRunServiceMutation,
} from "../../../../api/endpoints/superApi";
import {
  Button,
  Alert,
  Collapse,
  TextField,
  MenuItem,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";

import TSMConfigParams from "./tsmConfigParams";
import {
  JobItem,
  createJobItem,
} from "../../../../../components/Jobs/jobUtils";
import {
  fetchFolders,
  writeJsonToS3,
} from "../../../../../components/S3/S3Utils";
import { setState } from "./tsmSlice";

// --------------------------------------------------------------------------------------------------
interface ApiRequest {
  environment: string;
  // job_item: JobItem;
  dataset_path: string;
  color_dict: { [key: string]: string };
  response_path: string;
}

// --------------------------------------------------------------------------------------------------
interface TSMValidateProps {
  bucketName: string;
  pathPrefix: string;
}

// --------------------------------------------------------------------------------------------------
const matplotlibColors = [
  "red",
  "green",
  "blue",
  "yellow",
  "purple",
  "orange",
  "brown",
  "cyan",
  "magenta",
  "lightblue",
  "navy",
  "pink",
  "gold",
];

// --------------------------------------------------------------------------------------------------
const TSMValidate: React.FC<TSMValidateProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const tsm = useAppSelector((state) => state.tsm.TSMSlice);
  const dispatch = useAppDispatch();

  // --------------------------------------------------------------------------------------------------
  // Job Item & API Request

  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);

  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = "logs.tsm_validate";
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);
  const [lastJobID, setLastJobID] = useState<string | null>(null);
  const env = process.env.REACT_APP_ENV || "test";

  const [colorDict, setColorDIct] = useState<{ [key: string]: string }>({});

  const [folders, setFolders] = useState<string[]>([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      loadFolders();
    }, 1000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const loadFolders = async () => {
    try {
      const fetchedFolders = await fetchFolders(
        bucketName,
        pathPrefix + "/datasets"
      );

      // Extract folder names from the fetched folder keys
      const folderNames = fetchedFolders.map((folder) => {
        const folderKey = folder.Key.replace(pathPrefix + "/datasets", ""); // Remove the path prefix
        return folderKey.replace(/\/$/, ""); // Remove trailing slash from folder name
      });

      setFolders(folderNames); // Update state with folder names
    } catch (error) {
      console.error("Failed to fetch folders:", error);
    }
  };

  const handleAddItem = () => {
    // Add a new item with a default key and value
    const firstAvailableColor = matplotlibColors.filter(
      (element) => !Object.keys(colorDict).includes(element)
    )[0];
    setColorDIct((prevData) => ({
      ...prevData,
      [firstAvailableColor]: "",
    }));
  };

  const handleDeleteItem = (key: string) => {
    const newData = { ...colorDict };
    delete newData[key];
    setColorDIct(newData);
  };

  const handleKeyChange = (oldKey: string, newKey: string) => {
    if (newKey && !colorDict[newKey]) {
      const { [oldKey]: value, ...rest } = colorDict;
      setColorDIct({ ...rest, [newKey]: value });
    }
  };

  const handleValueChange = (key: string, value: string) => {
    setColorDIct((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleDatasetNameChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "dataset_name",
        value: event.target.value as string,
      })
    );
  };

  // --------------------------------------------------------------------------------------------------
  const [apiRequest, setApiRequest] = useState<ApiRequest>({
    environment: env,
    dataset_path:
      "s3://" + bucketName + "/" + pathPrefix + "/datasets/" + tsm.dataset_name,
    color_dict: colorDict,
    response_path:
      "s3://" + bucketName + "/" + pathPrefix + "/validation_responses",
  });

  // --------------------------------------------------------------------------------------------------
  // Send API Request
  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const handleSubmit = async (requestData: RunServiceRequest) => {
    console.log(JSON.stringify(requestData));
    runServiceMutation(requestData);
    const fixedItem = requestData.job_item as JobItem;
    setLastJobID(fixedItem.id);
    const pendingMessage = { status: "requested" };
    writeJsonToS3(
      bucketName,
      pathPrefix + "/validation_responses/" + tsm.dataset_name + ".json",
      pendingMessage
    );
  };

  const log = (type: any) => console.log.bind(console, type);

  // --------------------------------------------------------------------------------------------------
  const [open, setOpen] = useState(false);
  const handleAlertClose = (event: SyntheticEvent) => {
    setOpen(false);
  };

  useEffect(() => {
    setApiRequest({
      ...apiRequest,
      dataset_path:
        "s3://" +
        bucketName +
        "/" +
        pathPrefix +
        "/datasets/" +
        tsm.dataset_name,
      color_dict: colorDict,
      response_path:
        "s3://" + bucketName + "/" + pathPrefix + "/validation_responses",
    });
  }, [tsm, colorDict]);

  useEffect(() => {
    setOpen(true);
  }, [runServiceMutationResult]);

  // --------------------------------------------------------------------------------------------------
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <FormControl fullWidth>
        <InputLabel>Select a Dataset</InputLabel>
        <Select
          value={tsm.dataset_name}
          label="Dataset Name"
          onChange={handleDatasetNameChange}
        >
          {folders.map((folder) => (
            <MenuItem
              key={folder.replace("/", "")}
              value={folder.replace("/", "")}
            >
              {folder.replace("/", "")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Grid
        container
        spacing={2}
        style={{
          border: "2px solid black",
          padding: "2px",
          margin: "25px",
          maxHeight: "500px",
          overflow: "auto",
        }}
      >
        <Typography>
          Define the color dictionary (if you have labels as colored masks.)
        </Typography>
        {Object.keys(colorDict).map((key, index) => (
          <Grid
            item
            xs={12}
            key={index}
            container
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={4}>
              <TextField
                select
                label="Key"
                value={key}
                onChange={(e) => handleKeyChange(key, e.target.value)}
                fullWidth
              >
                {matplotlibColors
                  .filter(
                    (element) =>
                      !Object.keys(colorDict).includes(element) ||
                      element == key
                  )
                  .map((color) => (
                    <MenuItem key={color} value={color}>
                      {color}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Value"
                value={colorDict[key]}
                onChange={(e) => handleValueChange(key, e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => handleDeleteItem(key)}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleAddItem}
            disabled={
              matplotlibColors.filter(
                (element) => !Object.keys(colorDict).includes(element)
              ).length == 0
            }
          >
            Add Item
          </Button>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        onClick={(e) =>
          handleSubmit({
            service: "tsm_validate_dataset",
            request_body: apiRequest,
            job_item: jobItem,
            environment: env,
            spot: false,
          } as RunServiceRequest)
        }
        onError={log("errors")}
        disabled={runServiceMutationResult.isLoading}
      >
        Validate
      </Button>
      <Collapse in={open}>
        {(runServiceMutationResult?.isSuccess ||
          runServiceMutationResult?.isError) && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity={runServiceMutationResult?.isSuccess ? "success" : "error"}
            onClose={handleAlertClose}
          >
            {runServiceMutationResult?.isSuccess
              ? "Request sent successfully!"
              : "Request Sending has been ecnountered an error!"}
          </Alert>
        )}
      </Collapse>
      {/* <pre>{JSON.stringify(apiRequest, null, 2)}</pre> */}
    </div>
  );
};

export default TSMValidate;
