import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/store";
import { S3File } from "../../../../components/S3/S3Utils";
import { WritableDraft } from "immer";

export interface xrdState {
  files: S3File[];
}

const initialState: xrdState = {
  files: [],
};

type XRDStateKey = keyof xrdState;

export const xrdSlice = createSlice({
  name: "xrd",
  initialState: initialState,
  reducers: {
    // -------------------------------------------------------------------------------
    // General setter for updating any state attribute
    setState: <T extends XRDStateKey>(
      state: WritableDraft<xrdState>,
      action: PayloadAction<{ key: T; value: xrdState[T] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    // -------------------------------------------------------------------------------
    setFiles: (state, action: PayloadAction<{ files: S3File[] }>) => {
      state.files = action.payload.files;
    },
    // -------------------------------------------------------------------------------
  },
});

export const selectXRD = (state: RootState) => state.xrd;

// Action creators are generated for each case reducer function
export const {
  setState,
  setFiles,
} = xrdSlice.actions;

export default xrdSlice.reducer;
