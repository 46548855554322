import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../redux/store";
import { S3File } from "../../../../../components/S3/S3Utils";
import { WritableDraft } from "immer";

export interface TableData {
  columns: string[];
  data: any[];
}

export interface FileInfo {
  fileKey: string;
  metaData: any;
  tableData: TableData;
}

export interface talimTrainState {
  files: S3File[];
  filesInfo: { [key: string]: FileInfo };
  selectedReferenceFile: string;
  task_type: string;
  training_features: string[] | null;
  target_feature: string;
  tuning_metric: string;
  mode: string;
  method: string;
  pca_components: number;
  train_size: number;
  imbalance_fix: boolean;
  normalize: boolean;
  // manual_threshold: number | null;
  explain_models: boolean;
  cross_validation: boolean;
  ensemble_method: string | null;
  number_of_ensembles: number;
  register_model_to_litholens: boolean;
  // register_to_logsapi: boolean;
  // descriptive_log_name: string;
  notification_email: string | null;
  // structures_to_process: [] | null;
  // drillholes_to_process: [] | null;
  s3_model_save_name: string | null;
  // litholens_save_path_type: "s3" | "logsAPI" | null;
}

const initialState: talimTrainState = {
  files: [],
  filesInfo: {},
  selectedReferenceFile: "",
  task_type: "classification",
  training_features: null,
  target_feature: "",
  tuning_metric: "Accuracy",
  mode: "single",
  method: "lr",
  pca_components: 0.7,
  train_size: 0.8,
  imbalance_fix: false,
  normalize: false,
  explain_models: false,
  cross_validation: false,
  ensemble_method: null,
  number_of_ensembles: 2,
  register_model_to_litholens: false,
  // register_to_logsapi: false,
  // descriptive_log_name: "new_log",
  notification_email: "",
  // structures_to_process: null,
  // drillholes_to_process: null,
  s3_model_save_name: null,
  // litholens_save_path_type: "s3",
};

type TalimTrainStateKey = keyof talimTrainState;

export const talimTrainSlice = createSlice({
  name: "talim_train",
  initialState: initialState,
  reducers: {
    // -------------------------------------------------------------------------------
    // General setter for updating any state attribute
    setState: <T extends TalimTrainStateKey>(
      state: WritableDraft<talimTrainState>,
      action: PayloadAction<{ key: T; value: talimTrainState[T] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    // -------------------------------------------------------------------------------
    setSelectedReferenceFile: (
      state,
      action: PayloadAction<{ key: string }>
    ) => {
      state.selectedReferenceFile = action.payload.key;
    },
    // -------------------------------------------------------------------------------
    setFiles: (state, action: PayloadAction<{ files: S3File[] }>) => {
      state.files = action.payload.files;
    },
    // -------------------------------------------------------------------------------
    setFilesInfo: (
      state,
      action: PayloadAction<{ key: string; info: FileInfo }>
    ) => {
      state.filesInfo[action.payload.key] = action.payload.info;
    },
    // -------------------------------------------------------------------------------
  },
});

export const selectTalim = (state: RootState) => state.talim.talimTrainSlice;

// Action creators are generated for each case reducer function
export const { setState, setSelectedReferenceFile, setFiles, setFilesInfo } =
  talimTrainSlice.actions;

export default talimTrainSlice.reducer;
