import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  Button,
  Alert,
  Collapse,
  FormControl,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  TextField,
  Checkbox,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import {
  JobItem,
  createJobItem,
} from "../../../../../components/Jobs/jobUtils";
import {
  RunServiceRequest,
  useRunServiceMutation,
} from "../../../../api/endpoints/kuberApi";
import { ENVIRONMENT } from "../../../../../config";
import DownloadIcon from "@mui/icons-material/Download";
import { writeJsonToS3 } from "../../../../../components/S3/S3Utils";
import { setState } from "../smsSlice";
import { CheckBox } from "@mui/icons-material";
import {
  fetchFiles,
  deleteFile,
  getDownloadUrl,
  S3File,
} from "../../../../../components/S3/S3Utils";

// --------------------------------------------------------------------------------------------------
interface ApiFileMetaData {
  path: string;
  data_type: string | null;
  drillhole_column_name: string | null;
  interval_from_column_name: string | null;
  interval_to_column_name: string | null;
  interval_depth_column_name: string | null;
  sample_id_column_name: string | null;
  mineral_zone_column_name: string | null;
}

// --------------------------------------------------------------------------------------------------
interface ApiRequest {
  project_name: string;
  environment: string;
  minzones_info: ApiFileMetaData;
  columns_of_interest: string[] | null;
  columns_to_convert: string[] | null;
  target_unit: "pct" | "ppm" | "ppb";
  suffixes_to_remove: string[] | null;
  columns_to_clean: string[] | null;
  missing_values_treatment: "drop" | "fill";
  decimal_digits: number | null;
  minerals: string[] | null;
  save_path: string | null;
}

// --------------------------------------------------------------------------------------------------
interface CalculateProportionsRequestProps {
  bucketName: string;
  pathPrefix: string;
}

// --------------------------------------------------------------------------------------------------
function findValidOutputPath(str: string): string | null {
  const pattern = /s3:\/\/[^ ]+\.csv/;
  const match = str.match(pattern);
  return match ? match[0] : null;
}

// --------------------------------------------------------------------------------------------------
const CalculateProportionsRequest: React.FC<
  CalculateProportionsRequestProps
> = ({ bucketName, pathPrefix }) => {
  const sms = useAppSelector((state) => state.sms.SMSSlice);
  const dispatch = useAppDispatch();

  // --------------------------------------------------------------------------------------------------
  // Job Item & API Request

  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);

  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = "logs.proportions";
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);

  const [lastJobID, setLastJobID] = useState<string | null>(null);
  const env = process.env.REACT_APP_ENV || "test";

  // --------------------------------------------------------------------------------------------------
  const [apiRequest, setApiRequest] = useState<ApiRequest>({
    minzones_info: {} as ApiFileMetaData,
    // job_item: jobItem,
    project_name: projectName,
    environment: env,
    columns_of_interest: sms.columns_of_interest,
    columns_to_convert: sms.columns_to_convert,
    target_unit: sms.target_unit,
    suffixes_to_remove: sms.suffixes_to_remove,
    columns_to_clean: sms.columns_to_clean,
    missing_values_treatment: sms.missing_values_treatment,
    decimal_digits: sms.decimal_digits,
    minerals: sms.minerals,
    save_path: sms.min_prop_save_name,
  });

  // --------------------------------------------------------------------------------------------------
  // Send API Request
  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const handleSubmit = async (requestData: RunServiceRequest) => {
    runServiceMutation(requestData);
    const fixedItem = requestData.job_item as JobItem;
    // setLastJobID(fixedItem.id);
    const pendingMessage = { status: "requested" };
    // writeJsonToS3(
    //   bucketName,
    //   pathPrefix + "/responses/calculate_proportions/" + jobItem.id + ".json",
    //   pendingMessage
    // );
  };

  const log = (type: any) => console.log.bind(console, type);

  // --------------------------------------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const handleAlertClose = (event: SyntheticEvent) => {
    setOpen(false);
  };

  useEffect(() => {
    var newBaseStructuresInfo = {} as ApiFileMetaData;
    Object.values(sms.filesInfo).forEach((element) => {
      if (element.fileKey === sms.selectedMinZoneFile) {
        newBaseStructuresInfo = {
          path: "s3://" + bucketName + "/" + element.fileKey,
          data_type: element.metaData.selectedType,
          drillhole_column_name: element.metaData.drillhole,
          interval_from_column_name: element.metaData.begin,
          interval_to_column_name: element.metaData.end,
          interval_depth_column_name: element.metaData.pointDepth,
          sample_id_column_name: element.metaData.samplesID,
          mineral_zone_column_name: element.metaData.minZones,
        };
      }
    });
    setApiRequest({
      ...apiRequest,
      minzones_info: newBaseStructuresInfo,
      columns_of_interest: sms.columns_of_interest,
      columns_to_convert: sms.columns_to_convert,
      target_unit: sms.target_unit,
      suffixes_to_remove: sms.suffixes_to_remove,
      columns_to_clean: sms.columns_to_clean,
      minerals: sms.minerals,
      save_path: sms.min_prop_save_name
        ? "s3://" +
          bucketName +
          "/" +
          pathPrefix +
          "/data/" +
          sms.min_prop_save_name +
          ".csv"
        : null,
    });
  }, [sms, projects.selectedProject]);

  useEffect(() => {
    setOpen(true);
  }, [runServiceMutationResult]);

  const handleMineralsChange = (
    event: SyntheticEvent<Element, Event>,
    value: string[], // The selected values from the multiselect
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string>
  ) => {
    dispatch(
      setState({
        key: "minerals",
        value: value as string[], // Assuming the selected values are strings
      })
    );
  };

  const handleS3SaveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "min_prop_save_name",
        value: event.target.value as string,
      })
    );
  };

  const handleDownload = async (key: string) => {
    try {
      const url = await getDownloadUrl(bucketName, key);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  // --------------------------------------------------------------------------------------------------
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <FormControl fullWidth margin="normal">
        <Autocomplete
          multiple
          options={
            sms.filesInfo[sms.selectedMinZoneFile]
              ? sms.filesInfo[sms.selectedMinZoneFile].tableData.columns || []
              : []
          }
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          value={sms.minerals || []}
          onChange={handleMineralsChange}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Minerals" variant="outlined" />
          )}
          fullWidth
        />
      </FormControl>
      <TextField
        id="save-name"
        label="S3 Save Name"
        value={sms.save_name}
        error={sms.save_name === null || sms.save_name === ""}
        onChange={handleS3SaveNameChange}
      />
      <Button
        variant="contained"
        onClick={(e) =>
          handleSubmit({
            service: "sms_mineralogy/mineral_proportion_calculator",
            request_body: apiRequest,
            job_item: jobItem,
            environment: env,
          } as RunServiceRequest)
        }
        disabled={runServiceMutationResult.isLoading}
        onError={log("errors")}
      >
        Send Request
      </Button>
      {/* <Typography>{Object(runServiceMutationResult)?.data?.message}</Typography> */}
      <Collapse in={open}>
        {(runServiceMutationResult?.isSuccess ||
          runServiceMutationResult?.isError) && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity={runServiceMutationResult?.isSuccess ? "success" : "error"}
            onClose={handleAlertClose}
          >
            {runServiceMutationResult?.isSuccess
              ? Object(runServiceMutationResult)?.data?.message
              : "Request Sending has been ecnountered an error!"}
          </Alert>
        )}
      </Collapse>
      {runServiceMutationResult?.isSuccess &&
        Object(runServiceMutationResult)?.data?.response &&
        Object(runServiceMutationResult)?.data?.response?.output_path && (
          <Tooltip title="Download">
            <IconButton
              onClick={() =>
                handleDownload(
                  Object(
                    runServiceMutationResult
                  )?.data?.response?.output_path.replace(
                    "s3://" + bucketName + "/",
                    ""
                  )
                )
              }
              disabled={
                Object(runServiceMutationResult)?.data?.response
                  ?.output_path === null ||
                Object(runServiceMutationResult)?.data?.response
                  ?.output_path === ""
              }
              aria-label="download"
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}
    </div>
  );
};

export default CalculateProportionsRequest;
