import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  RunServiceRequest,
  useRunServiceMutation,
} from "../../../../api/endpoints/superApi";
import {
  RunServiceRequest as RunKuberServiceRequest,
  useRunServiceMutation as useRunKuberServiceMutation,
} from "../../../../api/endpoints/kuberApi";
import { Button, Alert, Collapse, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import EdaConfigParams from "./edaConfigParams";
import {
  JobItem,
  createJobItem,
} from "../../../../../components/Jobs/jobUtils";
import { writeJsonToS3 } from "../../../../../components/S3/S3Utils";

// --------------------------------------------------------------------------------------------------
interface ApiFileMetaData {
  path: string;
  data_type: string | null;
  drillhole_column_name: string | null;
  interval_from_column_name: string | null;
  interval_to_column_name: string | null;
  interval_depth_column_name: string | null;
}

// --------------------------------------------------------------------------------------------------
interface ApiRequest {
  project_name: string;
  environment: string;
  // job_item: JobItem;
  dataset_info: ApiFileMetaData;
  feature_families_method: string;
  feature_families_distance_metric: string;
  feature_correlation_method: string;
  feature_families_scaling: boolean;
  register_to_litholens: boolean;
  register_to_logsapi: boolean;
  descriptive_log_name: string;
  notification_email: string | null;
  structures_to_process: [] | null;
  drillholes_to_process: [] | null;
  s3_data_save_path: string | null;
  litholens_save_path_type: "s3" | "logsAPI" | null;
}

// --------------------------------------------------------------------------------------------------
interface EDARequestProps {
  bucketName: string;
  pathPrefix: string;
}

// --------------------------------------------------------------------------------------------------
const EDARequest: React.FC<EDARequestProps> = ({ bucketName, pathPrefix }) => {
  const talim_eda = useAppSelector((state) => state.talim.talimEdaSlice);
  const dispatch = useAppDispatch();

  // --------------------------------------------------------------------------------------------------
  // Job Item & API Request

  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);

  // const url: string = window.location.href;
  // const company: string = url.split("/").filter(Boolean)[2];
  // const project: string = url.split("/").filter(Boolean)[3];
  // const projectName: string = company + "_" + project;
  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = "logs.eda";
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);
  const [lastJobID, setLastJobID] = useState<string | null>(null);
  const env = process.env.REACT_APP_ENV || "test";

  // --------------------------------------------------------------------------------------------------
  const [apiRequest, setApiRequest] = useState<ApiRequest>({
    dataset_info: {} as ApiFileMetaData,
    // job_item: jobItem,
    project_name: projectName,
    environment: env,
    feature_families_method: talim_eda.feature_correlation_method,
    feature_families_distance_metric:
      talim_eda.feature_families_distance_metric,
    feature_correlation_method: talim_eda.feature_correlation_method,
    feature_families_scaling: talim_eda.feature_families_scaling,
    register_to_litholens: talim_eda.register_to_litholens,
    register_to_logsapi: talim_eda.register_to_logsapi,
    descriptive_log_name: talim_eda.descriptive_log_name,
    notification_email: talim_eda.notification_email,
    structures_to_process: talim_eda.structures_to_process,
    drillholes_to_process: talim_eda.drillholes_to_process,
    s3_data_save_path: talim_eda.s3_data_save_path,
    litholens_save_path_type: talim_eda.litholens_save_path_type,
  });

  // --------------------------------------------------------------------------------------------------
  // Send API Request
  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const [runKuberServiceMutation, runKuberServiceMutationResult] =
    useRunKuberServiceMutation();

  const handleSubmit = async (requestData: RunServiceRequest) => {
    runServiceMutation(requestData);
    const fixedItem = requestData.job_item as JobItem;
    setLastJobID(fixedItem.id);
    const pendingMessage = { status: "requested" };
    writeJsonToS3(
      bucketName,
      pathPrefix + "/responses/eda/" + fixedItem.id + ".json",
      pendingMessage
    );
  };

  const handleKuberSubmit = async (requestData: RunServiceRequest) => {
    console.log("Kuber Request Data: ", requestData);
    runKuberServiceMutation(requestData);
    const fixedItem = requestData.job_item as JobItem;
    setLastJobID(fixedItem.id);
  };

  const log = (type: any) => console.log.bind(console, type);

  // --------------------------------------------------------------------------------------------------
  const [open, setOpen] = useState(false);
  const handleAlertClose = (event: SyntheticEvent) => {
    setOpen(false);
  };

  useEffect(() => {
    var newBaseStructuresInfo = {} as ApiFileMetaData;
    Object.values(talim_eda.filesInfo)
      .filter((item) => item.fileKey === talim_eda.selectedReferenceFile)
      .forEach((element) => {
        newBaseStructuresInfo = {
          path: "s3://" + bucketName + "/" + element.fileKey,
          data_type: element.metaData.selectedType,
          drillhole_column_name: element.metaData.drillhole,
          interval_from_column_name: element.metaData.begin,
          interval_to_column_name: element.metaData.end,
          interval_depth_column_name: element.metaData.pointDepth,
        };
      });
    var modifiedLogDescripitveName = "new_log";
    if (typeof talim_eda.descriptive_log_name === "string") {
      modifiedLogDescripitveName = talim_eda.descriptive_log_name;
    }
    setApiRequest({
      ...apiRequest,
      dataset_info: newBaseStructuresInfo,
      register_to_litholens: talim_eda.register_to_litholens,
      feature_families_method: talim_eda.feature_families_method,
      feature_families_distance_metric:
        talim_eda.feature_families_distance_metric,
      feature_correlation_method: talim_eda.feature_correlation_method,
      feature_families_scaling: talim_eda.feature_families_scaling,
      register_to_logsapi: talim_eda.register_to_logsapi,
      notification_email: talim_eda.notification_email,
      descriptive_log_name: modifiedLogDescripitveName,
      s3_data_save_path: talim_eda.s3_data_save_path,
    });
  }, [talim_eda, projects.selectedProject]);

  useEffect(() => {
    // if (runServiceMutationResult?.isSuccess) {
    //   alert("API request has been sent successfully!");
    // } else {
    //   if (runServiceMutationResult?.isError) {
    //     alert("API request has been encountered an error!");
    //   }
    // }
    setOpen(true);
  }, [runServiceMutationResult, runKuberServiceMutationResult]);

  // --------------------------------------------------------------------------------------------------
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <EdaConfigParams />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            onClick={(e) =>
              handleSubmit({
                service: "ll_run_talim_eda",
                request_body: apiRequest,
                job_item: jobItem,
                environment: env,
              } as RunServiceRequest)
            }
            onError={log("errors")}
            disabled={runServiceMutationResult.isLoading}
          >
            Send Request
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            onClick={(e) =>
              handleKuberSubmit({
                service: "talim_eda/eda",
                request_body: apiRequest,
                job_item: jobItem,
                environment: env,
              } as RunServiceRequest)
            }
            onError={log("errors")}
            disabled={runKuberServiceMutationResult.isLoading}
          >
            Send Request (Beta)
          </Button>
        </Grid>
      </Grid>
      <Collapse in={open}>
        {(runServiceMutationResult?.isSuccess ||
          runServiceMutationResult?.isError) && (
            <Alert
              icon={<CheckIcon fontSize="inherit" />}
              severity={runServiceMutationResult?.isSuccess ? "success" : "error"}
              onClose={handleAlertClose}
            >
              {runServiceMutationResult?.isSuccess
                ? "Request sent successfully! Your request ID is " + lastJobID
                : "Request Sending has been ecnountered an error!"}
            </Alert>
          )}
      </Collapse>
      <Collapse in={open}>
        {(runKuberServiceMutationResult?.isSuccess ||
          runKuberServiceMutationResult?.isError) && (
            <Alert
              icon={<CheckIcon fontSize="inherit" />}
              severity={
                runKuberServiceMutationResult?.isSuccess ? "success" : "error"
              }
              onClose={handleAlertClose}
            >
              {runKuberServiceMutationResult?.isSuccess
                ? "Job done successfully! Your request ID is " + lastJobID
                : "Request Sending has been ecnountered an error!"}
            </Alert>
          )}
      </Collapse>
      {/* <pre>{JSON.stringify(apiRequest, null, 2)}</pre>
      <pre>{JSON.stringify(talim_eda.selectedReferenceFile, null, 2)}</pre> */}
    </div>
  );
};

export default EDARequest;
