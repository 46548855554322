import {
  S3File,
  fetchFiles,
  getFileInfo,
} from "../../../../components/S3/S3Utils";

// ------------------------------------------------------------------------------------
export const getFilesWithMetaData = async (
  bucketName: string,
  pathPrefix: string
): Promise<S3File[]> => {
  const fetchedCsvFiles = await fetchFiles(bucketName, pathPrefix + "/data", [
    "csv",
  ]);
  const fetchedJsonFiles = await fetchFiles(
    bucketName,
    pathPrefix + "/metaData",
    ["json"]
  );

  const jsonFileNames = new Set(
    fetchedJsonFiles.map((jsonFile) => getFileInfo(jsonFile.Key).fileName)
  );

  const csvsWithMetaData = fetchedCsvFiles.filter((csvFile) => {
    const baseName = getFileInfo(csvFile.Key).fileName;
    return jsonFileNames.has(baseName);
  });

  return csvsWithMetaData;
};

export const getModels = async (
  bucketName: string,
  pathPrefix: string
): Promise<S3File[]> => {
  const fetchedModelFiles = await fetchFiles(
    bucketName,
    pathPrefix + "/models",
    ["json", "pkl"]
  );

  return fetchedModelFiles;
};
// ------------------------------------------------------------------------------------
