import React, { useState, useRef, useEffect } from 'react';
import { Alert, Button, Box, CircularProgress } from '@mui/material';
import DrillholeSelector from '../gallery/DrillholeSelector';
import config, { DRILLHOLES_FOLDER_PATH, ENVIRONMENT } from "../../config";
import { listFolders, listFolder, getDownloadUrl, writeImageToS3 } from '../../components/S3/S3Utils';
import { getBase64String } from './image_processing_utils/rectify_utils';
import { whiteBalance, areImagesEqual, processImage } from './image_processing_utils/white_balance_utils';
import ImageSelector from '../gallery/ImageSelector';

interface WhiteBalanceProps {
    company: string;
    projectName: string;
}

const WhiteBalancePage: React.FC<WhiteBalanceProps> = ({ company, projectName }) => {
    const projects_s3_bucket = config[ENVIRONMENT].projects_s3_bucket;
    const [drillholes, setDrillholes] = useState<string[]>([]);
    const [selectedDrillhole, setSelectedDrillhole] = useState<string>("");
    const [images, setImages] = useState<{ name: string; url: string }[]>([]);
    const [selectedImage, setSelectedImage] = useState<string>("");
    const [originalImageUrl, setOriginalImageUrl] = useState<string | null>(null);
    const [imageDim, setImageDim] = useState<{ imageWidth: number; imageHeight: number } | null>(null);
    const [originalImageData, setOriginalImageData] = useState<ImageData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<{ type: 'error' | 'info' | 'success' | 'warning'; message: string } | null>(null);
    const [containerDim, setContainerDim] = useState<{ containerWidth: number; containerHeight: number }>({ containerWidth: 900, containerHeight: 500 });
    const outputCanvasRef = useRef<HTMLCanvasElement>(null);
    const imageRef = useRef<HTMLCanvasElement>(null);

    const bucketName = projects_s3_bucket;
    const pathPrefix = `${company}/${projectName}/${DRILLHOLES_FOLDER_PATH}`;

    useEffect(() => {
        const fetchDrillholes = async () => {
            setLoading(true);
            setDrillholes([]);
            setImages([]);
            setSelectedDrillhole("");
            setAlertMessage({ type: 'info', message: 'Fetching Drillholes List....' });
            try {
                const folders = await listFolders(bucketName, pathPrefix);
                const drillholeNames = folders.map((folder) => {
                    const parts = folder.split("/");
                    return parts[parts.length - 2];
                });
                setDrillholes(drillholeNames);
                setAlertMessage({ type: 'info', message: 'Select a drillhole' });
            } catch (error) {
                setAlertMessage({ type: 'error', message: 'Error fetching drillholes' });
                console.error("Error fetching drillholes: ", error);
            } finally {
                setLoading(false);
            }
        };
        fetchDrillholes();
    }, [bucketName, pathPrefix]);

    useEffect(() => {
        const fetchImages = async () => {
            if (selectedDrillhole === "") return;
            setLoading(true);
            setSelectedImage("");
            setAlertMessage({ type: 'info', message: 'Fetching Image List....' });
            const drillholePrefix = `${pathPrefix}/${selectedDrillhole}/Original`;
            try {
                const folders = await listFolder(bucketName, drillholePrefix);
                const imageNames = folders.map((folder) => {
                    const parts = folder.split("/");
                    return {
                        name: parts[parts.length - 1],
                        url: `${pathPrefix}/${selectedDrillhole}/thumbnails/Original/${parts[parts.length - 1].replace('jpg', 'png')}`
                    };
                });
                setImages(imageNames);
                setAlertMessage({ type: 'info', message: 'Select an image' });
            } catch (error) {
                setAlertMessage({ type: 'error', message: 'Error fetching images' });
                console.error("Error fetching images: ", error);
            } finally {
                setLoading(false);
            }
        };
        fetchImages();
    }, [pathPrefix, selectedDrillhole]);

    useEffect(() => {
        const fetchImageUrls = async () => {
            if (selectedDrillhole === "" || selectedImage === "") return;
            setAlertMessage({ type: 'info', message: 'Loading Image....' });
            setLoading(true);
            setOriginalImageUrl(null);
            setOriginalImageData(null);
            setImageDim(null);

            const outputCanvas = outputCanvasRef.current;
            if (!outputCanvas) return;
            const outputCtx = outputCanvas.getContext('2d');
            if (!outputCtx) return;
            outputCtx.clearRect(0, 0, outputCanvas.width, outputCanvas.height);

            const originalImagePrefix = `${pathPrefix}/${selectedDrillhole}/Original/${selectedImage}`;

            try {
                const url = await getDownloadUrl(bucketName, originalImagePrefix);
                setOriginalImageUrl(url);
            } catch (error) {
                setAlertMessage({ type: 'error', message: 'Error fetching image URLs' });
                console.error("Error fetching image URLs: ", error);
            } finally {
                setLoading(false);
            }
        };
        fetchImageUrls();
    }, [pathPrefix, selectedDrillhole, selectedImage]);

    useEffect(() => {
        setOriginalImageData(null);
        if (!originalImageUrl) return;

        const imageCanvas = imageRef.current;
        if (!imageCanvas) return;

        const imageCtx = imageCanvas.getContext('2d');
        if (!imageCtx) return;

        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.src = originalImageUrl;

        image.onload = () => {
            const { width, height } = image;
            imageCanvas.width = width;
            imageCanvas.height = height;
            setImageDim({ imageWidth: width, imageHeight: height });

            imageCtx.drawImage(image, 0, 0, width, height);
            setOriginalImageData(imageCtx.getImageData(0, 0, width, height));
            setAlertMessage(null);
        };
    }, [originalImageUrl]);

    useEffect(() => {
        const containerWidth = window.innerWidth * 0.6;
        const containerHeight = containerWidth * (imageDim?.imageHeight || 224) / (imageDim?.imageWidth || 224);
        setContainerDim({ containerWidth, containerHeight });
    }, [imageDim]);

    const submitImage = async () => {
        setAlertMessage({ type: 'warning', message: 'Submitting image....' });
        const outputCanvas = outputCanvasRef.current;
        if (!outputCanvas) return;

        setLoading(true);
        setTimeout(async () => {
            try {
                const imageData = processImage(outputCanvas);
                const base64String = getBase64String(imageData, imageDim ? imageDim : { imageWidth: 224, imageHeight: 224 });

                const originalKey = `${pathPrefix}/${selectedDrillhole}/Original/${selectedImage}`;
                await writeImageToS3(bucketName, originalKey, base64String, "image/jpg");
                setAlertMessage({ type: 'success', message: 'Image submitted successfully' });
            } catch (error) {
                setAlertMessage({ type: 'error', message: 'Error submitting image' });
                console.error("Error submitting White Balanced Image: ", error);
            } finally {
                setLoading(false);
            }
        }, 10);
    };

    const applyWhiteBalance = () => {
        setAlertMessage({ type: 'warning', message: 'Applying White Balance....' });
        setTimeout(() => {
            if (!originalImageData) return;
            const imageCanvas = imageRef.current;
            const outputCanvas = outputCanvasRef.current;
            if (!imageCanvas || !outputCanvas) return;

            const ctx = imageCanvas.getContext('2d');
            const outputCtx = outputCanvas.getContext('2d');
            if (!ctx || !outputCtx) return;

            setLoading(true);
            const { imageWidth, imageHeight } = imageDim ? imageDim : { imageWidth: 224, imageHeight: 224 };
            const imageData = ctx.getImageData(0, 0, imageWidth, imageHeight);
            try {
                const balacnedImageData = whiteBalance(imageData);
                if (areImagesEqual(originalImageData, balacnedImageData)) {
                    setAlertMessage({ type: 'error', message: "White balancing already applied." });
                } else {
                    outputCanvas.width = imageWidth;
                    outputCanvas.height = imageHeight;
                    outputCtx.putImageData(balacnedImageData, 0, 0);
                    setAlertMessage({ type: 'success', message: "White balance applied successfully." });
                }
            } catch (error) {
                setAlertMessage({ type: 'error', message: 'Error Applying White Balancing' });
                console.error("Error applying white balance: ", error);
            } finally {
                setLoading(false);
            }
        }, 10);
    };


    const previousImage = () => {
        const index = images.findIndex((image) => image.name === selectedImage);
        if (index > 0) {
            setSelectedImage(images[index - 1].name);
        }
    };

    const nextImage = () => {
        const index = images.findIndex((image) => image.name === selectedImage);
        if (index < images.length - 1) {
            setSelectedImage(images[index + 1].name);
        }
    };

    const previousDrillhole = () => {
        const index = drillholes.indexOf(selectedDrillhole);
        if (index > 0) {
            setSelectedDrillhole(drillholes[index - 1]);
        }
    };

    const nextDrillhole = () => {
        const index = drillholes.indexOf(selectedDrillhole);
        if (index < drillholes.length - 1) {
            setSelectedDrillhole(drillholes[index + 1]);
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {alertMessage && <Alert severity={alertMessage.type}>{alertMessage.message}</Alert>}
            <DrillholeSelector
                drillholes={drillholes}
                selectedDrillhole={selectedDrillhole}
                onDrillholeChange={setSelectedDrillhole}
            />
            {selectedDrillhole && (
                <div style={{ width: '100%' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={2}>
                        <Button variant="contained" disabled={drillholes.indexOf(selectedDrillhole) === 0} onClick={previousDrillhole}>Previous Drillhole</Button>
                        <Button variant="contained" disabled={drillholes.indexOf(selectedDrillhole) === drillholes.length - 1} onClick={nextDrillhole}>Next Drillhole</Button>
                    </Box>
                    <ImageSelector
                        images={images}
                        selectedImage={selectedImage}
                        onImageChange={setSelectedImage}
                        bucketName={bucketName}
                    />
                </div>
            )}
            {selectedDrillhole && selectedImage && (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {alertMessage?.type === 'info' || alertMessage?.type === 'warning'
                            ? <CircularProgress /> : null}
                    </div>
                    <div style={
                        {
                            visibility: alertMessage?.type === 'info' ? 'hidden' : 'visible',
                            opacity: alertMessage?.type === 'warning' ? 0.3 : 1
                        }
                    }>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            mt={2}
                        >
                            <Box position="relative" width={containerDim.containerWidth} height={containerDim.containerHeight}>
                                <canvas
                                    ref={imageRef}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 1,
                                    }}
                                />
                                <canvas
                                    ref={outputCanvasRef}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 2,
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={2}>
                            <Button variant="contained" disabled={images.findIndex((image) => image.name === selectedImage) === 0} onClick={previousImage}>Previous Image</Button>
                            <Button variant="contained" onClick={applyWhiteBalance}>Auto White Balance</Button>
                            <Button variant="contained" onClick={submitImage}>Submit</Button>
                            <Button variant="contained" disabled={images.findIndex((image) => image.name === selectedImage) === images.length - 1} onClick={nextImage}>Next Image</Button>
                        </Box>
                    </div>
                </div>
            )}
        </Box>
    );
};

export default WhiteBalancePage;
