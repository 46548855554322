// S3FileViewer.tsx
import React, { useEffect, useState } from "react";
import {
  fetchFiles,
  deleteFile,
  getDownloadUrl,
  S3File,
} from "../../../../../components/S3/S3Utils";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { readJsonFromS3 } from "../../../../../components/S3/S3Utils";

interface InputPreviewProps {
  inputs_dict: { [key: string]: any };
}

interface ApiResponse {
  merged_report?: string | null;
  out_location?: string | null;
  out_log_id?: string | null;
  out_generic_model_id?: number | null;
  warnings?: string[] | null;
  scored_features?: { [key: string]: { [key: string]: number } };
  error?: string | null;
  status?: string | null;
  inputs_dict?: InputPreviewProps;
}

interface FEngResponseViewerProps {
  refresh: any;
  bucketName: string;
  pathPrefix: string;
  serviceName: string;
}

const FEngResponseViewer: React.FC<FEngResponseViewerProps> = ({
  refresh,
  bucketName,
  pathPrefix,
  serviceName,
}) => {
  const [files, setFiles] = useState<S3File[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const loadFiles = async () => {
      try {
        const fetchedFiles = await fetchFiles(
          (bucketName = bucketName),
          (pathPrefix = pathPrefix + "/responses/" + serviceName),
          ["json"]
        );
        setFiles(fetchedFiles);
      } catch (error) {
        console.error("Failed to fetch files:", error);
      }
    };
    loadFiles();
  }, [refresh, bucketName, pathPrefix]);

  // Component Relevance Table

  interface FeatureRankingTableProbs {
    scored_features?: { [key: string]: { [key: string]: number } };
  }

  const FeatureRankingTable: React.FC<FeatureRankingTableProbs> = ({
    scored_features,
  }) => {
    if (!scored_features) {
      console.log(scored_features);
      return <div>Feature Ranking Table Not Found!</div>;
    }

    const getColor = (value: number, max: number) => {
      const hue = Math.floor((value / max) * 120);
      return `hsl(${hue}, 100%, 50%)`;
    };

    // const maxValue = Math.max(...data.map(row => Math.max(...columns.map(col => row[col.accessor]))));

    const maxValue = (key: string) => {
      const values = Object.values(scored_features?.[key]);
      return Math.max(...values);
    };

    const convertToCSV = () => {
      const headers =
        "feature, " + Object.keys(scored_features).join(",") + "\n";
      const rows = Object.keys(Object.values(scored_features)[0])
        .map((rowKey) => {
          return [
            rowKey,
            ...Object.keys(scored_features).map(
              (colKey) => scored_features[colKey][rowKey]
            ),
          ].join(",");
        })
        .join("\n");
      return headers + rows;
    };

    const downloadCSV = () => {
      const csv = convertToCSV();
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      const midfix = files[selectedIndex].Key.replace(
        pathPrefix + "/responses/" + serviceName + "/",
        ""
      );
      link.href = URL.createObjectURL(blob);
      link.download = "scored_features_" + midfix + ".csv";
      link.click();
    };

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={downloadCSV}
          style={{ marginBottom: "10px" }}
        >
          Download as CSV
        </Button>
        <TableContainer
          component={Paper}
          sx={{ maxWidth: 1000, margin: "auto", mt: 4 }}
        >
          <Table aria-label="relevance table">
            <TableHead>
              <TableRow>
                <TableCell>Relevance</TableCell>
                {Object.keys(scored_features).map((header) => (
                  <TableCell
                    key={header}
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(Object.values(scored_features)[0]).map((key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row" align="right">
                    {key}
                  </TableCell>
                  {Object.keys(scored_features).map((header) => (
                    <TableCell
                      key={header}
                      align="center"
                      style={{
                        backgroundColor: getColor(
                          scored_features?.[header]?.[key],
                          maxValue(header)
                        ),
                      }}
                    >
                      {scored_features?.[header]?.[key].toFixed(4)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  // Input Preview

  const InputPreview: React.FC<InputPreviewProps> = ({ inputs_dict }) => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Key</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(inputs_dict).map(([key, value]) => {
              if (
                key === "dataset_info" &&
                typeof value === "object" &&
                value !== null &&
                "path" in value
              ) {
                return (
                  <React.Fragment key={key}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Input File Name
                      </TableCell>
                      <TableCell>
                        {value.path.substring(value.path.lastIndexOf("/") + 1)}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              } else if (
                (typeof value === "number" ||
                  typeof value === "string" ||
                  value === null) &&
                !(
                  key === "project_name" ||
                  key === "environment" ||
                  key === "structures_to_process" ||
                  key === "drillholes_to_process" ||
                  key === "litholens_save_path_type" ||
                  key === "feature_list"
                )
              ) {
                return (
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {key !== "s3_data_save_path"
                        ? key
                        : key.replace("path", "name")}
                    </TableCell>
                    <TableCell>
                      {key !== "s3_data_save_path"
                        ? value === null
                          ? "null"
                          : value.toString()
                        : value === null
                        ? "null"
                        : value
                            .toString()
                            .substring(value.lastIndexOf("/") + 1)}
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const [apiResponse, setApiResponse] = useState<ApiResponse>(
    files.length > 0
      ? (readJsonFromS3(bucketName, files[selectedIndex].Key) as ApiResponse)
      : ({} as ApiResponse)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedIndex >= 0 && selectedIndex < files.length) {
          setIsLoading(true);
          const response = await readJsonFromS3(
            bucketName,
            files[selectedIndex].Key
          );
          setApiResponse(response as ApiResponse);
        }
      } catch (error) {
        console.error("Got an error: ", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [selectedIndex, files]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ maxHeight: 400, overflow: "auto" }}>
            <List
              component="nav"
              aria-label="main mailbox folders"
              title="List of FEng Jobs"
            >
              {files.map((file, index) => (
                <ListItemButton
                  key={index} // Ensure unique key for each item
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemText
                    primary={file.Key.replace(
                      `${pathPrefix}/responses/${serviceName}/`,
                      ""
                    )}
                  />
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div
            id="input-preview"
            style={{
              flex: "auto",
              textAlign: "center",
              margin: "20px",
              maxHeight: 400,
            }}
          >
            <Paper style={{ maxHeight: 400, overflow: "auto" }}>
              <InputPreview
                inputs_dict={apiResponse?.inputs_dict || {}}
              ></InputPreview>
            </Paper>
          </div>
        </Grid>
      </Grid>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={activeTab}
        onChange={handleChange}
        aria-label="file tabs"
        sx={{
          backgroundColor: "white", // Light teal background for the whole tabs bar
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Adding a subtle shadow under the tabs bar
          "& .MuiTabs-flexContainer": {
            gap: "10px", // Adds space between each tab/button
          },
        }}
      >
        <Tab
          key="ranking"
          label="Feature Ranking Table"
          id="ranking"
          aria-controls={`tabpanel-ranking`}
        />
      </Tabs>
      {isLoading ? (
        <div style={{ textAlign: "center", margin: "15px" }}>
          <CircularProgress />
        </div>
      ) : apiResponse?.status === "requested" ? (
        <div>
          <Alert severity="warning">Job in progress</Alert>
        </div>
      ) : apiResponse?.status === "failed" ? (
        <div>
          <Alert severity="error">Job failed!</Alert>
        </div>
      ) : (
        <div>
          <div
            hidden={activeTab !== 0}
            id="ranking"
            style={{ flex: "auto", textAlign: "center", margin: "20px" }}
          >
            <FeatureRankingTable
              scored_features={apiResponse?.scored_features}
            />
          </div>
        </div>
      )}
      ;
    </div>
  );
};

export default FEngResponseViewer;
