// import { Navigate, Outlet } from "react-router-dom";
// import { useAppSelector } from "../../redux/hooks";

// const LoggedInRoutes = () => {
//   const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

//   return isAuthenticated ? (
//     <Navigate to="/projects" replace={true} />
//   ) : (
//     <Outlet />
//   );
// };

// export default LoggedInRoutes;

import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

const LoggedInRoutes = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  if (isAuthenticated) {
    window.location.replace("/projects");
    return null;
  }

  return <Outlet />;
};

export default LoggedInRoutes;
