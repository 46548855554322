import React, { useEffect, useState } from "react";
import {
  Checkbox,
  TextField,
  Autocomplete,
  FormControl,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import {
  S3File,
  getFileInfo,
  getTableDataFromCsv,
  readJsonFromS3,
} from "../../../../../components/S3/S3Utils";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  setState,
  setFilesInfo,
  setSelectedAssayFile,
  setselectedReassayFiles,
  setSelectedMinZoneFile,
} from "../smsSlice";
import MinZonesPlot from "./calculateMinZonesPlot";

interface CalculateFileSelectionProps {
  bucketName: string;
  pathPrefix: string;
}

const CalculateFileSelection: React.FC<CalculateFileSelectionProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const sms = useAppSelector((state) => state.sms.SMSSlice);
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [dialogBoxKey, setDialogBoxKey] = useState<string | null>(null);

  const [expandedVisualizeFiles, setExpandedVisualizeFiles] = useState(false);
  const handleChangeVisualizeFilesAccordion = () => {
    setExpandedVisualizeFiles(!expandedVisualizeFiles);
  };

  const [expandedVisualizeFilesMinZone, setExpandedVisualizeFilesMinZone] =
    useState(false);
  const handleChangeVisualizeFilesMinZoneAccordion = () => {
    setExpandedVisualizeFilesMinZone(!expandedVisualizeFilesMinZone);
  };

  const dispatch = useAppDispatch();
  const allFileKeys = [
    sms.selectedAssayFile,
    sms.selectedMinZoneFile,
    ...sms.selectedReassayFiles,
  ];

  useEffect(() => {
    allFileKeys.forEach(async (fileKey) => {
      if (fileKey && !sms.filesInfo[fileKey]) {
        const metaDataKey = fileKey
          .replace("data", "metaData")
          .replace(".csv", ".json");
        try {
          const metaData = await readJsonFromS3(bucketName, metaDataKey);
          const { columns, data } = await getTableDataFromCsv(
            bucketName,
            fileKey
          );
          dispatch(
            setFilesInfo({
              key: fileKey,
              info: {
                fileKey: fileKey,
                metaData: metaData,
                tableData: { columns: columns, data: data },
              },
            })
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    });
  }, [
    sms.selectedAssayFile,
    sms.selectedMinZoneFile,
    sms.selectedReassayFiles,
    bucketName,
  ]);

  const handleAssayFileChange = (
    event: React.ChangeEvent<{}>,
    value: S3File | null
  ) => {
    const newReferenceFileKey = value ? value.Key : "";

    dispatch(setSelectedAssayFile({ key: newReferenceFileKey }));
    if (sms.selectedReassayFiles.includes(newReferenceFileKey)) {
      // Remove the newly selected reference file from the support files
      dispatch(
        setselectedReassayFiles({
          keys: sms.selectedReassayFiles.filter(
            (key) => key !== newReferenceFileKey
          ),
        })
      );
    }

    if (sms.selectedMinZoneFile == newReferenceFileKey) {
      // Remove the newly selected reference file from the support files
      dispatch(
        setSelectedMinZoneFile({
          key: "",
        })
      );
    }
  };

  useEffect(() => {
    if (sms.filesInfo[sms.selectedAssayFile]) {
      dispatch(
        setState({
          key: "columns_of_interest",
          value:
            sms.filesInfo[sms.selectedAssayFile].tableData.columns.filter(
              (column: string) => {
                return (
                  column.includes("pct") ||
                  column.includes("ppm") ||
                  column.includes("ppb")
                );
              }
            ) || [],
        })
      );
    } else {
      dispatch(
        setState({
          key: "columns_of_interest",
          value: [],
        })
      );
    }
  }, [sms.filesInfo[sms.selectedAssayFile]]);

  const handleMinZoneFileChange = (
    event: React.ChangeEvent<{}>,
    value: S3File | null
  ) => {
    const newReferenceFileKey = value ? value.Key : "";

    dispatch(setSelectedMinZoneFile({ key: newReferenceFileKey }));
    if (sms.selectedReassayFiles.includes(newReferenceFileKey)) {
      // Remove the newly selected reference file from the support files
      dispatch(
        setselectedReassayFiles({
          keys: sms.selectedReassayFiles.filter(
            (key) => key !== newReferenceFileKey
          ),
        })
      );
    }

    if (sms.selectedAssayFile == newReferenceFileKey) {
      // Remove the newly selected reference file from the support files
      dispatch(
        setSelectedAssayFile({
          key: "",
        })
      );
      dispatch(
        setState({
          key: "columns_of_interest",
          value: [],
        })
      );
    }
  };

  const handleReassayFilesChange = (
    event: React.ChangeEvent<{}>,
    value: S3File[]
  ) => {
    dispatch(
      setselectedReassayFiles({
        keys: value.map((file) => file.Key),
      })
    );
  };

  const filteredReassayFiles = sms.files
    .filter((file) => file.Key !== sms.selectedAssayFile)
    .filter((file) => file.Key !== sms.selectedMinZoneFile);

  const handleClickOpen = (key: string) => {
    setDialogBoxKey(key);
    setOpenDialogBox(true);
  };

  const handleClose = () => {
    setDialogBoxKey(null);
    setOpenDialogBox(false);
  };

  const getDialogText = (key: string) => {
    switch (key) {
      case "referenceFile":
        return (
          <span>
            <strong>Reference File (required): </strong>The file which the
            intervals and points in the other files will be mapped to its
            intervals (or points).
          </span>
        );
      case "supportFiles":
        return (
          <span>
            <strong>Support Files (required): </strong>The file(s) whose
            intervals and points are to be mapped to the intervals (or points)
            of the reference file.
          </span>
        );

      default:
        return "No info available!";
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Accordion
        expanded={expandedVisualizeFiles}
        onChange={handleChangeVisualizeFilesAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Assay File(s)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {/* Assay  */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={sms.files}
                  getOptionLabel={(option) => getFileInfo(option.Key).baseName}
                  value={
                    sms.files.find(
                      (file) => file.Key === sms.selectedAssayFile
                    ) || null
                  }
                  onChange={handleAssayFileChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assay File"
                      variant="outlined"
                      error={sms.selectedAssayFile === ""}
                    />
                  )}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  multiple
                  options={filteredReassayFiles}
                  disableCloseOnSelect
                  getOptionLabel={(option) => getFileInfo(option.Key).baseName}
                  value={filteredReassayFiles.filter((file) =>
                    sms.selectedReassayFiles.includes(file.Key)
                  )}
                  onChange={handleReassayFilesChange}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {getFileInfo(option.Key).baseName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Reassay Files"
                      variant="outlined"
                      error={sms.selectedReassayFiles.length === 0}
                    />
                  )}
                  fullWidth
                />
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedVisualizeFilesMinZone}
        onChange={handleChangeVisualizeFilesMinZoneAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Mineral Zones Log</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={sms.files}
                  getOptionLabel={(option) => getFileInfo(option.Key).baseName}
                  value={
                    sms.files.find(
                      (file) => file.Key === sms.selectedMinZoneFile
                    ) || null
                  }
                  onChange={handleMinZoneFileChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Mineral Zone Log"
                      variant="outlined"
                      error={sms.selectedMinZoneFile === ""}
                    />
                  )}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              {sms.filesInfo[sms.selectedMinZoneFile] && (
                <MinZonesPlot
                  metadata={sms.filesInfo[sms.selectedMinZoneFile].metaData}
                />
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Dialog open={openDialogBox} onClick={handleClose}>
        <DialogContent>
          <DialogContentText>
            {getDialogText(dialogBoxKey || "")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={handleClose}
          ></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CalculateFileSelection;
