// Binarize image to black and white
import { MouseEvent, RefObject } from 'react';

function binarizeImage(imageData: Uint8ClampedArray): Uint8ClampedArray {
    return imageData.map((pixel, index) => {
        if (index % 4 === 3) {
            return pixel;
        } else if (pixel > 127) {
            return 255;
        } else {
            return 0;
        }
    });
}

// Increase array dimension, ie 1D -> 2D, 2D -> 3D
function increaseArrayDimension<T>(data: T[], chunkSize: number): T[][] {
    const result: T[][] = [];
    for (let i = 0; i < data.length; i += chunkSize) {
        result.push(data.slice(i, i + chunkSize));
    }
    return result;
}

// Superimpose the edited mask on the original mask
function superimposeDrawing(drawArray: Uint8ClampedArray, maskArray: Uint8ClampedArray): Uint8ClampedArray {
    const result: number[] = [];
    for (let i = 0; i < drawArray.length; i += 4) {
        if (drawArray[i] >= 127 && maskArray[i] <= 127) {
            result.push(255, 255, 255, 255);
        } else if ((drawArray[i] < 127 && drawArray[i + 3] === 255) && maskArray[i] > 127) {
            result.push(0, 0, 0, 255);
        } else {
            result.push(...Array.from(maskArray.slice(i, i + 4)));
        }
    }
    return binarizeImage(new Uint8ClampedArray(result));
}

// Save image as png (for testing purposes; move this to functions.js or legacy)
function getBase64String(imageArray: Uint8ClampedArray, imageDim: { imageWidth: number; imageHeight: number }): string {
    const drawCanvas = document.createElement('canvas');
    const ctx = drawCanvas.getContext('2d');

    if (!ctx) throw new Error('2D context not available');

    const { imageWidth, imageHeight } = imageDim;

    drawCanvas.width = imageWidth;
    drawCanvas.height = imageHeight;

    const imageData = ctx.createImageData(imageWidth, imageHeight);
    const pixelData = imageData.data;

    for (let i = 0; i < imageArray.length; i++) {
        pixelData[i] = imageArray[i];
    }

    ctx.putImageData(imageData, 0, 0);

    // convert image to base64
    return drawCanvas.toDataURL().replace("data:image/png;base64,", "");
}

const getCanvasCoordinates = (event: MouseEvent<HTMLCanvasElement>, drawCanvasRef: RefObject<HTMLCanvasElement>) => {
    const drawCanvas = drawCanvasRef.current;
    if (!drawCanvas) return { x: 0, y: 0 };

    const rect = drawCanvas.getBoundingClientRect();
    const scaleX = drawCanvas.width / rect.width;
    const scaleY = drawCanvas.height / rect.height;

    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;

    return { x, y };
};

const convertColorImageToBinary = (data: Uint8ClampedArray, maskColorRGB: { r: number; g: number; b: number }, unmaskColorRGB: { r: number; g: number; b: number }) => {
    for (let i = 0; i < data.length; i += 4) {
        if (isSameColor(data.slice(i, i + 3), maskColorRGB)) {
            data[i] = 0;
            data[i + 1] = 0;
            data[i + 2] = 0;
            data[i + 3] = 255;
        } else if (isSameColor(data.slice(i, i + 3), unmaskColorRGB)) {
            data[i] = 255;
            data[i + 1] = 255;
            data[i + 2] = 255;
            data[i + 3] = 255;
        } else {
            data[i] = 0;
            data[i + 1] = 0;
            data[i + 2] = 0;
            data[i + 3] = 255;
        }
    }
    return data;
}

const processImage = (drawCanvas: HTMLCanvasElement, maskColor: string, unmaskColor: string): Uint8ClampedArray => {
    const ctx = drawCanvas.getContext('2d');
    if (!ctx) throw new Error('Context or mask image data not available');

    const imageData = ctx.getImageData(0, 0, drawCanvas.width, drawCanvas.height);
    const { data } = imageData;

    const maskColorRGB = parseRGBA(maskColor);
    const unmaskColorRGB = parseRGBA(unmaskColor);

    const finalData = convertColorImageToBinary(data, maskColorRGB, unmaskColorRGB);
    return finalData;
};

const isSameColor = (data: Uint8ClampedArray, color: { r: number; g: number; b: number }) => {
    const min_r = Math.max(color.r - 128, 0);
    const max_r = Math.min(color.r + 127, 255);
    const min_g = Math.max(color.g - 128, 0);
    const max_g = Math.min(color.g + 127, 255);
    const min_b = Math.max(color.b - 128, 0);
    const max_b = Math.min(color.b + 127, 255);
    return data[0] >= min_r && data[0] <= max_r && data[1] >= min_g && data[1] <= max_g && data[2] >= min_b && data[2] <= max_b;
}

const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.replace("#", ""), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
}

const parseRGBA = (rgba: string) => {
    const rgbaArray = rgba.split(',');
    const r = parseInt(rgbaArray[0].split('(')[1]);
    const g = parseInt(rgbaArray[1]);
    const b = parseInt(rgbaArray[2]);
    return { r, g, b };
}

export {
    binarizeImage,
    increaseArrayDimension,
    superimposeDrawing,
    getBase64String,
    getCanvasCoordinates,
    processImage,
    hexToRgb,
    parseRGBA,
    convertColorImageToBinary
};