import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/store";
import { S3File } from "../../../../components/S3/S3Utils";
import { WritableDraft } from "immer";

export interface environmentState {
  files: S3File[];
}

const initialState: environmentState = {
  files: [],
};

type EnvironmentStateKey = keyof environmentState;

export const environmentSlice = createSlice({
  name: "environment",
  initialState: initialState,
  reducers: {
    // -------------------------------------------------------------------------------
    // General setter for updating any state attribute
    setState: <T extends EnvironmentStateKey>(
      state: WritableDraft<environmentState>,
      action: PayloadAction<{ key: T; value: environmentState[T] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    // -------------------------------------------------------------------------------
    setFiles: (state, action: PayloadAction<{ files: S3File[] }>) => {
      state.files = action.payload.files;
    },
    // -------------------------------------------------------------------------------
  },
});

export const selectEnvironment = (state: RootState) => state.environment;

// Action creators are generated for each case reducer function
export const {
  setState,
  setFiles,
} = environmentSlice.actions;

export default environmentSlice.reducer;
