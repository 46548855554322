import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Chip,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { setSelectedImages } from "../projectsSlice"; // Import the action
import {
  ImageItem,
  fetchDrillholeImages,
} from "../../api/endpoints/projectsApi";

interface ImageSelectorProps {
  companyName: string;
  projectName: string;
  service: string;
}

// Define a type for the loading option
interface LoadingOption {
  name: string;
  id: string;
}

// Use a union type for options
type ImageOption = ImageItem | LoadingOption;

const ImageSelector: React.FC<ImageSelectorProps> = ({
  companyName,
  projectName,
  service,
}) => {
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch(); // Use useAppDispatch
  const [allImages, setAllImages] = useState<ImageItem[]>([]);
  const [filteredImages, setFilteredImages] = useState<ImageItem[]>([]);
  const [selectedImages, setSelectedImagesState] = useState<ImageItem[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!projectName || !projects.projectsDrillholes[projectName]) return;

    setLoading(true);
    const fetchAllImages = async () => {
      try {
        const promises = Object.values(
          projects.projectsDrillholes[projectName]
        ).map((drillholeItem) =>
          fetchDrillholeImages(companyName, projectName, drillholeItem.id)
        );
        const results = await Promise.all(promises);
        const images = results.flatMap((result) => result.items);
        setAllImages(images);
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllImages();
  }, [projectName, projects.projectsDrillholes]);

  useEffect(() => {
    if (!projects.selectedDrillholes[service]) {
      setFilteredImages([]);
      return;
    }

    const selectedIds = projects.selectedDrillholes[service].map(
      (item) => item.id
    );
    const newFilteredImages = allImages.filter((image) =>
      selectedIds.includes(image.drillholeId)
    );
    setFilteredImages(newFilteredImages);
  }, [projects.selectedDrillholes[service], allImages]);

  // useEffect(() => {
  //   const selectedDrillholeIds =
  //     projects.selectedDrillholes[service]?.map((item) => item.id) || [];
  //   const updatedSelectedImages = selectedImages.filter((image) =>
  //     selectedDrillholeIds.includes(image.drillholeId)
  //   );
  //   setSelectedImagesState(updatedSelectedImages);
  //   dispatch(
  //     setSelectedImages({
  //       service: service,
  //       images: updatedSelectedImages,
  //     })
  //   );
  // }, [projects.selectedDrillholes[service], enabled]);

  // useEffect(() => {
  //   setSelectedImagesState([]);
  //   dispatch(
  //     setSelectedImages({
  //       service: service,
  //       images: [],
  //     })
  //   );
  // }, [enabled]);

  const handleImageChange = (
    event: React.SyntheticEvent,
    value: ImageOption[],
    reason: any
  ) => {
    const newSelectedImages = value.filter(
      (option): option is ImageItem =>
        (option as ImageItem).drillholeId !== undefined
    );
    setSelectedImagesState(newSelectedImages);
    dispatch(
      setSelectedImages({
        service: service,
        images: newSelectedImages,
      })
    );
  };

  const handleOptionClick = (event: React.MouseEvent, option: ImageOption) => {
    event.stopPropagation();

    if ((option as LoadingOption).id === "loading") {
      return;
    }

    const optionIndex = selectedImages.findIndex(
      (image) => image.id === option.id
    );

    let newSelectedImages = [...selectedImages];

    if (optionIndex === -1) {
      newSelectedImages.push(option as ImageItem);
    } else {
      newSelectedImages.splice(optionIndex, 1);
    }

    setSelectedImagesState(newSelectedImages);
    dispatch(
      setSelectedImages({
        service: service,
        images: newSelectedImages,
      })
    );
  };

  const loadingOption: LoadingOption = { name: "Loading...", id: "loading" };

  return (
    <Autocomplete
      multiple
      id="images-outlined"
      options={loading ? [loadingOption] : (filteredImages as ImageOption[])}
      getOptionLabel={(option) => option.name || "Unnamed Image"}
      value={selectedImages} // Ensure the selected images are updated
      onChange={handleImageChange} // Handle selection change
      disableCloseOnSelect // Prevent the dropdown from closing on select
      renderInput={(params) => (
        <TextField
          {...params}
          label="Images"
          placeholder="Select Images"
          variant="outlined"
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={option.name || "Unnamed Image"}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <li {...props} onClick={(event) => handleOptionClick(event, option)}>
          <FormControlLabel
            control={<Checkbox checked={selected} />}
            label={option.name || "Unnamed Image"}
          />
        </li>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export default ImageSelector;
