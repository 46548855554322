import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setState } from "./talimMergeSlice";

const MergeConfigParams: React.FC = () => {
  const talim_merge = useAppSelector((state) => state.talim.talimMergeSlice);
  const projects = useAppSelector((state) => state.projects);
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [dialogBoxKey, setDialogBoxKey] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const handleIntervalizationRadiusChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "intervalization_radius",
        value: event.target.value as unknown as number,
      })
    );
  };

  const handleLoadLithoLensRoqenetReportChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "load_litholens_roqenet_reports",
        value: checked,
      })
    );
  };

  const handleLoadLithoLensVeinetReportChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "load_litholens_veinet_reports",
        value: checked,
      })
    );
  };

  const handleLoadLithoLensReportChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "load_litholens_reports",
        value: checked,
      })
    );
    if (!checked) {
      dispatch(
        setState({
          key: "load_litholens_roqenet_reports",
          value: false,
        })
      );
      dispatch(
        setState({
          key: "load_litholens_veinet_reports",
          value: false,
        })
      );
    }
  };

  const handleRegisterToLithoLensChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "register_to_litholens",
        value: checked,
      })
    );
  };

  const handleRegisterToLogsApiChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "register_to_logsapi",
        value: checked,
      })
    );
  };

  const handleNotificationEmailChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "notification_email",
        value: event.target.value as string,
      })
    );
  };

  const handleS3SaveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "s3_data_save_name",
        value: event.target.value as string,
      })
    );
  };

  const handleLogDescriptiveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "descriptive_log_name",
        value: event.target.value as string,
      })
    );
  };

  const handleClickOpen = (key: string) => {
    setDialogBoxKey(key);
    setOpenDialogBox(true);
  };

  const handleClose = () => {
    setDialogBoxKey(null);
    setOpenDialogBox(false);
  };

  const getDialogText = (key: string) => {
    switch (key) {
      case "intervalizationRadius":
        return (
          <span>
            <strong>Intervalization Radius: </strong>The radius to recast points
            to fixed-size intervals (only applicable to point data){" "}
          </span>
        );
      case "loadLithoLensReports":
        return (
          <span>
            <strong>Load Litholens Reports: </strong>Check if you want to read
            reports directly from LithoLens DataBases.
            <br />
            <br />
            <strong>Note 1: </strong>Getting RoQENet and VeiNet reports without
            texture and colour reports is not available in this version.
            <br />
            <strong>Note 2: </strong>To use these options, you need to attach a
            LithoLens project to your workspace first. Make sure that your
            selected project has these reports available.
            <br />
          </span>
        );
      case "outputRegistration":
        return (
          <span>
            <strong>Litholens: </strong>Check if you want to track your merged
            data through the LithoLens DataBase.
            <br />
            <br />
            <strong>LogsAPI: </strong>Check if you want to log your merged data
            into MongoDB.
            <br />
            <br />
            <strong>Note: </strong>To use the options above, you need to attach
            a LithoLens project to your workspace first. Make sure that your
            selected project has these reports available.
            <br />
          </span>
        );
      case "saveNames":
        return (
          <span>
            <strong>Notification E-Mail: </strong>If a validated Email is given,
            a notification will be sent to the given Email when the job is
            completed.
            <br />
            <br />
            <strong>S3 Save Name (required): </strong>The name of your merged
            file
            <br />
            <br />
            <strong>LogsAPI descriptive name: </strong>If you seek to log your
            merged data in MongoDB, you can give a description to it.
            <br />
          </span>
        );
      default:
        return "No info available!";
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Set Merge API Parameters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              id="intervalization-radius"
              label="Intervalization Radius"
              defaultValue={talim_merge.intervalization_radius}
              onChange={handleIntervalizationRadiusChange}
            />
            <Button
              variant="contained"
              startIcon={<HelpIcon />}
              onClick={() => handleClickOpen("intervalizationRadius")}
            ></Button>
          </Box>
          <Typography>Load LithoLens Reports</Typography>
          <div>
            <FormControlLabel
              control={
                <Switch
                  value="load_litholens_reports"
                  checked={talim_merge.load_litholens_reports}
                  onChange={handleLoadLithoLensReportChange}
                  disabled={projects.selectedProject === null}
                />
              }
              label="Colour/Texture"
            />
            <FormControlLabel
              control={
                <Switch
                  value="load_litholens_roqenet_reports"
                  checked={talim_merge.load_litholens_roqenet_reports}
                  onChange={handleLoadLithoLensRoqenetReportChange}
                  disabled={!talim_merge.load_litholens_reports}
                />
              }
              label="ROQENet Reports"
            />
            <FormControlLabel
              control={
                <Switch
                  value="load_litholens_veinet_reports"
                  checked={talim_merge.load_litholens_veinet_reports}
                  onChange={handleLoadLithoLensVeinetReportChange}
                  disabled={!talim_merge.load_litholens_reports}
                />
              }
              label="VeiNet Reports"
            />
            <Button
              variant="contained"
              startIcon={<HelpIcon />}
              onClick={() => handleClickOpen("loadLithoLensReports")}
            ></Button>
          </div>
          <Typography>Output Registration Options</Typography>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControlLabel
              control={
                <Switch
                  value="register_to_litholens"
                  checked={talim_merge.register_to_litholens}
                  onChange={handleRegisterToLithoLensChange}
                  disabled={projects.selectedProject === null}
                />
              }
              label="LithoLens"
            />
            <FormControlLabel
              control={
                <Switch
                  value="register_to_logsapi"
                  checked={talim_merge.register_to_logsapi}
                  onChange={handleRegisterToLogsApiChange}
                  disabled={projects.selectedProject === null}
                />
              }
              label="LogsAPI"
            />
            <Button
              variant="contained"
              startIcon={<HelpIcon />}
              onClick={() => handleClickOpen("outputRegistration")}
            ></Button>
          </Box>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="notification-email"
              label="Notification E-Mail"
              defaultValue={talim_merge.notification_email}
              onChange={handleNotificationEmailChange}
            />
            <TextField
              id="s3-save-name"
              label="S3 Save Name"
              defaultValue={talim_merge.s3_data_save_name}
              error={
                talim_merge.s3_data_save_name === null ||
                talim_merge.s3_data_save_name === ""
              }
              onChange={handleS3SaveNameChange}
            />
            <TextField
              id="logs-descriptive-name"
              label="LogsAPI descriptive name"
              defaultValue={talim_merge.descriptive_log_name}
              onChange={handleLogDescriptiveNameChange}
              disabled={!talim_merge.register_to_logsapi}
            />
            <Button
              variant="contained"
              startIcon={<HelpIcon />}
              onClick={() => handleClickOpen("saveNames")}
            ></Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Dialog open={openDialogBox} onClick={handleClose}>
        <DialogContent>
          <DialogContentText>
            {getDialogText(dialogBoxKey || "")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={handleClose}
          ></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MergeConfigParams;
