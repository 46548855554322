import React, { useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab"; // Ensure LoadingButton is imported from @mui/lab
import { uploadFilesToS3, bytesToMB, S3File } from "./S3Utils";
import S3FileViewer from "./S3FileViewer";

interface UploadProgress {
  [key: string]: number;
}

interface S3FileUploaderProps {
  bucketName: string;
  pathPrefix: string;
  allowedExtensions?: string[];
  onUploadSuccess?: () => void; // Add the optional callback prop
}

const S3FileUploader: React.FC<S3FileUploaderProps> = ({
  bucketName,
  pathPrefix,
  allowedExtensions = [],
  onUploadSuccess, // Destructure the optional callback prop
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileLoad = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      setFiles(Array.from(fileList));
      setUploadProgress({});
    }
  };

  const handleUploadClick = async () => {
    if (files.length === 0) {
      alert("No files selected.");
      return;
    }
    setLoading(true);
    try {
      await uploadFilesToS3(files, setUploadProgress, bucketName, pathPrefix);
      setFiles([]); // Clear the list after successful upload
      // Invoke the optional callback after successful upload
      if (onUploadSuccess) {
        onUploadSuccess();
      }
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        maxWidth: 800,
        mx: "auto",
        p: 2,
        bgcolor: "background.paper",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <input
        type="file"
        multiple
        ref={fileInputRef}
        onChange={handleFileLoad}
        style={{ display: "none" }}
        accept={allowedExtensions.join(", ")}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => fileInputRef.current?.click()}
        fullWidth
      >
        Select Files
      </Button>
      {files.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: "2px 10px" }}>File Name</TableCell>
                <TableCell sx={{ padding: "2px 10px" }} align="right">
                  Size (MB)
                </TableCell>
                <TableCell sx={{ padding: "2px 10px" }}>
                  Upload Progress
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((file, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ padding: "2px 10px" }}>
                    {file.name}
                  </TableCell>
                  <TableCell sx={{ padding: "2px 10px" }} align="right">
                    {bytesToMB(file.size)}
                  </TableCell>
                  <TableCell sx={{ padding: "2px 10px" }}>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress[file.name] || 0}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <LoadingButton
        loading={loading}
        variant="contained"
        color="secondary"
        onClick={handleUploadClick}
        fullWidth
        sx={{ mt: 2 }}
        disabled={files.length === 0 || loading} // Disabled if no files are selected or loading
      >
        Upload to S3
      </LoadingButton>
      <S3FileViewer
        bucketName={bucketName}
        pathPrefix={pathPrefix}
        refresh={handleUploadClick}
        allowedExtensions={allowedExtensions}
      />
    </Box>
  );
};

export default S3FileUploader;
