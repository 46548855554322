import React, {
  useState,
  useEffect,
  ChangeEventHandler,
  ChangeEvent,
} from "react";
import {
  MenuItem,
  InputAdornment,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import {
  defaultActionPolicyDescription,
  actionsDescription,
  lowLevelDetectionDescriptions,
  highLevelDetectionDescriptions,
  knnClustersDescription,
  invalidStringsDescription,
} from "./cleaningDescriptions";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  setFilesInfo,
  TableData,
  talimCleaningState,
} from "./talimCleaningSlice";

interface TableDataDropdownProps {
  fileKey: string;
}

const options = [
  "drop_samples_with_missing_values",
  "drop_feature",
  "replace_missing_values_with_mean",
  "replace_missing_values_with_median",
  "replace_missing_values_with_min",
  "replace_missing_values_with_max",
  "replace_missing_values_with_zero",
  "do_nothing",
  "knn_imputation",
  "iterative_imputation",
];

const detectionOptions = ["None", "Auto", "Value", "Percentage"];

interface Selections {
  [key: string]: string;
}

interface DetectionValues {
  [key: string]: number;
}

// ----------------------------------------------------------------------------------------------
export const getDefaultOptionsForColumns = (
  tableData: any[],
  metaData: any
): Selections => {
  if (!Array.isArray(tableData) || tableData.length === 0) {
    return {};
  }

  // Extract column names from the first row's keys
  const columnNames = Object.keys(tableData[0]);

  const selections: Selections = {};

  // Iterate over each column name to determine its type
  columnNames.forEach((columnName) => {
    selections[columnName] =
      metaData?.missing_values &&
      metaData.missing_values?.[columnName] &&
      metaData.missing_values?.[columnName]?.missing_percent &&
      metaData.missing_values?.[columnName].missing_percent < 0.3
        ? options[7]
        : options[7];
  });

  return selections;
};

// ----------------------------------------------------------------------------------------------
export const getDefaultDetectionOptionsForColumns = (
  tableData: any[],
  metaData: any
): Selections => {
  if (!Array.isArray(tableData) || tableData.length === 0) {
    return {};
  }

  // Extract column names from the first row's keys
  const columnNames = Object.keys(tableData[0]);

  const detectionTypes: Selections = {};

  // Iterate over each column name to determine its type
  columnNames.forEach((columnName) => {
    metaData?.missing_values &&
    metaData.missing_values?.[columnName] &&
    metaData.missing_values?.[columnName]?.missing_percent &&
    metaData.missing_values?.[columnName].missing_percent < 0.3
      ? (detectionTypes[columnName] = detectionOptions[1])
      : (detectionTypes[columnName] = detectionOptions[0]);
  });

  return detectionTypes;
};

// ----------------------------------------------------------------------------------------------
export const getDefaultLowDetectionValuesOptionsForColumns = (
  tableData: any[]
): DetectionValues => {
  if (!Array.isArray(tableData) || tableData.length === 0) {
    return {};
  }

  // Extract column names from the first row's keys
  const columnNames = Object.keys(tableData[0]);

  const lowDetectionValues: DetectionValues = {};

  // Iterate over each column name to determine its type
  columnNames.forEach((columnName) => {
    // Select the first option based on the type
    lowDetectionValues[columnName] = 0.0;
  });

  return lowDetectionValues;
};

export const getDefaultHighDetectionValuesOptionsForColumns = (
  tableData: any[]
): DetectionValues => {
  if (!Array.isArray(tableData) || tableData.length === 0) {
    return {};
  }

  // Extract column names from the first row's keys
  const columnNames = Object.keys(tableData[0]);

  const highDetectionValues: DetectionValues = {};

  // Iterate over each column name to determine its type
  columnNames.forEach((columnName) => {
    // Select the first option based on the type
    highDetectionValues[columnName] = 100.0;
  });

  return highDetectionValues;
};

const determineType = (columnData: any[]): "categorical" | "numeric" => {
  let numericCount = 0;
  for (const cell of columnData) {
    if (cell != null) {
      const cleanedCell = cell.toString();
      if (
        !isNaN(parseFloat(cleanedCell)) &&
        isFinite(parseFloat(cleanedCell))
      ) {
        numericCount++;
      }
    }
  }
  return numericCount / columnData.length > 0.2 ? "numeric" : "categorical";
};

export const getTypesForColumns = (tableData: any[]): Selections => {
  if (!Array.isArray(tableData) || tableData.length === 0) {
    return {};
  }

  // Extract column names from the first row's keys
  const columnNames = Object.keys(tableData[0]);

  const selections: Selections = {};

  // Iterate over each column name to determine its type
  columnNames.forEach((columnName) => {
    const columnData = tableData.map((row) => row[columnName]);
    const type = determineType(columnData);
    // Select the first option based on the type
    selections[columnName] = type;
  });
  return selections;
};

// ----------------------------------------------------------------------------------------------
const ColumnActions: React.FC<TableDataDropdownProps> = ({ fileKey }) => {
  const talim_cleaning = useAppSelector(
    (state) => state.talim.talimCleaningSlice
  );

  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [dialogBoxKey, setDialogBoxKey] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const [selections, setSelections] = useState<Record<string, string>>(
    talim_cleaning.filesInfo[fileKey]?.columnActions ||
      getDefaultOptionsForColumns(
        talim_cleaning.filesInfo[fileKey].tableData.columns,
        talim_cleaning.filesInfo[fileKey].metaData
      )
  );

  const [lowLevelDetectionTypes, setLowLevelDetectionTypes] = useState<
    Record<string, string>
  >(
    talim_cleaning.filesInfo[fileKey]?.lowLevelDetectionTypes ||
      getDefaultDetectionOptionsForColumns(
        talim_cleaning.filesInfo[fileKey].tableData.columns,
        talim_cleaning.filesInfo[fileKey].metaData
      )
  );

  const [lowLevelDetectionValues, setLowLevelDetectionValues] = useState<
    Record<string, string>
  >(
    talim_cleaning.filesInfo[fileKey]?.lowLevelDetectionValues ||
      getDefaultLowDetectionValuesOptionsForColumns(
        talim_cleaning.filesInfo[fileKey].tableData.columns
      )
  );

  const [highLevelDetectionTypes, setHighLevelDetectionTypes] = useState<
    Record<string, string>
  >(
    talim_cleaning.filesInfo[fileKey]?.highLevelDetectionTypes ||
      getDefaultDetectionOptionsForColumns(
        talim_cleaning.filesInfo[fileKey].tableData.columns,
        talim_cleaning.filesInfo[fileKey].metaData
      )
  );

  const [highLevelDetectionValues, setHighLevelDetectionValues] = useState<
    Record<string, string>
  >(
    talim_cleaning.filesInfo[fileKey]?.highLevelDetectionValues ||
      getDefaultHighDetectionValuesOptionsForColumns(
        talim_cleaning.filesInfo[fileKey].tableData.columns
      )
  );

  const tableData = talim_cleaning.filesInfo[fileKey].tableData.data;

  const metaDataColumns = [
    talim_cleaning.filesInfo[talim_cleaning.selectedReferenceFile]?.metaData
      ?.drillhole,
    talim_cleaning.filesInfo[talim_cleaning.selectedReferenceFile]?.metaData
      ?.begin,
    talim_cleaning.filesInfo[talim_cleaning.selectedReferenceFile]?.metaData
      ?.end,
    talim_cleaning.filesInfo[talim_cleaning.selectedReferenceFile]?.metaData
      ?.pointDepth,
    "",
  ];

  const handleActionSelectionsChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string[] | null,
    action: string
  ) => {
    const newActions = newValue
      ? {
          ...selections, // Spread the existing selections
          ...newValue.reduce((acc, value) => {
            acc[value] = action; // Set the action for each selected value
            return acc;
          }, {} as Selections),
        }
      : selections;
    setSelections(newActions);
    dispatch(
      setFilesInfo({
        key: fileKey,
        info: {
          fileKey: talim_cleaning.filesInfo[fileKey].fileKey,
          metaData: talim_cleaning.filesInfo[fileKey].metaData,
          tableData: talim_cleaning.filesInfo[fileKey].tableData,
          columnActions: newActions,
          lowLevelDetectionTypes:
            talim_cleaning.filesInfo[fileKey].lowLevelDetectionTypes,
          highLevelDetectionTypes:
            talim_cleaning.filesInfo[fileKey].highLevelDetectionTypes,
          lowLevelDetectionValues:
            talim_cleaning.filesInfo[fileKey].lowLevelDetectionValues,
          highLevelDetectionValues:
            talim_cleaning.filesInfo[fileKey].highLevelDetectionValues,
        },
      })
    );
  };

  const handleSelectionChange =
    (columnName: string) => (event: SelectChangeEvent) => {
      const newActions = {
        ...selections,
        [columnName]: event.target.value,
      };
      setSelections(newActions);
      dispatch(
        setFilesInfo({
          key: fileKey,
          info: {
            fileKey: talim_cleaning.filesInfo[fileKey].fileKey,
            metaData: talim_cleaning.filesInfo[fileKey].metaData,
            tableData: talim_cleaning.filesInfo[fileKey].tableData,
            columnActions: newActions,
            lowLevelDetectionTypes:
              talim_cleaning.filesInfo[fileKey].lowLevelDetectionTypes,
            highLevelDetectionTypes:
              talim_cleaning.filesInfo[fileKey].highLevelDetectionTypes,
            lowLevelDetectionValues:
              talim_cleaning.filesInfo[fileKey].lowLevelDetectionValues,
            highLevelDetectionValues:
              talim_cleaning.filesInfo[fileKey].highLevelDetectionValues,
          },
        })
      );
    };

  const handleLowLevelDetectionTypeChange =
    (columnName: string) => (event: SelectChangeEvent) => {
      const newDetectionTypes = {
        ...lowLevelDetectionTypes,
        [columnName]: event.target.value,
      };
      setLowLevelDetectionTypes(newDetectionTypes);
      dispatch(
        setFilesInfo({
          key: fileKey,
          info: {
            fileKey: talim_cleaning.filesInfo[fileKey].fileKey,
            metaData: talim_cleaning.filesInfo[fileKey].metaData,
            tableData: talim_cleaning.filesInfo[fileKey].tableData,
            columnActions: talim_cleaning.filesInfo[fileKey].columnActions,
            lowLevelDetectionTypes: newDetectionTypes,
            highLevelDetectionTypes:
              talim_cleaning.filesInfo[fileKey].highLevelDetectionTypes,
            lowLevelDetectionValues:
              talim_cleaning.filesInfo[fileKey].lowLevelDetectionValues,
            highLevelDetectionValues:
              talim_cleaning.filesInfo[fileKey].highLevelDetectionValues,
          },
        })
      );
    };

  const handleLowLevelDetectionValueChange =
    (columnName: string) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newDetectionValues = {
        ...lowLevelDetectionValues,
        [columnName]: event.target.value,
      };
      setLowLevelDetectionValues(newDetectionValues);
      dispatch(
        setFilesInfo({
          key: fileKey,
          info: {
            fileKey: talim_cleaning.filesInfo[fileKey].fileKey,
            metaData: talim_cleaning.filesInfo[fileKey].metaData,
            tableData: talim_cleaning.filesInfo[fileKey].tableData,
            columnActions: talim_cleaning.filesInfo[fileKey].columnActions,
            lowLevelDetectionTypes:
              talim_cleaning.filesInfo[fileKey].lowLevelDetectionTypes,
            highLevelDetectionTypes:
              talim_cleaning.filesInfo[fileKey].highLevelDetectionTypes,
            lowLevelDetectionValues: newDetectionValues,
            highLevelDetectionValues:
              talim_cleaning.filesInfo[fileKey].highLevelDetectionValues,
          },
        })
      );
    };

  const handleHighLevelDetectionTypeChange =
    (columnName: string) => (event: SelectChangeEvent) => {
      const newDetectionTypes = {
        ...highLevelDetectionTypes,
        [columnName]: event.target.value,
      };
      setHighLevelDetectionTypes(newDetectionTypes);
      dispatch(
        setFilesInfo({
          key: fileKey,
          info: {
            fileKey: talim_cleaning.filesInfo[fileKey].fileKey,
            metaData: talim_cleaning.filesInfo[fileKey].metaData,
            tableData: talim_cleaning.filesInfo[fileKey].tableData,
            columnActions: talim_cleaning.filesInfo[fileKey].columnActions,
            lowLevelDetectionTypes:
              talim_cleaning.filesInfo[fileKey].lowLevelDetectionTypes,
            highLevelDetectionTypes: newDetectionTypes,
            lowLevelDetectionValues:
              talim_cleaning.filesInfo[fileKey].lowLevelDetectionValues,
            highLevelDetectionValues:
              talim_cleaning.filesInfo[fileKey].highLevelDetectionValues,
          },
        })
      );
    };

  const handleHighLevelDetectionValueChange =
    (columnName: string) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newDetectionValues = {
        ...highLevelDetectionValues,
        [columnName]: event.target.value,
      };
      setHighLevelDetectionValues(newDetectionValues);
      dispatch(
        setFilesInfo({
          key: fileKey,
          info: {
            fileKey: talim_cleaning.filesInfo[fileKey].fileKey,
            metaData: talim_cleaning.filesInfo[fileKey].metaData,
            tableData: talim_cleaning.filesInfo[fileKey].tableData,
            columnActions: talim_cleaning.filesInfo[fileKey].columnActions,
            lowLevelDetectionTypes:
              talim_cleaning.filesInfo[fileKey].lowLevelDetectionTypes,
            highLevelDetectionTypes:
              talim_cleaning.filesInfo[fileKey].highLevelDetectionTypes,
            highLevelDetectionValues: newDetectionValues,
            lowLevelDetectionValues:
              talim_cleaning.filesInfo[fileKey].lowLevelDetectionValues,
          },
        })
      );
    };

  /// Default Handlers

  const [defaultSelection, setDefaultSelection] = useState(options[0]);

  const handleDefaultSelectionChange = (event: SelectChangeEvent) => {
    setDefaultSelection(event.target.value);
    var newSelections = selections;
    Object.entries(selections).map(([columnName, type]) => {
      newSelections = {
        ...newSelections,
        [columnName]: event.target.value,
      };
    });
    setSelections(newSelections);
    dispatch(
      setFilesInfo({
        key: fileKey,
        info: {
          fileKey: talim_cleaning.filesInfo[fileKey].fileKey,
          metaData: talim_cleaning.filesInfo[fileKey].metaData,
          tableData: talim_cleaning.filesInfo[fileKey].tableData,
          columnActions: newSelections,
          lowLevelDetectionTypes:
            talim_cleaning.filesInfo[fileKey].lowLevelDetectionTypes,
          lowLevelDetectionValues:
            talim_cleaning.filesInfo[fileKey].lowLevelDetectionValues,
          highLevelDetectionTypes:
            talim_cleaning.filesInfo[fileKey].highLevelDetectionTypes,
          highLevelDetectionValues:
            talim_cleaning.filesInfo[fileKey].highLevelDetectionValues,
        },
      })
    );
  };

  // const [defaultLowLevelDetectionType, setDefaultLowLevelDetectionType] =
  //   useState(detectionOptions[0]);

  // const handleDefaultLowLevelDetectionTypeChange = (
  //   event: SelectChangeEvent
  // ) => {
  //   setDefaultLowLevelDetectionType(event.target.value);
  //   var newSelections = lowLevelDetectionTypes;
  //   Object.entries(lowLevelDetectionTypes).map(([columnName, type]) => {
  //     newSelections = {
  //       ...newSelections,
  //       [columnName]: event.target.value,
  //     };
  //   });
  //   setLowLevelDetectionTypes(newSelections);
  //   dispatch(
  //     setFilesInfo({
  //       key: fileKey,
  //       info: {
  //         fileKey: talim_cleaning.filesInfo[fileKey].fileKey,
  //         metaData: talim_cleaning.filesInfo[fileKey].metaData,
  //         tableData: talim_cleaning.filesInfo[fileKey].tableData,
  //         columnActions: talim_cleaning.filesInfo[fileKey].columnActions,
  //         lowLevelDetectionTypes: newSelections,
  //         lowLevelDetectionValues:
  //           talim_cleaning.filesInfo[fileKey].lowLevelDetectionValues,
  //         highLevelDetectionTypes:
  //           talim_cleaning.filesInfo[fileKey].highLevelDetectionTypes,
  //         highLevelDetectionValues:
  //           talim_cleaning.filesInfo[fileKey].highLevelDetectionValues,
  //       },
  //     })
  //   );
  // };

  // const [defaultHighLevelDetectionType, setDefaultHighLevelDetectionType] =
  //   useState(detectionOptions[0]);

  // const handleDefaultHighLevelDetectionTypeChange = (
  //   event: SelectChangeEvent
  // ) => {
  //   setDefaultHighLevelDetectionType(event.target.value);
  //   var newSelections = highLevelDetectionTypes;
  //   Object.entries(highLevelDetectionTypes).map(([columnName, type]) => {
  //     newSelections = {
  //       ...newSelections,
  //       [columnName]: event.target.value,
  //     };
  //   });
  //   setHighLevelDetectionTypes(newSelections);
  //   dispatch(
  //     setFilesInfo({
  //       key: fileKey,
  //       info: {
  //         fileKey: talim_cleaning.filesInfo[fileKey].fileKey,
  //         metaData: talim_cleaning.filesInfo[fileKey].metaData,
  //         tableData: talim_cleaning.filesInfo[fileKey].tableData,
  //         columnActions: talim_cleaning.filesInfo[fileKey].columnActions,
  //         highLevelDetectionTypes: newSelections,
  //         lowLevelDetectionValues:
  //           talim_cleaning.filesInfo[fileKey].lowLevelDetectionValues,
  //         lowLevelDetectionTypes:
  //           talim_cleaning.filesInfo[fileKey].lowLevelDetectionTypes,
  //         highLevelDetectionValues:
  //           talim_cleaning.filesInfo[fileKey].highLevelDetectionValues,
  //       },
  //     })
  //   );
  // };

  // Dialog Box

  const handleClickOpen = (key: string) => {
    setDialogBoxKey(key);
    setOpenDialogBox(true);
  };

  const handleClose = () => {
    setDialogBoxKey(null);
    setOpenDialogBox(false);
  };

  const getDialogText = (key: string) => {
    switch (key) {
      case "defaultActionPolicyDescription":
        return defaultActionPolicyDescription;
      case "actionsDescription":
        return actionsDescription;
      case "lowLevelDetectionDescriptions":
        return lowLevelDetectionDescriptions;
      case "highLevelDetectionDescriptions":
        return highLevelDetectionDescriptions;
      case "knnClustersDescription":
        return knnClustersDescription;
      case "invalidStringsDescription":
        return invalidStringsDescription;
      default:
        return "No info available!";
    }
  };

  //

  return (
    <div style={{ overflowX: "auto" }}>
      {/* <Typography>Select Default Cleaning Actions</Typography>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Default Cleaning Action</InputLabel>
            <Select
              value={defaultSelection}
              label={defaultSelection}
              onChange={handleDefaultSelectionChange}
            >
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Default Low Level Detection Type</InputLabel>
            <Select
              value={defaultLowLevelDetectionType}
              label={defaultLowLevelDetectionType}
              onChange={handleDefaultLowLevelDetectionTypeChange}
            >
              {detectionOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Default High Level Detection Type</InputLabel>
            <Select
              value={defaultHighLevelDetectionType}
              label={defaultHighLevelDetectionType}
              onChange={handleDefaultHighLevelDetectionTypeChange}
            >
              {detectionOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid> */}
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Default Cleaning Action</InputLabel>
          <Select
            value={defaultSelection}
            label={defaultSelection}
            onChange={handleDefaultSelectionChange}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography>Select Cleaning Actions</Typography>
        <Button
          variant="contained"
          onClick={() => handleClickOpen("actionsDescription")}
          size="small"
          sx={{
            ml: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HelpIcon />
        </Button>
      </Box>
      <Grid container spacing={2} wrap="wrap">
        {options.map((action) => (
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            key={action}
            style={{ minWidth: 500 }}
          >
            <FormControl fullWidth margin="normal">
              <Autocomplete
                multiple
                options={
                  talim_cleaning.filesInfo[
                    talim_cleaning.selectedReferenceFile
                  ]?.tableData.columns
                    .filter((element) =>
                      Object.keys(
                        Object.fromEntries(
                          Object.entries(
                            talim_cleaning.filesInfo[
                              talim_cleaning.selectedReferenceFile
                            ].columnActions
                          ).filter(([key, value]) => value != action)
                        )
                      ).includes(element)
                    )
                    .filter(
                      (columnName) => !metaDataColumns.includes(columnName)
                    ) || []
                  // .filter(
                  //   (columnName) =>
                  //     getTypesForColumns(
                  //       talim_cleaning.filesInfo[
                  //         talim_cleaning.selectedReferenceFile
                  //       ].tableData.data
                  //     )[columnName] == "numeric"
                  // ) || []
                }
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                value={
                  talim_cleaning.filesInfo[
                    talim_cleaning.selectedReferenceFile
                  ]?.tableData.columns
                    .filter(
                      (element) =>
                        !Object.keys(
                          Object.fromEntries(
                            Object.entries(
                              talim_cleaning.filesInfo[
                                talim_cleaning.selectedReferenceFile
                              ].columnActions
                            ).filter(([key, value]) => value != action)
                          )
                        ).includes(element)
                    )
                    .filter(
                      (columnName) => !metaDataColumns.includes(columnName)
                    )
                  // .filter(
                  //   (columnName) =>
                  //     getTypesForColumns(
                  //       talim_cleaning.filesInfo[
                  //         talim_cleaning.selectedReferenceFile
                  //       ].tableData.data
                  //     )[columnName] == "numeric"
                  // )
                }
                onChange={(event, newValue) =>
                  handleActionSelectionsChange(event, newValue, action)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={action}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                        >
                          |
                        </InputAdornment>
                      ),
                      sx: {
                        maxHeight: "200px",
                        overflowY: "auto",
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>

      <Box display="flex" alignItems="center" mb={2}>
        <Typography>Set Low Level Detection Info</Typography>
        <Button
          variant="contained"
          onClick={() => handleClickOpen("lowLevelDetectionDescriptions")}
          size="small"
          sx={{
            ml: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HelpIcon />
        </Button>
      </Box>
      <Grid container spacing={2} wrap="nowrap">
        {Object.keys(tableData[0]).map(
          (columnName) =>
            !Object.values(talim_cleaning.filesInfo[fileKey].metaData).includes(
              columnName
            ) && (
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                key={columnName}
                style={{ minWidth: 160 }}
              >
                <FormControl fullWidth margin="normal">
                  <InputLabel>{columnName}</InputLabel>
                  <Select
                    value={lowLevelDetectionTypes[columnName]}
                    label={columnName}
                    onChange={handleLowLevelDetectionTypeChange(columnName)}
                  >
                    {detectionOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )
        )}
      </Grid>
      <Grid container spacing={2} wrap="nowrap">
        {Object.keys(tableData[0]).map(
          (columnName) =>
            !Object.values(talim_cleaning.filesInfo[fileKey].metaData).includes(
              columnName
            ) && (
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                key={columnName}
                style={{ minWidth: 160 }}
              >
                <FormControl fullWidth margin="normal">
                  {/* <InputLabel>{columnName}</InputLabel> */}
                  <TextField
                    label={columnName}
                    value={lowLevelDetectionValues[columnName]}
                    disabled={
                      lowLevelDetectionTypes[columnName] == "None" ||
                      lowLevelDetectionTypes[columnName] == "Auto"
                    }
                    onChange={handleLowLevelDetectionValueChange(columnName)}
                    type="number"
                    inputProps={{
                      step: 0.1,
                      min: 0,
                      max: highLevelDetectionValues[columnName],
                    }}
                    fullWidth
                  />
                </FormControl>
              </Grid>
            )
        )}
      </Grid>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography>Set High Level Detection Info</Typography>
        <Button
          variant="contained"
          onClick={() => handleClickOpen("highLevelDetectionDescriptions")}
          size="small"
          sx={{
            ml: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HelpIcon />
        </Button>
      </Box>
      <Grid container spacing={2} wrap="nowrap">
        {Object.keys(tableData[0]).map(
          (columnName) =>
            !Object.values(talim_cleaning.filesInfo[fileKey].metaData).includes(
              columnName
            ) && (
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                key={columnName}
                style={{ minWidth: 160 }}
              >
                <FormControl fullWidth margin="normal">
                  <InputLabel>{columnName}</InputLabel>
                  <Select
                    value={highLevelDetectionTypes[columnName]}
                    label={columnName}
                    onChange={handleHighLevelDetectionTypeChange(columnName)}
                  >
                    {detectionOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )
        )}
      </Grid>
      <Grid container spacing={2} wrap="nowrap">
        {Object.keys(tableData[0]).map(
          (columnName) =>
            !Object.values(talim_cleaning.filesInfo[fileKey].metaData).includes(
              columnName
            ) && (
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                key={columnName}
                style={{ minWidth: 160 }}
              >
                <FormControl fullWidth margin="normal">
                  {/* <InputLabel>{columnName}</InputLabel> */}
                  <TextField
                    label={columnName}
                    value={highLevelDetectionValues[columnName]}
                    disabled={
                      highLevelDetectionTypes[columnName] == "None" ||
                      highLevelDetectionTypes[columnName] == "Auto"
                    }
                    onChange={handleHighLevelDetectionValueChange(columnName)}
                    type="number"
                    inputProps={{
                      step: 0.1,
                      min: lowLevelDetectionValues[columnName],
                      max: 100,
                    }}
                    fullWidth
                  />
                </FormControl>
              </Grid>
            )
        )}
      </Grid>
      <Dialog open={openDialogBox} onClick={handleClose}>
        <DialogContent>
          <DialogContentText>
            {getDialogText(dialogBoxKey || "")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ColumnActions;
