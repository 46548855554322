import React from "react";
import { Select, MenuItem } from "@mui/material";

interface DrillholeSelectorProps {
  drillholes: string[];
  selectedDrillhole: string;
  onDrillholeChange: (drillhole: string) => void;
}

const DrillholeSelector: React.FC<DrillholeSelectorProps> = ({
  drillholes,
  selectedDrillhole,
  onDrillholeChange,
}) => {
  return (
    <Select
      value={selectedDrillhole}
      onChange={(e) => onDrillholeChange(e.target.value as string)}
      displayEmpty
      fullWidth
      sx={{ mb: 2 }}
    >
      <MenuItem value="" disabled>
        Select Drillhole
      </MenuItem>
      {drillholes.map((drillhole) => (
        <MenuItem key={drillhole} value={drillhole}>
          {drillhole}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DrillholeSelector;
