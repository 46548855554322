import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Dialog,
  Card,
  CardContent,
  CardActionArea,
  Box,
  CircularProgress,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useGetProjectsQuery, ProjectItem } from "../api/endpoints/projectsApi";
import { setSelectedProject } from "./projectsSlice";
const ProjectsSelector: React.FC = () => {
  const projects = useAppSelector((state) => state.projects);
  const talim = useAppSelector((state) => state.talim.talimSlice);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { data: projectsData, isLoading } = useGetProjectsQuery(
    { companyName: projects.company || "" },
    { skip: !projects.company }
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProjectSelection = (project: ProjectItem) => {
    dispatch(setSelectedProject({ project: project }));
    handleClose();
  };

  const handleRemoveProjectSelection = () => {
    dispatch(setSelectedProject({ project: null }));
    handleClose();
  };

  return (
    <div style={{ marginTop: "-10px" }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: "#122649" }}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {projects.selectedProject
                ? `Company: ${projects.selectedProject.company} | Project: ${projects.selectedProject.title}`
                : "unselected"}
            </Typography>
            <Button color="inherit" onClick={handleOpen}>
              {projects.selectedProject ? "Change Selection" : "Select Project"}
            </Button>
          </Toolbar>
        </AppBar>

        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
          <Box sx={{ padding: 2 }}>
            <Button
              sx={{ width: "100%", marginBottom: 2 }}
              variant="outlined"
              color="error"
              onClick={handleRemoveProjectSelection}
            >
              Remove Selection
            </Button>
            {isLoading ? (
              <Box
                sx={{ display: "flex", justifyContent: "center", padding: 3 }}
              >
                <CircularProgress />
              </Box>
            ) : (
              projectsData?.items.map((projectItem) => (
                <Card
                  key={projectItem.title}
                  sx={{
                    marginBottom: 2,
                    backgroundColor:
                      projects.selectedProject?.title === projectItem.title
                        ? "#f0f0f0"
                        : "#fff",
                  }}
                >
                  <CardActionArea
                    onClick={() => handleProjectSelection(projectItem)}
                  >
                    <CardContent>
                      <Typography variant="h5">{projectItem.title}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        Description or other details here
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))
            )}
          </Box>
        </Dialog>
      </Box>
    </div>
  );
};

export default ProjectsSelector;
