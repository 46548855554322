import {
  ChangeEvent,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  FormControl,
  Autocomplete,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Chip,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setState } from "../smsSlice";
import { CheckBox } from "@mui/icons-material";

const CalculateConfigParams: React.FC = () => {
  const sms = useAppSelector((state) => state.sms.SMSSlice);
  const projects = useAppSelector((state) => state.projects);
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [dialogBoxKey, setDialogBoxKey] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const handleDecimalDigitsChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "decimal_digits",
        value: event.target.value as unknown as number,
      })
    );
  };

  const handleColumnsOfInterestChange = (
    event: SyntheticEvent<Element, Event>,
    value: string[], // The selected values from the multiselect
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string>
  ) => {
    dispatch(
      setState({
        key: "columns_of_interest",
        value: value as string[], // Assuming the selected values are strings
      })
    );
  };

  const handleColumnsToConvertChange = (
    event: SyntheticEvent<Element, Event>,
    value: string[], // The selected values from the multiselect
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string>
  ) => {
    dispatch(
      setState({
        key: "columns_to_convert",
        value: value,
      })
    );
  };

  const handleColumnsToCleanChange = (
    event: SyntheticEvent<Element, Event>,
    value: string[], // The selected values from the multiselect
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string>
  ) => {
    dispatch(
      setState({
        key: "columns_to_clean",
        value: value as unknown as string[], // Assuming the selected values are strings
      })
    );
  };

  const handleTargetUnitChange = (
    event: SelectChangeEvent<"pct" | "ppm" | "ppb">, // Use SelectChangeEvent with explicit types
    child: ReactNode
  ) => {
    dispatch(
      setState({
        key: "target_unit",
        value: event.target.value,
      })
    );
  };

  const handleMissingValuesTreatmentChange = (
    event: SelectChangeEvent<"drop" | "fill">,
    child: ReactNode
  ) => {
    dispatch(
      setState({
        key: "missing_values_treatment",
        value: event.target.value,
      })
    );
  };

  const handleS3SaveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "save_name",
        value: event.target.value as string,
      })
    );
  };

  const [inputSuffix, setInputSuffix] = useState<string>("");
  const [selectedSuffixes, setSelectedSuffixes] = useState<string[]>(
    sms.suffixes_to_remove || []
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSuffix(event.target.value);
  };

  const handleAddSuffix = () => {
    if (inputSuffix && !selectedSuffixes.includes(inputSuffix)) {
      setSelectedSuffixes([...selectedSuffixes, inputSuffix]);
      setInputSuffix("");
      dispatch(
        setState({
          key: "suffixes_to_remove",
          value: selectedSuffixes as string[],
        })
      );
    }
  };

  const handleDeleteSuffix = (suffixToDelete: string) => {
    setSelectedSuffixes(
      selectedSuffixes.filter((str) => str !== suffixToDelete)
    );
    dispatch(
      setState({
        key: "suffixes_to_remove",
        value: selectedSuffixes.filter(
          (str) => str !== suffixToDelete
        ) as string[],
      })
    );
  };

  const handleClickOpen = (key: string) => {
    setDialogBoxKey(key);
    setOpenDialogBox(true);
  };

  const handleClose = () => {
    setDialogBoxKey(null);
    setOpenDialogBox(false);
  };

  const getDialogText = (key: string) => {
    switch (key) {
      case "intervalizationRadius":
        return (
          <span>
            <strong>Intervalization Radius: </strong>The radius to recast points
            to fixed-size intervals (only applicable to point data){" "}
          </span>
        );
      case "loadLithoLensReports":
        return (
          <span>
            <strong>Load Litholens Reports: </strong>Check if you want to read
            reports directly from LithoLens DataBases.
            <br />
            <br />
            <strong>Note 1: </strong>Getting RoQENet and VeiNet reports without
            texture and colour reports is not available in this version.
            <br />
            <strong>Note 2: </strong>To use these options, you need to attach a
            LithoLens project to your workspace first. Make sure that your
            selected project has these reports available.
            <br />
          </span>
        );
      case "outputRegistration":
        return (
          <span>
            <strong>Litholens: </strong>Check if you want to track your merged
            data through the LithoLens DataBase.
            <br />
            <br />
            <strong>LogsAPI: </strong>Check if you want to log your merged data
            into MongoDB.
            <br />
            <br />
            <strong>Note: </strong>To use the options above, you need to attach
            a LithoLens project to your workspace first. Make sure that your
            selected project has these reports available.
            <br />
          </span>
        );
      case "saveNames":
        return (
          <span>
            <strong>Notification E-Mail: </strong>If a validated Email is given,
            a notification will be sent to the given Email when the job is
            completed.
            <br />
            <br />
            <strong>S3 Save Name (required): </strong>The name of your merged
            file
            <br />
            <br />
            <strong>LogsAPI descriptive name: </strong>If you seek to log your
            merged data in MongoDB, you can give a description to it.
            <br />
          </span>
        );
      default:
        return "No info available!";
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "40ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl fullWidth margin="normal">
          <Autocomplete
            multiple
            options={
              sms.filesInfo[sms.selectedAssayFile]
                ? sms.filesInfo[sms.selectedAssayFile].tableData.columns || []
                : []
            }
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            value={sms.columns_of_interest || []}
            onChange={handleColumnsOfInterestChange}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Columns of Interest"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    maxHeight: "200px",
                    overflowY: "auto",
                  },
                }}
              />
            )}
            fullWidth
          />
        </FormControl>
      </Box>
      <Typography>Assay Unit Convertion</Typography>
      <div>
        <FormControl fullWidth margin="normal">
          <Autocomplete
            multiple
            options={sms.columns_of_interest || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            value={sms.columns_to_convert || []}
            onChange={handleColumnsToConvertChange}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Columns to Convert"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    maxHeight: "200px",
                    overflowY: "auto",
                  },
                }}
              />
            )}
            fullWidth
          />
        </FormControl>
        <FormControl
          variant="standard"
          disabled={
            sms.columns_to_convert === null ||
            (Array.isArray(sms.columns_to_convert) &&
              sms.columns_to_convert.length === 0)
          }
        >
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Target Unit
          </InputLabel>
          <Select value={sms.target_unit} onChange={handleTargetUnitChange}>
            <MenuItem value={"pct"}>Percentage</MenuItem>
            <MenuItem value={"ppm"}>Parts Per Million (ppm)</MenuItem>
            <MenuItem value={"ppb"}>Parts Per Billion (ppb)</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Typography>Assay Cleaning</Typography>
      <FormControl fullWidth margin="normal">
        <Autocomplete
          multiple
          options={sms.columns_of_interest || []}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          value={sms.columns_to_clean || []}
          onChange={handleColumnsToCleanChange}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Columns to Clean"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                sx: {
                  maxHeight: "200px",
                  overflowY: "auto",
                },
              }}
            />
          )}
          fullWidth
        />
      </FormControl>

      <FormControl variant="standard">
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          Missing Values Treatment
        </InputLabel>
        <Select
          value={sms.missing_values_treatment}
          onChange={handleMissingValuesTreatmentChange}
        >
          <MenuItem value={"drop"}>Drop</MenuItem>
          <MenuItem value={"fill"}>Fill with zero</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ maxWidth: 500, mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Suffixes to Remove
        </Typography>
        <Box display="flex" alignItems="center" mb={2}>
          <TextField
            label="Add a suffix"
            variant="outlined"
            value={inputSuffix}
            onChange={handleInputChange}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddSuffix}
            sx={{ ml: 2 }}
          >
            Add
          </Button>
        </Box>
        <div style={{ overflowX: "auto" }}>
          {selectedSuffixes.map((str) => (
            <Chip
              key={str}
              label={str}
              onDelete={() => handleDeleteSuffix(str)}
              color="primary"
            />
          ))}
        </div>
      </Box>

      <Dialog open={openDialogBox} onClick={handleClose}>
        <DialogContent>
          <DialogContentText>
            {getDialogText(dialogBoxKey || "")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={handleClose}
          ></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CalculateConfigParams;
