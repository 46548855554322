import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../redux/store";
import { S3File } from "../../../../../components/S3/S3Utils";
import { WritableDraft } from "immer";

export interface TableData {
  columns: string[];
  data: any[];
}

export interface FileInfo {
  fileKey: string;
  metaData: any;
  tableData: TableData;
}

export interface talimEdaState {
  files: S3File[];
  filesInfo: { [key: string]: FileInfo };
  selectedReferenceFile: string;
  feature_families_method: string;
  feature_families_distance_metric: string;
  feature_correlation_method: string;
  feature_families_scaling: true;
  register_to_litholens: false;
  register_to_logsapi: false;
  descriptive_log_name: string;
  notification_email: string | null;
  structures_to_process: [] | null;
  drillholes_to_process: [] | null;
  s3_data_save_path: string | null;
  litholens_save_path_type: "s3" | "logsAPI" | null;
}

const initialState: talimEdaState = {
  files: [],
  filesInfo: {},
  selectedReferenceFile: "",
  feature_families_method: "ward",
  feature_families_distance_metric: "euclidean",
  feature_correlation_method: "pearson",
  feature_families_scaling: true,
  register_to_litholens: false,
  register_to_logsapi: false,
  descriptive_log_name: "new_log",
  notification_email: "",
  structures_to_process: null,
  drillholes_to_process: null,
  s3_data_save_path: null,
  litholens_save_path_type: "s3",
};

type TalimEdaStateKey = keyof talimEdaState;

export const talimEdaSlice = createSlice({
  name: "talim_eda",
  initialState: initialState,
  reducers: {
    // -------------------------------------------------------------------------------
    // General setter for updating any state attribute
    setState: <T extends TalimEdaStateKey>(
      state: WritableDraft<talimEdaState>,
      action: PayloadAction<{ key: T; value: talimEdaState[T] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    // -------------------------------------------------------------------------------
    setSelectedReferenceFile: (
      state,
      action: PayloadAction<{ key: string }>
    ) => {
      state.selectedReferenceFile = action.payload.key;
    },
    // -------------------------------------------------------------------------------
    setFiles: (state, action: PayloadAction<{ files: S3File[] }>) => {
      state.files = action.payload.files;
    },
    // -------------------------------------------------------------------------------
    setFilesInfo: (
      state,
      action: PayloadAction<{ key: string; info: FileInfo }>
    ) => {
      state.filesInfo[action.payload.key] = action.payload.info;
    },
    // -------------------------------------------------------------------------------
  },
});

export const selectTalim = (state: RootState) => state.talim.talimEdaSlice;

// Action creators are generated for each case reducer function
export const { setState, setSelectedReferenceFile, setFiles, setFilesInfo } =
  talimEdaSlice.actions;

export default talimEdaSlice.reducer;
