import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  RunServiceRequest,
  useRunServiceMutation,
} from "../../../../api/endpoints/superApi";
import { Button, Alert, Collapse } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import TSMConfigParams from "./tsmConfigParams";
import {
  JobItem,
  createJobItem,
} from "../../../../../components/Jobs/jobUtils";
import { writeJsonToS3 } from "../../../../../components/S3/S3Utils";

// --------------------------------------------------------------------------------------------------
interface ApiRequest {
  environment: string;
  // job_item: JobItem;
  dataset_path: string;
  model_arch: string;
  backbone: string;
  optimizer: string;
  learning_rate: number;
  batch_size: number;
  s3_model_save_path: string;
  model_save_name: string;
  max_epochs: number;
  s3_pretrained_weights_path: string | null;
  num_workers: number;
  img_height: number;
  img_width: number;
}

// --------------------------------------------------------------------------------------------------
interface TSMRequestProps {
  bucketName: string;
  pathPrefix: string;
}

// --------------------------------------------------------------------------------------------------
const TSMRequest: React.FC<TSMRequestProps> = ({ bucketName, pathPrefix }) => {
  const tsm = useAppSelector((state) => state.tsm.TSMSlice);
  const dispatch = useAppDispatch();

  // --------------------------------------------------------------------------------------------------
  // Job Item & API Request

  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);

  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = "logs.tsm";
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);
  const [lastJobID, setLastJobID] = useState<string | null>(null);
  const env = process.env.REACT_APP_ENV || "test";

  // --------------------------------------------------------------------------------------------------
  const [apiRequest, setApiRequest] = useState<ApiRequest>({
    environment: env,
    dataset_path:
      "s3://" + bucketName + "/" + pathPrefix + "/datasets/" + tsm.dataset_name,
    model_arch: tsm.model_arch,
    backbone: tsm.backbone,
    optimizer: tsm.optimizer,
    learning_rate: tsm.learning_rate,
    batch_size: tsm.batch_size,
    s3_model_save_path: "s3://" + bucketName + "/" + pathPrefix + "/models",
    model_save_name: tsm.model_save_name,
    max_epochs: tsm.max_epochs,
    s3_pretrained_weights_path: tsm.s3_pretrained_weights_path,
    num_workers: tsm.num_workers,
    img_height: tsm.img_height,
    img_width: tsm.img_width,
  });

  // --------------------------------------------------------------------------------------------------
  // Send API Request
  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const handleSubmit = async (requestData: RunServiceRequest) => {
    console.log(JSON.stringify(requestData));
    runServiceMutation(requestData);
    const fixedItem = requestData.job_item as JobItem;
    setLastJobID(fixedItem.id);
    const pendingMessage = { status: "requested" };
    writeJsonToS3(
      bucketName,
      pathPrefix + "/responses/tsm_main/" + fixedItem.id + ".json",
      pendingMessage
    );
  };

  const log = (type: any) => console.log.bind(console, type);

  // --------------------------------------------------------------------------------------------------
  const [open, setOpen] = useState(false);
  const handleAlertClose = (event: SyntheticEvent) => {
    setOpen(false);
  };

  useEffect(() => {
    setApiRequest({
      ...apiRequest,
      dataset_path:
        "s3://" +
        bucketName +
        "/" +
        pathPrefix +
        "/datasets/" +
        tsm.dataset_name,
      model_arch: tsm.model_arch,
      backbone: tsm.backbone,
      optimizer: tsm.optimizer,
      learning_rate: tsm.learning_rate,
      batch_size: tsm.batch_size,
      // s3_model_save_path: tsm.s3_model_save_path,
      model_save_name: tsm.model_save_name,
      max_epochs: tsm.max_epochs,
      s3_pretrained_weights_path: tsm.s3_pretrained_weights_path,
      num_workers: tsm.num_workers,
      img_height: tsm.img_height,
      img_width: tsm.img_width,
    });
  }, [tsm]);

  useEffect(() => {
    setOpen(true);
  }, [runServiceMutationResult]);

  // --------------------------------------------------------------------------------------------------
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <TSMConfigParams bucketName={bucketName} pathPrefix={pathPrefix} />
      <Button
        variant="contained"
        onClick={(e) =>
          handleSubmit({
            service: "ll_run_tsm_main",
            request_body: apiRequest,
            job_item: jobItem,
            environment: env,
            spot: false,
          } as RunServiceRequest)
        }
        onError={log("errors")}
        disabled={runServiceMutationResult.isLoading}
      >
        Send Request
      </Button>
      <Collapse in={open}>
        {(runServiceMutationResult?.isSuccess ||
          runServiceMutationResult?.isError) && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity={runServiceMutationResult?.isSuccess ? "success" : "error"}
            onClose={handleAlertClose}
          >
            {runServiceMutationResult?.isSuccess
              ? "Request sent successfully! Your request ID is " + lastJobID
              : "Request Sending has been ecnountered an error!"}
          </Alert>
        )}
      </Collapse>
      {/* <pre>{JSON.stringify(apiRequest, null, 2)}</pre> */}
    </div>
  );
};

export default TSMRequest;
