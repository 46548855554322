import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { setUserProjects } from "./projectsSlice";
import { ProjectItem, useGetProjectsQuery } from "../api/endpoints/projectsApi";

const ProjectDetailsPage: React.FC = () => {
  const { projectName } = useParams<{ projectName: string }>();
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();

  const {
    data: projectsData,
    error: projectsError,
    isLoading,
    isFetching,
    refetch,
  } = useGetProjectsQuery({ companyName: "goldspot" });

  useEffect(() => {
    if (projectsData) {
      projectsData.items.forEach((projectItem: ProjectItem) => {
        dispatch(
          setUserProjects({
            project: projectItem.id,
            projectItem: projectItem,
          })
        );
      });
    }
  }, [projectsData, dispatch]);

  const project = projectName ? projects.userProjects[projectName] : null;

  const renderProjectDetail = (label: string, value: any) => (
    <Grid item xs={12} key={label}>
      <Typography variant="body1">
        {label}: {value}
      </Typography>
    </Grid>
  );

  return project ? (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          {project.title}
        </Typography>
        <Grid container spacing={2}>
          {Object.entries(project).map(([key, value]) => {
            if (key !== "title") {
              let displayValue = value;
              if (key === "createdAt") {
                displayValue = new Date(value).toLocaleString();
              }
              return renderProjectDetail(
                key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase()),
                displayValue
              );
            }
            return null;
          })}
        </Grid>
      </CardContent>
    </Card>
  ) : (
    <div>
      <CircularProgress />
    </div>
  );
};

export default ProjectDetailsPage;
