import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../redux/store";

export const apiSlice = createApi({
  reducerPath: "api", // optional
  baseQuery: fetchBaseQuery({
    baseUrl: "http://superapi.litholens.com:8000",
    prepareHeaders: (headers, { getState }) => {
      // const authToken = (getState() as RootState).auth.cognitoIdToken;
      // console.log(authTokens?.access);
      // if (authToken) {
      //   headers.set("Authorization", `Token ${authToken.auth_token}`);
      // }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints: (builder) => ({}),
});
