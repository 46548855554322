import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer";
import { RootState } from "../../redux/store";
import {
  DrillholeItem,
  ImageItem,
  ProjectItem,
} from "../api/endpoints/projectsApi";

export interface ProjectsState {
  company: string | null;
  userProjects: { [project: string]: ProjectItem };
  projectsDrillholes: { [project: string]: DrillholeItem[] };
  projectsImages: { [project: string]: { [drillholeId: number]: ImageItem[] } };
  selectedProject: ProjectItem | null;
  selectedDrillholes: { [service: string]: DrillholeItem[] };
  selectedImages: { [service: string]: ImageItem[] };
}

const company = localStorage.getItem("company");
const selectedProject = localStorage.getItem("current_project");

const initialState: ProjectsState = {
  company: company,
  userProjects: {},
  projectsDrillholes: {},
  projectsImages: {},
  selectedProject: selectedProject ? JSON.parse(selectedProject) : null,
  selectedDrillholes: {},
  selectedImages: {},
};

type ProjectsStateKey = keyof ProjectsState;

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    // -------------------------------------------------------------------------------
    // General setter for updating any state attribute
    setState: <T extends ProjectsStateKey>(
      state: WritableDraft<ProjectsState>,
      action: PayloadAction<{ key: T; value: ProjectsState[T] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    // -------------------------------------------------------------------------------
    setUserProjects: (
      state,
      action: PayloadAction<{ project: string; projectItem: ProjectItem }>
    ) => {
      state.userProjects[action.payload.project] = action.payload.projectItem;
    },
    // -------------------------------------------------------------------------------
    setProjectDrillholes: (
      state,
      action: PayloadAction<{ project: string; drillholes: DrillholeItem[] }>
    ) => {
      state.projectsDrillholes[action.payload.project] =
        action.payload.drillholes;
    },
    // -------------------------------------------------------------------------------
    setProjectImages: (
      state,
      action: PayloadAction<{
        project: string;
        drillholeId: number;
        images: ImageItem[];
      }>
    ) => {
      state.projectsImages[action.payload.project][action.payload.drillholeId] =
        action.payload.images;
    },
    // -------------------------------------------------------------------------------
    setCompany: (state, action: PayloadAction<{ company: string | null }>) => {
      state.company = action.payload.company;
      if (action.payload.company) {
        localStorage.setItem("company", action.payload.company);
      }
    },
    // -------------------------------------------------------------------------------
    setSelectedProject: (
      state,
      action: PayloadAction<{ project: ProjectItem | null }>
    ) => {
      state.selectedProject = action.payload.project;
      localStorage.setItem(
        "current_project",
        JSON.stringify(action.payload.project)
      );
    },
    // -------------------------------------------------------------------------------
    setSelectedDrillholes: (
      state,
      action: PayloadAction<{ service: string; drillholes: DrillholeItem[] }>
    ) => {
      state.selectedDrillholes[action.payload.service] =
        action.payload.drillholes;
    },
    // -------------------------------------------------------------------------------
    setSelectedImages: (
      state,
      action: PayloadAction<{ service: string; images: ImageItem[] }>
    ) => {
      state.selectedImages[action.payload.service] = action.payload.images;
    },
    // -------------------------------------------------------------------------------
  },
});

export const selectProjects = (state: RootState) => state.projects;

// Action creators are generated for each case reducer function
export const {
  setState,
  setProjectDrillholes,
  setProjectImages,
  setUserProjects,
  setSelectedProject,
  setSelectedDrillholes,
  setSelectedImages,
  setCompany,
} = projectsSlice.actions;

export default projectsSlice.reducer;
