import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setState } from "./talimDrSlice";

const DrConfigParams: React.FC = () => {
  const talim_dr = useAppSelector((state) => state.talim.talimDrSlice);
  const dispatch = useAppDispatch();

  const maxDimensions =
    talim_dr.filesInfo[talim_dr.selectedReferenceFile]?.tableData.columns
      .length;
  // --------------------------------------------------------------------------------------------------
  // Shared Parameters

  const handleDestinationDimensionsChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      if (talim_dr.method == "tsne" && parseInt(newValue) > 3) {
        dispatch(
          setState({
            key: "destination_dimensions",
            value: 3,
          })
        );
      }
      dispatch(
        setState({
          key: "destination_dimensions",
          value: parseInt(newValue),
        })
      );
    }
  };

  const handleDimensionReductionMethodChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "method",
        value: event.target.value as string,
      })
    );
  };

  const handleRegisterToLithoLensChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "register_to_litholens",
        value: checked,
      })
    );
  };

  const handleRegisterToLogsApiChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "register_to_logsapi",
        value: checked,
      })
    );
  };

  const handleNotificationEmailChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "notification_email",
        value: event.target.value as string,
      })
    );
  };

  const handleS3SaveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "s3_data_save_name",
        value: event.target.value as string,
      })
    );
  };

  const handleLogDescriptiveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "descriptive_log_name",
        value: event.target.value as string,
      })
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Set DR API Parameters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
          >
            <FormControl variant="standard">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Dimension Reduction Method
              </InputLabel>
              <Select
                defaultValue={talim_dr.method}
                onChange={handleDimensionReductionMethodChange}
              >
                <MenuItem value={"pca"}>
                  Principal Component Analysis (PCA)
                </MenuItem>
                <MenuItem value={"ica"}>
                  Independent Component Analysis (ICA)
                </MenuItem>
                <MenuItem value={"tsne"}>
                  t-distributed stochastic neighbor embedding (t-SNE)
                </MenuItem>
                <MenuItem value={"umap"}>
                  Uniform Manifold Approximation and Projection (UMAP)
                </MenuItem>
                <MenuItem value={"pacmap"}>
                  Pairwise Controlled Manifold Approximation (PACMAP)
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard">
              <TextField
                label="Number of target dimensions"
                variant="outlined"
                type="number"
                // defaultValue={destinationDimensions}
                value={talim_dr.destination_dimensions}
                onChange={handleDestinationDimensionsChange}
                inputProps={{
                  // Optional: Define min and max values
                  min: 2, // Minimum value
                  max:
                    talim_dr.method == "tsne"
                      ? 3
                      : maxDimensions
                      ? maxDimensions
                      : 2, // Maximum value
                  step: 1, // Step increment for keyboard arrows
                }}
              />
            </FormControl>
          </Box>
          <Typography>{}</Typography>
          <Typography>Output Registration Options</Typography>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControlLabel
              control={
                <Switch
                  value="register_to_litholens"
                  checked={talim_dr.register_to_litholens}
                  onChange={handleRegisterToLithoLensChange}
                />
              }
              label="LithoLens"
            />
            <FormControlLabel
              control={
                <Switch
                  value="register_to_logsapi"
                  checked={talim_dr.register_to_logsapi}
                  onChange={handleRegisterToLogsApiChange}
                />
              }
              label="LogsAPI"
            />
          </Box>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="notification-email"
              label="Notification E-Mail"
              defaultValue=""
              onChange={handleNotificationEmailChange}
            />
            <TextField
              id="s3-save-name"
              label="S3 Save Name"
              defaultValue={talim_dr.s3_data_save_name}
              onChange={handleS3SaveNameChange}
            />
            <TextField
              id="logs-descriptive-name"
              label="LogsAPI descriptive name"
              defaultValue=""
              onChange={handleLogDescriptiveNameChange}
              disabled={!talim_dr.register_to_logsapi}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DrConfigParams;
