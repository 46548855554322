import React, { useState } from "react";
import PercentageReportPage from "./percentage_report/percentageReportPage";
import TextureReportPage from "./texture_report/textureReportPage";
import RoqenetReportPage from "./roqenet_report/roqenet_report";
import VeinnetReportPage from "./veinnet_report/veinnet_report";
import ActiveLearningReportPage from "./active_learning_report/active_learning_report";
import ColorExtractionPage from "./percentage_report/colorExtractionPage";
import ColorClusteringPage from "./percentage_report/colorClusteringPage";
import DesurveysPage from "./desurveys/desurveysPage";

interface Tab {
  title: string;
  component: React.ReactNode;
}

const ReportsPage: React.FC = () => {
  const tabs: Tab[] = [
    {
      title: "Desurveys",
      component: <DesurveysPage />,
    },
    {
      title: "Color Extraction",
      component: <ColorExtractionPage />,
    },
    {
      title: "Color Clustering",
      component: <ColorClusteringPage />,
    },
    {
      title: "Percentage Report",
      component: <PercentageReportPage />,
    },
    {
      title: "Texture Report",
      component: <TextureReportPage />,
    },
    {
      title: "VeiNet Report",
      component: <VeinnetReportPage />,
    },
    {
      title: "RoqeNet Report",
      component: <RoqenetReportPage />,
    },
    {
      title: "Active Learning Report",
      component: <ActiveLearningReportPage />,
    },
  ];

  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div style={{ width: "200px", background: "#f0f0f0", padding: "10px" }}>
        {/* Tab titles */}
        {tabs.map((tab, index) => (
          <div
            key={index}
            style={{
              padding: "10px",
              cursor: "pointer",
              backgroundColor: activeTab === index ? "#ddd" : "transparent",
            }}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div style={{ flex: 1, padding: "10px" }}>
        {/* Tab content */}
        <h3 style={{ margin: "20px", textAlign: "center" }}>
          {tabs[activeTab].title}
        </h3>
        {tabs[activeTab].component}
      </div>
    </div>
  );
};

export default ReportsPage;

// Example of another component you might want to include
const SampleComponent: React.FC = () => {
  return <div>Sample Content</div>;
};
