import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  fetchFiles,
  S3File,
} from "../../../../components/S3/S3Utils";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Collapse,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import {
  RunServiceRequest as RunKuberServiceRequest,
  useRunServiceMutation as useRunKuberServiceMutation,
} from "../../../api/endpoints/kuberApi";



interface XRDFileProps {
  bucketName: string;
  pathPrefix: string;
}

interface FileConvertResponse {
  message: string;
  status: string;
  response: {
    successful_files: string[];
    failed_files: string[];
  }
}

const XRDFileConvert: React.FC<XRDFileProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const [files, setFiles] = useState<S3File[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);
  const [runKuberServiceMutation, runKuberServiceMutationResult] =
    useRunKuberServiceMutation();

  // Accessing runKuberServiceMutationResult with explicit type assertion
  const typedRunKuberServiceMutationResult = runKuberServiceMutationResult as {
    data?: FileConvertResponse;
    isSuccess?: boolean;
    isError?: boolean;
    isLoading?: boolean;
  };

  useEffect(() => {
    const loadFiles = async () => {
      setIsLoading(true);
      try {
        const fetchedFiles = await fetchFiles(
          bucketName,
          pathPrefix + "/data",
          ["brml"]
        );
        setFiles(fetchedFiles.filter((file) => file.Key.endsWith(".brml")));
      } catch (error) {
        console.error("Failed to fetch files:", error);
      }
      setIsLoading(false);
    };
    loadFiles();
  }, [bucketName, pathPrefix]);

  const handleFileSelect = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const key = event.target.value as string;
    setSelectedFile(key);
    setIsSaveDisabled(false);
  };

  const [open, setOpen] = useState(false);
  const handleAlertClose = (event: SyntheticEvent) => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);
  }, [typedRunKuberServiceMutationResult]);

  const convertFile = async () => {
    setIsLoading(true);
    setSuccess(false);
    const s3FilePath = "s3://" + bucketName + "/" + selectedFile;
    const s3SavePath = "s3://" + bucketName + "/" + pathPrefix + "/extracted_data";
    const kuberRequestData = {
      service: "xrd/extract_file_contents",
      request_body: {
        input_path: s3FilePath,
        save_to_s3: s3SavePath,
      }
    } as RunKuberServiceRequest;

    try {
      await runKuberServiceMutation(kuberRequestData);
      setSuccess(true);
    } catch (error) {
      console.error("Failed to convert file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "20px",
        minHeight: "150vh",
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="file-select-label">Select File</InputLabel>
        <Select
          labelId="file-select-label"
          value={selectedFile}
          onChange={(event) =>
            handleFileSelect(event as React.ChangeEvent<{ value: unknown }>)
          }
          displayEmpty
          label="Select File"
        >
          {files.map((file) => (
            <MenuItem key={file.Key} value={file.Key}>
              {file.Key.replace(pathPrefix + "/data" + "/", "")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
      >
        <LoadingButton
          onClick={convertFile}
          loading={isLoading}
          variant="contained"
          color="primary"
          disabled={isSaveDisabled || typedRunKuberServiceMutationResult.isLoading}
        >
          Convert Files
        </LoadingButton>
        <Collapse in={open}>
          {(typedRunKuberServiceMutationResult?.isSuccess ||
            typedRunKuberServiceMutationResult?.isError) && (
              <Alert
                icon={<CheckIcon fontSize="inherit" />}
                severity={
                  typedRunKuberServiceMutationResult?.isSuccess ? "success" : "error"
                }
                onClose={handleAlertClose}
              >
                {typedRunKuberServiceMutationResult?.isSuccess &&
                  typedRunKuberServiceMutationResult?.data?.response.successful_files.length === 1
                  ? "File Convertion successful!"
                  : "File Convertion failed!"}

              </Alert>
            )}
        </Collapse>
        {success && <p>Data saved successfully!</p>}
      </div>
    </div>
  );
};

export default XRDFileConvert;
