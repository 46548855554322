import React from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, Control } from "react-hook-form";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const CustomTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 16,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    maxWidth: 220,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
  },
}));

interface TextInputProps {
  name: string;
  control: Control<any>;
  label: string;
  type?: string;
  rules?: any;
  helpText?: string;
}

export const TextInput: React.FC<TextInputProps> = ({
  name,
  control,
  label,
  type = "text",
  rules,
  helpText,
}) => {
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                {...field}
                label={label}
                type={type}
                fullWidth
                value={field.value !== undefined ? field.value : ""}
              />
              {helpText && (
                <CustomTooltip title={helpText}>
                  <IconButton>
                    <HelpOutlineIcon />
                  </IconButton>
                </CustomTooltip>
              )}
            </div>
            {fieldState.error && (
              <Typography variant="body2" color="error">
                {fieldState.error.message}
              </Typography>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

interface SelectInputProps {
  name: string;
  control: Control<any>;
  label: string;
  options: { value: string | number; label: string }[];
  rules?: any;
  isLoading?: boolean;
  helpText?: string;
}

export const SelectInput: React.FC<SelectInputProps> = ({
  name,
  control,
  label,
  options,
  rules,
  isLoading,
  helpText,
}) => {
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputLabel>{label}</InputLabel>
              {helpText && (
                <CustomTooltip title={helpText}>
                  <IconButton>
                    <HelpOutlineIcon />
                  </IconButton>
                </CustomTooltip>
              )}
            </div>
            <Select {...field} label={label} value={field.value || ""}>
              {isLoading ? (
                <MenuItem disabled>
                  <CircularProgress size={24} />
                </MenuItem>
              ) : (
                options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              )}
            </Select>
            {fieldState.error && (
              <Typography variant="body2" color="error">
                {fieldState.error.message}
              </Typography>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

interface BooleanSwitchInputProps {
  name: string;
  control: Control<any>;
  label: string;
  rules?: any;
  helpText?: string;
}

export const BooleanSwitchInput: React.FC<BooleanSwitchInputProps> = ({
  name,
  control,
  label,
  rules,
  helpText,
}) => {
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>{label}</Typography>
              <Switch {...field} checked={field.value || false} />
              {helpText && (
                <CustomTooltip title={helpText}>
                  <IconButton>
                    <HelpOutlineIcon />
                  </IconButton>
                </CustomTooltip>
              )}
            </div>
            {fieldState.error && (
              <Typography variant="body2" color="error">
                {fieldState.error.message}
              </Typography>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

interface AccordionSectionProps {
  title: string;
  children: React.ReactNode;
}

export const AccordionSection: React.FC<AccordionSectionProps> = ({
  title,
  children,
}) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {children}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
