import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../redux/store";
import config, { ENVIRONMENT } from "../../config";
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from "@aws-sdk/client-secrets-manager";

const kuberApiUrl = config[ENVIRONMENT].kuberApiUrl;

async function getSecretValue(secretName: string): Promise<string | undefined> {
  // Initialize the AWS Secrets Manager client
  const client = new SecretsManagerClient({ region: "us-east-1" }); // Replace "us-east-1" with your AWS region

  try {
    // Get the secret value
    const command = new GetSecretValueCommand({ SecretId: secretName });
    const response = await client.send(command);

    // Check if the secret is in string format
    if (response.SecretString) {
      // Parse the secret string as JSON
      const secretObject = JSON.parse(response.SecretString);

      // Return the 'staging' value if it exists
      if (secretObject.staging) {
        return secretObject.staging;
      } else {
        console.error("Key 'staging' not found in the secret.");
        return undefined;
      }
    } else {
      console.error("Secret not found or is binary.");
      return undefined;
    }
  } catch (error) {
    console.error(`Error fetching secret: ${error}`);
    return undefined;
  }
}

export const kuberApiSlice = createApi({
  reducerPath: "kuberapi",
  baseQuery: fetchBaseQuery({
    baseUrl: kuberApiUrl,
    prepareHeaders: (headers, { getState }) => {
      const cognitoIdToken = getSecretValue("gsb_fastapi");
      console.log(cognitoIdToken);
      headers.set(
        "Authorization",
        `Bearer ${config[ENVIRONMENT].kuberApiToken}`
      );
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints: (builder) => ({}),
});
