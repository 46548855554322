import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Slider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setState } from "./talimInferenceSlice";

const InferenceConfigParams: React.FC = () => {
  const talim_inference = useAppSelector(
    (state) => state.talim.talimInferenceSlice
  );
  const dispatch = useAppDispatch();

  const maxDimensions =
    talim_inference.filesInfo[talim_inference.selectedReferenceFile]?.tableData
      .columns.length - 3;
  // --------------------------------------------------------------------------------------------------
  // Shared Parameters

  // const [taskType, setTaskType] = useState("classification");

  const handleTaskTypeChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "task_type",
        value: event.target.value as string,
      })
    );
  };

  // const handleRegisterToLithoLensChange = (
  //   event: ChangeEvent<HTMLInputElement>,
  //   checked: boolean
  // ) => {
  //   dispatch(
  //     setState({
  //       key: "register_to_litholens",
  //       value: checked,
  //     })
  //   );
  // };

  // const handleRegisterToLogsApiChange = (
  //   event: ChangeEvent<HTMLInputElement>,
  //   checked: boolean
  // ) => {
  //   dispatch(
  //     setState({
  //       key: "register_to_logsapi",
  //       value: checked,
  //     })
  //   );
  // };

  // const handleNotificationEmailChange = (
  //   event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   dispatch(
  //     setState({
  //       key: "notification_email",
  //       value: event.target.value as string,
  //     })
  //   );
  // };

  const handleS3SaveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "s3_save_name",
        value: event.target.value as string,
      })
    );
  };

  // const handleLogDescriptiveNameChange = (
  //   event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   dispatch(
  //     setState({
  //       key: "descriptive_log_name",
  //       value: event.target.value as string,
  //     })
  //   );
  // };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Set Inference API Parameters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
          >
            <FormControl variant="standard">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Task Type
              </InputLabel>
              <Select
                defaultValue={talim_inference.task_type}
                onChange={handleTaskTypeChange}
              >
                <MenuItem value={"classification"}>Classification</MenuItem>
                <MenuItem value={"regression"}>Regression</MenuItem>
                <MenuItem value={"clustering"}>Clustering</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="s3-save-name"
              label="S3 Save Name"
              defaultValue={talim_inference.s3_save_name}
              error={
                talim_inference.s3_save_name === null ||
                talim_inference.s3_save_name === ""
              }
              onChange={handleS3SaveNameChange}
            />
          </Box>

          {/* <Typography>Output Registration Options</Typography>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="s3-save-name"
              label="S3 Save Name"
              defaultValue={talim_inference.s3_save_name}
              error={
                talim_inference.s3_save_name === null ||
                talim_inference.s3_save_name === ""
              }
              onChange={handleS3SaveNameChange}
            />
          </Box> */}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default InferenceConfigParams;
