import axios from "axios";
import config, { ENVIRONMENT } from "../../../config";
import { RootState, store } from "../../../redux/store";
import { coreApiSlice } from "../coreApiSlice";

export interface GetProjectsResponse {
  items: ProjectItem[];
}

export interface ProjectItem {
  litholensName: string;
  createdAt: string;
  updatedBy: string;
  createdBy: string;
  companyType: string;
  company: string;
  allowed_usernames: string[];
  updatedAt: string;
  description: string;
  id: string;
  litholensId: number;
  title: string;
  type: string;
}

export interface GetDrillholesResponse {
  items: DrillholeItem[];
}

export interface DrillholeItem {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  path: string;
}

export interface GetImagesResponse {
  items: ImageItem[];
}

export interface ImageItem {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  drillholeId: number;
  drillholeName: string;
  fromDepth: number;
  toDepth: number;
  boxNo: string;
  path: string;
  thumbnailPath: string;
  filteredThumbnailPath: string;
  masksPath: string;
  splitPath: string;
  splitThumbnailPath: string;
  splitMasksPath: string;
  filteredPath: string;
}

export interface ReportItem {
  visualizationName: string;
  visualizationType: string;
  visualizer: boolean;
  visualizationCacheLocation: string; //"goldspot/cobre-wet/!_LITHOLENS-DATA/03_MachineLearning/04_VeiNet/!_EXPORT-API/{drillholeName}.json";
  reportId: number | null;
  modelTrainId: number | null;
  modelName: string; //"VeiNet-V3 + RF";
  modelId: number | null;
  modelCreatedAt: string; //"2023-08-26T01:21:16.000Z";
  stepSize: number | null;
  noData: boolean;
}

interface GetReportsResponse {
  items: ReportItem[];
}

interface JobItem {
  company: string;
  companyProject: string;
  companyProjectType: string;
  createdAt: string;
  createdBy: string;
  description: string;
  finishedAt: string;
  finishedTime: string;
  id: string;
  instancesCount: number;
  project: string;
  requestBody: string;
  requestMetadata: {};
  responseBody: string;
  responseCode: number;
  size: string;
  status: string;
  type: string;
  updatedAt: string;
  updatedBy: string;
}

interface GetJobsResponse {
  items: JobItem[];
}

interface GetJobDetailsResponse {}

interface PutJobRequest {
  company: string;
  project: string;
  jobType?: string;
  requestBody?: {};
  description?: string;
  metadata?: string;
  requestMetadata?: {};
  instancesCount?: number;
}

interface PutJobResponse {
  id: string; //"job.2024.05.20.07.50.25.025.012";
  company: string; //"goldspot";
  project: string; //"dailytest";
  type: string; //"litholens.cleanimages";
  description: string; //"";
  createdAt: string; //"2024-05-20T07:50:25.025Z";
  createdBy: string; //"Alireza.iranpour";
  status: string; //"todo";
  size: string; //"0.154296875KB";
  requestBody: string; //"goldspot/dailytest/!_JOBS-API/litholens.cleanimages/job.2024.05.20.07.50.25.025.012/request.json";
  requestMetadata: {};
  instancesCount: number;
  companyProject: string; //"goldspot|dailytest";
  companyProjectType: string; //"goldspot|dailytest|litholens.cleanimages";
}

const projectsApiUrl = config[ENVIRONMENT].projectsApiUrl;

export const extendedCoreApiSlice = coreApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // -------------------------------------------------------------------------------------------
    getProjects: builder.query<
      GetProjectsResponse,
      {
        companyName: string;
        limit?: number;
      }
    >({
      query: ({ companyName, limit = 100 }) => {
        const params = new URLSearchParams();
        if (limit) params.append("limit", limit.toString());
        return {
          url: `${projectsApiUrl}/${companyName}?${params.toString()}`,
          method: "get",
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
    getDrillholes: builder.query<
      GetDrillholesResponse,
      { companyName: string; projectName: string }
    >({
      query: ({ companyName, projectName }) => {
        console.log(projectName);
        return {
          url: `${projectsApiUrl}/${companyName}/${projectName}/drillholes`,
          method: "get",
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
    getDrillholeImages: builder.query<
      GetImagesResponse,
      { companyName: string; projectName: string; drillholeId: number }
    >({
      query: ({ companyName, projectName, drillholeId }) => {
        return {
          url: `${projectsApiUrl}/${companyName}/${projectName}/drillholes/${drillholeId.toString()}/images`,
          method: "get",
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
    getAllReports: builder.query<
      GetReportsResponse,
      { companyName: string; projectName: string }
    >({
      query: ({ companyName, projectName }) => {
        return {
          url: `${projectsApiUrl}/${companyName}/${projectName}/reports/all`,
          method: "get",
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
    getJobs: builder.query<
      GetJobsResponse,
      {
        companyName: string;
        projectName: string;
        jobType?: string;
        user?: string;
        limit?: number;
      }
    >({
      query: ({
        companyName,
        projectName,
        jobType = "",
        user = "",
        limit = 10,
      }) => {
        const params = new URLSearchParams();
        if (jobType) params.append("type", jobType);
        if (user) params.append("user", user);
        if (limit) params.append("limit", limit.toString());

        return {
          url: `${projectsApiUrl}/${companyName}/${projectName}/jobs?${params.toString()}`,
          method: "get",
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
    getJobDetails: builder.query<
      GetJobDetailsResponse,
      { companyName: string; projectName: string; jobId: string }
    >({
      query: ({ companyName, projectName, jobId }) => {
        return {
          url: `${projectsApiUrl}/${companyName}/${projectName}/jobs/${jobId}`,
          method: "get",
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
    putJob: builder.mutation<PutJobResponse, PutJobRequest>({
      query: ({
        company,
        project,
        jobType = "",
        requestBody = {},
        description,
        metadata = "",
        requestMetadata = {},
        instancesCount = 1,
      }) => {
        const payloadBody: any = {
          type: jobType,
          requestBody: requestBody,
          requestMetadata: requestMetadata,
        };
        if (description !== undefined) {
          payloadBody.description = description;
        }
        if (instancesCount >= 1) {
          payloadBody.instancesCount = instancesCount;
        } else {
          throw new Error("instancesCount must be greater than or equal to 1");
        }
        if (metadata !== "") {
          payloadBody.metadata = metadata;
        }

        return {
          url: `${projectsApiUrl}/${company}/${project}/jobs`,
          method: "put",
          body: payloadBody,
        };
      },
    }),
    // -------------------------------------------------------------------------------------------
  }),
});

export const {
  useGetProjectsQuery,
  useGetDrillholesQuery,
  useGetDrillholeImagesQuery,
  useLazyGetDrillholeImagesQuery,
  useLazyGetDrillholesQuery,
  useGetAllReportsQuery,
  useGetJobsQuery,
  useGetJobDetailsQuery,
  useLazyGetJobDetailsQuery,
  usePutJobMutation,
} = extendedCoreApiSlice;

// -------------------------------------------------------------------------------------------
export const fetchDrillholeImages = async (
  companyName: string,
  projectName: string,
  drillholeId: number
): Promise<GetImagesResponse> => {
  const state = store.getState() as RootState;
  try {
    const response = await axios.get<GetImagesResponse>(
      `${projectsApiUrl}/${companyName}/${projectName}/drillholes/${drillholeId}/images`,
      {
        headers: {
          Authorization: `Bearer ${state.auth.cognitoTokens?.IdToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch drillhole images", error);
    throw error; // Rethrow the error so you can handle it in the component
  }
};
// -------------------------------------------------------------------------------------------

// --------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------
