export const getImageDimensions = (
  url: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = reject;
    img.src = url;
  });
};

export const parseImageKey = (key: string): { from: number; to: number } => {
  const match = key.match(/_(\d+(\.\d+)?)-(\d+(\.\d+)?)/);
  if (!match) throw new Error(`Invalid key format: ${key}`);
  return {
    from: parseFloat(match[1]),
    to: parseFloat(match[3]),
  };
};
