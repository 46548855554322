import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Slider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  OutlinedInput,
} from "@mui/material";
import { useForm, Controller, useWatch, FormProvider } from "react-hook-form";
import DrillholesSelector from "../../../projects/drillholes_and_images/drillholesSelector";
import ImageSelector from "../../../projects/drillholes_and_images/imagesSelector";
import { useAppSelector } from "../../../../redux/hooks";
import { getFileInfo } from "../../../../components/S3/S3Utils";
import { TextInput, SelectInput, AccordionSection } from "../formComponents";
import {
  filterNullProperties,
  useRunServiceMutation,
} from "../../../api/endpoints/superApi";
import { createJobItem } from "../../../../components/Jobs/jobUtils";
import { ENVIRONMENT } from "../../../../config";
import config from "../../../../config";
import ALResponseViewer from "./active_learning_response";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface FormValues {
  project_name: string;
  model_type: string;
  budget_size: number;
  stride: number;
  reduction?: string;
  data_category?: string;
  save_folder?: string | null;
  sample_height?: number;
  drillholes_to_process?: string[];
}

const defaultValues: FormValues = {
  project_name: "",
  data_category: "core",
  model_type: "roqenet",
  budget_size: 10,
  stride: 0.5,
  reduction: "mean",
  save_folder:
    "s3://" + config[ENVIRONMENT].litholens_s3_bucket + "/litholens/default",
  sample_height: 1000,
  drillholes_to_process: [],
};

const ActiveLearningReportPage: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);
  const [responseTask, setResponseTask] = useState<string>("roqenet");
  const taskOptions = [
    { value: "roqenet", label: "RoQENet" },
    { value: "veinet", label: "VeiNet" },
    { value: "cobblenet", label: "CobbleNet" },
    { value: "cleaning", label: "Cleaning" },
  ];

  const methods = useForm<FormValues>({ defaultValues });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = methods;
  const service = "active_learning_report";
  const dataCategory = useWatch({ control, name: "data_category" });
  const [submittedData, setSubmittedData] = useState<{} | null>(null);

  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  useEffect(() => {
    if (projects.selectedProject) {
      setValue("project_name", projects.selectedProject.id);
    }
    if (projects.selectedDrillholes[service]) {
      const mappedDrillholes = projects.selectedDrillholes[service].map(
        (drillholeItem) => drillholeItem.name
      );
      setValue("drillholes_to_process", mappedDrillholes);
    }
    // if (projects.selectedImages[service]) {
    //   const mappedImages = projects.selectedImages[service].map(
    //     (imageItem) => imageItem.name
    //   );
    //   setValue("images_to_process", mappedImages);
    // }
    // if (modelsList) {
    //   const firstModel = modelsList.items[0]?.path
    //     ? getFileInfo(modelsList.items[0].path).baseName
    //     : "";
    //   // setValue("train_model_name", firstModel);
    // }
  }, [
    projects.selectedDrillholes[service],
    projects.selectedImages[service],
    setValue,
    projects.selectedProject,
  ]);

  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = "logs.active_learning";
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);

  const onSubmit = (data: FormValues) => {
    const formData = {
      project_name: projectName,
      budget_size: data.budget_size,
      model_type: data.model_type,
      save_folder:
        "s3://" +
        config[ENVIRONMENT].litholens_s3_bucket +
        "/litholens/" +
        data.project_name +
        "/active_learning",
      data_category: data.data_category,
      reduction: data.reduction,
      drillholes_to_process:
        data.drillholes_to_process && data.drillholes_to_process?.length > 0
          ? data.drillholes_to_process
          : null,
      // images_to_process:
      //   data.images_to_process && data.images_to_process?.length > 0
      //     ? data.images_to_process
      //     : null,
    };
    setSubmittedData(formData);
    const request_body = filterNullProperties(formData);
    runServiceMutation({
      service: "ll_generate_active_learning_report",
      request_body: request_body,
      environment: ENVIRONMENT,
      job_item: jobItem,
    });
  };

  const handleResponseTaskChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setResponseTask(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    projects.selectedProject && (
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", margin: "20px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                name="budget_size"
                control={control}
                label="Budget Size"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                name="model_type"
                control={control}
                label="Select Model"
                options={[
                  { value: "roqenet", label: "RoQENet" },
                  { value: "veinet", label: "VeiNet" },
                  { value: "cobblenet", label: "CobbleNet" },
                  { value: "cleaning", label: "Cleaning" },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <AccordionSection title="Advanced Options">
                <Grid item xs={12}>
                  <DrillholesSelector
                    companyName={projects.selectedProject.company}
                    projectName={projects.selectedProject.id}
                    service={service}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <ImageSelector
                    companyName={projects.selectedProject.company}
                    projectName={projects.selectedProject.id}
                    service={service}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <SelectInput
                    name="data_category"
                    control={control}
                    label="Data Category"
                    options={[
                      { value: "core", label: "core" },
                      { value: "atv", label: "atv" },
                      { value: "otv", label: "otv" },
                      { value: "wet", label: "wet" },
                      { value: "dry", label: "dry" },
                      { value: "hs", label: "hs" },
                    ]}
                  />
                </Grid>
                {dataCategory === "hs" && (
                  <Grid item xs={12}>
                    <TextInput
                      name="hs_wavelength"
                      control={control}
                      label="HS Wavelength"
                      type="number"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    name="stride"
                    control={control}
                    defaultValue={0}
                    render={({ field: { onChange, value } }) => (
                      <div>
                        <Typography id="stride-slider" gutterBottom>
                          Stride
                        </Typography>
                        <Slider
                          value={value}
                          onChange={onChange}
                          aria-labelledby="stride-slider"
                          step={0.01}
                          min={0}
                          max={1}
                          valueLabelDisplay="auto"
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectInput
                    name="reduction"
                    control={control}
                    label="Reduction"
                    options={[
                      { value: "mean", label: "Mean" },
                      { value: "median", label: "Median" },
                      { value: "sum", label: "Sum" },
                    ]}
                  />
                </Grid>
              </AccordionSection>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                // disabled={!isValid}
              >
                Submit
              </Button>
            </Grid>
            {submittedData && (
              <Grid item xs={12}>
                <Box mt={2}>
                  <Typography variant="h6">Payload:</Typography>
                  <pre>{JSON.stringify(submittedData, null, 2)}</pre>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>View & Download Available Responses</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl fullWidth>
              <InputLabel id="multi-select-label">Response Task</InputLabel>
              <Select
                labelId="single-select-label"
                value={responseTask}
                onChange={handleResponseTaskChange}
                input={<OutlinedInput label="Response Task" />}
              >
                {taskOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ALResponseViewer
              bucket={config[ENVIRONMENT].litholens_s3_bucket}
              prefix={
                "litholens/" +
                projectName.split("_")[1] +
                "/active_learning/" +
                responseTask
              }
              destination="test.zip"
            />
          </AccordionDetails>
        </Accordion>
      </FormProvider>
    )
  );
};

export default ActiveLearningReportPage;
