import React, { useEffect, useState, useRef } from "react";
import { CircularProgress } from "@mui/material";
import config, { DRILLHOLES_FOLDER_PATH, ENVIRONMENT } from "../../config";
import {
  fetchFiles,
  getDownloadUrl,
  getFileInfo,
} from "../../components/S3/S3Utils";
import { getImageDimensions, parseImageKey } from "../gallery/imageUtils";
import { ImageItem } from "./DrillholeViewer";

const HEIGHT = 50; // Constant height for all images and gaps
const PIXEL_INTERVAL = 20; // Interval in pixels for the ticks
const TICK_FREQUENCY = 1; // Control the frequency of ticks

interface TickViewerProps {
  company: string;
  projectName: string;
  selectedDrillhole: string;
  meterPixelRatio: number;
  projects_s3_bucket: string;
}

const TickViewer: React.FC<TickViewerProps> = ({
  company,
  projectName,
  selectedDrillhole,
  meterPixelRatio,
  projects_s3_bucket,
}) => {
  const [images, setImages] = useState<ImageItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const splitFileMap = useRef<Map<string, string>>(new Map());

  const bucketName = projects_s3_bucket;
  const pathPrefix = `${company}/${projectName}/${DRILLHOLES_FOLDER_PATH}`;

  useEffect(() => {
    setImages([]);
  }, [selectedDrillhole]);

  useEffect(() => {
    const fetchImages = async () => {
      if (!selectedDrillhole) return;
      setLoading(true);
      setError(null);
      try {
        const splitFiles = await fetchFiles(
          bucketName,
          `${pathPrefix}/${selectedDrillhole}/thumbnails/split/`
        );

        splitFileMap.current = new Map(
          splitFiles.map((file) => [
            getFileInfo(file.Key).fileName,
            file.Key || "",
          ])
        );

        const imageItems = await Promise.all(
          Array.from(splitFileMap.current.keys()).map(async (fileName) => {
            const splitKey = splitFileMap.current.get(fileName);
            const url = await getDownloadUrl(bucketName, splitKey!);
            const { width, height } = await getImageDimensions(url);
            const { from, to } = parseImageKey(fileName);
            return {
              key: fileName,
              url,
              width,
              height,
              from,
              to,
            };
          })
        );

        setImages(imageItems.filter((item) => item.url !== ""));
      } catch (error) {
        setError("Error fetching images");
        console.error("Error fetching images: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [bucketName, pathPrefix, selectedDrillhole]);

  if (images.length === 0) return null;

  const sortedImages = [...images].sort((a, b) => a.from - b.from);
  const startDepth = sortedImages[0].from;
  const endDepth = sortedImages[sortedImages.length - 1].to;
  const totalWidth = (endDepth - startDepth) * meterPixelRatio;

  // Calculate a reasonable tick interval based on meterPixelRatio
  const depthPerTick = PIXEL_INTERVAL / meterPixelRatio;
  let interval = Math.max(1, Math.round(depthPerTick)) * TICK_FREQUENCY;

  // Adjust intervals for different scales
  if (meterPixelRatio < 20) {
    interval = 20; // Show fewer ticks at lower zoom levels
  } else if (meterPixelRatio < 50) {
    interval = 10;
  } else if (meterPixelRatio < 100) {
    interval = 5;
  } else if (meterPixelRatio < 200) {
    interval = 2;
  } else if (meterPixelRatio < 400) {
    interval = 1;
  } else {
    interval = 0.5; // Show more ticks at higher zoom levels
  }

  const ticks: JSX.Element[] = [];
  for (let depth = startDepth; depth <= endDepth; depth += interval) {
    const tickPosition = (depth - startDepth) * meterPixelRatio;
    ticks.push(
      <div
        key={`tick-${depth}`}
        style={{
          position: "absolute",
          left: `${tickPosition}px`,
          height: HEIGHT,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderLeft: "2px solid grey",
          whiteSpace: "nowrap",
        }}
      >
        {depth}m
      </div>
    );
  }

  return (
    <div style={{ position: "relative", width: totalWidth, height: HEIGHT }}>
      {loading && <CircularProgress />}
      {error && <p>{error}</p>}
      {ticks}
    </div>
  );
};

export default TickViewer;
