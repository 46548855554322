import React, { useState, useEffect } from "react";
import { Select, MenuItem, ListItemText, ListItemIcon, Box } from "@mui/material";
import { getDownloadUrl } from "../../components/S3/S3Utils";

interface Image {
    name: string;
    url: string;
}

interface ImageSelectorProps {
    images: Image[];
    selectedImage: string;
    onImageChange: (image: string) => void;
    bucketName: string;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({
    images,
    selectedImage,
    onImageChange,
    bucketName
}) => {
    const [loadedImages, setLoadedImages] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const loadImages = async () => {
            const imagePromises = images.map(async (image) => {
                const originalUrl = await getDownloadUrl(bucketName, image.url);
                return fetch(originalUrl)
                    .then((res) => res.blob())
                    .then((blob) => URL.createObjectURL(blob)); // Return the created URL
            });
            const imageUrls = await Promise.all(imagePromises);
            const imageMap = images.reduce((acc, image, index) => {
                acc[image.name] = imageUrls[index];
                return acc;
            }, {} as { [key: string]: string });

            setLoadedImages(imageMap);
        };

        loadImages();
    }, [images]);

    return (
        <Select
            value={selectedImage}
            onChange={(e) => onImageChange(e.target.value as string)}
            displayEmpty
            fullWidth
            sx={{ mb: 2 }}
            renderValue={(selected) => {
                if (!selected) {
                    return <span>Select Image</span>;
                }

                const selectedImageObj = images.find((image) => image.name === selected);
                return (
                    <Box display="flex" alignItems="center">
                        {selectedImageObj && loadedImages[selectedImageObj.name] && (
                            <img
                                src={loadedImages[selectedImageObj.name]}
                                alt={selectedImageObj.name}
                                style={{ width: 100, height: 50, marginRight: 16 }}
                            />
                        )}
                        <span>{selected}</span>
                    </Box>
                );
            }}
        >
            <MenuItem value="" disabled>
                Select Image
            </MenuItem>
            {images.map((image) => (
                <MenuItem key={image.name} value={image.name}>
                    <ListItemIcon>
                        {loadedImages[image.name] ? (
                            <img
                                src={loadedImages[image.name]}
                                alt={image.name}
                                style={{ width: 100, height: 50, marginRight: 16 }}
                            />
                        ) : (
                            <div style={{ width: 100, height: 50, marginRight: 16, backgroundColor: '#f0f0f0' }} />
                        )}
                    </ListItemIcon>
                    <ListItemText primary={image.name} />
                </MenuItem>
            ))}
        </Select>
    );
};

export default ImageSelector;
