import React, { useEffect, useState } from "react";
import {
  fetchFiles,
  getDownloadUrl,
  getTableDataFromCsv,
  S3File,
} from "../../../../components/S3/S3Utils";
import {
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  InputLabel,
  FormControl,
} from "@mui/material";
import { parse } from "papaparse";
import SMSMetaDataForm from "./SMSMetaDataForm";
import CustomTable from "../../../../components/DataDisplay/DisplayTabularData";

interface S3CsvViewerProps {
  bucketName: string;
  pathPrefix: string;
}

const S3CsvViewer: React.FC<S3CsvViewerProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const [files, setFiles] = useState<S3File[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableHeaders, setTableHeaders] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadFiles = async () => {
      setIsLoading(true);
      try {
        const fetchedFiles = await fetchFiles(
          bucketName,
          pathPrefix + "/data",
          ["csv"]
        );
        setFiles(fetchedFiles.filter((file) => file.Key.endsWith(".csv")));
      } catch (error) {
        console.error("Failed to fetch files:", error);
      }
      setIsLoading(false);
    };
    loadFiles();
  }, [bucketName, pathPrefix]);

  useEffect(() => {
    const loadFiles = async () => {
      setIsLoading(true);
      try {
        const fetchedFiles = await fetchFiles(
          bucketName,
          pathPrefix + "/data",
          ["csv"]
        );
        setFiles(fetchedFiles.filter((file) => file.Key.endsWith(".csv")));
      } catch (error) {
        console.error("Failed to fetch files:", error);
      }
      setIsLoading(false);
    };
    loadFiles();
  }, [bucketName, pathPrefix]);

  const handleFileSelect = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const key = event.target.value as string;
    setSelectedFile(key);

    const fetchData = async () => {
      try {
        const { columns, data } = await getTableDataFromCsv(bucketName, key);
        setTableData(data);
        setTableHeaders(columns);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching CSV data:", err);
        setIsLoading(false);
      }
    };

    fetchData();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "20px",
        minHeight: "150vh",
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="file-select-label">Select File</InputLabel>
        <Select
          labelId="file-select-label"
          value={selectedFile}
          onChange={(event) =>
            handleFileSelect(event as React.ChangeEvent<{ value: unknown }>)
          }
          displayEmpty
          label="Select File"
        >
          {files.map((file) => (
            <MenuItem key={file.Key} value={file.Key}>
              {file.Key.replace(pathPrefix + "/data" + "/", "")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <CustomTable tableHeaders={tableHeaders} tableData={tableData} />

      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
      >
        <SMSMetaDataForm
          bucketName={bucketName}
          pathPrefix={pathPrefix}
          fileName={selectedFile.replace(pathPrefix + "/data" + "/", "")}
          tableData={tableData}
        />
      </div>
    </div>
  );
};

export default S3CsvViewer;
