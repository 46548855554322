import React, { useEffect, useState } from "react";
import { getFilesWithMetaData } from "../talimUtils";
import MergeFileSelection from "./mergeFileSelection";
import MergeFileTabs from "./mergeFileTabs";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFiles } from "./talimMergeSlice";
import MergeRequest from "./mergeRequest";
import S3ResponseViewer from "../../../../../components/S3/S3ResponseViewer";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import projectsSlice from "../../../../projects/projectsSlice";

interface MergePageProps {
  bucketName: string;
  pathPrefix: string;
}

const MergePage: React.FC<MergePageProps> = ({ bucketName, pathPrefix }) => {
  const talim_merge = useAppSelector((state) => state.talim.talimMergeSlice);
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadFiles = async () => {
      const fileWithJsonData = await getFilesWithMetaData(
        bucketName,
        pathPrefix
      );
      dispatch(setFiles({ files: fileWithJsonData }));
    };
    loadFiles();
  }, [pathPrefix]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const fileWithJsonData = await getFilesWithMetaData(
        bucketName,
        pathPrefix
      );
      dispatch(setFiles({ files: fileWithJsonData }));
    }, 2000);
    return () => clearInterval(intervalId);
  }, [pathPrefix]);

  const [viewJobs, setViewJobs] = React.useState(false);

  const handleClick = () => {
    setViewJobs(!viewJobs);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px",
          textAlign: "center",
        }}
      >
        <Button variant="outlined" onClick={handleClick}>
          {viewJobs ? "Request a New Job" : "View Completed Jobs"}
        </Button>
      </div>
      <div key={"main"} hidden={viewJobs}>
        <MergeFileSelection bucketName={bucketName} pathPrefix={pathPrefix} />
        <MergeFileTabs bucketName={bucketName} />
        <MergeRequest bucketName={bucketName} pathPrefix={pathPrefix} />
      </div>
      <div key={"dialog"} hidden={!viewJobs}>
        <S3ResponseViewer
          refresh=""
          bucketName={bucketName}
          pathPrefix={pathPrefix}
          serviceName="merge"
        ></S3ResponseViewer>
      </div>
    </div>
  );
};

export default MergePage;
