// S3FileViewer.tsx
import React, { useEffect, useState } from "react";
import {
  fetchFiles,
  deleteFile,
  getDownloadUrl,
  S3File,
} from "../../../../../components/S3/S3Utils";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { readJsonFromS3 } from "../../../../../components/S3/S3Utils";
import Plot from "react-plotly.js";
import { Data } from "plotly.js";

interface VisualizationScatterProps {
  x_coordinates?: { [key: string]: string };
  y_coordinates?: { [key: string]: string };
  values?: { [key: string]: string };
}

interface ApiResponse {
  task_type?: string | null;
  out_location?: string | null;
  pc?: VisualizationScatterProps;
  tsne?: VisualizationScatterProps;
  umap?: VisualizationScatterProps;
  status?: string | null;
}

interface TrainResponseViewerProps {
  refresh: any;
  bucketName: string;
  pathPrefix: string;
  serviceName: string;
}

const ClusteringResponseViewer: React.FC<TrainResponseViewerProps> = ({
  refresh,
  bucketName,
  pathPrefix,
  serviceName,
}) => {
  const [files, setFiles] = useState<S3File[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const loadFiles = async () => {
      try {
        const fetchedFiles = await fetchFiles(
          (bucketName = bucketName),
          (pathPrefix = pathPrefix + "/responses/" + serviceName),
          ["json"]
        );
        const filteredFetchedFiles = fetchedFiles
          ? fetchedFiles.filter(
              (file) => file.Key.lastIndexOf("/") < file.Key.lastIndexOf("job.")
            )
          : fetchedFiles;
        setFiles(filteredFetchedFiles);
      } catch (error) {
        console.error("Failed to fetch files:", error);
      }
    };
    loadFiles();
  }, [refresh, bucketName, pathPrefix]);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  /// PC Scatter

  interface PCScatterPlotProps {
    scatter_plots?: VisualizationScatterProps;
  }

  const [PCXData, setPCXData] = useState<number[]>([]);
  const [PCYData, setPCYData] = useState<number[]>([]);
  const [PCLabelData, setPCLabelData] = useState<string[]>([]);

  useEffect(() => {
    const loadPCAxis = async () => {
      const xData = await readJsonFromS3(
        bucketName,
        files[selectedIndex].Key.replace(".json", "/pc/x_coordinates.json")
      );
      if (Array.isArray(xData)) {
        setPCXData(xData as number[]);
      }

      const yData = await readJsonFromS3(
        bucketName,
        files[selectedIndex].Key.replace(".json", "/pc/y_coordinates.json")
      );
      if (Array.isArray(yData)) {
        setPCYData(yData as number[]);
      }

      const labelData = await readJsonFromS3(
        bucketName,
        files[selectedIndex].Key.replace(".json", "/pc/values.json")
      );
      if (Array.isArray(labelData)) {
        setPCLabelData(labelData as string[]);
      }
    };
    if (files.length > 0) {
      loadPCAxis();
    }
  }, [files, selectedIndex]);

  const PCScatterPlot: React.FC<PCScatterPlotProps> = ({ scatter_plots }) => {
    if (!scatter_plots || !PCXData || !PCYData || !PCLabelData) {
      return <div>PC Scatter Plot Not Found!</div>;
    }

    interface Trace {
      x: number[];
      y: number[];
      type: "scatter";
      mode: "markers";
      name: string;
      marker: {
        size: number;
      };
    }

    interface TraceMap {
      [label: string]: Trace; // Define an index signature
    }

    // Create a map to hold traces for each label
    const traces = PCLabelData.reduce((acc: TraceMap, label, index) => {
      acc[label] = acc[label] || {
        x: [],
        y: [],
        type: "scatter",
        mode: "markers",
        name: label,
        marker: { size: 8 }, // Customize marker appearance
      };
      acc[label].x.push(PCXData[index]);
      acc[label].y.push(PCYData[index]);
      return acc;
    }, {});

    const data = Object.values(traces);

    return (
      <Plot
        // data={[
        //   {
        //     x: PCXData,
        //     y: PCYData,
        //     type: "scatter",
        //     mode: "markers",
        //     marker: { color: "blue" },
        //   },
        // ]}
        data={data}
        layout={{
          title: `PC Scatter Plot`,
          xaxis: {
            title: "PC 1",
            // automargin: true,
            // scaleanchor: "y",
            // scaleratio: 1,
          },
          yaxis: {
            title: "PC 2",
            // automargin: true,
          },
          legend: {
            orientation: "h",
            xanchor: "right",
            yanchor: "top",
            x: 1,
            y: 1,
          },
        }}
        style={{ width: "100%", height: "100%" }}
      />
    );
  };

  const [apiResponse, setApiResponse] = useState<ApiResponse>(
    files.length > 0
      ? (readJsonFromS3(bucketName, files[selectedIndex].Key) as ApiResponse)
      : ({} as ApiResponse)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedIndex >= 0 && selectedIndex < files.length) {
          setIsLoading(true);
          const response = await readJsonFromS3(
            bucketName,
            files[selectedIndex].Key
          );
          setApiResponse(response as ApiResponse);
        }
      } catch (error) {
        console.error("Got an error: ", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [selectedIndex, files]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ maxHeight: 400, overflow: "auto" }}>
            <List
              component="nav"
              aria-label="main mailbox foldersfinished-jobs"
              title="List of Train Jobs"
            >
              {files.map((file, index) => (
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemText
                    primary={file.Key.replace(
                      pathPrefix + "/responses/" + serviceName + "/",
                      ""
                    )}
                  />
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <TableContainer component={Paper}>
            <TableBody>
              <TableRow>
                <TableCell>Out S3 File Name</TableCell>
                <TableCell>
                  {apiResponse.out_location?.substring(
                    apiResponse.out_location?.lastIndexOf("/") + 1
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Out Log ID</TableCell>
                <TableCell>
                  {apiResponse.out_log_id ? apiResponse.out_log_id : "N/A"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Out LithoLens Generic Model ID</TableCell>
                <TableCell>
                  {apiResponse.out_generic_model_id
                    ? apiResponse.out_generic_model_id
                    : "N/A"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Warnings</TableCell>
                <TableCell>
                  {apiResponse.warnings
                    ? apiResponse.warnings.length > 0
                      ? apiResponse.warnings
                      : "N/A"
                    : "N/A"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Error</TableCell>
                <TableCell>
                  {apiResponse.error ? apiResponse.error : "N/A"}
                </TableCell>
              </TableRow>
            </TableBody>
          </TableContainer> */}
        </Grid>
      </Grid>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={activeTab}
        onChange={handleChange}
        aria-label="file tabs"
        sx={{
          backgroundColor: "white", // Light teal background for the whole tabs bar
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Adding a subtle shadow under the tabs bar
          "& .MuiTabs-flexContainer": {
            gap: "10px", // Adds space between each tab/button
          },
        }}
      >
        <Tab
          key="pc"
          label="PC Scatter Plot"
          id="pc"
          aria-controls={`tabpanel-pc`}
        />
      </Tabs>
      {isLoading ? (
        <div style={{ textAlign: "center", margin: "15px" }}>
          <CircularProgress />
        </div>
      ) : apiResponse?.status === "requested" ? (
        <div>
          <Alert severity="warning">Job in progress</Alert>
        </div>
      ) : apiResponse?.status === "failed" ? (
        <div>
          <Alert severity="error">Job failed!</Alert>
        </div>
      ) : (
        <div>
          <div
            hidden={activeTab !== 0}
            id="roc-curve"
            style={{ flex: "auto", textAlign: "center", margin: "20px" }}
          >
            <PCScatterPlot scatter_plots={apiResponse?.pc} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClusteringResponseViewer;
