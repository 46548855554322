import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  writeJsonToS3,
  readJsonFromS3,
} from "../../../../components/S3/S3Utils";
import { LoadingButton } from "@mui/lab";
import {
  RunServiceRequest,
  useRunServiceMutation,
} from "../../../api/endpoints/superApi";
import CheckIcon from "@mui/icons-material/Check";
import {
  RunServiceRequest as RunKuberServiceRequest,
  useRunServiceMutation as useRunKuberServiceMutation,
} from "../../../api/endpoints/kuberApi";

interface ColumnTypesDisplayProps {
  bucketName: string;
  pathPrefix: string;
  fileName: string;
  tableData: any[];
}

const SMSMetaDataForm: React.FC<ColumnTypesDisplayProps> = ({
  bucketName,
  pathPrefix,
  fileName,
  tableData,
}) => {
  const [selectedType, setSelectedType] = useState<string>("");
  const [selectedColumns, setSelectedColumns] = useState<{
    drillhole: string;
    begin: string;
    end: string;
    pointDepth: string;
    samplesID: string;
    minZones: string;
  }>({
    drillhole: "",
    begin: "",
    end: "",
    pointDepth: "",
    samplesID: "",
    minZones: "",
  });

  const [jsonData, setJsonData] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (selectedType === "") {
      setIsSaveDisabled(true);
    } else if (selectedType === "interval") {
      const { drillhole, begin, end } = selectedColumns;
      setIsSaveDisabled(drillhole === "" || begin === "" || end === "");
    } else if (selectedType === "point") {
      const { drillhole, pointDepth } = selectedColumns;
      setIsSaveDisabled(drillhole === "" || pointDepth === "");
    } else {
      setIsSaveDisabled(false); // For other types if any in the future
    }
  }, [selectedType, selectedColumns]);

  useEffect(() => {
    const fetchData = async () => {
      const s3Key =
        pathPrefix + "/" + "metaData/" + fileName.replace(".csv", ".json");
      try {
        const data = await readJsonFromS3("superapi-dashboard", s3Key);
        if (data) {
          setSelectedType(data.selectedType || "");
          setSelectedColumns({
            drillhole: data.drillhole || "",
            begin: data.begin || "",
            end: data.end || "",
            pointDepth: data.pointDepth || "",
            samplesID: data.samolesID || "",
            minZones: data.minZones || "",
          });
        }
      } catch (error) {
        // console.error("Failed to fetch data:", error);
        setSelectedType("");
        setSelectedColumns({
          drillhole: "",
          begin: "",
          end: "",
          pointDepth: "",
          samplesID: "",
          minZones: "",
        });
      }
    };

    if (fileName) {
      fetchData();
    }
  }, [pathPrefix, fileName]);

  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const [runKuberServiceMutation, runKuberServiceMutationResult] =
    useRunKuberServiceMutation();

  const [open, setOpen] = useState(false);
  const handleAlertClose = (event: SyntheticEvent) => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);
  }, [runKuberServiceMutationResult]);

  const saveJsonData = async () => {
    setLoading(true);
    setSuccess(false);
    const s3Key =
      pathPrefix + "/" + "metaData/" + fileName.replace(".csv", ".json");
    const myJsonData = {
      selectedType,
      ...selectedColumns,
    };
    setJsonData(myJsonData);
    console.log(JSON.stringify(myJsonData, null, 2));
    try {
      await writeJsonToS3("superapi-dashboard", s3Key, myJsonData);
      setSuccess(true);
    } catch (error) {
      console.error("Failed to upload JSON data:", error);
    }
    setLoading(false);
    if (myJsonData.selectedType === "minzone") {
      const kuberRequestData = {
        service: "sms_mineralogy/get_unique_mineralzone_info",
        request_body: {
          minzones_info: {
            ...myJsonData,
            path:
              "s3://" +
              bucketName +
              "/" +
              pathPrefix +
              "/" +
              "data/" +
              fileName,
            data_type: myJsonData.selectedType,
            sample_id_column_name: myJsonData.samplesID,
            drillhole_column_name: myJsonData.drillhole,
            interval_from_column_name: myJsonData.begin,
            interval_to_column_name: myJsonData.end,
            interval_depth_column_name: myJsonData.pointDepth,
            mineral_zone_column_name: myJsonData.minZones,
          },
        },
        environment: "test",
      } as RunKuberServiceRequest;
      runKuberServiceMutation(kuberRequestData);
    }
  };

  if (!tableData.length) {
    return <div>No data available.</div>;
  }

  const columnNames = Object.keys(tableData[0]);

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedType(event.target.value);
    setSelectedColumns({
      drillhole: "",
      begin: "",
      end: "",
      pointDepth: "",
      samplesID: "",
      minZones: "",
    });
  };

  const handleColumnChange =
    (type: keyof typeof selectedColumns) =>
    (event: SelectChangeEvent<string>) => {
      setSelectedColumns((prev) => ({ ...prev, [type]: event.target.value }));
    };

  const getFilteredOptions = (excludeKey: keyof typeof selectedColumns) => {
    return columnNames.filter(
      (col) =>
        !Object.values(selectedColumns).includes(col) ||
        selectedColumns[excludeKey] === col
    );
  };

  return (
    <div>
      <FormControl fullWidth style={{ marginBottom: "20px" }}>
        <InputLabel>Type</InputLabel>
        <Select value={selectedType} onChange={handleTypeChange} label="Type">
          <MenuItem value="assay">Assay/Re-assay File</MenuItem>
          <MenuItem value="minzone">Mineral Zones Log</MenuItem>
        </Select>
      </FormControl>

      {selectedType === "assay" && (
        <>
          <FormControl fullWidth style={{ marginBottom: "20px" }}>
            <InputLabel>Drillhole Column Name</InputLabel>
            <Select
              value={selectedColumns.drillhole}
              onChange={handleColumnChange("drillhole")}
              label="Drillhole Column Name"
            >
              {getFilteredOptions("drillhole").map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: "20px" }}>
            <InputLabel>Samples ID Column Name</InputLabel>
            <Select
              value={selectedColumns.samplesID}
              onChange={handleColumnChange("samplesID")}
              label="Samples ID Column Name"
            >
              {getFilteredOptions("samplesID").map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: "20px" }}>
            <InputLabel>Interval Beginning Column Name</InputLabel>
            <Select
              value={selectedColumns.begin}
              onChange={handleColumnChange("begin")}
              label="Interval Beginning Column Name"
            >
              {getFilteredOptions("begin").map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: "20px" }}>
            <InputLabel>Interval End Column Name</InputLabel>
            <Select
              value={selectedColumns.end}
              onChange={handleColumnChange("end")}
              label="Interval End Column Name"
            >
              {getFilteredOptions("end").map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      {selectedType === "minzone" && (
        <>
          <FormControl fullWidth style={{ marginBottom: "20px" }}>
            <InputLabel>Drillhole Column Name</InputLabel>
            <Select
              value={selectedColumns.drillhole}
              onChange={handleColumnChange("drillhole")}
              label="Drillhole Column Name"
            >
              {getFilteredOptions("drillhole").map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: "20px" }}>
            <InputLabel>Samples ID Column Name</InputLabel>
            <Select
              value={selectedColumns.samplesID}
              onChange={handleColumnChange("samplesID")}
              label="Samples ID Column Name"
            >
              {getFilteredOptions("samplesID").map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: "20px" }}>
            <InputLabel>Mineral Zones Column Name</InputLabel>
            <Select
              value={selectedColumns.minZones}
              onChange={handleColumnChange("minZones")}
              label="Mineral Zones Column Name"
            >
              {getFilteredOptions("minZones").map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: "20px" }}>
            <InputLabel>Interval Beginning Column Name</InputLabel>
            <Select
              value={selectedColumns.begin}
              onChange={handleColumnChange("begin")}
              label="Interval Beginning Column Name"
            >
              {getFilteredOptions("begin").map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: "20px" }}>
            <InputLabel>Interval End Column Name</InputLabel>
            <Select
              value={selectedColumns.end}
              onChange={handleColumnChange("end")}
              label="Interval End Column Name"
            >
              {getFilteredOptions("end").map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <LoadingButton
        onClick={saveJsonData}
        loading={loading}
        variant="contained"
        color="primary"
        disabled={isSaveDisabled || runKuberServiceMutationResult.isLoading}
      >
        Save Meta Data
      </LoadingButton>
      <Collapse in={open}>
        {(runKuberServiceMutationResult?.isSuccess ||
          runKuberServiceMutationResult?.isError) && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity={
              runKuberServiceMutationResult?.isSuccess ? "success" : "error"
            }
            onClose={handleAlertClose}
          >
            {runKuberServiceMutationResult?.isSuccess
              ? "Data rendered successfully"
              : "Data rendering failed!"}
          </Alert>
        )}
      </Collapse>
      {success && <p>Data saved successfully!</p>}
    </div>
  );
};

export default SMSMetaDataForm;
