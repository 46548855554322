export const defaultActionPolicyDescription = (
  <span>
    <strong>Intervalization Radius: </strong>The radius to recast points to
    fixed-size intervals (only applicable to point data){" "}
  </span>
);

export const actionsDescription = (
  <span>
    <ul>
      <li>
        <strong>drop_samples_with_missing_values</strong>: Removes the samples
        containing missing values for each feature from the dataset.
      </li>
      <li>
        <strong>drop_feature</strong>: Removes the feature (column) from your
        dataset.
      </li>
      <li>
        <strong>replace_missing_values_with_mean</strong>: Replaces the missing
        values for each feature with the average of the existing values.
      </li>
      <li>
        <strong>replace_missing_values_with_min</strong>: Replaces the missing
        values for each feature with the minimum of the existing values.
      </li>
      <li>
        <strong>replace_missing_values_with_max</strong>: Replaces the missing
        values for each feature with the maximum of the existing values.
      </li>
      <li>
        <strong>replace_missing_values_with_zero</strong>: Replaces the missing
        values for each feature with the value "0".
      </li>
      <li>
        <strong>do_nothing</strong>: Retains the feature with its existing
        values.
      </li>
      <li>
        <strong>knn_imputation</strong>: Fits a KNN model to estimate the
        missing values for a feature based on averaging the "K" nearest
        neighbors (you can set "K" in the accordion below). By default, all
        features are used for fitting the KNN model.
      </li>
      <li>
        <strong>iterative_imputation</strong>: The process involves repeatedly
        estimating the missing values using regression models, updating these
        estimates iteratively until they stabilize or converge. This method
        leverages the relationships between multiple variables to provide a more
        accurate imputation, especially when the missing data patterns are
        complex or involve multiple features. By default, all features are used
        for fitting the KNN model.
      </li>
    </ul>
  </span>
);

export const lowLevelDetectionDescriptions = (
  <span>
    In case that low values in your dataset and invalid, you can apply a low
    threshold for each feature to convert below-threshold values to missing
    values. The cleaning service will replace such low values with imputed
    values. The options are as follows:
    <br></br>
    <ul>
      <li>
        <strong>None</strong>: no low threshold is applied.
      </li>
      <li>
        <strong>Auto</strong>: the low threshold is automatically set to the
        minimum value multiplied by -0.5.
      </li>
      <li>
        <strong>Value</strong>: the value of the low threshold is manually given
        by the user.
      </li>
      <li>
        <strong>Percentage</strong>: the percentage of the low threshold (with
        respect to the maximum value) is manually given by the user.
      </li>
    </ul>
  </span>
);

export const highLevelDetectionDescriptions = (
  <span>
    In case that high values in your dataset and invalid, you can apply a high
    threshold for each feature to convert above-threshold values to missing
    values. The cleaning service will replace such high values with imputed
    values. The options are as follows:
    <br></br>
    <ul>
      <li>
        <strong>None</strong>: no high threshold is applied.
      </li>
      <li>
        <strong>Auto</strong>: the high threshold is automatically set.
      </li>
      <li>
        <strong>Value</strong>: the value of the high threshold is manually
        given by the user.
      </li>
      <li>
        <strong>Percentage</strong>: the percentage of the high threshold (with
        respect to the maximum value) is manually given by the user.
      </li>
    </ul>
  </span>
);

export const knnClustersDescription = (
  <span>
    In <strong>KNN imputation</strong>, the parameter ("
    <strong>k</strong>") represents the number of nearest neighbors used to
    estimate missing values. Choosing the right "k" is crucial: a small "k" may
    cause high variance, while a large "k" can lead to bias. The optimal "k"
    depends on data size, variability, and missingness, and can be determined
    through cross-validation or heuristics. <br></br> <br></br> To enable this
    field, you should set the action for at least one feature to{" "}
    <strong>knn_imputation</strong>.
  </span>
);

export const invalidStringsDescription = (
  <span>
    Strings that represent an invalid measurement in the dataset (e.g.,{" "}
    {"<L, >H, <x, >x, <LOD, >HID"}, etc.). These strings are treated as missing
    values in the cleaning process, if detected.
  </span>
);
