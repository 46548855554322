import React, { useState, useEffect, ChangeEvent, SyntheticEvent } from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  TableCell,
  Grid,
  Checkbox,
  TextField,
  AutocompleteChangeReason,
  Autocomplete,
  AutocompleteChangeDetails,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFilesInfo } from "./talimInferenceSlice";

interface TableDataDropdownProps {
  fileKey: string;
}

interface Selections {
  [key: string]: string;
}

// ----------------------------------------------------------------------------------------------
const ColumnActions: React.FC<TableDataDropdownProps> = ({ fileKey }) => {
  const talim_inference = useAppSelector(
    (state) => state.talim.talimInferenceSlice
  );
  const dispatch = useAppDispatch();

  const [selections, setSelections] = useState<Record<string, string[]>>({
    standard_scaling: [] as string[],
    clr_transformation: [] as string[],
    binning: [] as string[],
    ingnore: [] as string[],
  });

  const tableData = talim_inference.filesInfo[fileKey].tableData.data;

  useEffect(() => {
    // console.log(selections);
  }, [tableData]);

  const handleSelectionChange =
    (key: string) =>
    (
      event: SyntheticEvent<Element, Event>,
      value: string[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<string> | undefined
    ) => {
      const newSelections = {
        ...selections,
        [key]: value,
      };
      setSelections(newSelections);
      dispatch(
        setFilesInfo({
          key: fileKey,
          info: {
            ...talim_inference.filesInfo[fileKey],
            fileKey: talim_inference.filesInfo[fileKey].fileKey,
            metaData: talim_inference.filesInfo[fileKey].metaData,
            tableData: talim_inference.filesInfo[fileKey].tableData,
            [key]: value,
            // clr_transformation: newSelections.clr_transformation,
            // binning: newSelections.binning,
            // ignore: newSelections.ignore,
          },
        })
      );
    };

  return (
    <div style={{ overflowX: "auto" }}>
      <Grid container spacing={2} wrap="nowrap">
        <FormControl fullWidth margin="normal">
          <Autocomplete
            multiple
            options={talim_inference.filesInfo[fileKey].tableData.columns}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            value={selections.scatter_plots}
            onChange={handleSelectionChange("scatter_plots")}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Scatter Plots" variant="outlined" />
            )}
            fullWidth
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Autocomplete
            multiple
            options={talim_inference.filesInfo[fileKey].tableData.columns}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            value={selections.crosstab_plots}
            onChange={handleSelectionChange("crosstab_plots")}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Crosstab Plots"
                variant="outlined"
              />
            )}
            fullWidth
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Autocomplete
            multiple
            options={talim_inference.filesInfo[fileKey].tableData.columns}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            value={selections.binning_plots}
            onChange={handleSelectionChange("binning_plots")}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Binning Plots" variant="outlined" />
            )}
            fullWidth
          />
        </FormControl>
      </Grid>
    </div>
  );
};

export default ColumnActions;
