import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Slider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setState } from "./tsmSlice";
import { Margin } from "@mui/icons-material";
import { fetchFolders } from "../../../../../components/S3/S3Utils";

const availableModels: { [key: string]: string } = {
  mask_rcnn: "Detectron2",
  cascaded_mask_rcnn: "OpenMMLab",
  yolact: "OpenMMLab",
  solo: "OpenMMLab",
  detectors: "OpenMMLab",
  mask_scoring_rcnn: "OpenMMLab",
  scnet: "OpenMMLab",
  unet: "SMP",
  unetplusplus: "SMP",
  deeplabv3: "SMP",
  deeplabv3plus: "SMP",
};

const availableBackbones: { [key: string]: string[] } = {
  mask_rcnn: [
    "resnet50",
    "resnet101",
    "cait",
    "efficientnet",
    "swin",
    "xcit",
    "regnetz",
  ],
  cascaded_mask_rcnn: [
    "resnet50",
    "resnet101",
    "resnext101_32x4d",
    "resnext101_64x4d",
  ],
  yolact: ["resnet50", "resnet101"],
  solo: ["resnet50"],
  detectors: ["resnet50", "resnet101"],
  mask_scoring_rcnn: [
    "resnet50",
    "resnet101",
    "resnext101_32x4d",
    "resnext101_64x4d",
  ],
  scnet: ["resnet50", "resnet101", "resnext101_64x4d"],
  unet: ["resnet34", "resnet50", "resnet101"],
  unetplusplus: ["resnet34", "resnet50", "resnet101"],
  deeplabv3: ["resnet34", "resnet50", "resnet101"],
  deeplabv3plus: ["resnet34", "resnet50", "resnet101"],
};

interface TSMConfigParamsProps {
  bucketName: string;
  pathPrefix: string;
}

const TSMConfigParams: React.FC<TSMConfigParamsProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const tsm = useAppSelector((state) => state.tsm.TSMSlice);
  const dispatch = useAppDispatch();

  // --------------------------------------------------------------------------------------------------
  const [folders, setFolders] = useState<string[]>([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      loadFolders();
    }, 1000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const loadFolders = async () => {
    try {
      const fetchedFolders = await fetchFolders(
        bucketName,
        pathPrefix + "/datasets"
      );

      // Extract folder names from the fetched folder keys
      const folderNames = fetchedFolders.map((folder) => {
        const folderKey = folder.Key.replace(pathPrefix + "/datasets", ""); // Remove the path prefix
        return folderKey.replace(/\/$/, ""); // Remove trailing slash from folder name
      });

      setFolders(folderNames); // Update state with folder names
    } catch (error) {
      console.error("Failed to fetch folders:", error);
    }
  };
  // Shared Parameters

  const handleModelChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "model_arch",
        value: event.target.value as string,
      })
    );
  };

  const handleBackboneChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "backbone",
        value: event.target.value as string,
      })
    );
  };

  const handleOptimizerChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "optimizer",
        value: event.target.value as string,
      })
    );
  };

  const handleLearningRateChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "learning_rate",
          value: parseFloat(newValue),
        })
      );
    }
  };

  const handleBatchSizeChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "batch_size",
          value: parseFloat(newValue),
        })
      );
    }
  };

  const handleMaxEpochsChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "max_epochs",
          value: parseFloat(newValue),
        })
      );
    }
  };

  const handleS3SaveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "model_save_name",
        value: event.target.value as string,
      })
    );
  };

  const handleDatasetNameChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "dataset_name",
        value: event.target.value as string,
      })
    );
  };

  const handleImageHeightChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "img_height",
          value: parseFloat(newValue),
        })
      );
    }
  };

  const handleImageWidthChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "img_width",
          value: parseFloat(newValue),
        })
      );
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Model Architecture</InputLabel>
            <Select
              value={tsm.model_arch}
              label="Model Architecture"
              onChange={handleModelChange}
            >
              {Object.keys(availableModels).map((column) => (
                <MenuItem key={column} value={column}>
                  {column}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Backbone</InputLabel>
            <Select
              value={tsm.backbone}
              label="Backbone"
              onChange={handleBackboneChange}
            >
              {availableBackbones[tsm.model_arch].map((element) => (
                <MenuItem key={element} value={element}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Training Configurations</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "120ch" } }}
          >
            <div style={{ margin: "15px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth variant="standard">
                    <TextField
                      label="Learning Rate"
                      variant="outlined"
                      type="number"
                      defaultValue={tsm.learning_rate}
                      onChange={handleLearningRateChange}
                      inputProps={{
                        // Optional: Define min and max values
                        min: 0, // Minimum value
                        max: 1, // Maximum value
                        step: 0.001, // Step increment for keyboard arrows
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth variant="standard">
                    <TextField
                      label="Batch Size"
                      variant="outlined"
                      type="number"
                      defaultValue={tsm.batch_size}
                      onChange={handleBatchSizeChange}
                      inputProps={{
                        // Optional: Define min and max values
                        min: 0, // Minimum value
                        max: 128, // Maximum value
                        step: 1, // Step increment for keyboard arrows
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth variant="standard">
                    <TextField
                      label="Maximum Number of Epochs"
                      variant="outlined"
                      type="number"
                      defaultValue={tsm.max_epochs}
                      onChange={handleMaxEpochsChange}
                      inputProps={{
                        // Optional: Define min and max values
                        min: 0, // Minimum value
                        max: 1000, // Maximum value
                        step: 1, // Step increment for keyboard arrows
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Optimizer
                    </InputLabel>
                    <Select
                      defaultValue={tsm.optimizer}
                      onChange={handleOptimizerChange}
                    >
                      <MenuItem value={"Adam"}>Adam</MenuItem>
                      <MenuItem value={"SGD"}>SGD</MenuItem>
                      <MenuItem value={"Adamax"}>Adamax</MenuItem>
                      <MenuItem value={"Adagrad"}>Adagrad</MenuItem>
                      <MenuItem value={"Adadelta"}>Adadelta</MenuItem>
                      <MenuItem value={"RMSprop"}>RMSprop</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Box>

          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "120ch" } }}
          >
            <div
              style={{ margin: "15px" }}
              hidden={availableModels[tsm.model_arch] != "SMP"}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth variant="standard">
                    <TextField
                      label="Image Height"
                      variant="outlined"
                      type="number"
                      defaultValue={tsm.img_height}
                      onChange={handleImageHeightChange}
                      inputProps={{
                        // Optional: Define min and max values
                        min: 0, // Minimum value
                        max: 1028, // Maximum value
                        step: 1, // Step increment for keyboard arrows
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormControl fullWidth variant="standard">
                    <TextField
                      label="Image Width"
                      variant="outlined"
                      type="number"
                      defaultValue={tsm.img_width}
                      onChange={handleImageWidthChange}
                      inputProps={{
                        // Optional: Define min and max values
                        min: 0, // Minimum value
                        max: 1028, // Maximum value
                        step: 1, // Step increment for keyboard arrows
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Box>

          <Typography>Data Flow</Typography>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
            style={{ margin: "10px" }}
          >
            <FormControl style={{ width: "300px" }}>
              <InputLabel>Dataset Name</InputLabel>
              <Select
                value={tsm.dataset_name}
                label="Dataset Name"
                onChange={handleDatasetNameChange}
              >
                {folders.map((folder) => (
                  <MenuItem
                    key={folder.replace("/", "")}
                    value={folder.replace("/", "")}
                  >
                    {folder.replace("/", "")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="s3-save-name"
              label="Model Save Name"
              defaultValue={tsm.model_save_name}
              onChange={handleS3SaveNameChange}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TSMConfigParams;
