import React, { useEffect, useState, useCallback } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import DrillholesSelector from "../../../projects/drillholes_and_images/drillholesSelector";
import ImageSelector from "../../../projects/drillholes_and_images/imagesSelector";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  S3File,
  fetchFiles,
  fetchImmediateFiles,
  getFileInfo,
} from "../../../../components/S3/S3Utils";
import { TextInput, SelectInput, BooleanSwitchInput } from "../formComponents";
import { createJobItem } from "../../../../components/Jobs/jobUtils";
import {
  filterNullProperties,
  useRunServiceMutation,
} from "../../../api/endpoints/superApi";
import config, {
  CLUSTERING_MODELS_FOLDER_PATH,
  ENVIRONMENT,
  SURVEY_COLLAR_FOLDER_PATH,
} from "../../../../config";
import JobsInfo from "../../../../components/Jobs/jobsInfo";
import S3FileUploader from "../../../../components/S3/S3FileUploader";

interface FormValues {
  project_name: string;
  collar_file?: string;
  survey_file?: string;
}

const defaultValues: FormValues = {
  project_name: "",
  collar_file: "",
  survey_file: "",
};

const DesurveysPage: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);
  const methods = useForm<FormValues>({ defaultValues });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = methods;
  const service = "ll_generate_desurvey";
  const dispatch = useAppDispatch();
  const [submittedData, setSubmittedData] = useState<{} | null>(null);

  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const [files, setFiles] = useState<S3File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const bucketName = config[ENVIRONMENT].projects_s3_bucket;
  const pathPrefix = `${projects.selectedProject?.company}/${projects.selectedProject?.id}/${SURVEY_COLLAR_FOLDER_PATH}`;

  const loadFiles = async () => {
    try {
      const fetchedFiles = await fetchFiles(bucketName, pathPrefix, ["csv"]);
      setFiles(fetchedFiles);
    } catch (error) {
      console.error("Failed to fetch files:", error);
    }
  };

  useEffect(() => {
    loadFiles();
  }, [bucketName, pathPrefix]);

  useEffect(() => {
    console.log(files);
  }, [files]);

  useEffect(() => {
    if (projects.selectedProject) {
      setValue("project_name", projects.selectedProject.id);
    }
  }, [setValue, projects.selectedProject]);

  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = `logs.${service}`;
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);

  const onSubmit = (data: FormValues) => {
    const formData = {
      project_name: data.project_name,
      collar_file: `${bucketName}/${data.collar_file}`,
      survey_file: `${bucketName}/${data.survey_file}`,
    };
    setSubmittedData(formData);
    const request_body = filterNullProperties(formData);
    // runServiceMutation({
    //   service: "ll_generate_desurvey",
    //   request_body: request_body,
    //   environment: ENVIRONMENT,
    //   job_item: jobItem,
    // });
  };

  const handleFileUploadSuccess = () => {
    loadFiles();
  };

  return (
    projects.selectedProject && (
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", margin: "20px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <S3FileUploader
                bucketName={bucketName}
                pathPrefix={pathPrefix}
                allowedExtensions={[".csv"]}
                onUploadSuccess={handleFileUploadSuccess}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                name="collar_file"
                control={control}
                label="Collar File"
                options={files.map((fileItem) => ({
                  value: fileItem.Key,
                  label: getFileInfo(fileItem.Key).baseName,
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                name="survey_file"
                control={control}
                label="Survey File"
                options={files.map((fileItem) => ({
                  value: fileItem.Key,
                  label: getFileInfo(fileItem.Key).baseName,
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                // disabled={!isValid}
              >
                Submit
              </Button>
            </Grid>
            {submittedData && (
              <Grid item xs={12}>
                <Box mt={2}>
                  <Typography variant="h6">Payload:</Typography>
                  <pre>{JSON.stringify(submittedData, null, 2)}</pre>
                  <div>
                    {runServiceMutationResult.isLoading && (
                      <p>Sending Request ...</p>
                    )}
                    {runServiceMutationResult.data ? (
                      <div>
                        {/* Render the data here */}
                        <p>
                          Data: {JSON.stringify(runServiceMutationResult.data)}
                        </p>
                      </div>
                    ) : (
                      <p>No Response Available</p>
                    )}
                  </div>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <JobsInfo jobType={"litholens.run_color_extraction"} />
      </FormProvider>
    )
  );
};

export default DesurveysPage;
