import React, { useEffect, useState } from "react";
import {
  fetchFiles,
  S3File,
  listFolders,
  downloadS3FolderAsZip,
} from "../../../../components/S3/S3Utils";
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

interface ALResponseViewerProps {
  bucket: string;
  prefix: string;
  destination: string;
}

const ALResponseViewer: React.FC<ALResponseViewerProps> = ({
  bucket,
  prefix,
  destination,
}) => {
  const [folders, setFolders] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFolders = async () => {
      setLoading(true);
      setError(null);

      try {
        const fetchedFolders = await listFolders(bucket, prefix);
        setFolders(fetchedFolders);
      } catch (err) {
        setError("Error fetching folders");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFolders();
  }, [bucket, prefix]);

  const handleDownload = async (folderPrefix: string) => {
    try {
      await downloadS3FolderAsZip(bucket, folderPrefix);
      alert("Download started!");
    } catch (error) {
      console.error("Error downloading folder:", error);
      alert("Failed to download folder.");
    }
  };

  return (
    <div>
      {loading && <CircularProgress />}
      {error && <p>{error}</p>}
      <List>
        {folders.map((folder) => (
          <ListItem key={folder}>
            <ListItemText
              primary={folder.substring(
                folder.lastIndexOf("/", folder.lastIndexOf("/") - 1) + 1
              )}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDownload(folder)}
            >
              Download as Zip
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default ALResponseViewer;
