import React, { useEffect, useState } from "react";
import {
  Checkbox,
  TextField,
  Autocomplete,
  FormControl,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import {
  S3File,
  getFileInfo,
  getTableDataFromCsv,
  readJsonFromS3,
} from "../../../../../components/S3/S3Utils";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  setFilesInfo,
  setSelectedReferenceFile,
  setselectedSupportFiles,
} from "./talimMergeSlice";
import { getDefaultOptionsForColumns } from "./ColumnStrategies";

interface MergeFileSelectionProps {
  bucketName: string;
  pathPrefix: string;
}

const MergeFileSelection: React.FC<MergeFileSelectionProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const talim_merge = useAppSelector((state) => state.talim.talimMergeSlice);
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [dialogBoxKey, setDialogBoxKey] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const allFileKeys = [
    talim_merge.selectedReferenceFile,
    ...talim_merge.selectedSupportFiles,
  ];
  useEffect(() => {
    allFileKeys.forEach(async (fileKey) => {
      if (fileKey && !talim_merge.filesInfo[fileKey]) {
        const metaDataKey = fileKey
          .replace("data", "metaData")
          .replace(".csv", ".json");
        try {
          const metaData = await readJsonFromS3(bucketName, metaDataKey);
          const { columns, data } = await getTableDataFromCsv(
            bucketName,
            fileKey
          );
          dispatch(
            setFilesInfo({
              key: fileKey,
              info: {
                fileKey: fileKey,
                metaData: metaData,
                tableData: { columns: columns, data: data },
                columnStrategies: getDefaultOptionsForColumns(data),
              },
            })
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    });
  }, [
    talim_merge.selectedReferenceFile,
    talim_merge.selectedSupportFiles,
    bucketName,
  ]);

  const handleReferenceFileChange = (
    event: React.ChangeEvent<{}>,
    value: S3File | null
  ) => {
    const newReferenceFileKey = value ? value.Key : "";

    dispatch(setSelectedReferenceFile({ key: newReferenceFileKey }));
    if (talim_merge.selectedSupportFiles.includes(newReferenceFileKey)) {
      // Remove the newly selected reference file from the support files
      dispatch(
        setselectedSupportFiles({
          keys: talim_merge.selectedSupportFiles.filter(
            (key) => key !== newReferenceFileKey
          ),
        })
      );
    }
  };

  const handleSupportFilesChange = (
    event: React.ChangeEvent<{}>,
    value: S3File[]
  ) => {
    dispatch(
      setselectedSupportFiles({
        keys: value.map((file) => file.Key),
      })
    );
  };

  const filteredSupportFiles = talim_merge.files.filter(
    (file) => file.Key !== talim_merge.selectedReferenceFile
  );

  const handleClickOpen = (key: string) => {
    setDialogBoxKey(key);
    setOpenDialogBox(true);
  };

  const handleClose = () => {
    setDialogBoxKey(null);
    setOpenDialogBox(false);
  };

  const getDialogText = (key: string) => {
    switch (key) {
      case "referenceFile":
        return (
          <span>
            <strong>Reference File (required): </strong>The file which the
            intervals and points in the other files will be mapped to its
            intervals (or points).
          </span>
        );
      case "supportFiles":
        return (
          <span>
            <strong>Support Files (required): </strong>The file(s) whose
            intervals and points are to be mapped to the intervals (or points)
            of the reference file.
          </span>
        );

      default:
        return "No info available!";
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <FormControl fullWidth margin="normal">
        <Autocomplete
          options={talim_merge.files}
          getOptionLabel={(option) => getFileInfo(option.Key).baseName}
          value={
            talim_merge.files.find(
              (file) => file.Key === talim_merge.selectedReferenceFile
            ) || null
          }
          onChange={handleReferenceFileChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Reference File"
              variant="outlined"
              error={talim_merge.selectedReferenceFile === ""}
            />
          )}
          fullWidth
        />
      </FormControl>
      <Button
        variant="contained"
        startIcon={<HelpIcon />}
        onClick={() => handleClickOpen("referenceFile")}
      ></Button>

      <FormControl fullWidth margin="normal">
        <Autocomplete
          multiple
          options={filteredSupportFiles}
          disableCloseOnSelect
          getOptionLabel={(option) => getFileInfo(option.Key).baseName}
          value={filteredSupportFiles.filter((file) =>
            talim_merge.selectedSupportFiles.includes(file.Key)
          )}
          onChange={handleSupportFilesChange}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              {getFileInfo(option.Key).baseName}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Support Files"
              variant="outlined"
              error={talim_merge.selectedSupportFiles.length === 0}
            />
          )}
          fullWidth
        />
      </FormControl>
      <Button
        variant="contained"
        startIcon={<HelpIcon />}
        onClick={() => handleClickOpen("supportFiles")}
      ></Button>

      <Dialog open={openDialogBox} onClick={handleClose}>
        <DialogContent>
          <DialogContentText>
            {getDialogText(dialogBoxKey || "")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={handleClose}
          ></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default MergeFileSelection;
