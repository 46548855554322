import React, { useState } from "react";
import Rectify from "./Rectify";
import WhiteBalancePage from "./WhiteBalancePage";

interface Tab {
  title: string;
  component: React.ReactNode;
}

interface VisualizePageProps {
  company: string;
  projectName: string;
}

const VisualizePage: React.FC<VisualizePageProps> = ({ company, projectName }) => {
  const tabs: Tab[] = [
    {
      title: "Rectify",
      component: <Rectify
        company={company}
        projectName={projectName}
      />,
    },
    {
      title: "White Balance",
      component: <WhiteBalancePage
        company={company}
        projectName={projectName} />,
    },
  ];

  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div style={{ width: "200px", background: "#f0f0f0", padding: "10px" }}>
        {/* Tab titles */}
        {tabs.map((tab, index) => (
          <div
            key={index}
            style={{
              padding: "10px",
              cursor: "pointer",
              backgroundColor: activeTab === index ? "#ddd" : "transparent",
            }}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div style={{ flex: 1, padding: "10px" }}>
        {/* Tab content */}
        <h3 style={{ margin: "20px", textAlign: "center" }}>
          {tabs[activeTab].title}
        </h3>
        {tabs[activeTab].component}
      </div>
    </div>
  );
};

export default VisualizePage;
