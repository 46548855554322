import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  RunServiceRequest,
  useRunServiceMutation,
} from "../../../../api/endpoints/superApi";
import { Button, Alert, Collapse } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import FengConfigParams from "./fengConfigParams";
import { setState } from "./talimFengSlice";
import {
  JobItem,
  createJobItem,
} from "../../../../../components/Jobs/jobUtils";
import { writeJsonToS3 } from "../../../../../components/S3/S3Utils";

// --------------------------------------------------------------------------------------------------
interface ApiFileMetaData {
  path: string;
  data_type: string | null;
  drillhole_column_name: string | null;
  interval_from_column_name: string | null;
  interval_to_column_name: string | null;
  interval_depth_column_name: string | null;
  standard_scaling: string[];
  clr_transform: string[];
  binning: string[];
  ignore_features: string[];
}

// --------------------------------------------------------------------------------------------------
interface ApiRequest {
  project_name: string;
  environment: string;
  // job_item: JobItem;
  dataset_info: ApiFileMetaData;
  task_type: string;
  target_feature: string;
  num_bins: number;
  service_sort_by: string;
  polynomial_degree: number | null;
  low_variance_threshold: number | null;
  outliers_method: string | null;
  outliers_threshold: number | null;
  transformation_method: string | null;
  normalize_method: string | null;
  feature_selection_method: string | null;
  n_features_to_select: number | null;
  register_to_litholens: boolean;
  register_to_logsapi: boolean;
  descriptive_log_name: string;
  notification_email: string | null;
  structures_to_process: [] | null;
  drillholes_to_process: [] | null;
  s3_data_save_path: string | null;
  litholens_save_path_type: "s3" | "logsAPI" | null;
}

// --------------------------------------------------------------------------------------------------
interface FEngRequestProps {
  bucketName: string;
  pathPrefix: string;
}

// --------------------------------------------------------------------------------------------------
const FEngRequest: React.FC<FEngRequestProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const talim_feng = useAppSelector((state) => state.talim.talimFengSlice);
  const dispatch = useAppDispatch();

  const maxDimensions =
    talim_feng.filesInfo[talim_feng.selectedReferenceFile]?.tableData.columns
      .length - 3;

  // --------------------------------------------------------------------------------------------------
  // Job Item & API Request

  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);

  // const url: string = window.location.href;
  // const company: string = url.split("/").filter(Boolean)[2];
  // const project: string = url.split("/").filter(Boolean)[3];
  // const projectName: string = company + "_" + project;
  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = "logs.feng";
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);
  const [lastJobID, setLastJobID] = useState<string | null>(null);
  const env = process.env.REACT_APP_ENV || "test";

  // --------------------------------------------------------------------------------------------------
  const [apiRequest, setApiRequest] = useState<ApiRequest>({
    dataset_info: {} as ApiFileMetaData,
    // job_item: jobItem,
    project_name: projectName,
    environment: env,
    task_type: talim_feng.task_type,
    target_feature: talim_feng.target_feature,
    num_bins: talim_feng.num_bins,
    service_sort_by: talim_feng.service_sort_by,
    polynomial_degree: talim_feng.polynomial_degree,
    low_variance_threshold: talim_feng.low_variance_threshold,
    outliers_method: talim_feng.outliers_method,
    outliers_threshold: talim_feng.outliers_threshold,
    transformation_method: talim_feng.transformation_method,
    normalize_method: talim_feng.normalize_method,
    feature_selection_method: talim_feng.feature_selection_method,
    n_features_to_select: talim_feng.n_features_to_select,
    register_to_litholens: talim_feng.register_to_litholens,
    register_to_logsapi: talim_feng.register_to_logsapi,
    descriptive_log_name: talim_feng.descriptive_log_name,
    notification_email: talim_feng.notification_email,
    structures_to_process: talim_feng.structures_to_process,
    drillholes_to_process: talim_feng.drillholes_to_process,
    s3_data_save_path: talim_feng.s3_data_save_name,
    litholens_save_path_type: talim_feng.litholens_save_path_type,
  });

  // --------------------------------------------------------------------------------------------------
  // Send API Request
  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const handleSubmit = async (requestData: RunServiceRequest) => {
    runServiceMutation(requestData);
    const fixedItem = requestData.job_item as JobItem;
    setLastJobID(fixedItem.id);
    const pendingMessage = { status: "requested" };
    writeJsonToS3(
      bucketName,
      pathPrefix + "/responses/feng/" + fixedItem.id + ".json",
      pendingMessage
    );
  };

  const log = (type: any) => console.log.bind(console, type);

  // --------------------------------------------------------------------------------------------------
  const [open, setOpen] = useState(false);
  const handleAlertClose = (event: SyntheticEvent) => {
    setOpen(false);
  };

  useEffect(() => {
    if (talim_feng.filesInfo[talim_feng.selectedReferenceFile]) {
      if (
        !(
          talim_feng.target_feature in
          talim_feng.filesInfo[talim_feng.selectedReferenceFile].tableData
            .columns
        )
      ) {
        // dispatch(
        //   setState({
        //     key: "target_feature",
        //     value:
        //       talim_feng.filesInfo[talim_feng.selectedReferenceFile]?.tableData
        //         .columns[maxDimensions + 2] || "",
        //   })
        // );
      }
    }
    var newBaseStructuresInfo = {} as ApiFileMetaData;
    Object.values(talim_feng.filesInfo).forEach((element) => {
      newBaseStructuresInfo = {
        path: "s3://" + bucketName + "/" + element.fileKey,
        data_type: element.metaData.selectedType,
        drillhole_column_name: element.metaData.drillhole,
        interval_from_column_name: element.metaData.begin,
        interval_to_column_name: element.metaData.end,
        interval_depth_column_name: element.metaData.pointDepth,
        standard_scaling: element.standard_scaling,
        clr_transform: element.clr_transformation,
        binning: element.binning,
        ignore_features: element.ignore,
      };
    });
    var modifiedLogDescripitveName = "new_log";
    if (typeof talim_feng.descriptive_log_name === "string") {
      modifiedLogDescripitveName = talim_feng.descriptive_log_name;
    }
    setApiRequest({
      ...apiRequest,
      dataset_info: newBaseStructuresInfo,
      register_to_litholens: talim_feng.register_to_litholens,
      num_bins: talim_feng.num_bins,
      target_feature: talim_feng.target_feature,
      service_sort_by: talim_feng.service_sort_by,
      polynomial_degree: talim_feng.polynomial_degree,
      low_variance_threshold: talim_feng.low_variance_threshold,
      outliers_method: talim_feng.outliers_method,
      outliers_threshold: talim_feng.outliers_threshold,
      transformation_method: talim_feng.transformation_method,
      normalize_method: talim_feng.normalize_method,
      feature_selection_method: talim_feng.feature_selection_method,
      n_features_to_select: talim_feng.n_features_to_select,
      register_to_logsapi: talim_feng.register_to_logsapi,
      notification_email: talim_feng.notification_email,
      descriptive_log_name: talim_feng.descriptive_log_name,
      s3_data_save_path: talim_feng.s3_data_save_name
        ? "s3://" +
          bucketName +
          "/" +
          pathPrefix +
          "/data/" +
          talim_feng.s3_data_save_name +
          ".csv"
        : null,
    });
  }, [talim_feng, projects.selectedProject]);

  useEffect(() => {
    // if (runServiceMutationResult?.isSuccess) {
    //   alert("API request has been sent successfully!");
    // } else {
    //   if (runServiceMutationResult?.isError) {
    //     alert("API request has been encountered an error!");
    //   }
    // }
    setOpen(true);
  }, [runServiceMutationResult]);

  // --------------------------------------------------------------------------------------------------
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <FengConfigParams />
      <Button
        variant="contained"
        onClick={(e) =>
          handleSubmit({
            service: "ll_run_talim_feng",
            request_body: apiRequest,
            job_item: jobItem,
            environment: env,
          } as RunServiceRequest)
        }
        onError={log("errors")}
        disabled={runServiceMutationResult.isLoading}
      >
        Send Request
      </Button>
      <Collapse in={open}>
        {(runServiceMutationResult?.isSuccess ||
          runServiceMutationResult?.isError) && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity={runServiceMutationResult?.isSuccess ? "success" : "error"}
            onClose={handleAlertClose}
          >
            {runServiceMutationResult?.isSuccess
              ? "Request sent successfully! Your request ID is " + lastJobID
              : "Request Sending has been ecnountered an error!"}
          </Alert>
        )}
      </Collapse>
      {/* <pre>{JSON.stringify(apiRequest, null, 2)}</pre> */}
    </div>
  );
};

export default FEngRequest;
