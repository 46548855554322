import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../redux/store";
import { S3File } from "../../../../../components/S3/S3Utils";
import { WritableDraft } from "immer";

export interface TableData {
  columns: string[];
  data: any[];
}

export interface FileInfo {
  fileKey: string;
  metaData: any;
  tableData: TableData;
}

export interface OperationMetaData {
  operation: string;
  argument: string;
}

export interface tsmState {
  // files: S3File[];
  // filesInfo: { [key: string]: FileInfo };
  dataset_name: string;
  model_arch: string;
  backbone: string;
  optimizer: string;
  learning_rate: number;
  batch_size: number;
  s3_model_save_path: string;
  model_save_name: string;
  max_epochs: number;
  s3_pretrained_weights_path: string | null;
  num_workers: number;
  img_height: number;
  img_width: number;
}

const initialState: tsmState = {
  // files: [],
  // filesInfo: {},
  dataset_name: "",
  model_arch: "mask_rcnn",
  backbone: "resnet50",
  learning_rate: 0.002,
  s3_model_save_path: "",
  model_save_name: "",
  optimizer: "Adam",
  batch_size: 4,
  max_epochs: 5,
  img_height: 512,
  img_width: 512,
  s3_pretrained_weights_path: null,
  num_workers: 1,
};

type TSMStateKey = keyof tsmState;

export const tsmSlice = createSlice({
  name: "tsm",
  initialState: initialState,
  reducers: {
    // -------------------------------------------------------------------------------
    // General setter for updating any state attribute
    setState: <T extends TSMStateKey>(
      state: WritableDraft<tsmState>,
      action: PayloadAction<{ key: T; value: tsmState[T] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const selectTSM = (state: RootState) => state.tsm;

// Action creators are generated for each case reducer function
export const { setState } = tsmSlice.actions;

export default tsmSlice.reducer;
