import React from "react";
import { Box, Button } from "@mui/material";

interface LightboxToolbarProps {
  imageType: string;
  onLoadImage: (type: string) => void;
  fileMaps: {
    original: boolean;
    filtered: boolean;
    split: boolean;
    masks: boolean;
    split_masks: boolean;
  };
}

const LightboxToolbar: React.FC<LightboxToolbarProps> = ({
  imageType,
  onLoadImage,
  fileMaps,
}) => {
  return (
    <Box display="flex" justifyContent="center" gap={2}>
      <Button
        variant={imageType === "Original" ? "contained" : "outlined"}
        onClick={() => onLoadImage("Original")}
        disabled={!fileMaps.original}
      >
        Original
      </Button>
      <Button
        variant={imageType === "filtered" ? "contained" : "outlined"}
        onClick={() => onLoadImage("filtered")}
        disabled={!fileMaps.filtered}
      >
        Filtered
      </Button>
      <Button
        variant={imageType === "split" ? "contained" : "outlined"}
        onClick={() => onLoadImage("split")}
        disabled={!fileMaps.split}
      >
        Split
      </Button>
      <Button
        variant={imageType === "masks" ? "contained" : "outlined"}
        onClick={() => onLoadImage("masks")}
        disabled={!fileMaps.masks}
      >
        Mask
      </Button>
      <Button
        variant={imageType === "split_masks" ? "contained" : "outlined"}
        onClick={() => onLoadImage("split_masks")}
        disabled={!fileMaps.split_masks}
      >
        Split Mask
      </Button>
    </Box>
  );
};

export default LightboxToolbar;
