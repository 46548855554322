import React, { useEffect, useState } from "react";
import { getFilesWithMetaData, getModels } from "../talimUtils";
import InferenceFileSelection from "./inferenceFileSelection";
import InferenceFileTabs from "./inferenceFileTabs";
import FEngFileTabs from "./inferenceFileTabs";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFiles, setModels } from "./talimInferenceSlice";
import InferenceRequest from "./inferenceRequest";
import InferenceResponseViewer from "./inferenceResponseViewer";
import { Button } from "@mui/material";

interface InferencePageProps {
  bucketName: string;
  pathPrefix: string;
}

const InferencePage: React.FC<InferencePageProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const talim_inference = useAppSelector(
    (state) => state.talim.talimInferenceSlice
  );
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadFiles = async () => {
      const fileWithJsonData = await getFilesWithMetaData(
        bucketName,
        pathPrefix
      );
      dispatch(setFiles({ files: fileWithJsonData }));
    };
    const loadModels = async () => {
      const modelFiles = await getModels(bucketName, pathPrefix);
      dispatch(setModels({ models: modelFiles }));
    };
    loadFiles();
    loadModels();
  }, [pathPrefix]);

  const [viewJobs, setViewJobs] = React.useState(false);

  const handleClick = () => {
    setViewJobs(!viewJobs);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px",
          textAlign: "center",
        }}
      >
        <Button variant="outlined" onClick={handleClick}>
          {viewJobs ? "Request a New Job" : "View Completed Jobs"}
        </Button>
      </div>
      <div key={"main"} hidden={viewJobs}>
        <InferenceFileSelection
          bucketName={bucketName}
          pathPrefix={pathPrefix}
        />
        <InferenceFileTabs bucketName={bucketName} />
        <InferenceRequest bucketName={bucketName} pathPrefix={pathPrefix} />
      </div>
      <div key={"dialog"} hidden={!viewJobs}>
        <InferenceResponseViewer
          refresh=""
          bucketName={bucketName}
          pathPrefix={pathPrefix}
          serviceName="inference"
        ></InferenceResponseViewer>
      </div>
    </div>
  );
};

export default InferencePage;
