import React, { useEffect, useState } from "react";
import { getFilesWithMetaData } from "../smsUtils";
import CalculateFileSelection from "./calculateFileSelection";
import CalculateFileTabs from "./calculateFileTabs";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFiles } from "../smsSlice";
import S3ResponseViewer from "../../../../../components/S3/S3ResponseViewer";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import projectsSlice from "../../../../projects/projectsSlice";
import CalculateConfigParams from "./calculateConfigParams";
import CalculateProportionsRequest from "./calculateProprotionsRequest";
import LabelAttributesRequest from "./labelAttributesRequest";
import CopperCalculationsRequest from "./copperCalculationsRequest";

interface CalculatePageProps {
  bucketName: string;
  pathPrefix: string;
}

const CalculatePage: React.FC<CalculatePageProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const sms = useAppSelector((state) => state.sms.SMSSlice);
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadFiles = async () => {
      const fileWithJsonData = await getFilesWithMetaData(
        bucketName,
        pathPrefix
      );
      dispatch(setFiles({ files: fileWithJsonData }));
      console.log({ files: fileWithJsonData });
    };
    loadFiles();
  }, [pathPrefix]);

  const [expandedVisualizeFiles, setExpandedVisualizeFiles] = useState(false);
  const handleChangeVisualizeFilesAccordion = () => {
    setExpandedVisualizeFiles(!expandedVisualizeFiles);
  };

  const [expandedVisualizeJobs, setExpandedVisualizeJobs] = useState(false);
  const handleChangeVisualizeJobsAccordion = () => {
    setExpandedVisualizeJobs(!expandedVisualizeJobs);
  };

  const [expandedPrepInputs, setExpandedPrepInputs] = useState(false);
  const handleChangePrepInputsAccordion = () => {
    setExpandedPrepInputs(!expandedPrepInputs);
  };

  const [expandedVisualizeResponseViewer, setExpandedVisualizeResponseViewer] =
    useState(false);
  const handleChangeVisualizeResponseViewerAccordion = () => {
    setExpandedVisualizeResponseViewer(!expandedVisualizeResponseViewer);
  };

  const [viewJobs, setViewJobs] = React.useState(false);

  const handleClick = () => {
    setViewJobs(!viewJobs);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px",
          textAlign: "center",
        }}
      ></div>
      <div key={"main"}>
        {/* hidden={!viewJobs} */}
        <CalculateFileSelection
          bucketName={bucketName}
          pathPrefix={pathPrefix}
        />
        <Accordion
          expanded={expandedVisualizeFiles}
          onChange={handleChangeVisualizeFilesAccordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>Preview Selected Files</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CalculateFileTabs bucketName={bucketName} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expandedPrepInputs}
          onChange={handleChangePrepInputsAccordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>Configure Data Preprocessing Inputs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CalculateConfigParams />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expandedVisualizeJobs}
          onChange={handleChangeVisualizeJobsAccordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Run Jobs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ border: "1px solid black", p: 2, borderRadius: "8px" }}>
              <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                Calculate Mineral Proportions
              </Typography>
              <CalculateProportionsRequest
                bucketName={bucketName}
                pathPrefix={pathPrefix}
              />
            </Box>
            <Box
              sx={{
                border: "1px solid black",
                p: 2,
                borderRadius: "8px",
                mt: 2,
              }}
            >
              <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                Label Attributes into Assay File
              </Typography>
              <Button variant="outlined" onClick={handleClick}>
                {viewJobs ? "Request a New Job" : "View Completed Jobs"}
              </Button>
              <div hidden={viewJobs}>
                <LabelAttributesRequest
                  bucketName={bucketName}
                  pathPrefix={pathPrefix}
                />
              </div>
              <div hidden={!viewJobs}>
                <S3ResponseViewer
                  refresh=""
                  bucketName={bucketName}
                  pathPrefix={pathPrefix}
                  serviceName="label_assays"
                ></S3ResponseViewer>
              </div>
            </Box>
            <Box
              sx={{
                border: "1px solid black",
                p: 2,
                borderRadius: "8px",
                mt: 2,
              }}
            >
              <Typography variant="h6" align="center" sx={{ mt: 10 }}>
                Copper Calculations
              </Typography>
              <CopperCalculationsRequest
                bucketName={bucketName}
                pathPrefix={pathPrefix}
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* <MergeRequest bucketName={bucketName} pathPrefix={pathPrefix} /> */}
      </div>
    </div>
  );
};

export default CalculatePage;
