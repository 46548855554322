import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProjectsSelector from "../../projects/projectSelector";
import { useAppSelector } from "../../../redux/hooks";
import ReportsPage from "./reportsPage";
import ImageGallery from "../../gallery/ImageGallery";
import Visualizer from "../../visualization/visualizer";
import CleanImagesPage from "./clean_images/CleanImagesPage";
import Rectify from "../../visualization/Rectify";
import VisualizePage from "../../visualization/Visualize";

const steps: string[] = [
  "Clean Images",
  "Gallery",
  "Visualize/Rectify",
  "Generate Reports",
  "Visualization",
];

const LitholensFlowPage: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const projects = useAppSelector((state) => state.projects);
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <div>
      <ProjectsSelector />
      {projects.selectedProject ? (
        <Box>
          <div
            style={{
              border: "1px solid #ccc",
              padding: "20px",
              borderRadius: "4px",
            }}
          >
            <Stepper
              nonLinear
              activeStep={activeStep}
              orientation={matches ? "vertical" : "horizontal"}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </div>
          <div>
            {
              <React.Fragment>
                {activeStep === 0 && <CleanImagesPage />}
                {activeStep === 1 && (
                  <ImageGallery
                    company={projects.selectedProject.company}
                    projectName={projects.selectedProject.id}
                  />
                )}
                {activeStep === 2 && (
                  <VisualizePage
                    company={projects.selectedProject.company}
                    projectName={projects.selectedProject.id}
                  />
                )}
                {activeStep === 3 && <ReportsPage />}
                {activeStep === 4 && (
                  <Visualizer
                    company={projects.selectedProject.company}
                    projectName={projects.selectedProject.id}
                  />
                )}
              </React.Fragment>
            }
          </div>
        </Box>
      ) : (
        <Typography sx={{ margin: "20px", textAlign: "center" }}>
          Please Choose a Project
        </Typography>
      )}
    </div>
  );
};

export default LitholensFlowPage;
