import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Slider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setState } from "./talimFengSlice";

const FengConfigParams: React.FC = () => {
  const talim_feng = useAppSelector((state) => state.talim.talimFengSlice);
  const dispatch = useAppDispatch();

  const maxDimensions =
    talim_feng.filesInfo[talim_feng.selectedReferenceFile]?.tableData.columns
      .length - 3;
  // --------------------------------------------------------------------------------------------------
  // Shared Parameters
  const MAX = 50;
  const MIN = 2;
  const marks = [
    {
      value: MIN,
      label: MIN,
    },
    {
      value: MAX,
      label: MAX,
    },
  ];

  const handleNumBinsChange = (event: Event, newValue: number | number[]) => {
    dispatch(
      setState({
        key: "num_bins",
        value: newValue as number,
      })
    );
  };

  // const [taskType, setTaskType] = useState("classification");

  const handleTaskTypeChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "task_type",
        value: event.target.value as string,
      })
    );
  };

  // const [targetFeature, setTargetFeature] = useState(
  //   talim_feng.filesInfo[talim_feng.selectedReferenceFile]?.tableData.columns[
  //     maxDimensions + 2
  //   ] || ""
  // );

  const handleTargetFeatureChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "target_feature",
        value: event.target.value as string,
      })
    );
  };

  const handleOutlierRemovalMethodChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "outliers_method",
        value: event.target.value as string,
      })
    );
  };

  const handleOutlierRemovalThresholdChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "outliers_threshold",
          value: parseFloat(newValue),
        })
      );
    }
  };

  const handleFeatureSelectionMethodChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "feature_selection_method",
        value: event.target.value as string,
      })
    );
  };

  const handleNumberOfSelectedFeaturesChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "n_features_to_select",
          value: parseInt(newValue),
        })
      );
    }
  };

  const handleTransformationMethodChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "transformation_method",
        value: event.target.value as string,
      })
    );
  };

  const handleNormalizationMethodChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "normalize_method",
        value: event.target.value as string,
      })
    );
  };

  const handleSortRankingsByChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "service_sort_by",
        value: event.target.value as string,
      })
    );
  };

  const [polynomialTransformation, setPolynomialTransformation] =
    useState<boolean>(false);

  const handlePolynomialTransformationChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setPolynomialTransformation(checked);
  };

  const handlePolynomialDegreeChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "polynomial_degree",
          value: polynomialTransformation ? parseInt(newValue) : null,
        })
      );
    }
  };

  const [discardLowVarianceFeatures, setDiscardLowVarianceFeatures] =
    useState<boolean>(false);

  const handleDiscardLowVarianceFeatures = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setDiscardLowVarianceFeatures(checked);
  };

  const handleLowVarianceThreshold = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      dispatch(
        setState({
          key: "low_variance_threshold",
          value: discardLowVarianceFeatures ? parseFloat(newValue) : null,
        })
      );
    }
  };

  const handleRegisterToLithoLensChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "register_to_litholens",
        value: checked,
      })
    );
  };

  const handleRegisterToLogsApiChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "register_to_logsapi",
        value: checked,
      })
    );
  };

  const handleNotificationEmailChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "notification_email",
        value: event.target.value as string,
      })
    );
  };

  const handleS3SaveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "s3_data_save_name",
        value: event.target.value as string,
      })
    );
  };

  const handleLogDescriptiveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "descriptive_log_name",
        value: event.target.value as string,
      })
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Set FEng API Parameters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
          >
            <FormControl variant="standard">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Task Type
              </InputLabel>
              <Select
                defaultValue={talim_feng.task_type}
                onChange={handleTaskTypeChange}
              >
                <MenuItem value={"classification"}>Classification</MenuItem>
                <MenuItem value={"regression"}>Regression</MenuItem>
                <MenuItem value={"clustering"}>Clustering</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ width: 250 }}>
              <Typography id="track-false-slider" gutterBottom>
                Number of bins for numeric values
              </Typography>
              <Slider
                marks={marks}
                step={1}
                value={talim_feng.num_bins}
                valueLabelDisplay="auto"
                min={MIN}
                max={MAX}
                onChange={handleNumBinsChange}
              />
            </Box>

            <FormControl>
              <InputLabel>Target Feature </InputLabel>
              <Select
                value={talim_feng.target_feature}
                label="Target Feature"
                onChange={handleTargetFeatureChange}
                error={
                  talim_feng.target_feature === null ||
                  talim_feng.target_feature === ""
                }
              >
                {talim_feng.filesInfo[
                  talim_feng.selectedReferenceFile
                ]?.tableData.columns.map((column) => (
                  <MenuItem key={column} value={column}>
                    {column}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
          >
            <FormControl variant="standard">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Outlier Removal Method
              </InputLabel>
              <Select
                defaultValue={talim_feng.outliers_method as string}
                onChange={handleOutlierRemovalMethodChange}
              >
                <MenuItem value={""}>None</MenuItem>
                <MenuItem value={"iforest"}>Isolation Forest</MenuItem>
                <MenuItem value={"lof"}>Local Outlier Factor</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard">
              <TextField
                label="Outlier Removal Threshold"
                variant="outlined"
                type="number"
                defaultValue={talim_feng.outliers_threshold}
                disabled={talim_feng.outliers_method == ""}
                onChange={handleOutlierRemovalThresholdChange}
                inputProps={{
                  // Optional: Define min and max values
                  min: 0, // Minimum value
                  max: 100, // Maximum value
                  step: 0.01, // Step increment for keyboard arrows
                }}
              />
            </FormControl>
          </Box>

          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
          >
            <FormControl variant="standard">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Feature Selection Method
              </InputLabel>
              <Select
                defaultValue={talim_feng.feature_selection_method as string}
                onChange={handleFeatureSelectionMethodChange}
              >
                <MenuItem value={""}>None</MenuItem>
                <MenuItem value={"classic"}>Classic</MenuItem>
                <MenuItem value={"univariate"}>Univariate</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard">
              <TextField
                label="Number of features to select"
                variant="outlined"
                type="number"
                defaultValue={talim_feng.n_features_to_select}
                disabled={talim_feng.feature_selection_method == ""}
                onChange={handleNumberOfSelectedFeaturesChange}
                inputProps={{
                  // Optional: Define min and max values
                  min: 0, // Minimum value
                  max: maxDimensions ? maxDimensions : 2, // Maximum value
                  step: 1, // Step increment for keyboard arrows
                }}
              />
            </FormControl>
          </Box>

          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
          >
            <FormControl variant="standard">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Transformation Method
              </InputLabel>
              <Select
                defaultValue={talim_feng.transformation_method as string}
                onChange={handleTransformationMethodChange}
              >
                <MenuItem value={""}>None</MenuItem>
                <MenuItem value={"yeo-johnson"}>Yeo-Johnson</MenuItem>
                <MenuItem value={"quantile"}>Quantile</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Normalization Method
              </InputLabel>
              <Select
                defaultValue={talim_feng.normalize_method as string}
                onChange={handleNormalizationMethodChange}
              >
                <MenuItem value={""}>None</MenuItem>
                <MenuItem value={"zscore"}>Z Score</MenuItem>
                <MenuItem value={"minmax"}>Min - Max</MenuItem>
                <MenuItem value={"maxabs"}>Maximum Abs.</MenuItem>
                <MenuItem value={"robust"}>Robust</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Feature ranking method to sort by
              </InputLabel>
              <Select
                defaultValue={talim_feng.service_sort_by}
                onChange={handleSortRankingsByChange}
              >
                {/* <MenuItem value={""}>None</MenuItem> */}
                <MenuItem value={"info_gain"}>Information Gain</MenuItem>
                <MenuItem value={"gain_ratio"}>Gain Ratio</MenuItem>
                <MenuItem value={"gini_index"}>Gini Index</MenuItem>
                <MenuItem value={"chi2"}>Chi-Square</MenuItem>
                <MenuItem value={"anova"}>ANOVA</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
          >
            <FormControlLabel
              control={
                <Switch
                  value="poly_transform"
                  checked={polynomialTransformation}
                  onChange={handlePolynomialTransformationChange}
                />
              }
              label="Apply Polynomial Transformation"
            />
            <FormControl variant="standard">
              <TextField
                label="Polynomial Transformation Degree"
                variant="outlined"
                type="number"
                defaultValue={talim_feng.polynomial_degree}
                disabled={!polynomialTransformation}
                onChange={handlePolynomialDegreeChange}
                inputProps={{
                  // Optional: Define min and max values
                  min: 1, // Minimum value
                  max: maxDimensions ? maxDimensions : 2, // Maximum value
                  step: 1, // Step increment for keyboard arrows
                }}
              />
            </FormControl>
          </Box>

          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
          >
            <FormControlLabel
              control={
                <Switch
                  value="discard_low_variance"
                  checked={discardLowVarianceFeatures}
                  onChange={handleDiscardLowVarianceFeatures}
                />
              }
              label="Discard Low-Variance Features"
            />
            <FormControl variant="standard">
              <TextField
                label="Variance Threshold"
                variant="outlined"
                type="number"
                defaultValue={talim_feng.low_variance_threshold}
                disabled={!discardLowVarianceFeatures}
                onChange={handleLowVarianceThreshold}
                inputProps={{
                  // Optional: Define min and max values
                  min: 0, // Minimum value
                  max: 100, // Maximum value
                  step: 0.01, // Step increment for keyboard arrows
                }}
              />
            </FormControl>
          </Box>
          <Typography>{}</Typography>
          <Typography>Output Registration Options</Typography>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControlLabel
              control={
                <Switch
                  value="register_to_litholens"
                  checked={talim_feng.register_to_litholens}
                  onChange={handleRegisterToLithoLensChange}
                />
              }
              label="LithoLens"
            />
            <FormControlLabel
              control={
                <Switch
                  value="register_to_logsapi"
                  checked={talim_feng.register_to_logsapi}
                  onChange={handleRegisterToLogsApiChange}
                />
              }
              label="LogsAPI"
            />
          </Box>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="notification-email"
              label="Notification E-Mail"
              defaultValue=""
              onChange={handleNotificationEmailChange}
            />
            <TextField
              id="s3-save-name"
              label="S3 Save Name"
              defaultValue={talim_feng.s3_data_save_name}
              onChange={handleS3SaveNameChange}
            />
            <TextField
              id="logs-descriptive-name"
              label="LogsAPI descriptive name"
              defaultValue=""
              onChange={handleLogDescriptiveNameChange}
              disabled={!talim_feng.register_to_logsapi}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FengConfigParams;
