import React, { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomTable from "../../../../../components/DataDisplay/DisplayTabularData";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import ColumnActions from "./ColumnActions";

interface FileTabProps {
  bucketName: string;
}

const FEngFileTabs: React.FC<FileTabProps> = ({ bucketName }) => {
  const talim_feng = useAppSelector((state) => state.talim.talimFengSlice);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const allFileKeys = [talim_feng.selectedReferenceFile];

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      {talim_feng.filesInfo[talim_feng.selectedReferenceFile] && (
        <CustomTable
          tableHeaders={
            talim_feng.filesInfo[talim_feng.selectedReferenceFile].tableData
              .columns
          }
          tableData={
            talim_feng.filesInfo[talim_feng.selectedReferenceFile].tableData
              .data
          }
        />
      )}
      {talim_feng.filesInfo[talim_feng.selectedReferenceFile] && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-strategies-content"
            id="panel-strategies-header"
          >
            <Typography>Specify/Edit Column FEng Actions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ColumnActions fileKey={talim_feng.selectedReferenceFile} />
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default FEngFileTabs;
