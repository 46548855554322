import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import config, { DRILLHOLES_FOLDER_PATH, ENVIRONMENT } from "../../config";
import { listFolders } from "../../components/S3/S3Utils";
import DrillholeSelector from "../gallery/DrillholeSelector";
import PercentageReportViewer from "./PercentageReportViewer";
import { useAppSelector } from "../../redux/hooks";
import DrillholeViewer from "./DrillholeViewer";
import TickViewer from "./TickViewer";
import {
  ReportItem,
  useGetAllReportsQuery,
} from "../api/endpoints/projectsApi";
import VeinNetReportViewer from "./VeinNetReportViewer";
import PolygonEditor from "./PolygonEditor/PolygonEditor";

interface VisualizerProps {
  company: string;
  projectName: string;
}

const Visualizer: React.FC<VisualizerProps> = ({ company, projectName }) => {
  const projects_s3_bucket = config[ENVIRONMENT].projects_s3_bucket;
  const projects = useAppSelector((state) => state.projects);
  const [drillholes, setDrillholes] = useState<string[]>([]);
  const [selectedDrillhole, setSelectedDrillhole] = useState<string>("");
  const [selectedReports, setSelectedReports] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [meterPixelRatio, setMeterPixelRatio] = useState<number>(200); // -Default METER_PIXEL_RATIO

  const bucketName = projects_s3_bucket;
  const pathPrefix = `${company}/${projectName}/${DRILLHOLES_FOLDER_PATH}`;

  const { data: allReportsResponse, isLoading: allReportsLoading } =
    useGetAllReportsQuery({
      companyName: projects.selectedProject?.company || "",
      projectName: projects.selectedProject?.id || "",
    });

  const allReportsList =
    allReportsResponse?.items.map(
      (item: ReportItem) => item.visualizationName
    ) || [];

  const selectedPercentageReports = selectedReports
    .map((reportName) =>
      allReportsResponse?.items.find(
        (report: ReportItem) =>
          report.visualizationName === reportName &&
          report.visualizationType === "Dominant Color"
      )
    )
    .filter((report) => report !== undefined) as ReportItem[];

  const selectedVeiNetReports = selectedReports
    .map((reportName) =>
      allReportsResponse?.items.find(
        (report: ReportItem) =>
          report.visualizationName === reportName &&
          report.visualizationType === "Vein Net"
      )
    )
    .filter((report) => report !== undefined) as ReportItem[];

  useEffect(() => {
    setSelectedReports([]);
  }, [projects.selectedProject]);

  useEffect(() => {
    const fetchDrillholes = async () => {
      setLoading(true);
      setError(null);
      try {
        const folders = await listFolders(bucketName, pathPrefix);
        const drillholeNames = folders.map((folder) => {
          const parts = folder.split("/");
          return parts[parts.length - 2]; // Get the folder name
        });
        setDrillholes(drillholeNames);
        if (drillholeNames.length > 0) {
          setSelectedDrillhole(drillholeNames[0]);
        }
      } catch (error) {
        setError("Error fetching drillholes");
        console.error("Error fetching drillholes: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDrillholes();
  }, [bucketName, pathPrefix]);

  return (
    <div>
      <DrillholeSelector
        drillholes={drillholes}
        selectedDrillhole={selectedDrillhole}
        onDrillholeChange={setSelectedDrillhole}
      />
      {loading && <CircularProgress />}
      {error && <p>{error}</p>}
      <div style={{ margin: "20px 0" }}>
        <Autocomplete
          multiple
          options={allReportsList}
          value={selectedReports}
          getOptionLabel={(option) => option}
          loading={allReportsLoading}
          onChange={(event, value) => setSelectedReports(value as string[])}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Reports"
              placeholder="Reports"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {allReportsLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </div>
      <div style={{ margin: "20px 0" }}>
        <Slider
          value={meterPixelRatio}
          onChange={(event, newValue) => setMeterPixelRatio(newValue as number)}
          min={10}
          max={1000}
          valueLabelDisplay="auto"
          aria-labelledby="meter-pixel-ratio-slider"
        />
      </div>
      <div style={{ overflowX: "auto", height: "auto", position: "relative" }}>
        <div>
          <TickViewer
            company={company}
            projectName={projectName}
            selectedDrillhole={selectedDrillhole}
            meterPixelRatio={meterPixelRatio}
            projects_s3_bucket={projects_s3_bucket}
          />
          <DrillholeViewer
            company={company}
            projectName={projectName}
            selectedDrillhole={selectedDrillhole}
            meterPixelRatio={meterPixelRatio}
            projects_s3_bucket={projects_s3_bucket}
          />
        </div>
        {selectedPercentageReports.map((report) => (
          <div key={report.reportId}>
            <div>{`Report: ${report.visualizationName}`}</div>
            <PercentageReportViewer
              company={company}
              projectName={projectName}
              selectedDrillhole={selectedDrillhole}
              meterPixelRatio={meterPixelRatio}
              projects_s3_bucket={projects_s3_bucket}
              reportId={report.reportId}
            />
          </div>
        ))}
        {selectedVeiNetReports.map((report) => (
          <div key={report.reportId}>
            <div>{`Report: ${report.visualizationName}`}</div>
            <VeinNetReportViewer
              company={company}
              projectName={projectName}
              selectedDrillhole={selectedDrillhole}
              meterPixelRatio={meterPixelRatio}
              projects_s3_bucket={projects_s3_bucket}
            />
          </div>
        ))}
      </div>
      {/* <PolygonEditor imageUrl="https://picsum.photos/id/237/200/300" /> */}
    </div>
  );
};

export default Visualizer;
