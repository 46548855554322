import config from "../../config";
import { useAppSelector } from "../../redux/hooks";

export interface JobItem {
  company: string;
  companyProject: string;
  project: string;
  type: string;
  companyProjectType: string;
  createdAt: string;
  createdBy: string;
  status: string;
  size: string;
  environment: string;
  requestMetadata: {};
  requestBody: string;
  id: string;
}

export function createJobItem(
  company: string,
  project: string,
  type: string,
  createdBy: string
): JobItem {
  const getFormattedDateTime = (): string => {
    const date = new Date();
    const formatNumber = (n: number, digits: number = 2) =>
      n.toString().padStart(digits, "0");

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    const millisecond = date.getMilliseconds();
    const microsecond = Math.floor(Math.random() * 1000);

    return `${year}.${formatNumber(month)}.${formatNumber(day)}.${formatNumber(
      hour
    )}.${formatNumber(minute)}.${formatNumber(second)}.${formatNumber(
      millisecond,
      3
    )}.${formatNumber(microsecond, 3)}`;
  };

  const job_id: string = "job." + getFormattedDateTime();

  const env = process.env.REACT_APP_ENV || "test";
  const projects_s3_bucket = config[env].projects_s3_bucket;

  const jobItem: JobItem = {
    company: company,
    companyProject: company + "|" + project,
    project: project,
    type: type,
    companyProjectType: company + "|" + project + "|" + type,
    createdAt: job_id,
    createdBy: createdBy,
    status: "todo",
    size: "",
    environment: env,
    requestMetadata: {},
    id: job_id,
    requestBody: `s3://${projects_s3_bucket}/${company}/${project}/!_JOBS_API/${type}/${job_id}/request.json`,
  };

  return jobItem;
}
