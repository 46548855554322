import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetJobsQuery,
  useLazyGetJobDetailsQuery,
} from "../../features/api/endpoints/projectsApi";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  CircularProgress,
  Box,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface JobsInfoProps {
  jobType: string;
  refresh?: any;
}

const JobsInfo: React.FC<JobsInfoProps> = ({ jobType, refresh }) => {
  const { projectName } = useParams<{ projectName: string }>();
  const projects = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();
  const [expandedJobId, setExpandedJobId] = useState<string | null>(null);

  const {
    data: jobsData,
    error: jobsError,
    isLoading,
    isFetching,
    refetch,
  } = useGetJobsQuery({
    companyName: projects.selectedProject?.company || "",
    projectName: projects.selectedProject?.id || "",
    jobType,
  });

  const [
    triggerGetJobDetails,
    { data: jobDetails, isLoading: jobDetailsLoading },
  ] = useLazyGetJobDetailsQuery();

  useEffect(() => {
    refetch();
  }, [refresh]);

  const handleExpandClick = (jobId: string) => {
    if (expandedJobId === jobId) {
      setExpandedJobId(null);
    } else {
      setExpandedJobId(jobId);
      // Uncomment the following lines if you want to fetch job details on expand
      // triggerGetJobDetails({
      //   companyName: projects.selectedProject?.company || "",
      //   projectName: projects.selectedProject?.id || "",
      //   jobId,
      // });
    }
  };

  if (isLoading || isFetching) {
    return <CircularProgress />;
  }

  if (jobsError) {
    return <div>Error loading jobs</div>;
  }

  const doneJobs = jobsData?.items.filter((job) => job.status === "done") || [];
  const otherJobs = jobsData?.items.filter((job) => job.status !== "done") || [];

  return (
    <div style={{ margin: "20px" }}>
      {doneJobs.length > 0 && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Done Jobs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {doneJobs.map((job) => (
              <Card key={job.id} sx={{ marginBottom: 2 }}>
                <CardHeader
                  title={`Job ID: ${job.id}`}
                  subheader={
                    <Box
                      sx={{
                        display: "inline-block",
                        padding: "2px 8px",
                        borderRadius: "4px",
                        backgroundColor:
                          job.responseCode === 200 ? "green" : "red",
                        color: "white",
                      }}
                    >
                      Status: {job.status}
                    </Box>
                  }
                  action={
                    <IconButton
                      onClick={() => handleExpandClick(job.id)}
                      aria-expanded={expandedJobId === job.id}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  }
                />
                <Collapse in={expandedJobId === job.id} timeout="auto" unmountOnExit>
                  <CardContent>
                    {jobDetailsLoading ? (
                      <CircularProgress />
                    ) : jobDetails && expandedJobId === job.id ? (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        <pre>{JSON.stringify(jobDetails, null, 2)}</pre>
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        No details available
                      </Typography>
                    )}
                  </CardContent>
                </Collapse>
              </Card>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
      {otherJobs.map((job) => (
        <Card key={job.id} sx={{ marginBottom: 2 }}>
          <CardHeader
            title={`Job ID: ${job.id}`}
            subheader={
              <Box
                sx={{
                  display: "inline-block",
                  padding: "2px 8px",
                  borderRadius: "4px",
                  backgroundColor: "grey",
                  color: "white",
                }}
              >
                Status: {job.status}
              </Box>
            }
            action={
              <IconButton
                onClick={() => handleExpandClick(job.id)}
                aria-expanded={expandedJobId === job.id}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            }
          />
          <Collapse in={expandedJobId === job.id} timeout="auto" unmountOnExit>
            <CardContent>
              <pre>{JSON.stringify(job, null, 2)}</pre>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </div>
  );
};

export default JobsInfo;