// S3FileViewer.tsx
import React, { useEffect, useRef, useState } from "react";
import {
  fetchFiles,
  deleteFile,
  getDownloadUrl,
  S3File,
} from "../../../../../components/S3/S3Utils";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Grid,
  Tabs,
  Tab,
  Button,
  TextField,
  CircularProgress,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  FormControl,
  Autocomplete,
  Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { readJsonFromS3 } from "../../../../../components/S3/S3Utils";
import Plot from "react-plotly.js";
import { Annotations, Datum } from "plotly.js";
import html2canvas from "html2canvas";

interface InputPreviewProps {
  inputs_dict: { [key: string]: any };
}

interface ApiResponse {
  merged_report?: string | null;
  out_location?: string | null;
  out_log_id?: string | null;
  out_generic_model_id?: number | null;
  warnings?: string[] | null;
  component_relevance_table?: { [key: string]: { [key: string]: number } };
  component_scatter?: { [key: string]: number[] };
  error?: string | null;
  status?: string | null;
  inputs_dict?: InputPreviewProps;
}

interface DRResponseViewerProps {
  refresh: any;
  bucketName: string;
  pathPrefix: string;
  serviceName: string;
}

const DRResponseViewer: React.FC<DRResponseViewerProps> = ({
  refresh,
  bucketName,
  pathPrefix,
  serviceName,
}) => {
  const [files, setFiles] = useState<S3File[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const [scale, setScale] = React.useState<number>(5);

  const handleSliderChange = (event: Event, value: number | number[]) => {
    if (typeof value === "number") {
      if (value > 5 && value < 100) {
        setScale(value);
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const loadFiles = async () => {
      try {
        const fetchedFiles = await fetchFiles(
          (bucketName = bucketName),
          (pathPrefix = pathPrefix + "/responses/" + serviceName),
          ["json"]
        );
        const filteredFetchedFiles = fetchedFiles
          ? fetchedFiles.filter(
              (file) => file.Key.lastIndexOf("/") < file.Key.lastIndexOf("job.")
            )
          : fetchedFiles;
        setFiles(filteredFetchedFiles);
      } catch (error) {
        console.error("Failed to fetch files:", error);
      }
    };
    loadFiles();
  }, [refresh, bucketName, pathPrefix]);

  const [xAxisName, setXAxisName] = useState<string | null>(null);

  const handleXAxisSelection = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    const newValue = value ? value : null;

    setXAxisName(newValue);
  };

  const [yAxisName, setYAxisName] = useState<string | null>(null);

  const [xData, setXData] = useState<number[]>([]);
  const [yData, setYData] = useState<number[]>([]);

  const handleYAxisSelection = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    const newValue = value ? value : null;
    setYAxisName(newValue);
  };

  // const [fAxisName, setFAxisName] = useState<string | null>(null);
  const [fAxisNames, setFAxisNames] = useState<string[]>([] as string[]);

  const handleFAxisSelection = (
    event: React.ChangeEvent<{}>,
    value: string[]
    // value: string | null,
    // reason: AutocompleteChangeReason,
    // details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    // const newValue = value ? value : null;
    setFAxisNames(value);
  };

  useEffect(() => {
    const loadXAxis = async () => {
      const newXData = await readJsonFromS3(
        bucketName,
        files[selectedIndex].Key.replace(".json", "/" + xAxisName + ".json")
      );
      if (Array.isArray(newXData)) {
        setXData(newXData as number[]);
      }
    };
    const loadYAxis = async () => {
      const newYData = await readJsonFromS3(
        bucketName,
        files[selectedIndex].Key.replace(".json", "/" + yAxisName + ".json")
      );
      if (Array.isArray(newYData)) {
        setYData(newYData as number[]);
      }
    };
    if (files.length > 0 && xAxisName) {
      loadXAxis();
    }
    if (files.length > 0 && yAxisName) {
      loadYAxis();
    }
  }, [xAxisName, yAxisName]);

  const tableRef = useRef(null);

  // Component Scatter Plot

  interface ComponentScatterPlotProps {
    scatter_plots?: { [key: string]: number[] };
    component_relevance_table?: { [key: string]: { [key: string]: number } };
    x_axis_name: string | null;
    y_axis_name: string | null;
    // f_axis_name: string | null;
    f_axis_names: string[];
    scale: number;
  }
  const ComponentScatterPlot: React.FC<ComponentScatterPlotProps> = ({
    scatter_plots,
    component_relevance_table,
    x_axis_name,
    y_axis_name,
    // f_axis_name,
    f_axis_names,
    scale,
  }) => {
    if (!x_axis_name || !y_axis_name) {
      return <div>Scatter Plot Not Found!</div>;
    }
    if (
      !scatter_plots ||
      !scatter_plots[x_axis_name] ||
      !scatter_plots[y_axis_name]
    ) {
      return <div>Scatter Plot Not Found!</div>;
    }
    if (!component_relevance_table) {
      return <div>Sth Missin!</div>;
    }
    if (
      !component_relevance_table[x_axis_name] ||
      !component_relevance_table[y_axis_name]
    ) {
      return <div>Sth Missin2!</div>;
    }

    const ax = Object.values(component_relevance_table?.[x_axis_name])[0];
    const ay = Object.values(component_relevance_table?.[y_axis_name])[0];

    const norm = Math.max(ax, ay);

    // const annots = f_axis_name
    //   ? {
    //       // (scale * ax) / norm
    //       // x:
    //       //   (scale * component_relevance_table?.[x_axis_name]?.[f_axis_name]) /
    //       //   Math.max(
    //       //     component_relevance_table?.[x_axis_name]?.[f_axis_name],
    //       //     component_relevance_table?.[y_axis_name]?.[f_axis_name]
    //       //   ), // x-coordinate where the arrow points
    //       // y:
    //       //   (scale * component_relevance_table?.[y_axis_name]?.[f_axis_name]) /
    //       //   Math.max(
    //       //     component_relevance_table?.[x_axis_name]?.[f_axis_name],
    //       //     component_relevance_table?.[y_axis_name]?.[f_axis_name]
    //       //   ), // y-coordinate where the arrow points
    //       x:
    //         (scale * component_relevance_table?.[x_axis_name]?.[f_axis_name]) /
    //         (component_relevance_table?.[x_axis_name]?.[f_axis_name] +
    //           component_relevance_table?.[y_axis_name]?.[f_axis_name]),
    //       y:
    //         (scale * component_relevance_table?.[y_axis_name]?.[f_axis_name]) /
    //         (component_relevance_table?.[x_axis_name]?.[f_axis_name] +
    //           component_relevance_table?.[y_axis_name]?.[f_axis_name]),
    //       xref: "x",
    //       yref: "y",
    //       text: f_axis_name,
    //       showarrow: true,
    //       arrowhead: 2,
    //       arrowsize: 1,
    //       ax: 0, // x-coordinate of the arrow tail
    //       ay: 0, // y-coordinate of the arrow tail (negative value to point upwards)
    //       axref: "x",
    //       ayref: "x",
    //       arrowcolor: "black",
    //     }
    //   : {};

    const annots = f_axis_names.flatMap((f_axis_name) => {
      const xValue = component_relevance_table?.[x_axis_name]?.[f_axis_name];
      const yValue = component_relevance_table?.[y_axis_name]?.[f_axis_name];

      return [
        {
          x: (scale * xValue) / (xValue + yValue),
          y: (scale * yValue) / (xValue + yValue),
          xref: "x",
          yref: "y",
          // text: f_axis_name,
          showarrow: true,
          arrowhead: 2,
          arrowsize: 1,
          ax: 0,
          ay: 0,
          axref: "x",
          ayref: "y",
          arrowcolor: "black" as const,
          // font: {
          //   size: 12,
          //   color: "white" as const,
          // },
          bgcolor: "black" as const,
          // bgcolor: undefined,
        } as Partial<Annotations>,
        {
          x:
            (scale * xValue) / (xValue + yValue) +
            scale * (xValue > 0 ? 0.2 : -0.2),
          y: (scale * yValue) / (xValue + yValue),
          // y:
          //   (scale * yValue) / (xValue + yValue) +
          //   scale * (yValue > 0 ? 0.2 : -0.2),
          xref: "x",
          yref: "y",
          text: f_axis_name,
          showarrow: false,
          font: {
            size: 10,
            color: "white",
          },
          bgcolor: "black",
        } as Partial<Annotations>,
      ];
    });

    return (
      <Plot
        data={[
          {
            x: xData,
            y: yData,
            type: "scatter",
            mode: "markers",
            marker: { color: "blue" },
          },
        ]}
        layout={{
          title: `Scatter Plot of ${x_axis_name} vs ${y_axis_name}`,
          // annotations: [
          //   {
          //     x: annots?.x, // x-coordinate where the arrow points
          //     y: annots?.y, // y-coordinate where the arrow points
          //     xref: "x",
          //     yref: "y",
          //     text: annots?.text,
          //     showarrow: true,
          //     arrowhead: 2,
          //     arrowsize: 1,
          //     ax: 0, // x-coordinate of the arrow tail
          //     ay: 0, // y-coordinate of the arrow tail (negative value to point upwards)
          //     axref: "x",
          //     ayref: "y",
          //     arrowcolor: "black",
          //     font: {
          //       size: 12,
          //       color: "white", // Text color to contrast with the black arrow
          //     },
          //     bgcolor: "black",
          //   },
          // ],
          annotations: annots,

          xaxis: {
            title: x_axis_name,
            // automargin: true,
            // scaleanchor: "y",
            // scaleratio: 1,
          },
          yaxis: {
            title: y_axis_name,
            // automargin: true,
          },
        }}
        style={{ width: "100%", height: "100%" }}
      />
    );
  };

  // Component Relevance Table

  interface ComponentRelevanceTableProbs {
    component_relevance_table?: { [key: string]: { [key: string]: number } };
  }

  const ComponentRelevanceTable: React.FC<ComponentRelevanceTableProbs> = ({
    component_relevance_table,
  }) => {
    if (!component_relevance_table) {
      return <div>Component Relevance Not Found!</div>;
    }

    const getColor = (value: number, max: number) => {
      const hue = Math.floor((value / max) * 60) + 60;
      return `hsl(${hue}, 100%, 50%)`;
    };

    // const maxValue = Math.max(...data.map(row => Math.max(...columns.map(col => row[col.accessor]))));

    const maxValue = (key: string) => {
      const values = Object.values(component_relevance_table?.[key]);
      return Math.max(...values);
    };

    // const handleDownload = () => {
    //   if (tableRef.current) {
    //     html2canvas(tableRef.current).then((canvas) => {
    //       const link = document.createElement("a");
    //       link.href = canvas.toDataURL("image/jpeg");
    //       link.download = "table.jpg";
    //       link.click();
    //     });
    //   }
    // };

    const convertToCSV = () => {
      const headers =
        "feature, " + Object.keys(component_relevance_table).join(",") + "\n";
      const rows = Object.keys(Object.values(component_relevance_table)[0])
        .map((rowKey) => {
          return [
            rowKey,
            ...Object.keys(component_relevance_table).map(
              (colKey) => component_relevance_table[colKey][rowKey]
            ),
          ].join(",");
        })
        .join("\n");
      return headers + rows;
    };

    const downloadCSV = () => {
      const csv = convertToCSV();
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      const midfix = files[selectedIndex].Key.replace(
        pathPrefix + "/responses/" + serviceName + "/",
        ""
      );
      link.href = URL.createObjectURL(blob);
      link.download = "component_relevance_table_" + midfix + ".csv";
      link.click();
    };

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={downloadCSV}
          style={{ marginBottom: "10px" }}
        >
          Download as CSV
        </Button>
        <TableContainer
          component={Paper}
          ref={tableRef}
          sx={{ maxWidth: 1000, margin: "auto", mt: 4 }}
        >
          <Table aria-label="relevance table">
            <TableHead>
              <TableRow>
                <TableCell>Relevance</TableCell>
                {Object.keys(component_relevance_table).map((header) => (
                  <TableCell
                    key={header}
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(Object.values(component_relevance_table)[0]).map(
                (key) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    {Object.keys(component_relevance_table).map((header) => (
                      <TableCell
                        key={header}
                        align="center"
                        style={{
                          backgroundColor: getColor(
                            component_relevance_table?.[header]?.[key],
                            maxValue(header)
                          ),
                        }}
                      >
                        {component_relevance_table?.[header]?.[key].toFixed(4)}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  // Input Preview

  const InputPreview: React.FC<InputPreviewProps> = ({ inputs_dict }) => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Key</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(inputs_dict).map(([key, value]) => {
              if (
                key === "dataset_info" &&
                typeof value === "object" &&
                value !== null &&
                "path" in value
              ) {
                return (
                  <React.Fragment key={key}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Input File Name
                      </TableCell>
                      <TableCell>
                        {value.path.substring(value.path.lastIndexOf("/") + 1)}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              } else if (
                (typeof value === "number" ||
                  typeof value === "string" ||
                  value === null) &&
                !(
                  key === "project_name" ||
                  key === "environment" ||
                  key === "structures_to_process" ||
                  key === "drillholes_to_process" ||
                  key === "litholens_save_path_type" ||
                  key === "feature_list"
                )
              ) {
                return (
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {key !== "s3_data_save_path"
                        ? key
                        : key.replace("path", "name")}
                    </TableCell>
                    <TableCell>
                      {key !== "s3_data_save_path"
                        ? value === null
                          ? "null"
                          : value.toString()
                        : value === null
                        ? "null"
                        : value
                            .toString()
                            .substring(value.lastIndexOf("/") + 1)}
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const [apiResponse, setApiResponse] = useState<ApiResponse>(
    files.length > 0
      ? (readJsonFromS3(bucketName, files[selectedIndex].Key) as ApiResponse)
      : ({} as ApiResponse)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedIndex >= 0 && selectedIndex < files.length) {
          setIsLoading(true);
          const response = await readJsonFromS3(
            bucketName,
            files[selectedIndex].Key
          );
          setApiResponse(response as ApiResponse);
          if (response?.component_scatter) {
            setXAxisName(Object.keys(response?.component_scatter)[0]);
            setYAxisName(Object.keys(response?.component_scatter)[1]);
          }
        }
      } catch (error) {
        console.error("Got an error: ", error);
      }
      console.log(apiResponse);
      setIsLoading(false);
    };

    fetchData();
  }, [selectedIndex, files]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ maxHeight: 400, overflow: "auto" }}>
            <List
              component="nav"
              aria-label="main mailbox folders"
              title="List of DR Jobs"
            >
              {files.map((file, index) => (
                <ListItemButton
                  key={index} // Ensure unique key for each item
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemText
                    primary={file.Key.replace(
                      `${pathPrefix}/responses/${serviceName}/`,
                      ""
                    )}
                  />
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div
            id="input-preview"
            style={{ flex: "auto", textAlign: "center", margin: "20px" }}
          >
            <InputPreview
              inputs_dict={apiResponse?.inputs_dict || {}}
            ></InputPreview>
          </div>
        </Grid>
      </Grid>

      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={activeTab}
        onChange={handleChange}
        aria-label="file tabs"
        sx={{
          backgroundColor: "white", // Light teal background for the whole tabs bar
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Adding a subtle shadow under the tabs bar
          "& .MuiTabs-flexContainer": {
            gap: "10px", // Adds space between each tab/button
          },
        }}
      >
        <Tab
          key="relevance"
          label="Component Relevance Table"
          id="relevance"
          aria-controls={`tabpanel-relevance`}
        />
        <Tab
          key="scatter-plots"
          label="Component Scatter Plot"
          id="scatter-plots"
          aria-controls={`tabpanel-scatter-plots`}
        />
      </Tabs>

      {isLoading ? (
        <div style={{ textAlign: "center", margin: "15px" }}>
          <CircularProgress />
        </div>
      ) : apiResponse?.status === "requested" ? (
        <div>
          <Alert severity="warning">Job in progress</Alert>
        </div>
      ) : apiResponse?.status === "failed" ? (
        <div>
          <Alert severity="error">Job failed!</Alert>
        </div>
      ) : (
        <div>
          <div
            hidden={activeTab !== 0}
            id="histogram"
            style={{ flex: "auto", textAlign: "center", margin: "20px" }}
          >
            <ComponentRelevanceTable
              component_relevance_table={apiResponse?.component_relevance_table}
            />
          </div>

          <div
            hidden={activeTab !== 1}
            id="scatter-plots"
            style={{ flex: "auto", textAlign: "center", margin: "20px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <Autocomplete
                    options={
                      apiResponse?.component_scatter
                        ? Object.keys(apiResponse?.component_scatter)
                        : []
                    }
                    getOptionLabel={(option) => option}
                    value={xAxisName}
                    onChange={handleXAxisSelection}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Scatter Plot: X Axis"
                        variant="outlined"
                      />
                    )}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <Autocomplete
                    options={
                      apiResponse?.component_scatter
                        ? Object.keys(apiResponse?.component_scatter)
                        : []
                    }
                    getOptionLabel={(option) => option}
                    value={yAxisName}
                    onChange={handleYAxisSelection}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Scatter Plot: Y Axis"
                        variant="outlined"
                      />
                    )}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <Autocomplete
                    multiple
                    options={
                      apiResponse?.component_relevance_table
                        ? Object.keys(
                            Object.values(
                              apiResponse?.component_relevance_table
                            )[0]
                          )
                        : []
                    }
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    value={fAxisNames}
                    onChange={handleFAxisSelection}
                    // renderOption={(props, option, { selected }) => (
                    //   <li {...props}>
                    //     <Checkbox checked={selected} />
                    //     {apiResponse?.component_relevance_table
                    //       ? Object.keys(
                    //           Object.values(
                    //             apiResponse?.component_relevance_table
                    //           )[0]
                    //         )
                    //       : []}
                    //   </li>
                    // )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Arrow Features"
                        variant="outlined"
                      />
                    )}
                    fullWidth
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Slider
              value={scale}
              min={1}
              max={100}
              step={1}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              valueLabelDisplay="auto"
            />
            <ComponentScatterPlot
              scatter_plots={apiResponse.component_scatter}
              component_relevance_table={apiResponse?.component_relevance_table}
              x_axis_name={xAxisName}
              y_axis_name={yAxisName}
              f_axis_names={fAxisNames}
              scale={scale}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DRResponseViewer;
