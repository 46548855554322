import React, { useState, useRef, useEffect, MouseEvent, RefObject } from 'react';
import { ColorResult, CirclePicker } from 'react-color';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import BrushIcon from '@mui/icons-material/Brush';
import PolylineIcon from '@mui/icons-material/Polyline';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import PolylineOutlined from '@mui/icons-material/PolylineOutlined';
import Delete from '@mui/icons-material/Delete';
import PaletteIcon from '@mui/icons-material/Palette';
import SettingsIcon from '@mui/icons-material/Settings';
import { Alert, Box, CircularProgress, IconButton, Modal, Typography } from '@mui/material';

import DrillholeSelector from '../gallery/DrillholeSelector';
import config, { DRILLHOLES_FOLDER_PATH, ENVIRONMENT } from "../../config";
import { listFolders, listFolder, getDownloadUrl, writeImageToS3 } from '../../components/S3/S3Utils';
import { superimposeDrawing, getBase64String, getCanvasCoordinates, processImage, parseRGBA } from './image_processing_utils/rectify_utils';
import EraserIcon from './image_processing_utils/EraserIcon';
import ImageSelector from '../gallery/ImageSelector';

const buttonsContainer: React.CSSProperties = { marginTop: 'auto' };

interface RectifyProps {
    company: string;
    projectName: string;
}

const colorPalette = [
    'rgba(0, 0, 0, 1)', 'rgba(0,0,255,1)',
    'rgba(0,128,0,1)', 'rgba(0,128,255,1)',
    'rgba(0,255,0,1)', 'rgba(0,255,255,1)',
    'rgba(128,0,0,1)', 'rgba(128,0,255,1)',
    'rgba(128,128,0,1)', 'rgba(128,128,255,1)',
    'rgba(128,255,128,1)',
    'rgba(255,0,0,1)',
    'rgba(255,128,0,1)', 'rgba(255,128,255,1)',
    'rgba(255,255,128,1)', 'rgba(255,255,255,1)'
];

const Rectify: React.FC<RectifyProps> = ({ company, projectName }) => {
    const projects_s3_bucket = config[ENVIRONMENT].projects_s3_bucket;
    const [drillholes, setDrillholes] = useState<string[]>([]);
    const [selectedDrillhole, setSelectedDrillhole] = useState<string>("");
    const [images, setImages] = useState<{ name: string; url: string }[]>([]);
    const [selectedImage, setSelectedImage] = useState<string>("");
    const [originalImage, setOriginalImage] = useState<string | null>(null);
    const [imageDim, setImageDim] = useState<{ imageWidth: number; imageHeight: number }>({ imageWidth: 224, imageHeight: 224 });
    const [maskImage, setMaskImage] = useState<string | null>(null);
    const [maskImageData, setMaskImageData] = useState<Uint8ClampedArray | null>(null);
    const [polygonPoints, setPolygonPoints] = useState<{ x: number; y: number; selectedToolType: string | null; color: string; size: number }[]>([]);
    const [selectedTool, setSelectedTool] = useState<'brush' | 'eraser' | 'polygon_unmask' | 'polygon_mask' | 'clear' | null>(null);
    const [selectedToolType, setSelectedToolType] = useState<'polygon' | 'brush' | null>(null);
    const [isBrushTool, setIsBrushTool] = useState(false);
    const [fillColor, setFillColor] = useState<string>('rgba(0, 0, 0, 1)');

    const [alertMessage, setAlertMessage] = useState<{ type: 'error' | 'info' | 'success' | 'warning'; message: string } | null>(null);
    const [maskOpacity, setMaskOpacity] = useState(() => {
        return parseFloat(localStorage.getItem('maskOpacity') ?? '0.5');
    });
    const [brushSize, setBrushSize] = useState(() => {
        return parseInt(localStorage.getItem('brushSize') ?? '50');
    });
    const [maskColor, setMaskColor] = useState(() => {
        return localStorage.getItem('maskColor') ?? 'rgba(0, 255, 0, 1)';
    })
    const [unmaskColor, setUnmaskColor] = useState(() => {
        return localStorage.getItem('unmaskColor') ?? 'rgba(255, 255, 255, 1)';
    });
    const [showBrushSizeSlider, setShowBrushSizeSlider] = useState(false);
    const drawCanvasRef = useRef<HTMLCanvasElement>(null);
    const tempCanvasRef = useRef<HTMLCanvasElement>(null);
    const imageRef = useRef<HTMLCanvasElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [history, setHistory] = useState<ImageData[]>([]);
    const [historyIndex, setHistoryIndex] = useState<number>(-1);
    const [containerDim, setContainerDim] = useState<{ containerWidth: number; containerHeight: number }>({ containerWidth: 900, containerHeight: 500 });
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [showMaskColorPicker, setShowMaskColorPicker] = useState(false);
    const [showUnmaskColorPicker, setShowUnmaskColorPicker] = useState(false);

    const handleMaskColorChange = (color: ColorResult) => {
        const rgba = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;

        if (rgba === unmaskColor) {
            setAlertMessage({ type: 'error', message: 'Mask color cannot be same as unmask color' });
            return;
        }

        setMaskColor(rgba);
    };

    const handleUnmaskColorChange = (color: ColorResult) => {
        const rgba = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;

        if (rgba === maskColor) {
            setAlertMessage({ type: 'error', message: 'Unmask color cannot be same as mask color' });
            return;
        }
        setUnmaskColor(rgba);
    };

    const bucketName = projects_s3_bucket;
    const pathPrefix = `${company}/${projectName}/${DRILLHOLES_FOLDER_PATH}`;

    useEffect(() => {
        localStorage.setItem('maskOpacity', maskOpacity.toString());
    }, [maskOpacity]);

    useEffect(() => {
        localStorage.setItem('brushSize', brushSize.toString());
    }, [brushSize]);

    useEffect(() => {
        localStorage.setItem('maskColor', maskColor);
    }, [maskColor]);

    useEffect(() => {
        localStorage.setItem('unmaskColor', unmaskColor);
    }, [unmaskColor]);

    const handleSettingsOpen = () => setSettingsOpen(true);
    const handleSettingsClose = () => {
        setSettingsOpen(false);
        setShowMaskColorPicker(false);
        setShowUnmaskColorPicker(false);

        if (selectedTool === 'brush') setFillColor(maskColor);
        if (selectedTool === 'eraser') setFillColor(unmaskColor);
        if (selectedTool === 'polygon_mask') setFillColor(maskColor);
        if (selectedTool === 'polygon_unmask') setFillColor(unmaskColor);
    };

    useEffect(() => {
        const fetchDrillholes = async () => {
            setLoading(true);
            setDrillholes([]);
            setImages([]);
            setSelectedDrillhole("");
            setAlertMessage({ type: 'info', message: 'Fetching Drillholes List....' });
            try {
                const folders = await listFolders(bucketName, pathPrefix);
                const drillholeNames = folders.map((folder) => {
                    const parts = folder.split("/");
                    return parts[parts.length - 2];
                });
                setDrillholes(drillholeNames);
                setAlertMessage({ type: 'info', message: 'Select a drillhole' })
            } catch (error) {
                setAlertMessage({ type: 'error', message: 'Error fetching drillholes' });
                console.error("Error fetching drillholes: ", error);
            } finally {
                setLoading(false);
            }
        };
        fetchDrillholes();
    }, [bucketName, pathPrefix]);

    useEffect(() => {
        const fetchImages = async () => {
            if (selectedDrillhole === "") return;
            setLoading(true);
            setSelectedImage("");
            setAlertMessage({ type: 'info', message: 'Fetching Image List....' });
            const drillholePrefix = `${pathPrefix}/${selectedDrillhole}/Original`;
            try {
                const folders = await listFolder(bucketName, drillholePrefix);
                const imageNames = folders.map((folder) => {
                    const parts = folder.split("/");
                    return {
                        name: parts[parts.length - 1],
                        url: `${pathPrefix}/${selectedDrillhole}/thumbnails/Original/${parts[parts.length - 1].replace('jpg', 'png')}`
                    };
                });
                setImages(imageNames);
                setAlertMessage({ type: 'info', message: 'Select an image' })
            } catch (error) {
                setAlertMessage({ type: 'error', message: 'Error fetching images' });
                console.error("Error fetching images: ", error);
            } finally {
                setLoading(false);
            }
        };
        fetchImages();
    }, [pathPrefix, selectedDrillhole]);

    useEffect(() => {
        const fetchImageUrls = async () => {
            if (selectedDrillhole === "" || selectedImage === "") return;
            setLoading(true);
            setOriginalImage(null);
            setMaskImage(null);
            setImageDim({ imageWidth: 224, imageHeight: 224 });
            setMaskImageData(null);
            setPolygonPoints([]);
            setSelectedTool(null);
            setSelectedToolType(null);
            setHistory([]);
            setHistoryIndex(-1);
            setSettingsOpen(false);
            setShowMaskColorPicker(false);
            setShowUnmaskColorPicker(false);
            setAlertMessage({ type: 'info', message: 'Loading Image....' });

            const drawCanvas = drawCanvasRef.current;
            const tempCanvas = tempCanvasRef.current;
            if (!drawCanvas || !tempCanvas) return;
            const drawCtx = drawCanvas.getContext('2d');
            const tempCtx = tempCanvas.getContext('2d');
            if (!drawCtx || !tempCtx) return;
            drawCtx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
            tempCtx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);

            const originalImagePrefix = `${pathPrefix}/${selectedDrillhole}/Original/${selectedImage}`;
            const maskImagePrefix = `${pathPrefix}/${selectedDrillhole}/masks/${selectedImage.replace('jpg', 'png')}`;

            try {
                const originalUrl = await getDownloadUrl(bucketName, originalImagePrefix);
                setOriginalImage(originalUrl);

                const maskUrl = await getDownloadUrl(bucketName, maskImagePrefix);
                setMaskImage(maskUrl);
            } catch (error) {
                setAlertMessage({ type: 'error', message: 'Error fetching image URLs' });
                console.error("Error fetching image URLs: ", error);
            } finally {
                setLoading(false);
            }
        };
        fetchImageUrls();
    }, [pathPrefix, selectedDrillhole, selectedImage]);

    useEffect(() => {
        setPolygonPoints([]);
        setLoading(true);
        if (!originalImage) return;

        const imageCanvas = imageRef.current;
        if (!imageCanvas) return;

        const imageCtx = imageCanvas.getContext('2d');
        if (!imageCtx) return;

        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.src = originalImage;

        image.onload = () => {
            const { width, height } = image;
            imageCanvas.width = width;
            imageCanvas.height = height;
            setImageDim({ imageWidth: width, imageHeight: height });

            imageCtx.drawImage(image, 0, 0, width, height);
            setAlertMessage(null);
        };
    }, [originalImage]);

    const colorizeImage = (data: Uint8ClampedArray) => {
        const maskRGBA = parseRGBA(maskColor);
        const unmaskRGBA = parseRGBA(unmaskColor);

        for (let i = 0; i < data.length; i += 4) {
            if (data[i] <= 127) {
                data[i] = maskRGBA.r
                data[i + 1] = maskRGBA.g;
                data[i + 2] = maskRGBA.b;
            } else if (data[i] > 127) {
                data[i] = unmaskRGBA.r;
                data[i + 1] = unmaskRGBA.g;
                data[i + 2] = unmaskRGBA.b;
            }
        }
        return data;
    }

    useEffect(() => {
        setPolygonPoints([]);
        setLoading(true);
        if (!maskImage || !imageDim) return;

        const drawCanvas = drawCanvasRef.current;
        const imageCanvas = imageRef.current;
        const tempCanvas = tempCanvasRef.current;
        if (!drawCanvas || !imageCanvas || !tempCanvas) return;

        const drawCtx = drawCanvas.getContext('2d');
        const imageCtx = imageCanvas.getContext('2d');
        const tempCtx = tempCanvas.getContext('2d');
        if (!drawCtx || !imageCtx || !tempCtx) return;

        const mask = new Image();
        mask.crossOrigin = "Anonymous";
        mask.src = maskImage;
        setAlertMessage({ type: 'info', message: 'Loading Mask.... (If taking too long, mask may not be available)' })

        mask.onload = () => {
            const { imageWidth, imageHeight } = imageDim;
            if (imageWidth !== mask.width || imageHeight !== mask.height) {
                setAlertMessage({ type: 'error', message: 'Image and mask dimensions do not match' });
                return;
            }

            drawCanvas.width = imageWidth;
            drawCanvas.height = imageHeight;
            tempCanvas.width = imageWidth;
            tempCanvas.height = imageHeight;
            drawCtx.clearRect(0, 0, imageWidth, imageHeight);
            tempCtx.clearRect(0, 0, imageWidth, imageHeight);
            drawCtx.drawImage(mask, 0, 0, imageWidth, imageHeight);

            if (brushSize === 0) setBrushSize(Math.ceil(imageHeight * imageWidth / 250000));

            const maskData = drawCtx.getImageData(0, 0, imageWidth, imageHeight);
            setMaskImageData(maskData.data);
            const colorizedData = colorizeImage(maskData.data);
            maskData.data.set(colorizedData);

            drawCtx.putImageData(maskData, 0, 0);

            saveHistory(drawCanvas, -1);
            setAlertMessage(null);
        };
    }, [maskImage, imageDim, maskColor, unmaskColor]);

    useEffect(() => {
        const drawCanvas = drawCanvasRef.current;
        if (drawCanvas && historyIndex >= 0) {
            const ctx = drawCanvas.getContext('2d');
            if (ctx) {
                ctx.putImageData(history[historyIndex], 0, 0);
            }
        }
    }, [historyIndex, history]);

    useEffect(() => {
        const containerWidth = window.innerWidth * 0.6;
        const containerHeight = containerWidth * (imageDim.imageHeight) / (imageDim.imageWidth);
        setContainerDim({ containerWidth, containerHeight });
    }, [imageDim]);

    const handleMouseDown = (event: MouseEvent<HTMLCanvasElement>) => {
        const drawCanvas = drawCanvasRef.current;
        const tempCanvas = tempCanvasRef.current;
        if (!drawCanvas || !tempCanvas) return;

        const ctx = tempCanvas.getContext('2d');
        if (!ctx) return;

        setShowBrushSizeSlider(false);

        const { x, y } = getCanvasCoordinates(event, tempCanvasRef);
        if (selectedToolType === 'brush') {
            setIsBrushTool(true);
        } else if (selectedToolType === 'polygon') {
            setPolygonPoints((prevPoints) => [...prevPoints, { x, y, selectedToolType, color: fillColor, size: brushSize }]);
            ctx.beginPath();
            ctx.strokeStyle = 'red';
            ctx.fillStyle = 'red';
            ctx.lineWidth = 20;
            ctx.arc(x, y, 20, 0, 2 * Math.PI);
            ctx.stroke();

            const lastPoint = polygonPoints.length > 0 ? polygonPoints[polygonPoints.length - 1] : { x, y };
            ctx.beginPath();
            ctx.strokeStyle = 'blue';
            ctx.fillStyle = 'blue';
            ctx.lineWidth = 10;
            ctx.moveTo(lastPoint.x, lastPoint.y);
            ctx.lineTo(x, y);
            ctx.stroke();
            ctx.closePath();

            if (polygonPoints.length > 1) {
                const { x: startX, y: startY } = polygonPoints[0];
                const dx = x - startX;
                const dy = y - startY;
                const distance = Math.sqrt(dx * dx + dy * dy);
                if (distance < 40) {
                    fillPolygon();
                }
            }
        }
    };

    const handleMouseMove = (event: MouseEvent<HTMLCanvasElement>) => {
        if (!isBrushTool) return;

        const tempCanvas = tempCanvasRef.current;
        if (!tempCanvas) return;

        const ctx = tempCanvas.getContext('2d');
        if (!ctx) return;

        const { x, y } = getCanvasCoordinates(event, tempCanvasRef);

        const lastPoint = polygonPoints.length > 0 ? polygonPoints[polygonPoints.length - 1] : { x: 0, y: 0 };
        const dx = x - lastPoint.x;
        const dy = y - lastPoint.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < brushSize * 0.35) return;

        ctx.fillStyle = fillColor;
        ctx.fillRect(x, y, brushSize, brushSize);
        setPolygonPoints((prevPoints) => [...prevPoints, { x, y, selectedToolType, color: fillColor, size: brushSize }]);
    };

    const handleMouseUp = () => {
        if (isBrushTool) fillPolygon();
        setIsBrushTool(false);
    };

    const buttonClick = (toolType: 'polygon' | 'brush', color: string, selectedTool: 'clear' | 'brush' | 'eraser' | 'polygon_unmask' | 'polygon_mask') => {
        setSelectedToolType(toolType);
        setFillColor(color);
        setSelectedTool(selectedTool);
        setPolygonPoints([]);

        const tempCanvas = tempCanvasRef.current;
        if (!tempCanvas) return;
        const tempCtx = tempCanvas.getContext('2d');
        if (!tempCtx) return;
        tempCtx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);

        if (toolType === 'brush') {
            setShowBrushSizeSlider(true);
        }
    };

    const fillPolygon = () => {
        const drawCanvas = drawCanvasRef.current;
        const tempCanvas = tempCanvasRef.current;
        if (!drawCanvas || !tempCanvas) return;

        const ctx = drawCanvas.getContext('2d');
        const tempCtx = tempCanvas.getContext('2d');
        if (!ctx || !tempCtx) return;

        tempCtx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);

        ctx.fillStyle = fillColor;

        if (isBrushTool) {
            polygonPoints.forEach(point => {
                ctx.fillStyle = point.color;
                ctx.fillRect(point.x, point.y, point.size, point.size);
            });
        } else {
            ctx.beginPath();
            ctx.moveTo(polygonPoints[0].x, polygonPoints[0].y);
            polygonPoints.forEach(point => ctx.lineTo(point.x, point.y));
            ctx.closePath();
            ctx.fill();
        }
        saveHistory(drawCanvas, historyIndex);
        setPolygonPoints([]);
    };

    const clearCanvas = () => {
        const drawCanvas = drawCanvasRef.current;
        const tempCanvas = tempCanvasRef.current;
        if (!drawCanvas || !tempCanvas) return;

        const ctx = drawCanvas.getContext('2d');
        const tempCtx = tempCanvas.getContext('2d');
        if (!ctx || !tempCtx) return;

        ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
        tempCtx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
        setPolygonPoints([]);
        setSelectedTool('clear');
    };

    const saveMaskToS3 = async () => {
        setAlertMessage({ type: 'warning', message: 'Submitting mask....' });
        const drawCanvas = drawCanvasRef.current;
        if (!drawCanvas) return;

        setLoading(true);
        setTimeout(async () => {
            try {
                const imageData = processImage(drawCanvas, maskColor, unmaskColor);

                // verify if the image is not all dark. (atleast 95% black)
                const totalPixels = imageData.length / 4;
                let blackPixels = 0;
                for (let i = 0; i < imageData.length; i += 4) {
                    if (imageData[i] === 0 && imageData[i + 1] === 0 && imageData[i + 2] === 0) {
                        blackPixels++;
                    }
                }
                if (blackPixels / totalPixels > 0.95) {
                    setAlertMessage({ type: 'error', message: "Same color doesn't differentiate for the algorithm. Please choose a different Color. If you think this is a mistake, please contact ALS Support" });
                    return;
                }

                const base64String = getBase64String(imageData, imageDim);

                const maskKey = `${pathPrefix}/${selectedDrillhole}/masks/${selectedImage.replace('jpg', 'png')}`;
                await writeImageToS3(bucketName, maskKey, base64String, "image/png");
                setAlertMessage({ type: 'success', message: 'Mask submitted successfully' });
            } catch (error) {
                setAlertMessage({ type: 'error', message: 'Error submitting mask' });
                console.error("Error submitting mask: ", error);
            } finally {
                setLoading(false);
            }
        }, 10);
    };

    const handleUndo = () => {
        if (historyIndex > 0) {
            setHistoryIndex(historyIndex - 1);
        }
    };

    const handleRedo = () => {
        if (historyIndex < history.length - 1) {
            setHistoryIndex(historyIndex + 1);
        }
    };

    const saveHistory = (canvas: HTMLCanvasElement, indexHistory: number) => {
        const ctx = canvas.getContext('2d');
        if (ctx) {
            const newHistory = history.slice(0, indexHistory + 1);
            const { imageWidth, imageHeight } = imageDim;
            newHistory.push(ctx.getImageData(0, 0, imageWidth, imageHeight));
            setHistory(newHistory);
            setHistoryIndex(newHistory.length - 1);
        }
    };

    const previousImage = () => {
        const index = images.findIndex((image) => image.name === selectedImage);
        if (index > 0) {
            setSelectedImage(images[index - 1].name);
        }
    };

    const nextImage = () => {
        const index = images.findIndex((image) => image.name === selectedImage);
        if (index < images.length - 1) {
            setSelectedImage(images[index + 1].name);
        }
    };

    const previousDrillhole = () => {
        const index = drillholes.indexOf(selectedDrillhole);
        if (index > 0) {
            setSelectedDrillhole(drillholes[index - 1]);
        }
    };

    const nextDrillhole = () => {
        const index = drillholes.indexOf(selectedDrillhole);
        if (index < drillholes.length - 1) {
            setSelectedDrillhole(drillholes[index + 1]);
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {alertMessage && <Alert severity={alertMessage.type}>{alertMessage.message}</Alert>}
            <DrillholeSelector
                drillholes={drillholes}
                selectedDrillhole={selectedDrillhole}
                onDrillholeChange={setSelectedDrillhole}
            />
            {selectedDrillhole && (
                <div style={{ width: '100%' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={2}>
                        <Button variant="contained" disabled={drillholes.indexOf(selectedDrillhole) === 0} onClick={previousDrillhole}>Previous Drillhole</Button>
                        <Button variant="contained" disabled={drillholes.indexOf(selectedDrillhole) === drillholes.length - 1} onClick={nextDrillhole}>Next Drillhole</Button>
                    </Box>
                    <ImageSelector
                        images={images}
                        selectedImage={selectedImage}
                        onImageChange={setSelectedImage}
                        bucketName={bucketName}
                    />
                </div>
            )}
            {selectedDrillhole && selectedImage && (
                <div>
                    <Modal open={settingsOpen} onClose={handleSettingsClose}>
                        <Box
                            position="absolute"
                            top="50%"
                            left="50%"
                            bgcolor="background.paper"
                            boxShadow={24}
                            p={4}
                            width={400}
                            style={{ transform: 'translate(-50%, -50%)' }}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="h6" component="h2">
                                Settings
                            </Typography>
                            <Box mt={2} width="90%">
                                <Typography gutterBottom>Mask Opacity</Typography>
                                <Slider
                                    value={maskOpacity}
                                    min={0}
                                    max={1}
                                    step={0.05}
                                    onChange={(event, newValue) => setMaskOpacity(newValue as number)}
                                    aria-labelledby="opacity-slider"
                                />
                            </Box>
                            <Box mt={2} width="90%">
                                <Typography gutterBottom>Brush Size</Typography>
                                <Slider
                                    value={brushSize}
                                    min={10}
                                    max={200}
                                    step={5}
                                    onChange={(event, newValue) => setBrushSize(newValue as number)}
                                    aria-labelledby="brush-size-slider"
                                />
                            </Box>
                            <Box display="flex" justifyContent="space-around" width="100%" mb={2}>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <h3>Mask Color</h3>
                                    <IconButton onClick={() => {
                                        setShowMaskColorPicker(!showMaskColorPicker);
                                        setShowUnmaskColorPicker(false);
                                    }}>
                                        <PaletteIcon />
                                    </IconButton>
                                    {showMaskColorPicker && (
                                        <CirclePicker colors={colorPalette} color={maskColor} onChange={handleMaskColorChange}
                                            className='circle-picker' />
                                    )}
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <h3>Unmask Color</h3>
                                    <IconButton onClick={() => {
                                        setShowUnmaskColorPicker(!showUnmaskColorPicker);
                                        setShowMaskColorPicker(false);
                                    }}>
                                        <PaletteIcon />
                                    </IconButton>
                                    {showUnmaskColorPicker && (
                                        <CirclePicker colors={colorPalette} color={unmaskColor} onChange={handleUnmaskColorChange}
                                            className='circle-picker'
                                        />
                                    )}
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <Button variant="contained" onClick={handleSettingsClose}>Save</Button>
                            </Box>
                        </Box>
                    </Modal>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {alertMessage?.type === 'info' || alertMessage?.type === 'warning'
                            ? <CircularProgress /> : null}
                    </div>
                    <div style={
                        {
                            visibility: alertMessage?.type === 'info' ? 'hidden' : 'visible',
                            opacity: alertMessage?.type === 'warning' ? 0.3 : 1
                        }
                    }>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            mt={2}
                        >
                            <IconButton onClick={handleSettingsOpen}>
                                <SettingsIcon />
                            </IconButton>
                            <Box position="relative" width={containerDim.containerWidth} height={containerDim.containerHeight}>
                                <canvas
                                    ref={imageRef}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 1,
                                    }}
                                />
                                <canvas
                                    ref={drawCanvasRef}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 2,
                                        opacity: maskOpacity,
                                    }}
                                    onMouseDown={handleMouseDown}
                                    onMouseUp={handleMouseUp}
                                    onMouseMove={handleMouseMove}
                                    onContextMenu={(e) => e.preventDefault()}
                                />
                                <canvas
                                    ref={tempCanvasRef}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 3,
                                        pointerEvents: 'none',
                                    }}
                                // onMouseDown={handleMouseDown}
                                // onMouseUp={handleMouseUp}
                                // onMouseMove={handleMouseMove}
                                />
                            </Box>
                            <div style={buttonsContainer}>
                                {showBrushSizeSlider && (
                                    <Slider
                                        value={brushSize}
                                        min={10}
                                        max={200}
                                        step={5}
                                        onChange={(event, newValue) => setBrushSize(newValue as number)}
                                        aria-labelledby="brush-size-slider"
                                        style={{ marginRight: '16px', width: '150px' }}
                                    />
                                )}
                                <Tooltip title="Brush">
                                    <Button variant={selectedTool === 'brush' ? "contained" : "outlined"} onClick={() => buttonClick('brush', maskColor, 'brush')}><BrushIcon /></Button>
                                </Tooltip>
                                <Tooltip title="Eraser">
                                    <Button variant={selectedTool === 'eraser' ? "contained" : "outlined"} onClick={() => buttonClick('brush', unmaskColor, 'eraser')}><EraserIcon /></Button>
                                </Tooltip>
                                <Tooltip title="Polygon Mask">
                                    <Button variant={selectedTool === 'polygon_mask' ? "contained" : "outlined"} onClick={() => buttonClick('polygon', maskColor, 'polygon_mask')}><PolylineIcon /></Button>
                                </Tooltip>
                                <Tooltip title="Polygon Unmask">
                                    <Button variant={selectedTool === 'polygon_unmask' ? "contained" : "outlined"} onClick={() => buttonClick('polygon', unmaskColor, 'polygon_unmask')}><PolylineOutlined /></Button>
                                </Tooltip>
                                <Tooltip title="Undo">
                                    <Button variant="outlined" onClick={handleUndo} disabled={historyIndex <= 0}><UndoIcon /></Button>
                                </Tooltip>
                                <Tooltip title="Redo">
                                    <Button variant="outlined" onClick={handleRedo} disabled={historyIndex >= history.length - 1}><RedoIcon /></Button>
                                </Tooltip>
                                <Tooltip title="Clear Canvas">
                                    <Button variant={selectedTool === 'clear' ? "contained" : "outlined"} onClick={clearCanvas}><Delete /></Button>
                                </Tooltip>
                            </div>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={2}>
                            <Button variant="contained" disabled={images.findIndex((image) => image.name === selectedImage) === 0} onClick={previousImage}>Previous Image</Button>
                            <Button variant="contained" onClick={saveMaskToS3}>Submit</Button>
                            <Button variant="contained" disabled={images.findIndex((image) => image.name === selectedImage) === images.length - 1} onClick={nextImage}>Next Image</Button>
                        </Box>
                    </div>
                </div>
            )}
        </Box>
    );
};

export default Rectify;
