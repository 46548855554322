import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRefreshTokenMutation } from "../api/cognitoApiSlice";
import { RootState } from "../../redux/store";
import { logout, setAuthTokens } from "./authSlice";

const TokenRefresh: React.FC = () => {
  const dispatch = useDispatch();
  const [refreshTokenMutation] = useRefreshTokenMutation();
  const authState = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const refreshAuthToken = async () => {
      if (authState.cognitoTokens) {
        try {
          const result = await refreshTokenMutation({
            refreshToken: authState.cognitoTokens.RefreshToken,
          }).unwrap();
          dispatch(
            setAuthTokens({ cognitoTokens: result.AuthenticationResult })
          );
          console.log("refreshed Token");
        } catch (error) {
          console.error("Failed to refresh token:", error);
          dispatch(logout()); // Optionally log out the user if refresh fails
        }
      }
    };

    if (authState.cognitoTokens) {
      const expiresIn = authState.cognitoTokens.ExpiresIn;
      const intervalId = setInterval(refreshAuthToken, (expiresIn - 60) * 1000); // Refresh 60 seconds before expiry

      return () => clearInterval(intervalId); // Clear interval on component unmount
    }
  }, [authState.cognitoTokens, refreshTokenMutation, dispatch]);

  return null; // This component does not render anything visible
};

export default TokenRefresh;
