import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  uploadDatasetToS3,
  bytesToMB,
  S3File,
  fetchFolders,
  uploadFilesToS3,
} from "../../../../../components/S3/S3Utils";
import S3DatasetViewer from "./S3DatasetViewer";
import S3DatasetCreater from "./S3DatasetCreater";
import S3FileViewer from "../../../../../components/S3/S3FileViewer";

interface UploadProgress {
  [key: string]: number;
}

interface S3DatasetUploaderProps {
  bucketName: string;
  pathPrefix: string;
  onUploadSuccess?: () => void; // Add the optional callback prop
}

const S3DatasetUploader: React.FC<S3DatasetUploaderProps> = ({
  bucketName,
  pathPrefix,
  onUploadSuccess, // Destructure the optional callback prop
}) => {
  const [datasetFiles, setDatasetFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({});
  const [loading, setLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [folders, setFolders] = useState<string[]>([]);
  const [selectedDataset, setSelectedDataset] = useState<string>("");
  const [datasetSplit, setDatasetSplit] = useState<"train" | "validation">(
    "train"
  );
  const [dataType, setDataType] = useState<"images" | "labels">("images");

  const allowedExtensions =
    dataType === "images" ? ["png", "jpg"] : ["png", "jpg", "json"];

  useEffect(() => {
    const intervalId = setInterval(() => {
      loadFolders();
    }, 1000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const loadFolders = async () => {
    try {
      const fetchedFolders = await fetchFolders(bucketName, pathPrefix);

      // Extract folder names from the fetched folder keys
      const folderNames = fetchedFolders.map((folder) => {
        const folderKey = folder.Key.replace(pathPrefix, ""); // Remove the path prefix
        return folderKey.replace(/\/$/, ""); // Remove trailing slash from folder name
      });

      setFolders(folderNames); // Update state with folder names
    } catch (error) {
      console.error("Failed to fetch folders:", error);
    }
  };

  const handleFileLoad = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      setDatasetFiles(Array.from(fileList));
      setUploadProgress({});
    }
  };

  const handleUploadClick = async () => {
    if (datasetFiles.length === 0) {
      alert("No folders selected.");
      return;
    }
    setLoading(true);
    try {
      await uploadFilesToS3(
        datasetFiles,
        setUploadProgress,
        bucketName,
        (pathPrefix =
          pathPrefix + selectedDataset + "/" + datasetSplit + "/" + dataType)
      );
      setDatasetFiles([]); // Clear the list after successful upload
      // Invoke the optional callback after successful upload
      if (onUploadSuccess) {
        onUploadSuccess();
      }
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  return (
    <div>
      <S3DatasetCreater
        bucketName={bucketName}
        pathPrefix={pathPrefix}
        onCreateSuccess={() => {
          // Optional callback for when a folder is successfully created
          console.log("Folder created successfully!");
        }}
      />
      <Box
        sx={{
          maxWidth: 800,
          mx: "auto",
          p: 2,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Dataset Name</InputLabel>
              <Select
                value={selectedDataset}
                label="Model Architecture"
                onChange={(e) => setSelectedDataset(e.target.value)}
              >
                {folders.map((folderName) => (
                  <MenuItem key={folderName} value={folderName}>
                    {folderName.replace("/", "")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Dataset Split</InputLabel>
              <Select
                value={datasetSplit}
                label="Split"
                onChange={(e) =>
                  setDatasetSplit(e.target.value as "train" | "validation")
                }
              >
                <MenuItem key="train" value="train">
                  Train
                </MenuItem>
                <MenuItem key="validation" value="validation">
                  Validation
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Data Type</InputLabel>
              <Select
                value={dataType}
                label="Data Type"
                onChange={(e) =>
                  setDataType(e.target.value as "images" | "labels")
                }
              >
                <MenuItem key="images" value="images">
                  Data (Images)
                </MenuItem>
                <MenuItem key="labels" value="labels">
                  Annotations (Labels)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* <input
          type="file"
          multiple
          ref={fileInputRef}
          onChange={handleFileLoad}
          style={{ display: "none" }}
          {...({ webkitdirectory: "true" } as any)}
        /> */}{" "}
        <input
          type="file"
          multiple
          ref={fileInputRef}
          onChange={handleFileLoad}
          style={{ display: "none" }}
          accept={allowedExtensions.join(", ")}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => fileInputRef.current?.click()}
          fullWidth
        >
          Select Files
        </Button>
        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
          {datasetFiles.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: "2px 10px" }}>
                      File Name
                    </TableCell>
                    <TableCell sx={{ padding: "2px 10px" }} align="right">
                      Size (MB)
                    </TableCell>
                    <TableCell sx={{ padding: "2px 10px" }}>
                      Upload Progress
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datasetFiles.map((file, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: "2px 10px" }}>
                        {file.name}
                      </TableCell>
                      <TableCell sx={{ padding: "2px 10px" }} align="right">
                        {bytesToMB(file.size)}
                      </TableCell>
                      <TableCell sx={{ padding: "2px 10px" }}>
                        <LinearProgress
                          variant="determinate"
                          value={uploadProgress[file.name] || 0}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="secondary"
          onClick={handleUploadClick}
          fullWidth
          sx={{ mt: 2 }}
          disabled={datasetFiles.length === 0 || loading} // Disabled if no files are selected or loading
        >
          Upload to S3
        </LoadingButton>
        <S3FileViewer
          bucketName={bucketName}
          pathPrefix={
            pathPrefix + selectedDataset + "/" + datasetSplit + "/" + dataType
          }
          allowedExtensions={allowedExtensions}
          refresh={handleUploadClick}
        />
      </Box>
    </div>
  );
};

export default S3DatasetUploader;
