import React, { useEffect, useState } from "react";
import { Checkbox, TextField, Autocomplete, FormControl } from "@mui/material";
import {
  S3File,
  getFileInfo,
  getTableDataFromCsv,
  readJsonFromS3,
} from "../../../../../components/S3/S3Utils";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFilesInfo, setSelectedReferenceFile } from "./talimFengSlice";
import { setState } from "./talimFengSlice";

interface FEngFileSelectionProps {
  bucketName: string;
  pathPrefix: string;
}

const FEngFileSelection: React.FC<FEngFileSelectionProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const talim_feng = useAppSelector((state) => state.talim.talimFengSlice);
  const dispatch = useAppDispatch();
  const allFileKeys = [talim_feng.selectedReferenceFile];
  useEffect(() => {
    allFileKeys.forEach(async (fileKey) => {
      if (fileKey && !talim_feng.filesInfo[fileKey]) {
        // console.log(fileKey);
        const metaDataKey = fileKey
          .replace("data", "metaData")
          .replace(".csv", ".json");
        try {
          const metaData = await readJsonFromS3(bucketName, metaDataKey);
          const { columns, data } = await getTableDataFromCsv(
            bucketName,
            fileKey
          );
          dispatch(
            setFilesInfo({
              key: fileKey,
              info: {
                fileKey: fileKey,
                metaData: metaData,
                tableData: { columns: columns, data: data },
                standard_scaling: [],
                clr_transformation: [],
                binning: [],
                ignore: [],
              },
            })
          );
          dispatch(
            setState({
              key: "target_feature",
              value:
                columns[columns.length - 1],
            })
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    });
  }, [talim_feng.selectedReferenceFile, bucketName]);

  const handleReferenceFileChange = (
    event: React.ChangeEvent<{}>,
    value: S3File | null
  ) => {
    const newReferenceFileKey = value ? value.Key : "";

    dispatch(setSelectedReferenceFile({ key: newReferenceFileKey }));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <FormControl fullWidth margin="normal">
        <Autocomplete
          options={talim_feng.files}
          getOptionLabel={(option) => getFileInfo(option.Key).baseName}
          value={
            talim_feng.files.find(
              (file) => file.Key === talim_feng.selectedReferenceFile
            ) || null
          }
          onChange={handleReferenceFileChange}
          renderInput={(params) => (
            <TextField {...params} label="Dataset File" variant="outlined" />
          )}
          fullWidth
        />
      </FormControl>
    </div>
  );
};
export default FEngFileSelection;
