import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setState } from "./talimEdaSlice";

const EdaConfigParams: React.FC = () => {
  const talim_eda = useAppSelector((state) => state.talim.talimEdaSlice);
  const dispatch = useAppDispatch();

  const lockDistanceMetrics =
    talim_eda.feature_families_method === "ward" ||
    talim_eda.feature_families_method === "centroid" ||
    talim_eda.feature_families_method === "median";

  //   const [lockDistanceMetrics, setLockDistanceMetrics] = useState((talim_eda.feature_families_method === "ward" ||
  //   talim_eda.feature_families_method === "centroid";
  // talim_eda.feature_families_method === "median";));

  // --------------------------------------------------------------------------------------------------
  // Shared Parameters

  const handleFeatureFamiliesMethodChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "feature_families_method",
        value: event.target.value as string,
      })
    );
    console.log(event.target.value);
    if (
      event.target.value === "ward" ||
      event.target.value === "centroid" ||
      event.target.value === "median"
    ) {
      console.log("INNNN");
      dispatch(
        setState({
          key: "feature_families_distance_metric",
          value: "euclidean",
        })
      );
    }
  };

  const handleFeatureCorrelationMethodChange = (event: SelectChangeEvent) => {
    dispatch(
      setState({
        key: "feature_correlation_method",
        value: event.target.value as string,
      })
    );
  };

  const handleFeatureFamiliesDistanceMetricChange = (
    event: SelectChangeEvent
  ) => {
    dispatch(
      setState({
        key: "feature_families_distance_metric",
        value: event.target.value as string,
      })
    );
  };

  const handleFeatureFamiliesScalingChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "feature_families_scaling",
        value: checked,
      })
    );
  };

  const handleRegisterToLithoLensChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "register_to_litholens",
        value: checked,
      })
    );
  };

  const handleRegisterToLogsApiChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setState({
        key: "register_to_logsapi",
        value: checked,
      })
    );
  };

  const handleNotificationEmailChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "notification_email",
        value: event.target.value as string,
      })
    );
  };

  const handleS3SavePathChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "s3_data_save_path",
        value: event.target.value as string,
      })
    );
  };

  const handleLogDescriptiveNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setState({
        key: "descriptive_log_name",
        value: event.target.value as string,
      })
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Set EDA API Parameters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
          >
            <FormControl variant="standard">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Feature Families Method
              </InputLabel>
              <Select
                defaultValue={talim_eda.feature_families_method}
                onChange={handleFeatureFamiliesMethodChange}
              >
                <MenuItem value={"ward"}>Ward</MenuItem>
                <MenuItem value={"single"}>Single</MenuItem>
                <MenuItem value={"weighted"}>Weighted</MenuItem>
                <MenuItem value={"complete"}>Complete</MenuItem>
                <MenuItem value={"average"}>Average</MenuItem>
                <MenuItem value={"median"}>Median</MenuItem>
                <MenuItem value={"centroid"}>Centroid</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="standard">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Feature Families Distance Metric
              </InputLabel>
              <Select
                value={talim_eda.feature_families_distance_metric}
                onChange={handleFeatureFamiliesDistanceMetricChange}
              >
                <MenuItem value={"euclidean"}>euclidean</MenuItem>

                <MenuItem value={"braycurtis"} disabled={lockDistanceMetrics}>
                  braycurtis
                </MenuItem>
                <MenuItem value={"canberra"} disabled={lockDistanceMetrics}>
                  canberra
                </MenuItem>
                <MenuItem value={"chebyshev"} disabled={lockDistanceMetrics}>
                  chebyshev
                </MenuItem>
                <MenuItem value={"cityblock"} disabled={lockDistanceMetrics}>
                  cityblock
                </MenuItem>
                <MenuItem value={"correlation"} disabled={lockDistanceMetrics}>
                  correlation
                </MenuItem>
                <MenuItem value={"cosine"} disabled={lockDistanceMetrics}>
                  cosine
                </MenuItem>
                <MenuItem value={"dice"} disabled={lockDistanceMetrics}>
                  dice
                </MenuItem>
                <MenuItem value={"hamming"} disabled={lockDistanceMetrics}>
                  hamming
                </MenuItem>
                <MenuItem value={"jaccard"} disabled={lockDistanceMetrics}>
                  jaccard
                </MenuItem>
                <MenuItem
                  value={"jensenshannon"}
                  disabled={lockDistanceMetrics}
                >
                  jensenshannon
                </MenuItem>
                <MenuItem value={"kulczynski1"} disabled={lockDistanceMetrics}>
                  kulczynski1
                </MenuItem>
                <MenuItem value={"mahalanobis"} disabled={lockDistanceMetrics}>
                  mahalanobis
                </MenuItem>
                <MenuItem value={"matching"} disabled={lockDistanceMetrics}>
                  matching
                </MenuItem>
                <MenuItem value={"minkowski"} disabled={lockDistanceMetrics}>
                  minkowski
                </MenuItem>
                <MenuItem
                  value={"rogerstanimoto"}
                  disabled={lockDistanceMetrics}
                >
                  rogerstanimoto
                </MenuItem>
                <MenuItem value={"russellrao"} disabled={lockDistanceMetrics}>
                  russellrao
                </MenuItem>
                <MenuItem value={"seuclidean"} disabled={lockDistanceMetrics}>
                  seuclidean
                </MenuItem>
                <MenuItem
                  value={"sokalmichener"}
                  disabled={lockDistanceMetrics}
                >
                  sokalmichener
                </MenuItem>
                <MenuItem value={"sokalsneath"} disabled={lockDistanceMetrics}>
                  sokalsneath
                </MenuItem>
                <MenuItem value={"sqeuclidean"} disabled={lockDistanceMetrics}>
                  sqeuclidean
                </MenuItem>
                <MenuItem value={"yule"} disabled={lockDistanceMetrics}>
                  yule
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="standard">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Feature Correlation Method
              </InputLabel>
              <Select
                defaultValue={talim_eda.feature_correlation_method}
                onChange={handleFeatureCorrelationMethodChange}
              >
                <MenuItem value={"pearson"}>Pearson</MenuItem>
                <MenuItem value={"kendall"}>Kendall</MenuItem>
                <MenuItem value={"spearman"}>Spearman</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Switch
                  value="feature_families_scaling"
                  checked={talim_eda.feature_families_scaling}
                  onChange={handleFeatureFamiliesScalingChange}
                />
              }
              label="Scaling (for feature families)"
            />
          </Box>
          {/* <Typography>Output Registration Options</Typography>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControlLabel
              control={
                <Switch
                  value="register_to_litholens"
                  checked={talim_eda.register_to_litholens}
                  onChange={handleRegisterToLithoLensChange}
                />
              }
              label="LithoLens"
            />
            <FormControlLabel
              control={
                <Switch
                  value="register_to_logsapi"
                  checked={talim_eda.register_to_logsapi}
                  onChange={handleRegisterToLogsApiChange}
                />
              }
              label="LogsAPI"
            />
          </Box>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="notification-email"
              label="Notification E-Mail"
              defaultValue=""
              onChange={handleNotificationEmailChange}
            />
            <TextField
              id="s3-save-path"
              label="S3 Save Path"
              defaultValue=""
              onChange={handleS3SavePathChange}
            />
            <TextField
              id="logs-descriptive-name"
              label="LogsAPI descriptive name"
              defaultValue=""
              onChange={handleLogDescriptiveNameChange}
              disabled={!talim_eda.register_to_logsapi}
            />
          </Box> */}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default EdaConfigParams;
