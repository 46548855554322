import React, { useEffect, useState } from "react";
import {
  Checkbox,
  TextField,
  Autocomplete,
  FormControl,
  Grid,
} from "@mui/material";
import {
  S3File,
  getFileInfo,
  getTableDataFromCsv,
  readJsonFromS3,
} from "../../../../../components/S3/S3Utils";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFilesInfo, setSelectedReferenceFile } from "./talimTrainSlice";

interface TrainFileSelectionProps {
  bucketName: string;
  pathPrefix: string;
}

const TrainFileSelection: React.FC<TrainFileSelectionProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const talim_train = useAppSelector((state) => state.talim.talimTrainSlice);
  const dispatch = useAppDispatch();
  const allFileKeys = [talim_train.selectedReferenceFile];

  // const allModelKeys = [talim_train.selectedReferenceModel];

  useEffect(() => {
    console.log("TEST2");
    allFileKeys.forEach(async (fileKey) => {
      if (fileKey && !talim_train.filesInfo[fileKey]) {
        console.log(talim_train.filesInfo[fileKey]);
        const metaDataKey = fileKey
          .replace("data", "metaData")
          .replace(".csv", ".json");
        try {
          const metaData = await readJsonFromS3(bucketName, metaDataKey);
          const { columns, data } = await getTableDataFromCsv(
            bucketName,
            fileKey
          );
          dispatch(
            setFilesInfo({
              key: fileKey,
              info: {
                fileKey: fileKey,
                metaData: metaData,
                tableData: { columns: columns, data: data },
              },
            })
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    });
  }, [talim_train.selectedReferenceFile, bucketName]);

  const handleReferenceFileChange = (
    event: React.ChangeEvent<{}>,
    value: S3File | null
  ) => {
    const newReferenceFileKey = value ? value.Key : "";

    dispatch(setSelectedReferenceFile({ key: newReferenceFileKey }));
    console.log(talim_train.selectedReferenceFile);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <FormControl fullWidth margin="normal">
        <Autocomplete
          options={talim_train.files}
          getOptionLabel={(option) => getFileInfo(option.Key).baseName}
          value={
            talim_train.files.find(
              (file) => file.Key === talim_train.selectedReferenceFile
            ) || null
          }
          onChange={handleReferenceFileChange}
          renderInput={(params) => (
            <TextField {...params} label="Dataset File" variant="outlined" />
          )}
          fullWidth
        />
      </FormControl>
    </div>
  );
};
export default TrainFileSelection;
